import React from 'react';
import PropTypes from 'prop-types';

import { ButtonStyled } from './button.styles';

import { LoaderStyled } from 'components/common.styles';

const Button = ({
  type,
  category,
  size,
  disabled,
  className,
  handleClick,
  autoFocus,
  loading,
  label,
  children,
  ...props
}) => (
  <ButtonStyled
    disabled={disabled}
    type={type}
    category={category}
    size={size}
    onClick={handleClick}
    className={className}
    autoFocus={autoFocus}
    aria-label={label}
    data-testid={props['data-testid'] || 'button'}
    data-pendoid={props['data-pendoid']}
  >
    {children} {loading && <LoaderStyled data-testid="button-loader" />}
  </ButtonStyled>
);

Button.propTypes = {
  type: PropTypes.oneOf(['button', 'submit']).isRequired,
  category: PropTypes.oneOf(['primary', 'outline', 'danger']).isRequired,
  size: PropTypes.oneOf(['large', 'medium']).isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  handleClick: PropTypes.func,
  autoFocus: PropTypes.bool,
  loading: PropTypes.bool,
  label: PropTypes.string.isRequired
};

Button.defaultProps = {
  type: 'button',
  category: 'primary',
  size: 'large',
  disabled: false,
  className: '',
  autoFocus: false,
  loading: false
};

export default Button;
