import styled from 'styled-components';

export const ListStyled = styled.ul`
  li {
    list-style: disc;
  }
`;

export const ShowMoreLess = styled.li`
  list-style: none !important;
  cursor: pointer;
  color: ${({ theme }) => theme?.colors?.primary?.default};
`;
