import styled from 'styled-components/macro';

import { Link } from 'react-router-dom';

export const LinkStyled = styled(Link)`
  ${({ theme: { links = {}, transitions, typography } }) => `
    ${typography?.(16)}
    cursor: pointer;
    color: ${links.color};
    text-decoration: ${links.textDecoration};
    transition: all ${transitions?.fast} ease-in-out;
    &:hover, &:focus {
      color: ${links.hover?.color};
      text-decoration: ${links.hover?.textDecoration};
    }
  `};
`;
