/**
 * @description
 * Gets the host param given in the URL
 *
 * @return {String}
 */
const getHostParam = (): string => {
  const params: string = window.location.search.slice(1);

  const hostKeyPair: string = params
    .split('&')
    .find(key => key.match(/^host=/i));

  if (hostKeyPair) {
    return hostKeyPair.split('=')[1];
  }
  return '';
};

export default getHostParam;
