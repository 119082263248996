import styled from 'styled-components/macro';

import { Card } from 'components';
import { devices } from 'shared/css/devices';

export const CardStyled = styled(Card)`
  ${({ theme: { spacing, typography, colors } }) => `
    box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.08);
    width: 90%;
    display: flex;
    flex-direction: row;
    margin: -${spacing?.(4)} 0 0 0;
    h3 {
      ${typography?.(20, 'bold')};
      color: ${colors.primary?.darkest};
    }

    @media ${devices.tablet} {
      padding: ${spacing?.(2)};
      width: ${spacing?.(75)};
      height: ${spacing?.(10)};
    }
  `}
`;

export const IconWrapperStyled = styled.div`
  ${({ theme: { spacing } }) => `
    width: ${spacing?.(5)};
    align-self: center;
    display: flex;
    justify-content: center;

    @media ${devices.tablet} {
      margin: 0;
    }
  `};
`;

export const TitleStyled = styled.h2`
  ${({ theme: { colors, typography } }) => `
    ${typography(16, 600)};
    color: ${colors?.grey?.darkest};
    @media ${devices.tablet} {
      text-align: left;
    }
  `};
`;

export const WrapperStyled = styled.div`
  ${({ theme: { spacing } }) => `
      margin-left: ${spacing?.(2.2)};
  `}
`;

export const IconStyled = styled.img`
  width: 100%;
`;
