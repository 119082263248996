import styled from 'styled-components/macro';
import theme from 'shared/css/theme';
import ArrowDropDownIcon from 'assets/icons/arrow-dropdown';

export const SelectButtonStyled = styled.div`
  ${({ isOpen }) => `
    font-weight: 700;
    font-size: 11px;
    line-height: 130%;
    height: 100%;
    cursor: pointer;
    color: ${theme?.colors.grey.darker};
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 5px 10px;
    background: ${isOpen ? theme?.colors?.primary?.lightest : `#FFFFFF`};
    border-radius: 2px;
    &:hover, &:focus {
      background-color: ${theme?.colors?.primary?.lightest};
    }
  `};
`;

export const SelectTextStyled = styled.div`
  ${({ isOpen }) => `
    font-family: 'Lato';
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-transform: none;
    color: ${
      isOpen ? theme?.colors?.primary?.light : theme?.colors.grey.default
    };
    display: flex;
    align-items: center;
    justify-content:space-between;
    ${SelectButtonStyled}:hover &, ${SelectButtonStyled}:focus & {
      color: ${theme?.colors?.primary?.light};
    }
  `};
`;

export const ArrowDropDownStyled = styled(ArrowDropDownIcon)`
  ${({ isOpen }) => `
    width: 7px;
    height: 5px;
    stroke-width: 1;
    margin-left: 3px;
    margin-top: 1px;
    stroke: ${
      isOpen ? theme?.colors?.primary?.light : theme?.colors.grey.default
    };
    ${SelectButtonStyled}:hover &, ${SelectButtonStyled}:focus & {
      stroke: ${theme?.colors?.primary?.light};
    }
  `};
`;
