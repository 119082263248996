import styled from 'styled-components/macro';

import ArrowLeftIcon from 'assets/icons/arrow-left';
import ArrowRightIcon from 'assets/icons/arrow-right';

export const WrapperStyled = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  ${({
    theme: { paginations = {} },
    arrowLeftDisabled,
    arrowRightDisabled
  }) => `
    margin: ${paginations.wrapper?.margin};
    & svg {
      :first-child {
        cursor: ${arrowLeftDisabled ? 'default' : 'pointer'};
        margin-right: ${paginations.wrapper?.child?.marginRight};
      }
      :last-child {
        cursor: ${arrowRightDisabled ? 'default' : 'pointer'};
        margin-left: ${paginations.wrapper?.child?.marginLeft};
      }
    }
  `};
`;

export const ItemStyled = styled.div`
  ${({ theme: { paginations = {}, transitions }, active, edge }) => `
    background-color: ${
      paginations.item?.backgroundColor?.[
        active && !edge ? 'active' : 'default'
      ]
    };
    border: ${paginations.item?.border};
    border-radius: ${paginations.item?.borderRadius};
    color: ${
      paginations.item?.color?.[active ? (edge ? 'edge' : 'active') : 'default']
    };
    cursor: ${active && edge ? 'default' : 'pointer'};
    font-weight: ${active ? (edge ? 'normal' : '700') : '400'};
    text-align: ${paginations.item?.textAlign};
    transition: all ${transitions?.fast} ease-in-out;
    width: ${paginations.item?.width};
    &:hover {
      background-color: ${
        paginations.item?.hover?.backgroundColor?.[edge ? 'edge' : 'default']
      }
    }
    &:focus {
      border-color:${paginations.item?.focus?.borderColor};
    }
  `};
`;

export const ItemsGroupStyled = styled.div`
  align-items: center;
  display: flex;
`;

export const ArrowLeftStyled = styled(ArrowLeftIcon)`
  ${({ theme: { paginations = {}, transitions }, disabled }) => `
    border: 2px solid transparent;
    cursor: ${disabled ? 'default' : 'pointer'};
    height: 22px;
    padding: 3px;
    ${
      !disabled
        ? `
        &:hover {
          & path {
            transition: all ${transitions?.fast}
              ease-in-out;
            fill: ${paginations.arrows?.fill};
          }
        }
        &:focus {
          border-color:${paginations.item?.focus?.borderColor} !important;
        }
      `
        : ''
    }
    width: ${paginations.arrows?.width};
  `};
`;

export const ArrowRightStyled = styled(ArrowRightIcon)`
  ${({ theme: { paginations = {}, transitions }, disabled }) => `
    border: 2px solid transparent;
    cursor: ${disabled ? 'default' : 'pointer'};
    height: 22px;
    padding: 3px;
    ${
      !disabled
        ? `
        &:hover {
          & path {
            transition: all ${transitions?.fast}
              ease-in-out;
            fill: ${paginations.arrows?.fill};
          }
        }
        &:focus {
          border-color:${paginations.item?.focus?.borderColor} !important;
        }
      `
        : ''
    }
    width: ${paginations.arrows?.width};
  `};
`;
