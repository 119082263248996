import React from 'react';

export default ({ className, color = '#000000' }) => (
  <svg
    className={className}
    width="24"
    height="14"
    viewBox="0 0 24 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <title>Password lock</title>
    <rect
      x="14.25"
      y="5.5"
      width="9"
      height="7.5"
      rx="1.5"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.75 5.5V4C15.75 2.34315 17.0931 1 18.75 1V1C20.4069 1 21.75 2.34315 21.75 4V5.5"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.75 8.875C18.5429 8.875 18.375 9.04289 18.375 9.25C18.375 9.45711 18.5429 9.625 18.75 9.625C18.9571 9.625 19.125 9.45711 19.125 9.25C19.1253 9.15046 19.0858 9.05493 19.0155 8.98454C18.9451 8.91416 18.8495 8.87473 18.75 8.875V8.875"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.125 8.875C3.91789 8.875 3.75 9.04289 3.75 9.25C3.75 9.45711 3.91789 9.625 4.125 9.625C4.33211 9.625 4.5 9.45711 4.5 9.25C4.50027 9.15046 4.46084 9.05493 4.39046 8.98454C4.32008 8.91416 4.22454 8.87473 4.125 8.875V8.875"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.875 8.875C7.66789 8.875 7.5 9.04289 7.5 9.25C7.5 9.45711 7.66789 9.625 7.875 9.625C8.08211 9.625 8.25 9.45711 8.25 9.25C8.25027 9.15046 8.21084 9.05493 8.14046 8.98454C8.07008 8.91416 7.97454 8.87473 7.875 8.875V8.875"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.25 13H2.25C1.42157 13 0.75 12.3284 0.75 11.5V5.5C0.75 4.67157 1.42157 4 2.25 4H11.25"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
