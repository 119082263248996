import React from 'react';
import PropTypes from 'prop-types';
import { ToastBody } from 'components';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import {
  CopyButton,
  VanityInput,
  CopyContainerStyled,
  ExternalLinkIconStyled
} from './copy-url.styles';

const CopyURL = ({ url }) => {
  const { t } = useTranslation();
  const handleClick = () => {
    navigator.clipboard.writeText(url);
    toast.success(<ToastBody category="success" text={t?.('link_success')} />);
  };

  return (
    <CopyContainerStyled>
      <VanityInput
        type="text"
        name="urlInput"
        value={url}
        disabled={true}
      ></VanityInput>
      <CopyButton handleClick={handleClick} label={t?.('buttons_copy')}>
        <ExternalLinkIconStyled />
        {t?.('buttons_copy')}
      </CopyButton>
    </CopyContainerStyled>
  );
};

CopyURL.propTypes = {
  url: PropTypes.string.isRequired
};

export default CopyURL;
