export default {
  signin_2fa_email_title: "We've sent the verification code",
  signin_2fa_email_message:
    'Check your email if you received the verification code.',
  signin_title_credentials: 'Welcome to Acuity Insights',
  signin_button: 'Log in',
  signin_reset_password: 'I forgot my password',
  signin_modal_error_title: 'Complete Account Setup',
  signin_modal_error_message:
    'Hello! Your account has not been set up properly yet.',
  signin_modal_error_message2:
    'Click the button below to continue your set up process.',
  signin_modal_error_button: 'Complete Account Setup',
  signin_title_2fa: '2-Factor Authentication',
  signin_remember: 'Remember this device for 30 days',
  signin_sms_message:
    'In order to confirm it is you, we have just sent a text message to',
  signin_sms_call:
    'In order to confirm it is you, an automated phone call is on its way to',
  signin_phone_common: 'We have sent a verification code to',
  signin_email_message:
    "In order to confirm it is you, we've just sent an email to your registered address for verification.",
  signin_invalid_2fa_phone_title: 'SMS / Phone Delivery Error',
  signin_invalid_2fa_email_title: 'Email Delivery Error',
  signin_invalid_2fa_phone_message:
    'Select an alternative delivery option to login',
  signin_page_title: 'Sign In'
};
