import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  HttpLink
} from '@apollo/client';
import { datadogLogs } from '@datadog/browser-logs';

const middleware = new ApolloLink((operation, forward) => {
  // Sift through Apollo cache to find user data

  const headers = {
    'x-custom-referrer': window.location.href
  };

  operation.setContext({
    headers
  });

  try {
    const { cache } = operation.getContext();
    const apolloQueryResponseCache = cache?.data?.data?.ROOT_QUERY;
    const userId = apolloQueryResponseCache?.user?._id || '';
    // Log operation to datadog
    const operationName = operation?.operationName || 'CurrentUser';
    const { ...params } = operation?.variables || {};
    delete params.password;
    delete params.newPassword;
    delete params.currentPassword;

    datadogLogs.logger.info(operationName, {
      query: operation.query,
      params,
      userId
    });
  } catch (e) {}

  return forward(operation);
});

const httpLink = new HttpLink({
  uri: '/graphql'
});

const client = new ApolloClient({
  link: new ApolloLink.from([middleware, httpLink]),
  cache: new InMemoryCache({
    addTypename: false,
    resultCaching: false
  })
});

export default client;
