import React from 'react';

export default ({ className, datatestId = 'password-success' }) => (
  <svg
    className={className}
    width="15"
    height="14"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid={datatestId}
  >
    <title>Input successfully added</title>
    <path
      d="M6 13.223L8.45 16.7C8.63855 16.9817 8.95158 17.1548 9.29037 17.165C9.62916 17.1751 9.95197 17.0209 10.157 16.751L18 6.828"
      stroke="#52C41A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 23.249C18.2132 23.249 23.25 18.2122 23.25 11.999C23.25 5.7858 18.2132 0.749001 12 0.749001C5.7868 0.749001 0.75 5.7858 0.75 11.999C0.75 18.2122 5.7868 23.249 12 23.249Z"
      stroke="#52C41A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
