import { USER_ROLES } from 'shared/enums';

export default (
  necessaryPermissions = [],
  roles = [],
  permissionsArray = []
) => {
  const skipCheck = necessaryPermissions.length === 0;
  const isAdmin = roles?.includes(USER_ROLES.ADMIN);
  const hasPermission = necessaryPermissions.some(permission =>
    permissionsArray?.includes(permission)
  );

  if (skipCheck || isAdmin) {
    return true;
  }

  return hasPermission;
};
