import { format } from 'date-fns';
import { getSingleRole } from 'shared/helpers/roles';
import intercomSetup from 'shared/helpers/intercomSetup';

export const getSelectedSchool = ({ schools = [], schoolId = null }) =>
  schools.find(({ value }) => value === schoolId) || schools.at(0) || null;

export const updateIntercomProperties = ({
  program_selection,
  cycle_and_test_selection,
  roles,
  createdAt
}) => {
  intercomSetup({
    userProperties: {
      program_selection,
      cycle_and_test_selection,
      user_role: getSingleRole(roles),
      user_since: format(new Date(createdAt), 'MMM d, y h:mm:ss a')
    }
  });
};

export const generateAdmissionCycles = ({ school: selectedSchool, schools }) =>
  schools.find(school => school?.value === selectedSchool?.value)
    ?.applicationCycles || [];

export const generateAdmissionCycleOptions = ({ admissionCycles }) =>
  admissionCycles.map(cycle => ({
    label: cycle,
    value: cycle
  }));

export const generateSchools = ({ schools }) =>
  schools.map(school => ({
    label: school.name,
    value: school._id,
    applicationCycles: school.applicationCycles,
    productCycles: school.productCycles,
    qlik: school.qlik,
    salesforceAccountId: school.salesforceAccountId,
    products: school?.products
  }));

// prevents "text" of the Select Button from resetting on each input change
export const getLabel = (selected, currentState, setFunction) => {
  if (!!selected && selected.label !== currentState) {
    setFunction(selected.label);
  }
  return currentState;
};
