import React from 'react';
import PropTypes from 'prop-types';

import {
  CardStyled,
  WrapperStyled,
  IconWrapperStyled
} from './home-non-action-card.styles';

const NonActionCard = ({
  className,
  icon: IconComponent,
  title,
  noPadding,
  children,
  dataTestId
}) => {
  return (
    <CardStyled
      className={className}
      noPadding={noPadding}
      dataTestId={dataTestId}
    >
      <IconWrapperStyled>
        <IconComponent />
      </IconWrapperStyled>
      <WrapperStyled>
        <h3>{title}</h3>
        {children}
      </WrapperStyled>
    </CardStyled>
  );
};

NonActionCard.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string,
  noPadding: PropTypes.bool,
  children: PropTypes.object,
  dataTestId: PropTypes.string
};

NonActionCard.defaultProps = {
  className: '',
  icon: null,
  title: '',
  noPadding: false,
  children: null,
  dataTestId: ''
};

export default NonActionCard;
