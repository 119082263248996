import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import FocusLock from 'react-focus-lock';

import ArrowDropdown from 'assets/icons/arrow-dropdown';
import useOutsideAlerter from 'shared/hooks/useOutsideAlerter';
import {
  WrapperStyled,
  DropdownStyled,
  TitleStyled,
  DropdownOptionsStyled,
  DropdownItemStyled
} from './dropdown.styles';

const Dropdown = ({
  size,
  title,
  className,
  options,
  handleChange,
  cleanSelectedOption
}) => {
  const [open, setOpen] = useState(false);
  const wrapperRef = useRef(null);

  useOutsideAlerter({
    ref: wrapperRef,
    handleClose: setOpen,
    currentValue: open,
    cleanSelectedOption
  });

  const handleClick = option => {
    if (option.disabled) {
      return;
    }
    handleChange(option.value);
  };

  return (
    <WrapperStyled
      ref={wrapperRef}
      className={className}
      data-testid="dropdown"
    >
      <FocusLock disabled={!open}>
        <DropdownStyled
          size={size}
          title={title}
          onClick={() => setOpen(prevState => !prevState)}
          onKeyPress={e => {
            e.preventDefault();
            if (e.which === 32 || e.which === 13) {
              setOpen(prevState => !prevState);
            }
          }}
          onKeyDown={e => {
            // 27 = esc
            if (e.which === 27) {
              setOpen(false);
            }
          }}
          tabIndex="0"
        >
          <TitleStyled>
            <span>{title}</span>
            <ArrowDropdown />
          </TitleStyled>
          <DropdownOptionsStyled open={open}>
            {options.map(option => (
              <DropdownItemStyled
                key={option.value}
                onClick={() => handleClick(option)}
                onKeyPress={e => {
                  e.preventDefault();
                  if (e.which === 32 || e.which === 13) {
                    handleClick(option);
                    setOpen(prevState => !prevState);
                  }
                }}
                disabled={option.disabled || false}
                tabIndex="0"
                data-testid="dropdown-item"
              >
                {option.label}
              </DropdownItemStyled>
            ))}
          </DropdownOptionsStyled>
        </DropdownStyled>
      </FocusLock>
    </WrapperStyled>
  );
};

Dropdown.propTypes = {
  size: PropTypes.oneOf(['large', 'medium', 'small']).isRequired,
  title: PropTypes.string,
  handleClick: PropTypes.func
};

Dropdown.defaultProps = {
  size: 'medium',
  title: '',
  options: []
};

export default Dropdown;
