import colors from './colors';

export default {
  color: colors.primary?.default,
  cursor: 'pointer',
  textDecoration: 'none',
  hover: {
    color: colors.primary?.darkest,
    textDecoration: 'underline'
  }
};
