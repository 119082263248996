import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { IconWrapperStyled } from './icon-wrapper.styles';

const NavUserIconStyled = styled(IconWrapperStyled)`
  ${({ theme: { typography, colors = {} } }) => `
  background: #ffffff;
  div {
    ${typography?.(14, 400)}
    background-color: ${colors.grey?.dark};
    width: 32px;
    height: 32px;
    border-radius: 50%;

    line-height: 32px;
    text-align: center;
    vertical-align: middle;
    color: #ffffff;
    }
  `}
`;

const NavUserIcon = ({ userInitials, tabIndex }) => {
  const { t } = useTranslation();
  return (
    <NavUserIconStyled
      title="User Icon"
      role="menu"
      aria-label={`${userInitials}, ${t('navbar_user_icon')}`}
      data-testid="user-icon"
      data-pendoid="user-icon"
      tabIndex={tabIndex >= 0 ? tabIndex : -1}
    >
      <div>{userInitials}</div>
    </NavUserIconStyled>
  );
};
export default NavUserIcon;
