import styled, { keyframes } from 'styled-components/macro';

import { devices } from 'shared/css/devices';
import { fullCenter } from 'shared/css/common-classes';
import Link from '../link/link';
import Close from 'assets/icons/close';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const WrapperStyled = styled.div`
  ${fullCenter};
  ${({ theme: { modals = {} } }) => `
    background-color: ${modals?.wrapper?.backgroundColor};
    bottom: ${modals?.wrapper?.bottom};
    height: ${modals?.wrapper?.height};
    min-height: ${modals?.wrapper?.minHeight};
    left: ${modals?.wrapper?.left};
    position: ${modals?.wrapper?.position};
    right: ${modals?.wrapper?.right};
    top: ${modals?.wrapper?.top};
    width: ${modals?.wrapper?.width};
    z-index: ${modals?.wrapper?.zIndex};
  `};
  animation: ${props => props.theme.transitions?.medium} ${fadeIn} ease-in-out;
`;

export const ModalBoxStyled = styled.div`
  ${({ theme: { modals = {} }, size }) => `
    background-color: ${modals?.box?.backgroundColor};
    border-radius: ${modals?.box?.borderRadius};
    box-shadow: ${modals?.box?.boxShadow};
    min-height: ${modals?.box?.minHeight?.[size]};
    width: ${modals?.box?.width?.mobile};
    @media ${devices.tablet} {
      width: ${modals?.box?.width?.desktop};
    }
  `};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const HeaderStyled = styled.div`
  ${({ theme: { modals = {} } }) => `
    min-height: ${modals?.header?.minHeight};
    padding: ${modals?.header?.padding};
  `};
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const BodyStyled = styled.div`
  ${({ theme: { modals = {} }, isScrollable }) => `
    border-top: ${modals?.body?.borderTop};
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: ${modals?.body?.padding};
    overflow-y: ${isScrollable ? 'scroll' : 'visible'};
  `};
`;

export const FooterStyled = styled.div`
  ${({ theme: { modals = {} } }) => `
    min-height: ${modals?.footer?.minHeight};
    padding: ${modals?.footer?.padding};
    & * {
      margin-left: ${modals?.footer?.marginLeft};
    }
  `};
  align-items: center;
  display: flex;
  justify-content: flex-end;
`;

export const TitleStyled = styled.div`
  ${({ theme: { modals = {}, typography } }) => `
    display: flex;
    color: ${modals?.header?.color};
    ${typography?.(16)};
    align-items: center;
  `};
`;

export const IconStyled = styled.div`
  margin-top: 2px;
  padding-right: 0.5rem;
`;

export const LinkStyled = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;

export const CloseButtonStyled = styled(Close)`
  cursor: pointer;
  &:focus {
    border: 2px solid ${props => props.theme?.colors?.primary?.default};
    outline: none;
`;
