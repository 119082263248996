import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import { Route, Redirect, useLocation, useHistory } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { datadogLogs } from '@datadog/browser-logs';
import { useQuery } from '@apollo/client';
import { format } from 'date-fns';
import { CURRENT_USER } from 'graphql/queries/user';

// Helpers
import checkRole from 'shared/helpers/checkRole';
import intercomSetup from 'shared/helpers/intercomSetup';
import { getSingleRole } from 'shared/helpers/roles';
import usePendoIdentity from 'shared/helpers/usePendoIdentity';

// Atoms
import { userProfileAtom } from 'shared/store/user';
import {
  programFilterAtom,
  userPermissionsPerSchool
} from 'shared/store/program-filter';

// Components
import Loading from 'shared/components/loader-full-page/loader-full-page';

// Enums
import { WithPermissions, DocumentTitle } from 'shared/components';
import { USER_ROLES, STORAGE_KEY, GRAPHQL_ERROR_CODE } from 'shared/enums';

import { QlikAppProvider } from 'shared/hoc/qlikAppProvider';
import saveProgramFilterSelection from '../helpers/saveProgramFilterSelection';
import clearLocalStorage from '../../shared/helpers/clearLocalStorage';

export default ({
  component: Component,
  permissions,
  componentProps,
  title,
  skipQuery = false,
  isDashboard = false,
  ...rest
}) => {
  // Location
  const history = useHistory();
  const location = useLocation() || {};
  const { state, pathname, search } = location;

  const [userLoading, setUserLoading] = useState(true);
  const [isReviewer, setIsReviewer] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  // LocalStorage - Program Filter
  const storedProgramFilter = JSON.parse(
    localStorage.getItem(STORAGE_KEY.PROGRAM_FILTER)
  );

  // App State
  // User Profile
  const setUserProfile = useSetRecoilState(userProfileAtom);
  // Profile - Cycle Selector
  const programFilter = useRecoilValue(programFilterAtom);
  const setProgramFilter = useSetRecoilState(programFilterAtom);
  // User Permissions
  const setUserPermissions = useSetRecoilState(userPermissionsPerSchool);

  // Set location to include whether it is a qlik powered dashboard.
  // This is used by the QlikAppProvider to determine if it safe to close the qlik app Web Socket.
  useEffect(() => {
    history.push({
      ...location,
      state: { ...state, dashboard: isDashboard }
    });
  }, []);

  const {
    error,
    data = {},
    refetch
  } = useQuery(CURRENT_USER, {
    /*
    Since admins are able to access all schools,
    school data is injected into the query on the api
    thus the current workaround is to refetch the data everytime
    */
    fetchPolicy: isAdmin ? 'network-only' : 'cache-and-network',
    onCompleted: ({ user }) => {
      // Set core recoil app state
      setUserProfile({ ...user?.profile, userId: user._id });
      if (user?.profile?.lang !== i18n.languages[0]) {
        i18n.changeLanguage(user?.profile?.lang);
      }

      setUserPermissions(user?.schoolPermissions);

      saveProgramFilterSelection({
        user,
        storedProgramFilter,
        setProgramFilter,
        history,
        search
      });

      setIsAdmin(
        checkRole({
          roles: user?.roles,
          role: USER_ROLES.ADMIN
        })
      );
      setIsReviewer(
        checkRole({
          roles: user?.roles,
          role: USER_ROLES.TEAM_MEMBER
        })
      );

      // Set Intercom
      intercomSetup({
        userProperties: {
          email: user?.emails?.[0]?.address,
          user_id: user?._id,
          user_role: getSingleRole(user?.roles),
          user_since: format(
            new Date(user?.createdAt || new Date()),
            'MMM d, y h:mm:ss a'
          ),
          program_selection: isReviewer
            ? null
            : user?.profile?.schools?.find(
                school => school?._id === programFilter?.schoolId
              )?.name || '',
          cycle_and_test_selection: isReviewer
            ? null
            : programFilter?.admissionCycle
        }
      });

      setUserLoading(false);
    },
    onError: () => {
      setUserLoading(false);
    }
  });

  // useEffect when routes changes
  useEffect(() => {
    // If localStorage/cookies have been wiped,
    // only way to know if user is still authenticated need to refetch currentUser,
    refetch();
  }, [pathname]);

  // Set Pendo
  usePendoIdentity(data?.user);

  if (process.env.REACT_APP_MAINTENANCE_MODE === 'true') {
    return <Redirect to="/503" />;
  }

  if (userLoading) {
    return <Loading />;
  } else if (error) {
    const isUnauthenticated =
      error.graphQLErrors?.at(0)?.extensions?.code ===
      GRAPHQL_ERROR_CODE.UNAUTHENTICATED;
    // clear all local storage & recoil states
    if (isUnauthenticated) {
      clearLocalStorage([STORAGE_KEY.LOGIN_TOKEN]);
    } else {
      datadogLogs.logger.error('Unexpected Error', {}, error);
    }
    return (
      <Redirect
        to={{
          pathname: isUnauthenticated ? '/signin' : '/404',
          state: {
            ...state,
            isLogged: !isUnauthenticated,
            previousPath: pathname
          }
        }}
      />
    );
  } else {
    return (
      <Route
        {...rest}
        render={props => (
          <DocumentTitle title={title}>
            <WithPermissions routePermissions={permissions} user={data?.user}>
              <QlikAppProvider
                schoolId={
                  programFilter?.schoolId || storedProgramFilter?.schoolId
                }
                user={data?.user}
              >
                <Component
                  {...props}
                  {...componentProps}
                  user={data?.user}
                  isReviewer={isReviewer}
                  isAdmin={isAdmin}
                  title={title}
                />
              </QlikAppProvider>
            </WithPermissions>
          </DocumentTitle>
        )}
      />
    );
  }
};
