import { toast } from 'react-toastify';
import { ToastBody } from 'components';
import { datadogLogs } from '@datadog/browser-logs';
/**
 * Displays toast and logs error to datadog.
 *
 * @param {Error} error
 * @param {string} appId
 * @param {string} userId
 */
const handleQlikAppError = (error, appId, userId) => {
  toast.error(
    <ToastBody
      category="error"
      title="Error!"
      text="Unable to open the Qlik app. Please try again in a few minutes. If the problem persists, contact support for assistance."
    />
  );

  datadogLogs.logger.error(
    'Insights Qlik app error: Failed to open Qlik app.',
    {
      appId,
      userId
    },
    error
  );
};

export default handleQlikAppError;
