import styled from 'styled-components/macro';
import { ToastContainer } from 'react-toastify';
import theme from 'shared/css/theme';

export const ToastContainerStyled = styled(ToastContainer)`
  .Toastify__toast {
    border: none;
    border-radius: ${theme?.alerts?.box?.borderRadius};
    margin: ${theme?.alerts?.box?.margin};
    min-height: ${theme?.alerts?.box?.minHeight};
    padding: ${theme?.alerts?.box?.padding};
    color: ${theme?.colors?.grey?.darker};
  }

  .Toastify__toast-theme--colored.Toastify__toast--success {
    background-color: ${theme?.alerts.box?.backgroundColor?.success};
  }

  .Toastify__toast-theme--colored.Toastify__toast--warning {
    background-color: ${theme?.alerts.box?.backgroundColor?.alert};
  }

  .Toastify__toast-theme--colored.Toastify__toast--error {
    background-color: ${theme?.alerts.box?.backgroundColor?.error};
  }

  .Toastify__toast-theme--colored.Toastify__toast--info {
    background-color: ${theme?.alerts.box?.backgroundColor?.info};
  }

  .Toastify__toast-body {
    padding: 0;
  }

  .Toastify__close-button {
    color: ${theme?.colors?.grey?.darker};
    opacity: 1;
  }
`;
