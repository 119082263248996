import React, { useState, useEffect } from 'react';

import { Loader } from 'components';
import { WrapperStyled } from './loader-full-page.styles';

export default () => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    let timeout = setTimeout(() => setShow(true), 500);
    return () => {
      clearTimeout(timeout);
    };
  }, []);
  return <WrapperStyled>{show && <Loader />}</WrapperStyled>;
};
