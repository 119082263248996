import React, { FC } from 'react';

import {
  TextAreaWrapperStyled,
  TextAreaContentWrapperStyled,
  TextAreaStyled,
  LabelStyled,
  MessageStyled
} from './text-area.styles';
import { TextAreaProps } from './types';

const TextArea: FC<TextAreaProps> = React.forwardRef(
  (
    {
      name,
      label,
      value,
      help,
      error = false,
      errorMessage = '',
      errorPlaceholder = false,
      disabled = false,
      readOnly = false,
      placeholder,
      required = false,
      className,
      handleChange,
      onFocus,
      onBlur,
      autoFocus = false,
      defaultValue,
      rows = 10,
      isMessageBelowInput = true,
      ...rest
    },
    ref
  ) => {
    const message = error ? errorMessage : help;
    const errorPlaceholderMessage = errorPlaceholder ? 'message' : '';

    return (
      <TextAreaWrapperStyled className={className}>
        <TextAreaContentWrapperStyled>
          <LabelStyled
            htmlFor={name}
            data-testid={`${rest?.['data-testid'] || 'text-area'}-label`}
          >
            {label ? (
              <span>{label}</span>
            ) : (
              <span style={{ visibility: 'hidden', height: '0px' }}>
                {placeholder}
              </span>
            )}
            {!isMessageBelowInput && (
              <MessageStyled
                error={error}
                hide={!Boolean(message)}
                data-testid={`${rest?.['data-testid'] || 'text-area'}-message`}
              >
                {message || errorPlaceholderMessage}
              </MessageStyled>
            )}
            <TextAreaStyled
              id={name}
              ref={ref}
              name={name}
              aria-label={name}
              aria-required={required}
              onChange={handleChange}
              disabled={disabled}
              readonly={readOnly}
              placeholder={placeholder}
              required={required}
              error={error}
              autoFocus={autoFocus}
              onFocus={onFocus}
              onBlur={onBlur}
              data-testid={rest?.['data-testid'] || 'text-area'}
              data-pendoid={rest?.['data-pendoid'] || 'text-area'}
              rows={rows}
              value={value || defaultValue}
            >
              {value || defaultValue}
            </TextAreaStyled>
          </LabelStyled>
        </TextAreaContentWrapperStyled>
        {isMessageBelowInput && (
          <MessageStyled
            error={error}
            hide={!Boolean(message)}
            data-testid={`${rest?.['data-testid'] || 'text-area'}-message`}
          >
            {message || errorPlaceholderMessage}
          </MessageStyled>
        )}
      </TextAreaWrapperStyled>
    );
  }
);

export default TextArea;
