import React from 'react';

export default ({ className }) => (
  <svg
    className={className}
    width="104"
    height="103"
    viewBox="0 0 104 103"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Success</title>
    <path
      d="M67.9375 42.4332L51.0183 64.9922C50.4636 65.7308 49.6159 66.1924 48.6946 66.2577C47.7732 66.3229 46.8689 65.9853 46.2157 65.3322L36.0625 55.1832"
      stroke="#00A59B"
      strokeWidth="6.375"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M46.6024 6.7672C47.9174 5.13275 49.9021 4.18219 51.9999 4.18219C54.0976 4.18219 56.0824 5.13275 57.3974 6.7672L63.8234 14.7614C65.3024 16.6046 67.6194 17.566 69.9689 17.3114L80.1689 16.2022C82.2604 15.9699 84.3442 16.7008 85.8322 18.1889C87.3203 19.6769 88.0512 21.7607 87.8189 23.8522L86.7096 34.0522C86.4551 36.4017 87.4165 38.7187 89.2596 40.1977L97.2539 46.6237C98.8883 47.9387 99.8389 49.9234 99.8389 52.0212C99.8389 54.119 98.8883 56.1037 97.2539 57.4187L89.2341 63.8149C87.3916 65.2963 86.4305 67.6142 86.6841 69.9647L87.7934 80.1647C88.0274 82.2566 87.297 84.3414 85.8085 85.8298C84.3201 87.3183 82.2353 88.0487 80.1434 87.8147L69.9434 86.7054C67.5938 86.4501 65.2764 87.4116 63.7979 89.2554L57.3974 97.2199C56.0833 98.8558 54.0982 99.8075 51.9999 99.8075C49.9016 99.8075 47.9165 98.8558 46.6024 97.2199L40.1806 89.2299C38.6998 87.3867 36.3815 86.4255 34.0309 86.68L23.8309 87.7892C21.7386 88.0249 19.6528 87.295 18.1639 85.8061C16.6751 84.3173 15.9452 82.2315 16.1809 80.1392L17.2901 69.9392C17.5446 67.5885 16.5833 65.2703 14.7401 63.7894L6.75014 57.3677C5.11424 56.0536 4.1626 54.0685 4.1626 51.9702C4.1626 49.8719 5.11424 47.8868 6.75014 46.5727L14.7401 40.1467C16.5839 38.6682 17.5455 36.3508 17.2901 34.0012L16.1809 23.8012C15.9469 21.7093 16.6773 19.6245 18.1657 18.1361C19.6542 16.6476 21.739 15.9172 23.8309 16.1512L34.0309 17.2604C36.3814 17.5141 38.6993 16.553 40.1806 14.7104L46.6024 6.7672Z"
      stroke="#00A59B"
      strokeWidth="6.375"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
