import styled from 'styled-components/macro';
import theme from 'shared/css/theme';

export const SkipStyled = styled.nav`
  position: absolute;
  top: -100vh;
  left: -100vw;
  height: 1px;
  width: 1px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 100ms linear;
  font-size: 1rem;

  &:active,
  &:focus,
  &:hover {
    background-color: ${theme.colors?.primary?.default || 'red'};
    color: white;
    z-index: 1;
    left: 0;
    top: 0;
    height: ${theme.spacing?.(8)};
    width: 100%;
    overflow: visible;
    text-decoration: underline;
  }

  & > span {
    cursor: pointer;
  }
`;
