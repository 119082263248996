import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import Input from '../input/input';
import { flushErrorsState } from 'shared/helpers/flushErrorsState';

import {
  PasswordValidationWrapperStyled,
  PasswordValidationItemStyled,
  ErrorStyled,
  SuccessStyled
} from './password.styles';

const CHECK_LOWERCASE = /[a-z]/;
const CHECK_UPPERCASE = /[A-Z]/;
const CHECK_DIGIT = /\d/;
const CHECK_SPECIAL = /(_|[^\w\d ])/;
const CHECK_WHITESPACE = /\s/;

const validatePassword = ({ password }) => {
  return {
    hasLowercase: Boolean(password.match(CHECK_LOWERCASE)),
    hasUppercase: Boolean(password.match(CHECK_UPPERCASE)),
    hasDigit: Boolean(password.match(CHECK_DIGIT)),
    hasSpecial: Boolean(password.match(CHECK_SPECIAL)),
    hasMinimum: Boolean(password.length >= 8 && password.length <= 30),
    hasWhitespace: Boolean(!password.match(CHECK_WHITESPACE))
  };
};

const checkPasswordErrors = ({ password = {} }) => {
  const values = Object.values(password);
  return values.every(val => val);
};

const isPasswordValid = ({ type, passwordContent = {} }) => {
  const options = {
    LOWERCASE: passwordContent.current?.hasLowercase,
    UPPERCASE: passwordContent.current?.hasUppercase,
    DIGIT: passwordContent.current?.hasDigit,
    SPECIAL: passwordContent.current?.hasSpecial,
    MINIMUM: passwordContent.current?.hasMinimum,
    WHITESPACE: passwordContent.current?.hasWhitespace
  };
  return options[type];
};

const Password = ({
  label,
  name,
  password,
  setPassword,
  errors,
  setErrors,
  showError,
  errorMessage,
  passwordValidationBetween,
  passwordValidationLowercase,
  passwordValidationUppercase,
  passwordValidationDigit,
  passwordValidationSpecial,
  passwordValidationWhitespace
}) => {
  const passwordContent = useRef({});
  const [passwordIsValid, setPasswordIsValid] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState(false);

  return (
    <>
      <Input
        label={label}
        name={name}
        type="password"
        value={password}
        handleChange={e => {
          if (!passwordValidation && !passwordIsValid) {
            setPasswordValidation(true);
          }
          const checkPassword = validatePassword({
            password: e.target.value
          });
          const noPasswordErrors = checkPasswordErrors({
            password: checkPassword
          });

          setPasswordIsValid(noPasswordErrors);
          setPasswordValidation(!noPasswordErrors);

          passwordContent.current = checkPassword;
          flushErrorsState({
            errors,
            name,
            value: e.target.value,
            updateErrors: setErrors,
            updateState: setPassword
          });
        }}
        required={true}
        aria-required={true}
        error={showError}
        errorMessage={errorMessage}
        onFocus={() => setPasswordValidation(true)}
        onBlur={() => {
          if (!password) {
            setPasswordValidation(false);
          }
        }}
      />
      <>
        <PasswordValidationWrapperStyled error={showError}>
          <PasswordValidationItemStyled>
            {isPasswordValid({ type: 'MINIMUM', passwordContent }) ? (
              <SuccessStyled datatestId="success-minimum" />
            ) : (
              <ErrorStyled datatestId="error-minimum" />
            )}
            {passwordValidationBetween}
          </PasswordValidationItemStyled>
          <PasswordValidationItemStyled>
            {isPasswordValid({ type: 'LOWERCASE', passwordContent }) ? (
              <SuccessStyled datatestId="success-lowercase" />
            ) : (
              <ErrorStyled datatestId="error-lowercase" />
            )}
            {passwordValidationLowercase}
          </PasswordValidationItemStyled>
          <PasswordValidationItemStyled>
            {isPasswordValid({ type: 'UPPERCASE', passwordContent }) ? (
              <SuccessStyled datatestId="success-uppercase" />
            ) : (
              <ErrorStyled datatestId="error-uppercase" />
            )}
            {passwordValidationUppercase}
          </PasswordValidationItemStyled>
          <PasswordValidationItemStyled>
            {isPasswordValid({ type: 'DIGIT', passwordContent }) ? (
              <SuccessStyled datatestId="success-digit" />
            ) : (
              <ErrorStyled datatestId="error-digit" />
            )}
            {passwordValidationDigit}
          </PasswordValidationItemStyled>
          <PasswordValidationItemStyled>
            {isPasswordValid({ type: 'SPECIAL', passwordContent }) ? (
              <SuccessStyled datatestId="success-special" />
            ) : (
              <ErrorStyled datatestId="error-special" />
            )}
            {passwordValidationSpecial}
          </PasswordValidationItemStyled>
          <PasswordValidationItemStyled>
            {isPasswordValid({ type: 'WHITESPACE', passwordContent }) ? (
              <SuccessStyled datatestId="success-whitespace" />
            ) : (
              <ErrorStyled datatestId="error-whitespace" />
            )}
            {passwordValidationWhitespace}
          </PasswordValidationItemStyled>
        </PasswordValidationWrapperStyled>
      </>
    </>
  );
};

Password.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  password: PropTypes.string,
  setPassword: PropTypes.func.isRequired,
  errors: PropTypes.array.isRequired,
  setErrors: PropTypes.func,
  showError: PropTypes.bool,
  errorMessage: PropTypes.string,
  passwordValidationBetween: PropTypes.string.isRequired,
  passwordValidationLowercase: PropTypes.string.isRequired,
  passwordValidationUppercase: PropTypes.string.isRequired,
  passwordValidationDigit: PropTypes.string.isRequired,
  passwordValidationSpecial: PropTypes.string.isRequired,
  passwordValidationWhitespace: PropTypes.string.isRequired
};

Password.defaultProps = {
  passwordValidationBetween: 'between 8 and 30 characters',
  passwordValidationLowercase: 'at least 1 lower case',
  passwordValidationUppercase: 'at least 1 upper case',
  passwordValidationDigit: 'at least 1 digit',
  passwordValidationSpecial: 'at least 1 special character',
  passwordValidationWhitespace: 'cannot contain whitespace'
};

export default Password;
