import React from 'react';

export default ({ className, color = '#000000' }) => (
  <svg
    className={className}
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>2fa authentication</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 17C18.7604 17 20.1875 15.5729 20.1875 13.8125C20.1875 12.0521 18.7604 10.625 17 10.625C15.2396 10.625 13.8125 12.0521 13.8125 13.8125C13.8125 15.5729 15.2396 17 17 17Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17 17V23.375"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 32.9375C25.802 32.9375 32.9375 25.802 32.9375 17C32.9375 8.19796 25.802 1.0625 17 1.0625C8.19796 1.0625 1.0625 8.19796 1.0625 17C1.0625 25.802 8.19796 32.9375 17 32.9375Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
