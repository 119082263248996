import React from 'react';
import PropTypes from 'prop-types';

import theme from 'shared/css/theme';
import {
  ExternalLinkStyled,
  ExternalLinkIconStyled
} from './external-link.styles';

const ExternalLink = ({
  className,
  href,
  target = '_blank',
  onClick,
  onKeyPress,
  children,
  showIcon,
  ...props
}) => (
  <ExternalLinkStyled
    onClick={onClick}
    onKeyPress={onKeyPress}
    href={href}
    className={className}
    data-testid="externalLink"
    data-pendoid={props['data-pendoid']}
    target={target}
  >
    {children}
    {showIcon && <ExternalLinkIconStyled color={theme?.colors.grey?.darker} />}
  </ExternalLinkStyled>
);

ExternalLink.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired
};

ExternalLink.defaultProps = {
  className: '',
  href: '/',
  target: '_blank',
  showIcon: false
};

export default ExternalLink;
