import styled from 'styled-components/macro';

import ErrorIcon from 'assets/icons/password-error';
import SuccessIcon from 'assets/icons/password-success';

export const PasswordValidationWrapperStyled = styled.div`
  ${({ theme, error }) => `
    background-color: ${theme.colors?.outline?.default};
    color: ${theme.colors?.grey?.darker};
    display: flex;
    flex-wrap: wrap;
    min-height: ${error ? '0px' : '22px'};
    & > div {
      flex-basis: 50%;
    }
  `};
`;

export const PasswordValidationItemStyled = styled.div`
  align-items: center;
  display: flex;
`;

export const SuccessStyled = styled(SuccessIcon)`
  margin-right: 8px;
`;

export const ErrorStyled = styled(ErrorIcon)`
  margin-right: 8px;
`;
