/**
 * @description
 * Opens a websocket connection to Qlik server
 * @param {string} host       // Refers to Qlik's host server
 * @param {String} prefix     // Refers to Qlik's virtual proxy
 * @param {Object} qlikBank   // Stores multiple active qlikApp sessions
 * @param {String} appName    // Key reference to stored qlikApp
 *
 * @return {Promise} qlikApp
 */
const openQlikWebsocket = async ({ host, prefix, qlikBank, appName }) => {
  return new Promise((resolve, reject) => {
    try {
      const configuration = {
        host,
        prefix: `/${prefix}/`, // Must keep slashes here for data exporting.
        port: 443,
        isSecure: true
      };

      const baseUrl = `https://${configuration.host}${
        configuration.port ? `:${configuration.port}` : ''
      }${configuration.prefix}`;

      window.require.config({
        baseUrl: `${baseUrl}resources`,
        paths: {
          dependency: `${baseUrl}resources/js/qlik`
        }
      });

      // Check if pre-existing qlik app is already open
      if (qlikBank[appName]) {
        resolve({ qlik: qlikBank[appName], configuration });
      } else {
        window.require(['js/qlik'], qlik => {
          resolve({ qlik, configuration });
        });
      }
    } catch (e) {
      console.log('Failed to open Qlik connection', e);
      reject(e);
    }
  });
};

export default openQlikWebsocket;
