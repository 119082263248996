import React from 'react';

export default ({ className }) => (
  <svg
    className={className}
    width="43"
    height="43"
    viewBox="0 0 43 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Snapshot</title>
    <rect
      x="2"
      y="2"
      width="39"
      height="39"
      rx="1.304"
      stroke="#4C55A5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.4976 24.6408C16.1846 24.803 15.8122 24.803 15.4992 24.6408C15.1988 24.4907 15.0065 24.1862 15 23.8504V12.3705C15.0065 12.0347 15.1988 11.7303 15.4992 11.5801C15.8122 11.4179 16.1846 11.4179 16.4976 11.5801L27.4956 17.3192C27.8035 17.4631 28.0003 17.7723 28.0003 18.1122C28.0003 18.4521 27.8035 18.7613 27.4956 18.9052L16.4976 24.6408Z"
      stroke="#4C55A5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.19995 33.2H35.7999"
      stroke="#4C55A5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28 30.6V35.8"
      stroke="#4C55A5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
