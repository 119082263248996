import { TWO_FA_METHODS } from 'shared/enums';

export const twoFaMethod = ({ t }) => [
  {
    name: TWO_FA_METHODS.SMS,
    key: TWO_FA_METHODS.SMS,
    text: t?.('common_2fa_method_text_message'),
    checked: true
  },
  {
    name: TWO_FA_METHODS.CALL,
    key: TWO_FA_METHODS.CALL,
    text: t?.('common_2fa_method_phone_call'),
    checked: false
  },
  {
    name: TWO_FA_METHODS.EMAIL,
    key: TWO_FA_METHODS.EMAIL,
    text: t?.('common_2fa_method_email'),
    checked: false
  }
];
