import React from 'react';
import PropTypes from 'prop-types';

import 'react-toastify/dist/ReactToastify.css';
import { ToastContainerStyled } from './toast.styles';

const Toast = ({ className }) => (
  <ToastContainerStyled
    style={{ top: '80px', right: '20px', zIndex: '25' }}
    className={className}
    data-testid="toast"
    hideProgressBar
    pauseOnFocusLoss={false}
    theme="colored"
    icon={false}
  />
);

Toast.propTypes = {
  className: PropTypes.string
};

Toast.defaultProps = {
  className: ''
};

export default Toast;
