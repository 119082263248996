import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';

import { useResetRecoilState, useRecoilValue } from 'recoil';

import intercomSetup from 'shared/helpers/intercomSetup';
import { programFilterAtom } from 'shared/store/program-filter';
import { userRoutes } from 'shared/store/userRoutes';
import { DocumentTitle } from 'shared/components';
import { ROUTES, STORAGE_KEY } from 'shared/enums';

export default ({ component: Component, title, ...rest }) => {
  const { state } = useLocation() || {};
  const programFilter = localStorage.getItem(STORAGE_KEY.PROGRAM_FILTER);

  const { menu } = useRecoilValue(userRoutes);
  const resetProgramFilter = useResetRecoilState(programFilterAtom);

  const isLogged = state?.isLogged !== false && programFilter;

  if (!menu && programFilter) {
    localStorage.removeItem(STORAGE_KEY.PROGRAM_FILTER);
    resetProgramFilter();
  }

  intercomSetup();

  if (process.env.REACT_APP_MAINTENANCE_MODE === 'true') {
    return <Redirect to="/503" />;
  }

  const canRedirect = menu && isLogged;
  const redirectURL =
    !state?.previousPath || state.previousPath === ROUTES.LOG_OUT.path
      ? ROUTES.HOME.path
      : state.previousPath;

  return (
    <Route
      {...rest}
      render={props =>
        canRedirect ? (
          <Redirect to={redirectURL} />
        ) : (
          <DocumentTitle title={title}>
            <Component {...props} />
          </DocumentTitle>
        )
      }
    />
  );
};
