import styled from 'styled-components';
import { Button } from 'components';

export const Container = styled.div`
  ${({ theme: { spacing, colors } }) => `
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    width: 100vw;
    height: 100vh;
    flex-direction: column;
    background-color: ${colors.white};
  `};
`;

export const ButtonStyled = styled(Button)`
  ${({ theme: { spacing } }) => `
    margin-top: ${spacing(2)};
  `};
`;
