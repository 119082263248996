import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import FocusLock from 'react-focus-lock';

import Checkbox from '../checkbox/checkbox';

import ArrowSelect from 'assets/icons/arrow-select';
import useOutsideAlerter from 'shared/hooks/useOutsideAlerter';

import {
  WrapperStyled,
  SelectStyled,
  TitleStyled,
  SelectOptionsStyled,
  SelectItemStyled,
  LabelStyled,
  MessageStyled
} from './select-checkbox.styles';

const SelectCheckbox = ({
  title,
  className,
  options,
  handleChange,
  name,
  label,
  error,
  errorMessage,
  errorPlaceholder,
  ...rest
}) => {
  const [open, setOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(options);
  const [selectAll, setSelectAll] = useState(false);
  const updateAllCheckboxes = useRef(false);
  const wrapperRef = useRef(null);

  const message = error ? errorMessage : '';
  const errorPlaceholderMessage = errorPlaceholder ? 'message' : '';

  useOutsideAlerter({
    ref: wrapperRef,
    handleClose: setOpen,
    currentValue: open
  });

  useEffect(() => {
    setSelectAll(selectedOptions.every(op => op.checked || op.disabled));
    handleChange(selectedOptions);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions]);

  useEffect(() => {
    if (updateAllCheckboxes.current) {
      const newSelectedOptionsState = selectedOptions.map(selectedOption => ({
        ...selectedOption,
        checked: selectedOption.disabled ? selectedOption.checked : selectAll
      }));
      setSelectedOptions(newSelectedOptionsState);
      updateAllCheckboxes.current = false;
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectAll]);

  const handleClick = ({ option, checked }) => {
    if (option.disabled) {
      return;
    }
    const newSelectedOptionsState = selectedOptions.map(selectedOption =>
      selectedOption.value === option.value
        ? {
            ...selectedOption,
            checked
          }
        : selectedOption
    );
    setSelectedOptions(newSelectedOptionsState);
  };

  const handleSelectAll = checked => {
    updateAllCheckboxes.current = true;
    setSelectAll(checked);
  };

  const numSelected = selectedOptions.filter(op => op.checked).length;

  return (
    <WrapperStyled
      className={className}
      data-testid="select-checkbox"
      ref={wrapperRef}
    >
      <LabelStyled id={`${name}-label`} htmlFor={name}>
        {label}
      </LabelStyled>
      <SelectStyled
        id={name}
        aria-labelledby={`${name}-label`}
        data-testid={rest?.['data-testid']}
        data-pendoid={rest?.['data-pendoid']}
        role="listbox"
        onClick={() => setOpen(prevState => !prevState)}
        onKeyDown={e => {
          if (e.which === 27) {
            setOpen(false);
          }
          if (e.which === 32 || e.which === 13) {
            setOpen(prevState => !prevState);
          }
        }}
        tabIndex={0}
        error={error}
      >
        <TitleStyled>
          <span>{numSelected > 0 ? `${numSelected} selected` : title}</span>
          <ArrowSelect />
        </TitleStyled>
      </SelectStyled>
      <FocusLock disabled={!open}>
        <SelectOptionsStyled open={open}>
          {selectedOptions.length > 1 && (
            <SelectItemStyled
              key="all"
              onClick={() => handleSelectAll(!selectAll)}
              onKeyDown={e => {
                if (e.which === 27) {
                  e.preventDefault();
                  setOpen(false);
                }
                if (e.which === 32 || e.which === 13) {
                  e.preventDefault();
                  updateAllCheckboxes.current = true;
                  setSelectAll(prevState => !prevState);
                }
              }}
              tabIndex={open ? 0 : -1}
              data-pendoid={`${rest?.['data-pendoid']}-all`}
              data-testid={
                rest['data-testid']
                  ? `${rest?.['data-testid']}-all`
                  : 'select-checkbox-all'
              }
            >
              <Checkbox
                name="select_all"
                checked={selectAll}
                handleClick={() => handleSelectAll(!selectAll)}
                text={'Select all'}
                tabIndex="-1"
              />
            </SelectItemStyled>
          )}
          {selectedOptions.map(option => (
            <SelectItemStyled
              key={option.value}
              onClick={() => handleClick({ option, checked: !option.checked })}
              onKeyDown={e => {
                if (e.which === 27) {
                  e.preventDefault();
                  setOpen(false);
                }
                if (e.which === 32 || e.which === 13) {
                  e.preventDefault();
                  handleClick({ option, checked: !option.checked });
                }
              }}
              disabled={option.disabled || false}
              tabIndex="0"
              data-pendoid={`${rest?.['data-pendoid']}-${option.value}`}
              data-testid={
                rest['data-testid']
                  ? `${rest?.['data-testid']}-${option.value}`
                  : 'select-checkbox-item'
              }
            >
              <Checkbox
                name={`select_${option.value}`}
                checked={option.checked}
                handleClick={() =>
                  handleClick({ option, checked: !option.checked })
                }
                text={option.label}
                disabled={option.disabled}
                tabIndex="-1"
              />
            </SelectItemStyled>
          ))}
        </SelectOptionsStyled>
      </FocusLock>
      <MessageStyled error={error} hide={!Boolean(message)}>
        {message || errorPlaceholderMessage}
      </MessageStyled>
    </WrapperStyled>
  );
};

SelectCheckbox.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  options: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  errorPlaceholder: PropTypes.bool
};

SelectCheckbox.defaultProps = {
  title: '',
  options: [],
  handleChange: () => {}
};

export default SelectCheckbox;
