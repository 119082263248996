import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Select } from 'components';
import { LANGUAGE } from 'shared/enums';
import { LanguageDisclaimer, WrapperStyled } from './language-selector.styles';

export const transformTextToLanguage = ({ language }) => {
  const options = {
    english: 'en',
    french: 'fr'
  };
  return options[language] || 'en';
};

export const transformLanguageToText = ({ text }) => {
  const options = {
    en: { ...LANGUAGE.ENGLISH },
    fr: { ...LANGUAGE.FRENCH }
  };
  return options[text] || LANGUAGE.ENGLISH;
};

const LanguageSelector = ({
  language,
  label,
  handleSwitchLanguage,
  showDisclaimer
}) => {
  const { t } = useTranslation();
  return (
    <WrapperStyled>
      <Select
        data-testid="language_selector"
        data-pendoid="select_default_message"
        name="language"
        label={label}
        selectedOption={language}
        options={Object.values(LANGUAGE)}
        handleChange={handleSwitchLanguage}
        noOptionsMessage={t?.('select_default_message')}
      />
      {showDisclaimer && (
        <LanguageDisclaimer data-pendoid="account_language_disclaimer">
          {t?.('account_language_disclaimer')}
        </LanguageDisclaimer>
      )}
    </WrapperStyled>
  );
};

export default LanguageSelector;

LanguageSelector.propTypes = {
  language: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string
  }),
  label: PropTypes.string,
  handleSwitchLanguage: PropTypes.func,
  showDisclaimer: PropTypes.bool
};

LanguageSelector.defaultProps = {
  showDisclaimer: true
};
