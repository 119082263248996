import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import FocusLock from 'react-focus-lock';

import useOutsideAlerter from 'shared/hooks/useOutsideAlerter';
import {
  WrapperStyled,
  DropdownStyled,
  TitleStyled,
  DropdownOptionsStyled,
  DropdownItemLinkStyled,
  DropdownItemStyled,
  ArrowStyled,
  ExternalLinkStyled,
  IconWrapperStyled
} from './dropdown-navbar.styles';

import InnerDropdownNavbar from './inner-dropdown';

const DropdownNavbar = ({ className, title, icon, options, hideArrowIcon }) => {
  const [open, setOpen] = useState(false);
  const wrapperRef = useRef(null);
  const innerDropdownRef = useRef(null);
  const LEFT = 'left';
  const RIGHT = 'right';

  useOutsideAlerter({
    ref: wrapperRef,
    handleClose: setOpen,
    currentValue: open
  });

  const handleOpen = e => {
    const isInnerDropdownClick =
      innerDropdownRef.current && innerDropdownRef.current.contains(e.target);
    return isInnerDropdownClick ? null : setOpen(prevState => !prevState);
  };

  return (
    <WrapperStyled
      ref={wrapperRef}
      className={className}
      data-testid="dropdown-navbar"
    >
      <FocusLock disabled={!open}>
        <DropdownStyled
          onClick={handleOpen}
          onKeyPress={e => {
            e.preventDefault();
            if (e.which === 32 || e.which === 13) {
              handleOpen(e);
            }
          }}
          onKeyDown={e => {
            // 27 = esc
            if (e.which === 27) {
              const isInnerDropdownClick =
                innerDropdownRef.current &&
                innerDropdownRef.current.contains(e.target);
              if (!isInnerDropdownClick) {
                setOpen(false);
              }
            }
          }}
          open={open}
          data-testid="dropdown-navbar-options"
        >
          <TitleStyled data-testid="dropdown-title">
            {!!title && (
              <span data-testid="dropdown-navbar-title">{title}</span>
            )}
            {!!icon && icon}
            {!hideArrowIcon && <ArrowStyled open={open} />}
          </TitleStyled>

          <DropdownOptionsStyled data-testid="dropdown-options" open={open}>
            {options.map(option =>
              option.link ? (
                <DropdownItemLinkStyled
                  role="menuitem"
                  data-pendoid={option.id}
                  key={option.value}
                  to={option.link}
                  active={option.active}
                  tabIndex={option.disabled ? '-1' : '0'}
                  disabled={option.disabled || false}
                  showLink={!option.navItem || false}
                  topDivider={option.topDivider || false}
                  ariaLabel={option.label}
                >
                  {option.position === LEFT && (
                    <IconWrapperStyled>{option.icon}</IconWrapperStyled>
                  )}
                  {option.label}
                  <ExternalLinkStyled showIcon={option.externalLink} />
                  {option.position === RIGHT && (
                    <IconWrapperStyled>{option.icon}</IconWrapperStyled>
                  )}
                </DropdownItemLinkStyled>
              ) : option.isDropdown ? (
                <InnerDropdownNavbar
                  key={option.value}
                  title={option.title}
                  options={option.innerOptions}
                  innerDropdownRef={innerDropdownRef}
                />
              ) : (
                <DropdownItemStyled
                  role="menuitem"
                  key={option.value}
                  onClick={option.handleClick}
                  onKeyPress={e => {
                    e.preventDefault();
                    if (e.which === 32 || e.which === 13) {
                      option.handleClick();
                    }
                  }}
                  tabIndex={option.disabled ? '-1' : '0'}
                  disabled={option.disabled || false}
                  topDivider={option.topDivider || false}
                  data-testid="dropdown-navbar-item"
                >
                  {option.position === LEFT && (
                    <IconWrapperStyled>{option.icon}</IconWrapperStyled>
                  )}
                  {option.label}
                  {option.position === RIGHT && (
                    <IconWrapperStyled>{option.icon}</IconWrapperStyled>
                  )}
                </DropdownItemStyled>
              )
            )}
          </DropdownOptionsStyled>
        </DropdownStyled>
      </FocusLock>
    </WrapperStyled>
  );
};

DropdownNavbar.propTypes = {
  title: PropTypes.string,
  handleClick: PropTypes.func,
  hideArrowIcon: PropTypes.bool,
  icon: PropTypes.node
};

DropdownNavbar.defaultProps = {
  title: '',
  options: []
};

export default DropdownNavbar;
