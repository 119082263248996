import styled from 'styled-components/macro';

import { fullCenter } from 'shared/css/common-classes';

export const InnerDropdownStyled = styled.div`
  ${({ theme: { dropdownsNavbar = {}, typography } }) => `
    ${fullCenter};
    ${typography?.(14)}
    background-color: ${dropdownsNavbar.group?.backgroundColor};
    border: 2px solid transparent;
    color: ${dropdownsNavbar.group?.color};
    cursor: ${dropdownsNavbar.group?.cursor};
    outline: ${dropdownsNavbar.group?.outline};
    flex-direction: column;
     &:hover {
      background-color: ${dropdownsNavbar.itemLink?.hover?.backgroundColor}};
      border-color: ${dropdownsNavbar.itemLink?.hover?.borderColor};
      color: ${dropdownsNavbar.itemLink?.color?.['default']};
    }
    &:focus {
      color: ${dropdownsNavbar.itemLink?.color?.['default']};
      background-color: ${
        dropdownsNavbar.itemLink?.focus?.backgroundColor?.['default']
      }};
    }
  `};
`;

export const TitleInnerDropdownStyled = styled.div`
  ${({ theme: { dropdownsNavbar = {}, typography, transitions } }) => `
    ${typography?.(14)}
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: ${dropdownsNavbar.itemLink?.padding};
    width: ${dropdownsNavbar.title?.width};
    transition: all ${transitions?.fast} ease-in-out;
    &:hover {
      background-color: ${
        dropdownsNavbar.item?.hover?.backgroundColor?.['default']
      }};
      border-color: ${dropdownsNavbar.item?.hover?.borderColor};
    }
    &:active {
      background-color: ${
        dropdownsNavbar.item?.active?.backgroundColor?.['default']
      }};
      border-color: ${dropdownsNavbar.item?.active?.borderColor};
    }
    &:focus {
      border-color: ${dropdownsNavbar.item?.focus?.borderColor};
    }
  `};
`;
