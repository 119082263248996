import styled from 'styled-components/macro';
import ArrowRight from 'assets/icons/arrow-right';
import { Card } from 'components';

export const ArrowRightStyled = styled(ArrowRight)`
  margin-left: 5px;
  top: 1px;
  position: relative;
`;

export const ListItemStyled = styled.li`
  padding-top: 0.5rem;
`;

export const CardStyled = styled(Card)`
  ${({ theme: { spacing } }) => `
    display: flex;
    justify-content: flex-start !important;
    flex-direction: column;
    flex-basis: 100% !important;
    padding: ${spacing?.(3)} ${spacing?.(2)} ${spacing?.(3)} ${spacing?.(3)};
    margin-right: 0;
    min-height: ${spacing?.(27)};
  `};
`;

export const CardTitleStyled = styled.h2`
  ${({ theme: { typography, spacing } = {} }) => `
      margin-bottom: ${spacing?.(1)};
      ${typography?.(24, 500)}
    `};
`;
