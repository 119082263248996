import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  WrapperStyled,
  HeaderStyled,
  ContentStyled,
  LogoStyled,
  SnapshotLogoStyled
} from './header.styles';

const HeaderLayout = ({
  hideBorder,
  snapshotPage,
  isDesktop,
  children,
  isFixed = false
}) => {
  return (
    <WrapperStyled
      hideBorder={hideBorder}
      data-testid="header"
      isFixed={isFixed}
    >
      <HeaderStyled>
        {snapshotPage ? (
          <Link ariaLabel="snapshot_logo" to="/snapshot/training">
            <SnapshotLogoStyled />
          </Link>
        ) : isDesktop ? (
          <LogoStyled />
        ) : null}
        <ContentStyled snapshotPage={snapshotPage} isDesktop={isDesktop}>
          {children}
        </ContentStyled>
      </HeaderStyled>
    </WrapperStyled>
  );
};

HeaderLayout.propTypes = {
  hideBorder: PropTypes.bool.isRequired,
  snapshotPage: PropTypes.bool.isRequired,
  isDesktop: PropTypes.bool.isRequired
};

HeaderLayout.defaultProps = {
  hideBorder: false,
  snapshotPage: false,
  isDesktop: false
};

export default HeaderLayout;
