import { GridColDef } from '@mui/x-data-grid-pro';

/**
 * Verifies the columns to ensure the valueFormatter field is present if renderCell is defined.
 * @param columns The columns to verify
 * @returns The verified columns
 */
export const verifyColumns = (columns: GridColDef[]): GridColDef[] => {
  columns.forEach(column => {
    if (column.renderCell && !column.valueFormatter) {
      console.error(
        'The valueFormatter field is required if renderCell is defined'
      );
      throw new Error(
        'The valueFormatter field is required if renderCell is defined'
      );
    }
  });

  return columns;
};
