import styled from 'styled-components/macro';
import { devices } from 'shared/css/devices';

export const WrapperStyled = styled.div`
  display: block;
  @media ${devices.tablet} {
    display: flex;
  }
  background: #ffffff;
  padding: 5px 5px 5px 22px;
  width: 100vw;
  margin-top: -15px;
  box-shadow: 0px 2px 4px rgba(3, 55, 103, 0.1);
  gap: 40px;
`;
