import React from 'react';
import PropTypes from 'prop-types';

import generatePaginationNumbers from 'shared/helpers/generatePaginationNumbers';
import theme from 'shared/css/theme';

import {
  WrapperStyled,
  ItemStyled,
  ArrowLeftStyled,
  ArrowRightStyled,
  ItemsGroupStyled
} from './pagination.styles';

const Pagination = ({
  currentPage,
  itemsPerPage,
  totalItems,
  handlePageChange,
  firstText,
  lastText
}) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const items = generatePaginationNumbers({ currentPage, totalPages });
  const arrowLeftDisabled = currentPage === 1;
  const arrowRightDisabled = currentPage === totalPages;
  const showEdges = totalPages > 5;
  return (
    <WrapperStyled
      arrowLeftDisabled={arrowLeftDisabled}
      arrowRightDisabled={arrowRightDisabled}
      data-testid="pagination"
    >
      <ItemsGroupStyled>
        {showEdges && (
          <ItemStyled
            key={'pagination_first'}
            edge={true}
            active={1 === currentPage}
            onClick={() => 1 !== currentPage && handlePageChange(1, 'First')}
            onKeyPress={e => {
              e.preventDefault();
              if (
                currentPage &&
                currentPage !== 1 &&
                (e.which === 32 || e.which === 13)
              ) {
                handlePageChange(1, 'First');
              }
            }}
            tabIndex="0"
          >
            {firstText}
          </ItemStyled>
        )}
        <ArrowLeftStyled
          color={
            arrowLeftDisabled
              ? theme.paginations?.arrows?.color?.['disabled']
              : theme.paginations?.arrows?.color?.['default']
          }
          disabled={arrowLeftDisabled}
          handleClick={() =>
            !arrowLeftDisabled && handlePageChange(currentPage - 1, 'Prev')
          }
          tabIndex={arrowLeftDisabled ? '-1' : '0'}
        />
        {items.map((item, index) => (
          <ItemStyled
            key={`pagination_${index}`}
            active={item === currentPage}
            onClick={() => item !== currentPage && handlePageChange(item)}
            onKeyPress={e => {
              e.preventDefault();
              if (item !== currentPage && (e.which === 32 || e.which === 13)) {
                handlePageChange(item);
              }
            }}
            tabIndex="0"
          >
            {item}
          </ItemStyled>
        ))}
        <ArrowRightStyled
          color={
            arrowRightDisabled
              ? theme.paginations?.arrows?.color?.['disabled']
              : theme.paginations?.arrows?.color?.['default']
          }
          disabled={arrowRightDisabled}
          handleClick={() =>
            !arrowRightDisabled && handlePageChange(currentPage + 1, 'Next')
          }
          tabIndex={arrowRightDisabled ? '-1' : '0'}
        />
        {showEdges && (
          <ItemStyled
            key={'pagination_last'}
            edge={true}
            active={totalPages === currentPage}
            onClick={() =>
              totalPages !== currentPage && handlePageChange(totalPages, 'Last')
            }
            onKeyPress={e => {
              e.preventDefault();
              if (
                totalPages !== currentPage &&
                (e.which === 32 || e.which === 13)
              ) {
                handlePageChange(totalPages, 'Last');
              }
            }}
            tabIndex="0"
          >
            {lastText}
          </ItemStyled>
        )}
      </ItemsGroupStyled>
    </WrapperStyled>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  firstText: PropTypes.string.isRequired,
  lastText: PropTypes.string.isRequired
};

Pagination.defaultProps = {
  currentPage: 1,
  itemsPerPage: 10,
  firstText: 'First',
  lastText: 'Last'
};

export default Pagination;
