import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import FocusLock from 'react-focus-lock';

import Button from '../button/button';
import { KEYBOARD_EVENT_VALUES } from 'shared/enums';

import {
  WrapperStyled,
  ModalBoxStyled,
  HeaderStyled,
  BodyStyled,
  FooterStyled,
  TitleStyled,
  LinkStyled,
  CloseButtonStyled,
  IconStyled
} from './modal.styles';

const useCloseOnEsc = ({ closeModal }) => {
  useEffect(() => {
    const handleKeyDownEsc = e => {
      if (e.which === 27 && !e.defaultPrevented) {
        closeModal();
      }
    };
    document.addEventListener('keydown', handleKeyDownEsc);
    return () => {
      document.removeEventListener('keydown', handleKeyDownEsc);
    };
  }, [closeModal]);
};

const Modal = ({
  className,
  title,
  titleIcon: Icon,
  buttons,
  size,
  closeModal,
  hideFooter,
  isScrollable,
  children
}) => {
  useCloseOnEsc({
    closeModal
  });
  return (
    <FocusLock>
      <WrapperStyled tabIndex="0" data-testid="modal">
        <ModalBoxStyled className={className} size={size}>
          <HeaderStyled data-testid="modal-header">
            <TitleStyled>
              {Icon && (
                <IconStyled tabIndex="-1">
                  <Icon color={'#000000'} />
                </IconStyled>
              )}
              {title}
            </TitleStyled>
            <CloseButtonStyled
              handleClick={closeModal}
              onKeyPress={e => {
                e.preventDefault();
                if (
                  e.key === KEYBOARD_EVENT_VALUES.SPACE ||
                  e.key === KEYBOARD_EVENT_VALUES.ENTER
                ) {
                  closeModal();
                }
              }}
              data-testid="modal-close-button"
              data-pendoid="modal-close-button"
              tabIndex="0"
            />
          </HeaderStyled>
          <BodyStyled
            isScrollable={isScrollable}
            data-testid="modal-body"
            data-pendoid="modal-body"
          >
            {children}
          </BodyStyled>
          {hideFooter ? null : (
            <FooterStyled data-testid="modal-footer">
              {buttons.map((btn, index) =>
                btn.link ? (
                  <LinkStyled
                    ariaLabel={`footer_btn_${index}`}
                    to={btn.link}
                    key={`footer_btn_${index}`}
                    data-pendoid={`footer_btn_${index}`}
                  >
                    <Button data-pendoid={btn.pendoid} {...btn}>
                      {btn.text}
                    </Button>
                  </LinkStyled>
                ) : (
                  <Button
                    data-pendoid={`footer_btn_${index}-${btn.text}`}
                    key={`footer_btn_${index}`}
                    {...btn}
                  >
                    {btn.text}
                  </Button>
                )
              )}
            </FooterStyled>
          )}
        </ModalBoxStyled>
      </WrapperStyled>
    </FocusLock>
  );
};

Modal.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  titleIcon: PropTypes.func,
  buttons: PropTypes.array,
  size: PropTypes.oneOf(['large', 'medium', 'small']).isRequired,
  closeModal: PropTypes.func,
  isScrollable: PropTypes.bool,
  hideFooter: PropTypes.bool
};

Modal.defaultProps = {
  title: '',
  buttons: [],
  size: 'medium',
  closeModal: () => {},
  isScrollable: false,
  hideFooter: false
};

export default Modal;
