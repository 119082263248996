import { Button, Input } from 'components';
import styled from 'styled-components/macro';
import ExternalLinkIcon from 'assets/icons/chain-link';

export const CopyButton = styled(Button)`
  ${({ theme: { colors } }) => `
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    box-shadow: 0 0 0 1px ${colors.grey.dark};
    z-index: 1;

    &:hover {
      box-shadow: 0 0 0 1px ${colors.grey.dark};
    }

    &:focus {
      box-shadow: 0 0 0 1px ${colors.grey.darkest};
      border: solid 1px${colors.grey.darkest};
    }
  `};
`;

export const VanityInput = styled(Input)`
  margin: 0;
  grid-column-start: 1;
  grid-column-end: 5;

  input {
    margin: 0;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    text-overflow: clip;
  }
`;

export const CopyContainerStyled = styled.div`
  display: inline-grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(5, 1fr);
  width: 100%;
`;

export const ExternalLinkIconStyled = styled(ExternalLinkIcon)`
  width: 1.75rem;
  padding-right: 0.5rem;
`;
