import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import {
  BreadcrumbContainerStyled,
  LinkStyled,
  ArrowRightStyled
} from './breadcrumb.styles';

interface IBreadcrumbProps {
  trail: { pathname?: string; title: string }[];
}

const Breadcrumb: FC<IBreadcrumbProps> = ({ trail }) => {
  const location = useLocation();

  return (
    <BreadcrumbContainerStyled>
      {trail.slice(0, -1)?.map(({ pathname, title }, index) => (
        <div key={`${title}-${index}`}>
          <LinkStyled
            ariaLabel={title}
            to={{
              ...location,
              pathname
            }}
          >
            {title}
          </LinkStyled>
          <ArrowRightStyled tabIndex={-1} />
        </div>
      ))}
      <span>{trail.at(-1)?.title}</span>
    </BreadcrumbContainerStyled>
  );
};

export default Breadcrumb;
