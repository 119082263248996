import colors from './colors';
import spacing from './spacing';

const table = {
  borderCollapse: 'collapse',
  display: 'block',
  margin: '0 auto',
  overflowX: 'auto',
  overflowY: 'scroll',
  padding: '0',
  position: 'relative',
  tableLayout: 'fixed',
  whiteSpace: 'nowrap'
};

const thead = {
  color: colors.grey?.dark,
  padding: `${spacing?.(2)} ${spacing?.(1)}`,
  textAlign: 'left'
};

const trThead = {
  border: '1px solid transparent',
  borderTopColor: colors.grey?.lighter,
  minWidth: {
    desktop: '1240px',
    default: '1024px'
  },
  hover: {
    backgroundColor: colors.grey?.lighter,
    borderBottomColor: 'transparent',
    borderTopColor: 'transparent',
    th: {
      backgroundColor: colors.grey?.lighter,
      color: colors.grey?.default
    }
  },
  lastChild: {
    borderBottomColor: colors.grey?.lighter
  }
};

const td = {
  backgroundColor: {
    checked: colors.grey?.lighter,
    default: colors.grey?.lightest
  },
  color: colors.primary?.darkest,
  padding: `${spacing?.(2)} ${spacing?.(1)}`,
  textAlign: 'left',
  wordWrap: 'break-word',
  wordBreak: 'break-word',
  whiteSpace: 'normal'
};

const trTbody = {
  border: '1px solid transparent',
  borderBottomColor: colors.grey?.lighter,
  borderTopColor: colors.grey?.lighter,
  minWidth: {
    desktop: '1240px',
    default: '1024px'
  },
  hover: {
    backgroundColor: colors.primary?.lightest,
    td: {
      backgroundColor: colors.primary?.lightest
    }
  }
};

const selectedItem = {
  backgroundColor: {
    show: '#f3f6fc',
    default: 'transparent'
  },
  height: { show: `${spacing?.(9)}`, default: '0px' },
  padding: `0 0 0 ${spacing?.(2)}`,
  width: '100%'
};

const actionsThead = {
  backgroundColor: colors.grey?.lightest,
  color: colors.grey?.dark,
  maxWidth: `${spacing?.(12.5)}`,
  minWidth: `${spacing?.(12.5)}`,
  padding: `${spacing?.(2)} ${spacing?.(1)}`,
  position: 'sticky',
  right: '-1px',
  textAlign: 'left',
  width: `${spacing?.(12.5)}`
};

const actionsTbody = {
  backgroundColor: {
    checked: colors.grey?.lighter,
    default: colors.grey?.lightest
  },
  color: colors.primary?.darkest,
  maxWidth: `${spacing?.(12.5)}`,
  minWidth: `${spacing?.(12.5)}`,
  padding: `${spacing?.(2)} ${spacing?.(1)}`,
  position: 'sticky',
  right: '-1px',
  textAlign: 'left',
  width: `${spacing?.(12.5)}`
};

const sortArrow = {
  display: 'inline-block',
  marginBottom: `${spacing?.(0.25)}`,
  marginRight: `${spacing?.(0.5)}`,
  transform: {
    desc: 'rotate(180deg)',
    default: 'rotate(0deg)'
  },
  verticalAlign: 'middle',
  hover: {
    cursor: 'pointer'
  }
};

export default {
  table,
  thead,
  trThead,
  trTbody,
  td,
  selectedItem,
  actionsThead,
  actionsTbody,
  sortArrow
};
