import React from 'react';

export default ({ className, color = '#666666' }) => (
  <svg
    className={className}
    width="21px"
    height="21px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Help</title>
    <path
      d="M9 9.0004C9.00011 8.45043 9.15139 7.91107 9.43732 7.44127C9.72325 6.97147 10.1328 6.58931 10.6213 6.33655C11.1097 6.0838 11.6583 5.97018 12.2069 6.00812C12.7556 6.04605 13.2833 6.23408 13.7323 6.55165C14.1813 6.86922 14.5344 7.30411 14.7529 7.80879C14.9715 8.31347 15.0471 8.86852 14.9715 9.41327C14.8959 9.95802 14.6721 10.4716 14.3244 10.8977C13.9767 11.3238 13.5185 11.6461 13 11.8294C12.7074 11.9329 12.4541 12.1245 12.275 12.3779C12.0959 12.6314 11.9998 12.9341 12 13.2444V14.2504"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 18C11.7929 18 11.625 17.8321 11.625 17.625C11.625 17.4179 11.7929 17.25 12 17.25"
      stroke={color}
      strokeWidth="1.5"
    />
    <path
      d="M12 18C12.2071 18 12.375 17.8321 12.375 17.625C12.375 17.4179 12.2071 17.25 12 17.25"
      stroke={color}
      strokeWidth="1.5"
    />
    <path
      d="M12 23.25C18.2132 23.25 23.25 18.2132 23.25 12C23.25 5.7868 18.2132 0.75 12 0.75C5.7868 0.75 0.75 5.7868 0.75 12C0.75 18.2132 5.7868 23.25 12 23.25Z"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);
