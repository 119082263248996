export default {
  signin_2fa_email_title: 'Nous vous avons envoyé le code de vérification',
  signin_2fa_email_message:
    'Vérifiez dans votre boîte courriel si vous avez reçu le code.',
  signin_title_credentials: 'Bienvenue sur Acuity Insights',
  signin_button: 'Se connecter',
  signin_reset_password: "J'ai oublié mon mot de passe",
  signin_modal_error_title: `La création du compte n'est pas terminée`,
  signin_modal_error_message: `Bonjour ! La création de votre compte n'est pas encore terminée.`,
  signin_modal_error_message2: `Cliquez sur le bouton ci-dessous pour continuer le processus d'inscription.`,
  signin_modal_error_button: `Terminer la création du compte`,
  signin_title_2fa: 'Authentification à deux facteurs',
  signin_remember: 'Se souvenir de cet appareil pendant 30 jours',
  signin_sms_message: `Pour confirmer qu’il s’agit bien de vous, nous avons envoyé un message texte à`,
  signin_sms_call: `Pour confirmer qu’il s’agit bien de vous, un appel automatisé est en cours pour`,
  signin_phone_common:
    'Pour confirmer qu’il s’agit bien de vous, nous avons envoyé un code de vérification à',
  signin_invalid_2fa_phone_title: 'Erreur d’envoi par SMS / Téléphone',
  signin_invalid_2fa_phone_message: `Sélectionnez une autre méthode de vérification de la connexion`,
  signin_invalid_2fa_email_title: 'Erreur de livraison courriel',
  signin_page_title: 'Se connecter',
  signin_email_message:
    'Nous avons envoyé un courriel de vérification à l’adresse enregistrée.'
};
