import account from './account';
import applicants from './applicants';
import buttons from './buttons';
import common from './common';
import duetProfile from './duet-profile';
import forbidden from './403';
import notFound from './404';
import maintenance from './503';
import files from './files';
import home from './home';
import inputs from './inputs';
import navbar from './navbar';
import pagination from './pagination';
import reset from './reset';
import selectors from './selectors';
import signin from './signin';
import signup from './signup';
import snapshotTraining from './snapshot-training';
import tables from './tables';
import token_expired from './token_expired';
import users from './users';
import mashupGrid from './mashupGrid';
import manageUsers from './manageUsers';
import granularPermissionKeys from './granularPermissionKeys';

export default {
  ...account,
  ...applicants,
  ...buttons,
  ...common,
  ...duetProfile,
  ...forbidden,
  ...notFound,
  ...maintenance,
  ...files,
  ...home,
  ...inputs,
  ...navbar,
  ...pagination,
  ...reset,
  ...selectors,
  ...signin,
  ...signup,
  ...snapshotTraining,
  ...tables,
  ...token_expired,
  ...users,
  ...mashupGrid,
  ...manageUsers,
  ...granularPermissionKeys
};
