import { IProgramFilterAtom } from '@types';
import { atom, selector } from 'recoil';
import { PERMISSIONS, STORAGE_KEY, PERMISSION_PRODUCTS } from 'shared/enums';

export const programFilterAtom = atom<IProgramFilterAtom>({
  key: 'programFilter',
  default: {
    admissionCycle: '',
    schoolId: '',
    schoolLabel: '',
    testMasterId: '',
    duetDeadlineAt: null,
    salesforceAccountId: '',
    userDirectory: '',
    products: []
  }
});

export const userPermissionsPerSchool = atom({
  key: 'userPermissionsPerSchool',
  default: []
});

export const userPermissionsFiltered = selector({
  key: 'userPermissionsFiltered',
  get: ({ get }) => {
    const permissionGroupMapping = {
      [PERMISSIONS.REVIEW_SNAPSHOT]: PERMISSION_PRODUCTS.SNAPSHOT,
      [PERMISSIONS.MANAGE_SNAPSHOT_REVIEWERS]: PERMISSION_PRODUCTS.SNAPSHOT,
      [PERMISSIONS.TAKE_DUET]: PERMISSION_PRODUCTS.DUET,
      [PERMISSIONS.VIEW_DUET_PROFILE]: PERMISSION_PRODUCTS.DUET
    };
    const defaultSchoolId =
      JSON.parse(localStorage.getItem(STORAGE_KEY.PROGRAM_FILTER))?.schoolId ||
      '';

    const { products = [], schoolId = defaultSchoolId } =
      get(programFilterAtom);

    const userPermissions = get(userPermissionsPerSchool)
      ?.find(school => school.schoolId === schoolId)
      ?.keys?.filter(permission => {
        return (
          !(permission in permissionGroupMapping) ||
          products.includes(permissionGroupMapping[permission])
        );
      });

    return userPermissions || [];
  }
});
