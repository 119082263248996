import { USER_ROLES } from 'shared/enums';

export const formatRole = ({ role, t } = {}) => {
  const options = {};
  options[USER_ROLES.ADMIN] = USER_ROLES.ADMIN;
  options[USER_ROLES.SCHOOL_ADMIN] = t?.('common_role_program_admin');
  options[USER_ROLES.TEAM_MEMBER] = t?.('common_role_team_member');
  return options[role] || role;
};

export const formatRoles = ({ roles = [], t } = {}) =>
  roles.map(role => ({
    label: formatRole({ role: role.label, t }),
    value: role.value
  }));

export const isSchoolAdmin = (roles = []) => {
  return [USER_ROLES.SCHOOL_ADMIN, 'Program Admin'].some(adminRole =>
    roles.includes(adminRole)
  );
};

export const isAdmin = (roles = []) => {
  return roles.includes(USER_ROLES.ADMIN);
};

export const getSingleRole = (roles = []) => {
  if (roles.includes(USER_ROLES.ADMIN)) {
    return USER_ROLES.ADMIN;
  }
  if (isSchoolAdmin(roles)) {
    return 'program admin';
  }
  return USER_ROLES.REVIEWER;
};
