export default {
  reset_email_title: 'Reset Your Password',
  reset_email_return: 'Return to login',
  reset_email_message:
    'Please check your inbox. A password reset email was sent to',
  reset_password_title: 'Reset Your Password',
  reset_password_success_title: 'Password reset success!',
  reset_password_success_1: 'Your password has been reset successfully.',
  reset_password_success_2: 'Press the button below to sign into your account.',
  reset_password_page_title: 'Reset Password',
  reset_password_error:
    'Failed to send email. Please try again or contact support.'
};
