import colors from './colors';
import config from './config';
import spacing from './spacing';

const item = {
  border: '2px solid transparent',
  color: {
    disabled: colors.grey?.default,
    default: 'inherit'
  },
  padding: `${spacing?.(1)} ${spacing?.(2)}`,
  outline: 'none',
  hover: {
    backgroundColor: {
      disabled: 'transparent',
      default: colors.outline?.lighter
    },
    borderColor: 'transparent'
  },
  active: {
    backgroundColor: {
      disabled: 'transparent',
      default: colors.primary?.lighter
    },
    borderColor: 'transparent'
  },
  focus: {
    borderColor: colors.primary?.default
  }
};

const options = {
  backgroundColor: '#ffffff',
  borderRadius: config.borderRadius,
  boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.15)',
  margin: `${spacing?.(0.25)} 0`,
  minWidth: `${spacing?.(25)}`,
  padding: `${spacing?.(1)} 0`,
  position: 'absolute',
  right: '-1px',
  top: `${spacing?.(5)}`,
  width: '100%'
};

const group = {
  backgroundColor: colors.outline?.default,
  border: `1px solid ${colors.outline?.dark}`,
  borderRadius: config.borderRadius,
  color: colors.grey?.darkest,
  minWidth: `${spacing?.(14)}`,
  padding: {
    large: `${spacing?.(1)} ${spacing?.(2)}`,
    medium: `${spacing?.(0.5)} ${spacing?.(2)}`,
    small: `${spacing?.(0.5)} ${spacing?.(1.5)}`
  },
  outline: 'none',
  hover: {
    backgroundColor: colors.outline?.lighter,
    borderColor: colors.outline?.dark,
    boxShadow: 'none'
  },
  focus: {
    backgroundColor: colors.outline?.lighter,
    borderColor: 'transparent',
    boxShadow: `0 0 0 2px ${colors.primary?.default}`
  }
};

export default {
  group,
  item,
  options
};
