import styled from 'styled-components/macro';
import { TextButton } from 'components';

import NextIcon from 'assets/icons/next-right';
import ArrowLinkIcon from 'assets/icons/arrow-link';

export const BackIconStyled = styled(NextIcon)`
  padding-left: 4px;
  padding-bottom: 1px;
  transform: rotate(180deg);
`;

export const ArrowLinkStyled = styled(ArrowLinkIcon)`
  margin-left: 4px;
`;

export const TextButtonStyled = styled(TextButton)`
  margin-bottom: ${props => props.customMargin || '24px'};
`;

export const ButtonsGroupStyled = styled.div`
  ${props => `
    display: flex;
    justify-content: ${
      props.buttonsAlignment === 'end' ? 'flex-end' : 'space-between'
    };
    & button:first-child {
      margin-right: ${props.buttonsAlignment === 'end' ? '8px' : '0'};
    }
  `}
`;

export const MessageStyled = styled.div`
  ${({ theme, customMargin }) => `
    color: ${theme.colors?.grey?.darker};
    margin-bottom: ${customMargin || '24px'};
  `};
`;

export const NextIconStyled = styled(NextIcon)`
  padding-left: 4px;
  padding-top: 1px;
`;

export const FormStyled = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`;
