import {
  AlertSuccess,
  AlertWarning,
  AlertCelebrate,
  AlertError,
  AlertInfo
} from '../icons';

/**
 * Returns the appropriate alert icon component based on the given category.
 *
 * @param {string} [category='info'] - The category of the alert
 *    (e.g., 'success', 'info', 'error', 'alert', 'celebrate').
 * @returns {JSX.Element} The corresponding alert icon component.
 */
const getAlertIcon = category => {
  let component;
  switch (category) {
    case 'success':
      component = AlertSuccess;
      break;
    case 'info':
      component = AlertInfo;
      break;
    case 'error':
      component = AlertError;
      break;
    case 'alert':
      component = AlertWarning;
      break;
    case 'celebrate':
      component = AlertCelebrate;
      break;
    default:
      component = AlertInfo;
      break;
  }

  return component;
};

export default getAlertIcon;
