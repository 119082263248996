import navbar from './navbar';

export default {
  applicants_title: navbar.navbar_assessment_results,
  applicants_subtitle: 'Access applicant results',
  applicants_filter_zScore: 'Filter Casper z-Score',
  applicants_filter_zScore_greaterOrEqual: 'is greater or equal to',
  applicants_filter_zScore_greater: 'is greater than',
  applicants_filter_zScore_lesserOrEqual: 'is less than or equal to',
  applicants_filter_zScore_lesser: 'is less than',
  applicants_filter_zScore_between: 'is in between',
  applicants_filter_zScore_exactly: 'is exactly',
  applicants_filter_apply: 'Apply filter',
  applicants_columns_configuration: 'Configure columns',
  applicants_filter_reset: 'Reset',
  applicants_axis_x: 'Casper z-Score',
  applicants_axis_y: 'Number of applicants',
  applicants_table_title: 'Applicants',
  applicants_table_search: 'Search by name, email or ID',
  applicants_table_export: 'Export',
  applicants_table_export_all: 'All applicants in .csv',
  applicants_table_export_selected: 'Selected applicants in .csv',
  applicants_table_applicant_selected: '{{count}} applicant selected',
  applicants_table_applicant_selected_plural: '{{count}} applicants selected',
  applicants_export_message1:
    'Are you sure you want to export {{totalApplicants}} applicant?',
  applicants_export_message1_plural:
    'Are you sure you want to export {{totalApplicants}} applicants?',
  applicants_export_message2:
    'This will export all the columns and data for {{totalApplicants}} applicant.',
  applicants_export_message2_plural:
    'This will export all the columns and data for {{totalApplicants}} applicants.',
  applicants_export_title: 'Export applicants',
  applicants_export_selected_warning: 'Please select applicants to export.',
  applicants_red_flag_title: 'Red Flag Rater Comment',
  applicants_snapshot_modal_comments_title: 'S - Comment(s)',
  applicants_snapshot_modal_ratings_title: 'S - Score(s)',
  applicants_snapshot_modal_reviewer: 'Reviewer',
  applicants_snapshot_modal_comments: 'Comment',
  applicants_snapshot_modal_ratings: 'Score',
  applicants_config_modal_title: 'Configure Columns',
  applicants_config_modal_subtitle:
    'Select columns you would like to see in your Applicants table.',
  applicants_config_modal_alert:
    'Note: Changes applied here will be applied for all test cycles for this program and will be reflected for all users',
  applicants_config_modal_update_button: 'Update Columns',
  applicants_config_modal_success:
    'Columns have been configured successfully for all team members'
};
