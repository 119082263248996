import React from 'react';
import PropTypes from 'prop-types';

import {
  InputWrapperStyled,
  InputContentWrapperStyled,
  InputStyled,
  LabelStyled,
  MessageStyled
} from './input.styles';

const Input = React.forwardRef(
  (
    {
      type,
      name,
      label,
      value,
      help,
      error,
      errorMessage,
      errorPlaceholder,
      disabled,
      readOnly,
      placeholder,
      required,
      className,
      handleChange,
      onFocus,
      onBlur,
      autoFocus,
      showIcon = false,
      icon: Icon,
      defaultValue,
      isMessageBelowInput = true,
      ...rest
    },
    ref
  ) => {
    const message = error ? errorMessage : help;
    const errorPlaceholderMessage = errorPlaceholder ? 'message' : '';

    const labelId = `${name?.replaceAll(' ', '-')}-label`;

    return (
      <InputWrapperStyled className={className}>
        <InputContentWrapperStyled>
          <LabelStyled htmlFor={name} data-testid="input-label">
            {label ? (
              <span id={labelId}>{label}</span>
            ) : (
              <span
                id={labelId}
                style={{ visibility: 'hidden', height: '0px' }}
              >
                {placeholder}
              </span>
            )}
            {!isMessageBelowInput && (
              <MessageStyled
                error={error}
                hide={!Boolean(message)}
                data-testid="input-message"
              >
                {message || errorPlaceholderMessage}
              </MessageStyled>
            )}
            <InputStyled
              id={name}
              ref={ref}
              type={type}
              name={name}
              aria-labelledby={labelId}
              aria-required={required}
              value={value}
              onChange={handleChange}
              disabled={disabled}
              readOnly={readOnly}
              placeholder={placeholder}
              required={required}
              error={error}
              autoFocus={autoFocus}
              onFocus={onFocus}
              onBlur={onBlur}
              data-testid={rest?.['data-testid'] || 'input'}
              data-pendoid={rest?.['data-pendoid'] || 'input'}
              defaultValue={defaultValue}
            />
          </LabelStyled>
          {showIcon && <Icon />}
        </InputContentWrapperStyled>
        {isMessageBelowInput && (
          <MessageStyled
            error={error}
            hide={!Boolean(message)}
            data-testid="input-message"
          >
            {message || errorPlaceholderMessage}
          </MessageStyled>
        )}
      </InputWrapperStyled>
    );
  }
);

Input.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  help: PropTypes.string,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  errorPlaceholder: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool,
  required: PropTypes.bool.isRequired,
  placeholder: PropTypes.string,
  handleChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  autoFocus: PropTypes.bool,
  defaultValue: PropTypes.string
};

Input.defaultProps = {
  type: 'text',
  disabled: false,
  readOnly: false,
  required: false,
  autoFocus: false,
  error: false,
  errorMessage: '',
  errorPlaceholder: false
};

export default Input;
