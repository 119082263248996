import styled from 'styled-components/macro';

export const TabStyled = styled.div`
  ${({ theme: { tabs = {}, typography }, active }) => `
    ${typography?.(16, active ? '700' : '400')};
    align-items: center;
    box-shadow: ${tabs.boxShadow?.[active ? 'active' : 'default']};
    color: ${tabs.color?.[active ? 'active' : 'default']};
    cursor: ${active ? 'default' : 'pointer'};
    display: flex;
    height: ${tabs.height};
    justify-content: space-between;
    padding: ${tabs.padding};
    ${
      !active
        ? `
        &:hover {
          color: ${tabs.hover?.color};
        }
        
        &:active {
          text-decoration: ${tabs.active?.textDecoration};
        }
      `
        : ''
    }
  `};
`;

export const TextStyled = styled.span`
  line-height: 1;
  &:first-child {
    padding-right: ${props => props.theme?.spacing?.(0.5)};
  }
`;
