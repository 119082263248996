import React from 'react';

export default ({ className, ariaHidden = true, title = 'Chain link' }) => (
  <svg
    className={className}
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden={ariaHidden}
  >
    {!ariaHidden && <title>{title}</title>}
    <path
      d="M9.1526 16.0744L6.7551 18.4719C6.29 18.9138 5.67293 19.1602 5.03135 19.1602C4.38977 19.1602 3.7727 18.9138 3.3076 18.4719L2.52885 17.6931C2.29806 17.4644 2.11561 17.1917 1.99235 16.891C1.8691 16.5904 1.80754 16.268 1.81135 15.9431C1.80603 15.618 1.86689 15.2952 1.99024 14.9944C2.11358 14.6936 2.29684 14.4209 2.52885 14.1931L7.60385 9.1094C8.06259 8.65521 8.68205 8.40043 9.3276 8.40043C9.97315 8.40043 10.5926 8.65521 11.0514 9.1094L11.8301 9.8969C11.8925 10.0157 11.9814 10.1186 12.0899 10.1975C12.1984 10.2765 12.3236 10.3295 12.4558 10.3523C12.5881 10.3752 12.7238 10.3673 12.8525 10.3293C12.9812 10.2913 13.0995 10.2242 13.1981 10.1332C13.2967 10.0422 13.3731 9.92975 13.4213 9.80452C13.4696 9.67929 13.4883 9.54464 13.4762 9.41099C13.4641 9.27734 13.4214 9.14828 13.3514 9.03377C13.2814 8.91927 13.186 8.8224 13.0726 8.75065L12.2939 7.9719C11.508 7.18698 10.4427 6.74609 9.33198 6.74609C8.22126 6.74609 7.15597 7.18698 6.3701 7.9719L1.28635 13.0469C0.501431 13.8328 0.0605469 14.8981 0.0605469 16.0088C0.0605469 17.1195 0.501431 18.1848 1.28635 18.9706L2.0651 19.7494C2.4538 20.1404 2.91597 20.4507 3.42504 20.6625C3.9341 20.8742 4.48 20.9832 5.03135 20.9832C5.5827 20.9832 6.1286 20.8742 6.63766 20.6625C7.14673 20.4507 7.6089 20.1404 7.9976 19.7494L10.3864 17.3606C10.4684 17.2793 10.5335 17.1825 10.5779 17.0759C10.6223 16.9693 10.6452 16.8549 10.6452 16.7394C10.6452 16.6239 10.6223 16.5095 10.5779 16.4029C10.5335 16.2963 10.4684 16.1995 10.3864 16.1181C10.2283 15.9492 10.0097 15.8498 9.77851 15.8416C9.5473 15.8334 9.32226 15.9171 9.1526 16.0744Z"
      fill="white"
    />
    <path
      d="M19.7139 2.0654L18.9264 1.28665C18.1394 0.503866 17.0745 0.0644531 15.9645 0.0644531C14.8545 0.0644531 13.7897 0.503866 13.0027 1.28665L10.5002 3.8329C10.4086 3.91134 10.3342 4.00787 10.2817 4.11643C10.2291 4.22499 10.1996 4.34323 10.195 4.46373C10.1903 4.58424 10.2106 4.7044 10.2546 4.81668C10.2986 4.92897 10.3653 5.03095 10.4506 5.11622C10.5359 5.20149 10.6378 5.26822 10.7501 5.31221C10.8624 5.3562 10.9826 5.37651 11.1031 5.37185C11.2236 5.3672 11.3418 5.33768 11.4504 5.28516C11.5589 5.23264 11.6555 5.15825 11.7339 5.06665L14.2802 2.52915C14.7378 2.07284 15.3577 1.8166 16.0039 1.8166C16.6502 1.8166 17.27 2.07284 17.7277 2.52915L18.5064 3.31665C18.7384 3.54445 18.9217 3.81706 19.045 4.11791C19.1684 4.41875 19.2292 4.74155 19.2239 5.06665C19.2277 5.39155 19.1662 5.7139 19.0429 6.01453C18.9196 6.31516 18.7372 6.58794 18.5064 6.81665L13.4314 11.8917C13.2027 12.1224 12.9299 12.3049 12.6293 12.4281C12.3287 12.5514 12.0063 12.613 11.6814 12.6092C11.3564 12.614 11.0337 12.5529 10.733 12.4296C10.4322 12.3062 10.1595 12.1233 9.93141 11.8917C9.76708 11.8071 9.58021 11.7768 9.3976 11.8051C9.21498 11.8333 9.04599 11.9186 8.91484 12.0488C8.78369 12.179 8.69712 12.3473 8.66754 12.5297C8.63795 12.7121 8.66688 12.8992 8.75016 13.0642C9.13828 13.4559 9.60035 13.7667 10.1095 13.9785C10.6187 14.1903 11.1649 14.2989 11.7164 14.2979C12.2659 14.2993 12.8102 14.1918 13.3178 13.9816C13.8255 13.7713 14.2864 13.4624 14.6739 13.0729L19.7577 7.98915C20.5426 7.20329 20.9835 6.13799 20.9835 5.02728C20.9835 3.91656 20.5426 2.85127 19.7577 2.0654H19.7139Z"
      fill="white"
    />
  </svg>
);
