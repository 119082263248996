import { useEffect } from 'react';

export default ({
  ref,
  handleClose,
  currentValue,
  cleanSelectedOption = () => {}
}) => {
  useEffect(() => {
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target) && currentValue) {
        handleClose(!currentValue);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      cleanSelectedOption();
    };
  }, [ref, handleClose, currentValue, cleanSelectedOption]);
};
