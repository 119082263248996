import React from 'react';
import PropTypes from 'prop-types';
import getAlertIcon from '../../assets/helpers/getAlertIcon';
import { AlertStyled, TextStyled, TitleStyled } from './alert.styles';

const Alert = ({ className, border, category, title, text, pendoid }) => {
  const AlertIcon = getAlertIcon(category);
  return (
    <AlertStyled
      className={className}
      border={border}
      category={category}
      data-testid="alert"
      data-pendoid={`alert-${pendoid}`}
    >
      <span>
        <AlertIcon data-testid="alert-icon" />
      </span>
      {title ? (
        <div>
          <TitleStyled data-testid="alert-title">{title}</TitleStyled>
          <TextStyled data-testid="alert-text">{text}</TextStyled>
        </div>
      ) : (
        <TextStyled data-testid="alert-text">{text}</TextStyled>
      )}
    </AlertStyled>
  );
};

Alert.propTypes = {
  border: PropTypes.bool.isRequired,
  category: PropTypes.oneOf(['success', 'info', 'alert', 'error', 'celebrate'])
    .isRequired,
  className: PropTypes.string
};

Alert.defaultProps = {
  border: false,
  category: 'info',
  className: ''
};

export default Alert;
