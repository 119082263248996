import React from 'react';

export default () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Circle Step 2</title>
      <circle
        cx="16"
        cy="16"
        r="15"
        fill="#364699"
        stroke="#364699"
        strokeWidth="2"
      />
      <path
        d="M19.2554 18.648C19.41 18.648 19.5327 18.6933 19.6234 18.784C19.714 18.8747 19.7594 18.992 19.7594 19.136V20H12.1114V19.512C12.1114 19.4107 12.1327 19.3067 12.1754 19.2C12.218 19.0933 12.2847 18.9947 12.3754 18.904L16.0474 15.216C16.3514 14.9067 16.6287 14.6107 16.8794 14.328C17.13 14.04 17.3434 13.752 17.5194 13.464C17.6954 13.176 17.8314 12.8853 17.9274 12.592C18.0234 12.2933 18.0714 11.976 18.0714 11.64C18.0714 11.304 18.018 11.0107 17.9114 10.76C17.8047 10.504 17.658 10.2933 17.4714 10.128C17.29 9.96267 17.074 9.84 16.8234 9.76C16.5727 9.67467 16.3034 9.632 16.0154 9.632C15.722 9.632 15.4527 9.67467 15.2074 9.76C14.962 9.84533 14.7434 9.96533 14.5514 10.12C14.3647 10.2693 14.2074 10.448 14.0794 10.656C13.9514 10.864 13.8607 11.0933 13.8074 11.344C13.7434 11.5307 13.6554 11.656 13.5434 11.72C13.4367 11.7787 13.2847 11.7947 13.0874 11.768L12.3434 11.64C12.418 11.1173 12.562 10.656 12.7754 10.256C12.994 9.85067 13.266 9.512 13.5914 9.24C13.922 8.968 14.298 8.76267 14.7194 8.624C15.1407 8.48 15.5967 8.408 16.0874 8.408C16.5727 8.408 17.026 8.48 17.4474 8.624C17.8687 8.768 18.234 8.97867 18.5434 9.256C18.8527 9.528 19.0954 9.86133 19.2714 10.256C19.4474 10.6507 19.5354 11.0987 19.5354 11.6C19.5354 12.0267 19.4714 12.424 19.3434 12.792C19.2154 13.1547 19.042 13.5013 18.8234 13.832C18.6047 14.1627 18.3514 14.4853 18.0634 14.8C17.7807 15.1147 17.4794 15.432 17.1594 15.752L14.1354 18.84C14.3487 18.7813 14.5647 18.736 14.7834 18.704C15.002 18.6667 15.2127 18.648 15.4154 18.648H19.2554Z"
        fill="white"
      />
    </svg>
  );
};
