import styled from 'styled-components/macro';

export const DropdownStyled = styled.div`
  height: 100%;
`;

export const MenuStyled = styled.div`
  background-color: white;
  border-radius: 4px;
  margin-top: 8px;
  position: absolute;
  z-index: 2;
`;
