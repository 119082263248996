import React from 'react';

export default ({ className, testId = 'arrowDropdown', color = 'black' }) => (
  <svg
    className={className}
    width="7"
    height="4"
    viewBox="0 0 7 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid={testId}
    title="arrow-dropdown"
  >
    <path
      d="M3.03155 3.8337C3.24099 4.00472 3.55516 4.00472 3.74366 3.8337L6.59209 1.24942C6.80153 1.05939 6.80153 0.774363 6.59209 0.603344L6.13131 0.166296C5.92187 -0.00472293 5.6077 -0.00472293 5.4192 0.166296L3.3876 2.0095L1.37695 0.166296C1.18845 -0.00472304 0.853341 -0.00472305 0.664842 0.166296L0.183122 0.603344C-0.00537669 0.774363 -0.00537669 1.05939 0.183122 1.24941L3.03155 3.8337Z"
      fill={color}
    />
  </svg>
);
