import React, { FC } from 'react';
import { ArrowDropdown } from 'assets/icons';
import { Autocomplete } from '@mui/material';
import { ISelectV2Props } from './types';

import {
  WrapperStyled,
  LabelStyled,
  MessageStyled,
  TextFieldStyled,
  OptionStyled
} from './select.styles';

const SelectV2: FC<ISelectV2Props> = React.forwardRef(
  (
    {
      className,
      name,
      label,
      placeholder,
      selectedOption,
      hideArrow,
      options,
      handleChange,
      handleInputChange,
      isSearchable,
      error,
      errorMessage,
      errorPlaceholder,
      isDisabled,
      hideLabel,
      filterOptions,
      noOptionsMessage,
      useSecondaryColor,
      menuListHeight,
      ...props
    },
    ref
  ) => {
    const message = error ? errorMessage : '';
    const errorPlaceholderMessage = errorPlaceholder ? 'message' : '';

    const inputId = `${name?.replaceAll(' ', '-')}`;

    return (
      <WrapperStyled className={className} error={error}>
        <LabelStyled htmlFor={inputId} hideLabel={hideLabel}>
          {label}
        </LabelStyled>
        <Autocomplete
          data-pendoid={props['data-pendoid']}
          data-testid={props['data-testid'] || name}
          id={inputId}
          ref={ref}
          autoComplete={true}
          disablePortal
          options={options}
          value={selectedOption}
          filterOptions={filterOptions}
          isOptionEqualToValue={(option, value) => option.name === value?.value}
          getOptionLabel={option => option?.label || ''}
          popupIcon={hideArrow ? null : <ArrowDropdown />}
          renderInput={params => {
            if (handleInputChange) {
              params['onChange'] = handleInputChange;
            }
            return (
              <TextFieldStyled
                {...params}
                disabled={isDisabled}
                InputProps={{
                  ...params.InputProps,
                  readOnly: !isSearchable
                }}
                inputProps={{
                  ...params.inputProps,
                  'aria-label': label
                }}
                placeholder={placeholder}
                multiple={props['multiple']}
              />
            );
          }}
          disableClearable={true}
          onChange={(_, value) => handleChange(value)}
          disabled={isDisabled}
          clearOnBlur={true}
          aria-label={name}
          openOnFocus={true}
          noOptionsText={noOptionsMessage}
          renderOption={(props, option, _, ownerState) => {
            return (
              <OptionStyled
                $secondaryColor={useSecondaryColor}
                selected={option.name === selectedOption?.value}
                component="li"
                data-testid={`dropdown_option_${option.label}`}
                data-pendoid={`${props['data-pendoid']}-${option.label}`}
                {...props}
              >
                {ownerState.getOptionLabel(option)}
              </OptionStyled>
            );
          }}
          ListboxProps={{
            sx: {
              height: menuListHeight,
              overflowX: 'hidden',
              overflowY: 'scroll',
              borderRadius: '0px',
              filter: 'drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.15))'
            }
          }}
          {...props}
        />
        <MessageStyled
          error={error}
          hide={!Boolean(message)}
          data-testid="select-message"
        >
          {message || errorPlaceholderMessage}
        </MessageStyled>
      </WrapperStyled>
    );
  }
);

SelectV2.defaultProps = {
  hideArrow: false,
  isDisabled: false,
  options: [],
  isSearchable: true,
  hideLabel: false,
  handleChange: () => {},
  menuListHeight: '170',
  useSecondaryColor: false,
  noOptionsMessage: 'No options',
  placeholder: 'Select...'
};

export default SelectV2;
