import React from 'react';

import Book from 'assets/icons/book';

import { IconWrapperStyled } from './icon-wrapper.styles';

interface iBookIcon {
  tabIndex?: number;
  handleClick: () => void;
  'data-pendoid': string;
  'data-testid': string;
  'aria-label': string;
  title: string;
}

const BookIcon = ({
  tabIndex,
  handleClick = () => {},
  ...props
}: iBookIcon) => {
  return (
    <IconWrapperStyled
      role="button"
      tabIndex={tabIndex >= 0 ? tabIndex : -1}
      onClick={handleClick}
      {...props}
    >
      <Book />
    </IconWrapperStyled>
  );
};

export default BookIcon;
