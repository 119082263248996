/**
 * @description
 * Imports css and js resources from Qlik server
 * This pre-requisite is required before starting a websocket connection
 *
 * @param {String} prefix  // Refers to the virtual proxy prefix
 */
const importQlikResources = async ({ prefix }) => {
  const { REACT_APP_QLIK_URL } = process.env;
  return new Promise((resolve, reject) => {
    try {
      // Key is used to cache bust the resource forcing the browser to fetch the latest script
      // It can just be any random string
      const key = Math.random().toString(36).substring(7);
      const qlikUrl = `${REACT_APP_QLIK_URL}/${prefix}/resources/assets/external/requirejs/require.js?key=${key}`;
      const scriptTag = document.createElement('script');
      scriptTag.setAttribute('src', qlikUrl);
      scriptTag.setAttribute('id', 'qlik-require-js');
      document.head.appendChild(scriptTag);

      // Need to load the custom qlik stylesheet that targets '.mashup-container' instead of '*' and 'body'. This ensures we don't alter Insights' styles when loading.
      // Found at -> C:\Program Files\Qlik\Sense\Client\autogenerated\qlik-styles-for-mashup.css on qlik server.
      // const qlikCssUrl = `${REACT_APP_QLIK_URL}/${prefix}/resources/autogenerated/qlik-styles-for-mashup.css`;
      // const cssScriptTag = document.createElement('link');
      // cssScriptTag.setAttribute('href', qlikCssUrl);
      // cssScriptTag.setAttribute('rel', 'stylesheet');
      // document.head.appendChild(cssScriptTag);

      scriptTag.onload = () => {
        if (window.require) {
          resolve();
        }
      };
    } catch (error) {
      console.log('Failed to get resources', error);
      reject(error);
    }
  });
};

export default importQlikResources;
