import React from 'react';
import PropTypes from 'prop-types';

import { LinkStyled } from './link.styles';

const Link = ({
  ariaLabel,
  className,
  to,
  children,
  onClick,
  tabIndex,
  ...props
}) => (
  <LinkStyled
    aria-label={ariaLabel}
    to={to}
    className={className}
    data-testid={props['data-testid'] || 'pagelink'}
    data-pendoid={props['data-pendoid'] || 'pagelink'}
    tabIndex={tabIndex}
    onClick={onClick}
  >
    {children}
  </LinkStyled>
);

Link.propTypes = {
  ariaLabel: PropTypes.string.isRequired,
  className: PropTypes.string,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  tabIndex: PropTypes.string
};

Link.defaultProps = {
  className: '',
  to: '/',
  tabIndex: '0'
};

export default Link;
