import { gql } from '@apollo/client';

export const USERS = gql`
  query getUsers(
    $page: Int!
    $limit: Int!
    $search: String
    $sortField: String
    $sortDir: String
  ) {
    users(
      page: $page
      limit: $limit
      search: $search
      sortField: $sortField
      sortDir: $sortDir
    ) {
      docs {
        _id
        emails {
          address
        }
        profile {
          name
          school {
            _id
            name
          }
          schools {
            _id
            name
          }
          givenname
          surname
        }
        roles
        createdAt
        schoolPermissions {
          schoolId
          general {
            _id
            key
            label {
              en
              fr
            }
            selected
          }
          duet {
            _id
            key
            label {
              en
              fr
            }
            selected
          }
          snapshot {
            _id
            key
            label {
              en
              fr
            }
            selected
          }
        }
      }
      total
    }
  }
`;

export const GET_USERS = gql`
  query getUsers($ids: [String]) {
    getUsers(ids: $ids) {
      _id
      emails {
        address
      }
      profile {
        name
        givenname
        surname
        schools {
          _id
          name
        }
        lang
      }
      roles
      schoolPermissions {
        school {
          _id
          name
        }
        insightsPermissionIds {
          key
          label {
            en
            fr
          }
          category
        }
        availableProductsAndPermissions {
          name
          permissions {
            _id
            key
            label {
              en
              fr
            }
            category
          }
        }
        dataFilterSummary {
          product
          columns
          rows
        }
      }
      createdAt
      twoFactor {
        method
      }
      acceptanceHistory {
        dateAccepted
        checked
      }
    }
  }
`;

export const CURRENT_USER = gql`
  {
    user {
      _id
      uuid
      emails {
        address
      }
      profile {
        givenname
        surname
        school {
          _id
          name
        }
        schools {
          _id
          name
          applicationCycles
          productCycles {
            key
            applicationCycle
            testMasterId
            duetDeadlineAt
          }
          salesforceAccountId
          qlik {
            userDirectory
          }
          products
        }
        testMasterIds {
          admissionCycle
          testMasterId
          schoolId
        }
        lang
      }
      schoolPermissions {
        schoolId
        keys
      }
      roles
      twoFactor {
        method
      }
      featureSwitches {
        name
        value
      }
      hasPassword
      createdAt
    }
  }
`;

export const GET_USER_BY_ID = gql`
  query userById($_id: String!) {
    userById(_id: $_id) {
      _id
      emails {
        address
      }
      profile {
        name
        givenname
        surname
        schoolIds
        lang
      }
      roles
    }
  }
`;

export const GET_USER_BY_TOKEN = gql`
  query getUserByToken($token: String!) {
    getUserByToken(token: $token) {
      _id
      emails {
        address
      }
      roles
      onboardingPage
      twoFactor {
        countryCode
        phoneNumber
        method
      }
      featureSwitches {
        name
        value
      }
      createdAt
    }
  }
`;

export const SNAPSHOT_LINK = gql`
  query SnapshotLink($cycle: String!) {
    getSnapshotLink(cycle: $cycle)
  }
`;

export const DUET_LINK = gql`
  query DuetLink(
    $testMasterId: String
    $schoolId: String
    $applicationCycle: String
  ) {
    getDuetLink(
      testMasterId: $testMasterId
      schoolId: $schoolId
      applicationCycle: $applicationCycle
    )
  }
`;

export const DUET_TOKEN_CHECK = gql`
  query DuetTokenCheck($token: String) {
    duetTokenCheck(token: $token)
  }
`;

export const DUET_PARTICIPANT_RESULT = gql`
  query DuetParticipantResults(
    $schoolId: String
    $testMasterId: String
    $applicationCycle: String
  ) {
    duetParticipants(
      schoolId: $schoolId
      testMasterId: $testMasterId
      applicationCycle: $applicationCycle
    ) {
      _id
      surname
      givenname
      roles
      duetStatus
      email
    }
  }
`;

export const GET_TEAM_SUPPORT_LINK = gql`
  query GetTeamSupportLink($redirect: String) {
    getTeamSupportLink(redirect: $redirect)
  }
`;

export const GET_RECENT_DASHBOARD_VIEW_HISTORY = gql`
  query GetRecentDashboardViewHistory($schoolId: String!, $limit: Int!) {
    getRecentDashboardViewHistory(schoolId: $schoolId, limit: $limit) {
      url
      label {
        en
        fr
      }
    }
  }
`;

export const GET_FORMATIVE_LINK = gql`
  query GetFormativeLink {
    getFormativeLink
  }
`;
