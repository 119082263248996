import styled from 'styled-components/macro';

export const RadioWrapperStyled = styled.span`
  ${({ theme: { radios = {} } }) => `
    margin: ${radios.wrapper?.margin};
    text-align: ${radios.wrapper?.textAlign};
  `};
`;

export const RadioStyled = styled.div`
  ${({ theme: { radios = {}, transitions }, disabled, checked }) => `
    background-color: ${
      radios.item?.backgroundColor?.[disabled ? 'disabled' : 'default']
    };
    border: ${radios.item?.border};
    border-color: ${
      radios.item?.borderColor?.[
        disabled ? 'disabled' : checked ? 'checked' : 'default'
      ]
    };
    border-radius: ${radios.item?.borderRadius};
    display: ${radios.item?.display};
    height:${radios.item?.height};
    margin-right: ${radios.item?.marginRight};
    transition: all ${transitions?.fast} ease-in-out;
    width: ${radios.item?.width};
    &:hover {
      background-color:${
        radios.item?.hover?.backgroundColor?.[
          disabled ? 'disabled' : checked ? 'checked' : 'default'
        ]
      };
      border-color: ${
        radios.item?.hover?.borderColor?.[
          disabled ? 'disabled' : checked ? 'checked' : 'default'
        ]
      };
    }
    svg {
      margin-bottom: 1px;
      transition: all ${transitions?.fast} ease-in-out;
      visibility: ${checked ? 'visible' : 'hidden'};
    }
  `};
`;

export const LabelStyled = styled.label`
  ${({ theme: { radios = {}, typography }, disabled }) => `
    ${typography?.(14)}
    border: 1px solid transparent;
    color: ${radios.label?.color?.[disabled ? 'disabled' : 'default']};
    cursor: ${disabled ? 'default' : 'pointer'};
    &:focus-within {
      border-color: ${radios.label?.focus?.[disabled ? 'disabled' : 'default']};
    }
  `};
  line-height: 1;
  white-space: nowrap;
`;

export const RadioHiddenStyled = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;
