import React from 'react';
import { IIcons } from '@types';
import styled from 'styled-components/macro';

import theme from 'shared/css/theme';

const SvgStyled = styled.svg`
  margin-right: 16px;
`;

const AlertInfo = ({ title = 'Info alert', ...props }: IIcons) => {
  return (
    <SvgStyled
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid={props['data-testid']}
      aria-hidden="true"
    >
      <title>{title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z"
        fill={theme?.colors?.secondary?.default}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 11C12.5523 11 13 11.4477 13 12V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V12C11 11.4477 11.4477 11 12 11Z"
        fill={theme?.colors?.secondary?.default}
      />
      <path
        d="M13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8C11 7.44772 11.4477 7 12 7C12.5523 7 13 7.44772 13 8Z"
        fill={theme?.colors?.secondary?.default}
      />
    </SvgStyled>
  );
};

export default AlertInfo;
