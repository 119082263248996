import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FocusLock from 'react-focus-lock';

import {
  DropdownOptionsStyled,
  DropdownItemLinkStyled,
  ArrowStyled,
  WrapperStyled
} from './dropdown-navbar.styles';

import {
  InnerDropdownStyled,
  TitleInnerDropdownStyled
} from './inner-dropdown.styles';

const InnerDropdownNavbar = ({ title, options, innerDropdownRef }) => {
  const [open, setOpen] = useState(false);

  return (
    <WrapperStyled data-testid="inner-dropdown-navbar">
      <FocusLock disabled={!open}>
        <InnerDropdownStyled
          onClick={() => setOpen(prevState => !prevState)}
          onKeyPress={e => {
            e.preventDefault();
            if (e.which === 32 || e.which === 13) {
              setOpen(prevState => !prevState);
            }
          }}
          onKeyDown={e => {
            // 27 = esc
            if (e.which === 27) {
              setOpen(false);
            }
          }}
          open={open}
          ref={innerDropdownRef}
          data-testid="dropdown-navbar-item-dropdown"
          tabIndex={'0'}
        >
          <TitleInnerDropdownStyled>
            <span data-testid="dropdown-navbar-title">{title}</span>
            <ArrowStyled open={open} />
          </TitleInnerDropdownStyled>
          <DropdownOptionsStyled
            data-testid="dropdown-options"
            open={open}
            isInnerDropdown={true}
          >
            {options.map(option => (
              <DropdownItemLinkStyled
                key={option.value}
                to={option.link}
                active={option.active}
                tabIndex={'0'}
                showLink={!option.navItem || false}
              >
                {option.label}
              </DropdownItemLinkStyled>
            ))}
          </DropdownOptionsStyled>
        </InnerDropdownStyled>
      </FocusLock>
    </WrapperStyled>
  );
};

InnerDropdownNavbar.propTypes = {
  title: PropTypes.string,
  options: PropTypes.array
};

InnerDropdownNavbar.defaultProps = {
  title: '',
  options: []
};

export default InnerDropdownNavbar;
