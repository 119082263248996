export default {
  signup_create_account_title: 'Créez votre compte Acuity Insights',
  signup_create_account_email: 'Pour le compte courriel :',
  signup_secure_account_title: 'Sécurisez votre compte',
  signup_secure_account_subtitle: `Ajoutez un niveau de sécurité à votre compte. Vous recevrez un code à usage unique pour chaque nouvelle connexion. Choisissez comment vous souhaitez recevoir ce code.`,
  signup_2fa_title: 'Authentification à deux facteurs',
  signup_2fa_title_sms: '2 facteurs d’authentification par message texte',
  signup_2fa_title_call: '2 facteurs d’authentification par téléphone',
  signup_2fa_title_email: '2 facteurs d’authentification par courriel',
  signup_terms_title: `Conditions d'utilisation`,
  signup_terms_before: `Avant d'utiliser Acuity Insights, vous devez consulter les`,
  signup_terms_and: 'et notre',
  signup_terms_checkbox: `J'accepte les conditions d'utilisation et j'ai lu la politique de confidentialité.`,
  signup_confirmation_title: 'Inscription réussie !',
  signup_confirmation_message: `Votre compte a été créé avec succès. Cliquez sur me connecter pour accéder à votre compte.`,
  signup_confirmation_button: 'Me connecter',
  signup_preferred_language: 'Langue de préférence',
  signup_provide_phone_number_sms:
    'Fournissez votre numéro de téléphone pour une authentification par message texte',
  signup_provide_phone_number_call:
    'Fournissez votre numéro de téléphone pour une authentification par appel automatisé',
  select_a_valid_method: 'Veuillez sélectionner une méthode valide'
};
