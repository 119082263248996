import React from 'react';

export default ({
  className,
  color = '#002766',
  tabIndex = '0',
  handleClick
}) => (
  <svg
    className={className}
    width="7"
    height="12"
    viewBox="0 0 7 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={handleClick}
    onKeyPress={e => {
      e.preventDefault();
      if (e.which === 32 || e.which === 13) {
        handleClick();
      }
    }}
    tabIndex={tabIndex}
    data-testid="pagination-arrow-right"
  >
    <title>Arrow</title>
    <path
      d="M6.46854 5.65551L0.305274 0.841647C0.289169 0.828968 0.269815 0.821089 0.249434 0.818914C0.229052 0.816739 0.208471 0.820356 0.190053 0.829351C0.171635 0.838346 0.156128 0.852353 0.145312 0.869764C0.134496 0.887175 0.12881 0.907283 0.128907 0.92778V1.98462C0.128907 2.05161 0.160353 2.11587 0.212306 2.15688L5.13417 6.00005L0.212306 9.84321C0.158986 9.88422 0.128907 9.94848 0.128907 10.0155V11.0723C0.128907 11.1639 0.234181 11.2145 0.305274 11.1584L6.46854 6.34458C6.52092 6.30372 6.5633 6.25145 6.59245 6.19174C6.62159 6.13204 6.63674 6.06648 6.63674 6.00005C6.63674 5.93361 6.62159 5.86805 6.59245 5.80835C6.5633 5.74865 6.52092 5.69638 6.46854 5.65551Z"
      fill={color}
    />
  </svg>
);
