export default {
  input_email: 'Email',
  input_email_blank: 'Please enter an email',
  input_email_invalid: 'Please enter a valid email',
  input_email_invalid_role:
    'Login failed. You may not have permission to access this app.',
  input_password: 'Password',
  input_password_blank: 'Please enter a password',
  input_password_invalid: 'Please enter a valid password',
  input_password_invalid_role:
    'Login failed. You may not have permission to access this app.',
  input_password_not_match: 'Passwords do not match',
  input_phone_number: 'Phone number',
  input_country_code: 'Country code',
  input_phone_extension: 'Phone extension (if applicable)',
  input_phone_extension_error: 'Please enter a valid phone extension',
  input_verification_code: 'Verification code',
  input_verification_code_error:
    'Wrong verification code. Please try again or click re-send verification code.',
  input_verification_code_message: 'Please enter a valid verification code',
  input_string_error: 'Please enter a',
  input_string_invalid_characters:
    'cannot begin any of the following: =, +, -, @, TAB or RETURN.',
  input_givenname: 'first name',
  input_surname: 'last name',
  input_password_validation_between: 'between 8 and 30 characters',
  input_password_validation_lowercase: 'at least 1 lower case',
  input_password_validation_uppercase: 'at least 1 upper case',
  input_password_validation_digit: 'at least 1 digit',
  input_password_validation_special: 'at least 1 special character',
  input_password_validation_whitespace: 'cannot contain whitespace',
  input_login_disabled_prefix: 'Login disabled for',
  input_login_disabled_suffix: 'due to multiple failed attempts.',
  input_first_name_required: 'First name is required',
  input_last_name_required: 'Last name is required',
  input_email_required: 'Email is required',
  input_first_name_invalid: 'First name contains invalid characters',
  input_last_name_invalid: 'Last name contains invalid characters',
  input_email_duplicate: 'This email address is already in use'
};
