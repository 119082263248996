import styled from 'styled-components/macro';

import CheckMarkIcon from 'assets/icons/check-mark';

export const CheckboxWrapperStyled = styled.div`
  ${({ theme: { checkboxes = {} } }) => `
    margin: ${checkboxes.wrapper?.margin};
  `};
`;

export const CheckboxStyled = styled.div`
  ${({
    theme: { checkboxes = {}, transitions, spacing },
    disabled,
    checked,
    tabIndex
  }) => `
    background-color: ${
      checkboxes.item?.backgroundColor?.[
        disabled ? 'disabled' : checked ? 'checked' : 'default'
      ]
    };
    border: ${checkboxes.item?.border?.[checked ? 'checked' : 'default']};
    border-radius: ${checkboxes.item?.borderRadius};
    display: inline-block;
    flex-shrink: 0;
    height: ${checkboxes.item?.height};
    margin: ${checkboxes.item?.margin};
    transition: all ${transitions?.fast_50} ease-in-out;
    width: ${checkboxes.item?.width};
    text-align: center;
    &:hover {
      ${
        !checked && !disabled
          ? `
        background-color: ${checkboxes.item?.hover?.backgroundColor};
        border-color: ${checkboxes.item?.hover?.borderColor};
        `
          : ''
      };
    }
    &:focus {
      ${
        !disabled &&
        `
           border-color: ${
             tabIndex === '-1' ? '' : checkboxes.item?.focus?.borderColor
           };
           box-shadow: ${
             tabIndex === '-1' ? '' : checkboxes.item?.focus?.boxShadow
           };
        `
      }
    }
    svg {
      visibility: ${checked ? 'visible' : 'hidden'};
      padding-bottom: ${spacing?.(0.08)};
    }
  `};
`;

export const LabelStyled = styled.label`
  ${({ theme: { checkboxes = {}, typography }, disabled }) => `
    display: flex;
    ${typography?.(14)}
    color: ${checkboxes.label?.color?.[disabled ? 'disabled' : 'default']};
    cursor: ${disabled ? 'default' : 'pointer'};
    line-height: 1;
    white-space: nowrap;
  `};
`;

export const CheckboxHiddenStyled = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const CheckMarkIconStyled = styled(CheckMarkIcon)`
  width: 75%;
  height: auto;
`;

export const TextWrapperStyled = styled.span`
  ${({ theme: { spacing } }) => `
    white-space: normal;
    line-height: 1.6;
    margin-top: -${spacing?.(0.4)};
  `};
`;

export const TitleWrapperStyled = styled.span`
  visibility: hidden;
  height: 0;
`;
