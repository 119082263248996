import React from 'react';

export default ({ className, color = '#000000' }) => (
  <svg
    className={className}
    width="24"
    height="18"
    viewBox="0 0 24 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <title>User's profile</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5 9C9.77817 9 11.625 7.15317 11.625 4.875C11.625 2.59683 9.77817 0.75 7.5 0.75C5.22183 0.75 3.375 2.59683 3.375 4.875C3.375 7.15317 5.22183 9 7.5 9Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.75 17.25C0.75 13.5221 3.77208 10.5 7.5 10.5C11.2279 10.5 14.25 13.5221 14.25 17.25"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.251 1.41599C15.9763 0.294947 18.2639 0.616735 19.613 2.17024C20.9621 3.72375 20.9601 6.03388 19.6083 7.58505C18.2565 9.13621 15.9684 9.45403 14.245 8.32999"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 10.667C17.0024 10.2133 19.1025 10.6947 20.7074 11.9753C22.3123 13.256 23.2479 15.1968 23.25 17.25"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
