export default {
  snapshot_training_title: 'Snapshot reviewer training',
  snapshot_training_subtitle:
    'Before reviewing and rating Snapshot one-way video interviews, you must complete this reviewer training. This training takes approximately <b>20 minutes</b> to complete.',
  snapshot_training_section_1_title: 'Section 1: What is Snapshot?',
  snapshot_training_section_1_subtitle: '~2 mins',
  snapshot_training_section_1_p1:
    'View this video to learn about Snapshot and how it works.',
  snapshot_training_section_2_title:
    'Section 2: How to rate Snapshot and what to look for',
  snapshot_training_section_2_subtitle: '~10 mins',
  snapshot_training_section_2_p1:
    'View this video to learn how to review and rate Snapshot one-way interviews. Use the handout as a reference while you review.',
  snapshot_training_section_2_link: 'How to Rate Snapshot: Handout',
  snapshot_training_section_3_title: 'Section 3: Implicit Bias Training',
  snapshot_training_section_3_subtitle: '~8 mins',
  snapshot_training_section_3_p1:
    'View this video to learn about implicit bias and how to reduce its effects on you, as a Snapshot reviewer. Use the handout as a reference while you review.',
  snapshot_training_section_3_link: 'Implicit Bias Training: Handout',
  snapshot_training_confirmation:
    'I have completed all sections of the Snapshot training.',
  snapshot_training_confirmation_button: 'Review video responses'
};
