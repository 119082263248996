export default {
  select_program_filter: 'Programme',
  select_program_filter_plural: 'Programme(s)',
  select_program_search: 'Taper pour rechercher',
  select_program_error: 'Veuillez sélectionner au moins un programme',
  select_cycle_filter: `Période d'admission`,
  select_role: 'Rôle',
  select_role_search: `Sélectionnez un rôle d'utilisateur`,
  select_role_error: `Veuillez sélectionner un rôle`,
  select_code_error: 'Veuillez sélectionner un code',
  select_phone_number: 'numéro de téléphone valide',
  select_phone_number_error: 'Veuillez entrer un',
  select_default_message: `Pas d'options`
};
