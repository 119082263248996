import React, { FC } from 'react';
import {
  ArrowRightStyled,
  ListItemStyled,
  CardStyled,
  CardTitleStyled
} from './dashboard-nav-card.styles.js';
import { Link } from 'components';
import { DashboardNavCardProps } from './types.js';
import { useRecoilValue } from 'recoil';
import { programFilterAtom } from 'shared/store/program-filter';

export const DashboardNavCard: FC<DashboardNavCardProps> = ({
  dashboardList,
  pendoPrefix,
  title,
  dataTestId
}) => {
  const programFilter = useRecoilValue(programFilterAtom);
  if (dashboardList.length > 0) {
    return (
      <CardStyled dataTestId={dataTestId}>
        <CardTitleStyled>{title}</CardTitleStyled>
        <ul>
          {dashboardList?.map(dashboard => {
            return (
              <ListItemStyled
                data-pendoid={`bookmark-${pendoPrefix}-${dashboard.url}`}
                key={dashboard.url}
              >
                {/* Need to update this label once we support French for Qlik */}
                <Link
                  ariaLabel={dashboard.label.en}
                  to={{
                    pathname: dashboard.url,
                    search: `?host=${programFilter.userDirectory?.toLowerCase()}`
                  }}
                >
                  {dashboard.label.en}
                  <ArrowRightStyled tabIndex="-1" />
                </Link>
              </ListItemStyled>
            );
          })}
        </ul>
      </CardStyled>
    );
  } else {
    return null;
  }
};

export default DashboardNavCard;
