import styled from 'styled-components/macro';

import { fullCenter } from 'shared/css/common-classes';
import { LogoHeader } from 'shared/components/icons';
import SnapshotLogoIcon from 'assets/icons/logo-header-snapshot';

export const WrapperStyled = styled.div`
  ${({ theme: { spacing }, hideBorder, isFixed }) => `
    ${fullCenter}
    background: #ffffff;
    box-shadow: ${hideBorder ? 'none' : '0px 2px 4px rgba(3, 55, 103, 0.1)'};
    margin-bottom: ${spacing?.(2)};
    width: 100vw;
    z-index: 3;
    position: ${isFixed ? 'fixed' : 'inherit'}
  `};
`;

export const HeaderStyled = styled.header`
  ${({ theme: { spacing } }) => `
    align-items: center;
    display: flex;
    height: ${spacing?.(8)};
    padding: 0 ${spacing?.(4)};
    width: 100%;
  `};
`;

export const ContentStyled = styled.div`
  ${({ theme: { spacing }, snapshotPage, isDesktop }) => `
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-right: ${spacing?.(1)};
    width: 100%;
    height: 100%;
  `};
`;

export const LogoStyled = styled(LogoHeader)`
  ${({ theme: { spacing } }) => `
    height: ${spacing?.(5)};
    margin-bottom: ${spacing?.(1)};
    margin-top: ${spacing?.(2)};
    margin-right: ${spacing?.(3)};
  `};
`;

export const SnapshotLogoStyled = styled(props => (
  <SnapshotLogoIcon {...props} />
))`
  ${({ theme: { spacing } }) => `
    height: ${spacing?.(6)};
    margin-bottom: ${spacing?.(1)};
    margin-top: ${spacing?.(2)};
    margin-right: ${spacing?.(3)};
  `};
`;

export const HeaderContainerStyled = styled.div`
  padding: 2rem 0;
`;

export const SubtitleStyled = styled.h2`
  color: ${({ theme: { colors } }) => colors.grey.darker};
  font-weight: normal;
`;

export const LineStyled = styled.hr`
  background: ${({ theme: { colors } }) => colors.grey.light};
  height: 1px;
  border: 0;
`;
