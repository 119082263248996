import React, { Suspense, lazy, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';

import { useQuery } from '@apollo/client';
import { USER_ROUTES } from 'graphql/queries/routes';
import { ScrollToTop } from 'shared/helpers/scrollTop';
import { userRoutes } from '../shared/store/userRoutes';

import PrivateRoute from './components/private';
import LoggedRoute from './components/logged';
import PublicRoute from './components/public';
import WildcardRoute from './components/wildcard';
import { LoaderFullPage } from 'shared/components';
import { PERMISSIONS, ROUTES, NON_ANALYTICS_PERMISSIONS } from 'shared/enums';
import { redirectToAnalytics } from './helpers/redirectToAnalytics';

const Applicants = lazy(() => import('pages/applicants/applicants'));
const Mashup = lazy(() => import('pages/analytics/mashup'));
const Home = lazy(() => import('pages/home/home'));
const SignUp = lazy(() => import('pages/signup/signup'));
const SignIn = lazy(() => import('pages/signin/signin'));
const Users = lazy(() => import('pages/users/users'));
const TeamSupport = lazy(() => import('pages/team-support/team-support'));
const UsersInvitation = lazy(
  () => import('pages/users/invitation/users-invitation')
);
const ResetEmail = lazy(() => import('pages/password-reset/reset-email'));
const ResetPassword = lazy(() => import('pages/password-reset/reset-password'));
const PageNotFound = lazy(() => import('pages/404/404'));
const ForbiddenPage = lazy(() => import('pages/403/403'));
const MaintenancePage = lazy(() => import('pages/503/503'));
const TokenExpired = lazy(() => import('pages/token-expired/token-expired'));
const Account = lazy(() => import('pages/account/account'));
const Developer = lazy(() => import('pages/developer/developer'));
const Logout = lazy(() => import('pages/logout/logout'));
const Snapshot = lazy(() => import('pages/snapshot/snapshot'));
const SnapshotTraining = lazy(() => import('pages/snapshot/snapshot-training'));
const Files = lazy(() => import('pages/files/files'));
const Duet = lazy(() => import('pages/duet/duet'));
const PassthroughCreate = lazy(() => import('pages/passthrough/create'));
const PassthroughVerify = lazy(() => import('pages/passthrough/verify'));
const Impersonate = lazy(() => import('pages/impersonate/impersonate'));
const PrivacyPolicy = lazy(() => import('pages/privacy-policy/privacy-policy'));
const TermsOfUse = lazy(() => import('pages/terms-of-use/terms-of-use'));
const DuetProfile = lazy(() => import('pages/duet-profile/duet-profile'));
const EditSchoolConfig = lazy(
  () => import('pages/edit-school-config/edit-school-config')
);
const ConfigureMenu = lazy(() => import('pages/configure-menu/configure-menu'));
const ConfigureUsers = lazy(
  () => import('pages/configure-users/configure-users')
);
const ManagePermissions = lazy(
  () => import('pages/manage-permissions/manage-permissions')
);
const AccessLogs = lazy(() => import('pages/access-logs/access-logs'));
const ConfigureIntegrations = lazy(
  () => import('pages/configure-integrations/configure-integrations')
);
const ConfigureIntegrationsSetup = lazy(
  () => import('pages/configure-integrations/setup/setup')
);
const RulesEditor = lazy(() => import('pages/rules-editor/rules-editor'));
const TaggingsAndEmbellishments = lazy(
  () => import('pages/taggings-embellishments/taggings-embellishments')
);
const ManageUsers = lazy(() => import('pages/manage-users/manage-users'));
const EditUser = lazy(() => import('pages/edit-user/edit-user'));

const Routes = () => {
  const { t } = useTranslation();
  const setUserTabs = useSetRecoilState(userRoutes);

  /*
  /  This state is used to stop a race condition where the router
  /  hits dynamic routes before they are created, resulting in a /404 from the
  /  wildcard route.
  */
  const [routesLoaded, setRoutesLoaded] = useState(false);

  const onRoutesComplete = ({ getRoutes }) => {
    setUserTabs({
      routes: getRoutes?.routes || [],
      menu: getRoutes?.menu || [],
      refetch
    });
    setRoutesLoaded(true);
  };

  const onRoutesError = () => {
    redirectToAnalytics();
    setRoutesLoaded(true);
  };

  const { data, refetch } = useQuery(USER_ROUTES, {
    onCompleted: onRoutesComplete,
    onError: onRoutesError,
    notifyOnNetworkStatusChange: true
  });

  if (!routesLoaded) {
    return <LoaderFullPage />;
  } else {
    return (
      <Suspense fallback={<LoaderFullPage />}>
        <Router>
          <ScrollToTop />
          <Switch>
            {data?.getRoutes?.routes?.map(
              ({ sheetId, route, insightPermissions, tabLabel }) => (
                <PrivateRoute
                  key={sheetId}
                  path={route}
                  exact={true}
                  component={Mashup}
                  componentProps={{
                    sheetId,
                    key: sheetId
                  }}
                  isDashboard={!!sheetId}
                  permissions={insightPermissions}
                  title={tabLabel}
                />
              )
            )}
            <PrivateRoute
              path="/account"
              component={Account}
              permissions={NON_ANALYTICS_PERMISSIONS}
            />
            <PrivateRoute path="/developer" component={Developer} />
            <PrivateRoute path={ROUTES.LOG_OUT.path} component={Logout} />
            <PrivateRoute
              path="/analytics/dashboard/:sheetId"
              component={Mashup}
              isDashboard={true}
            />
            <PrivateRoute
              path={ROUTES.EDIT_SCHOOL.path}
              exact={true}
              component={EditSchoolConfig}
              title={ROUTES.EDIT_SCHOOL.title}
            />
            <PrivateRoute
              path={ROUTES.MANAGE_PERMISSIONS.path}
              exact={true}
              component={ManagePermissions}
              title={ROUTES.MANAGE_PERMISSIONS.title}
            />
            <PrivateRoute
              path={ROUTES.USERS.path}
              exact={true}
              component={Users}
              permissions={[PERMISSIONS.MANAGE_USERS]}
              title={t(ROUTES.USERS.titleKey)}
            />
            <PrivateRoute
              path={ROUTES.ACCESS_LOGS.path}
              exact={true}
              component={AccessLogs}
              permissions={[PERMISSIONS.VIEW_ACCESS_LOGS]}
              title={t(ROUTES.ACCESS_LOGS.titleKey)}
            />
            <PrivateRoute
              path="/users/invite"
              component={UsersInvitation}
              permissions={[PERMISSIONS.MANAGE_USERS]}
            />

            <PrivateRoute
              path={ROUTES.APPLICANTS.path}
              exact={true}
              component={Applicants}
              permissions={[PERMISSIONS.ACCESS_RESULTS]}
              title={t(ROUTES.APPLICANTS.titleKey)}
            />
            <PrivateRoute
              path={ROUTES.FILES.path}
              component={Files}
              permissions={[PERMISSIONS.ACCESS_RESULTS]}
              title={t(ROUTES.FILES.titleKey)}
            />
            <PrivateRoute
              path={ROUTES.TAKE_DUET.path}
              component={Duet}
              permissions={[PERMISSIONS.TAKE_DUET]}
              title={t(ROUTES.TAKE_DUET.titleKey)}
            />
            <PrivateRoute
              path={ROUTES.VIEW_DUET_PROFILE.path}
              exact={true}
              component={DuetProfile}
              permissions={[PERMISSIONS.VIEW_DUET_PROFILE]}
              title={t(ROUTES.VIEW_DUET_PROFILE.titleKey)}
            />
            <PrivateRoute
              path={ROUTES.EDIT_SCHOOL.path}
              exact={true}
              component={EditSchoolConfig}
              title={ROUTES.EDIT_SCHOOL.title}
            />
            <PrivateRoute
              path={ROUTES.CONFIGURE_USERS.path}
              exact={true}
              component={ConfigureUsers}
              title={ROUTES.CONFIGURE_USERS.title}
            />
            <PrivateRoute
              path={ROUTES.CONFIGURE_MENU.path}
              exact={true}
              component={ConfigureMenu}
              title={ROUTES.CONFIGURE_MENU.title}
            />
            <PrivateRoute
              path={ROUTES.CONFIGURE_INTEGRATIONS.path}
              exact={true}
              component={ConfigureIntegrations}
              title={ROUTES.CONFIGURE_INTEGRATIONS.title}
            />
            <PrivateRoute
              path={`${ROUTES.CONFIGURE_INTEGRATIONS_SETUP.path}/:integration`}
              exact={true}
              component={ConfigureIntegrationsSetup}
              title={ROUTES.CONFIGURE_INTEGRATIONS_SETUP.title}
            />
            <PrivateRoute
              path={[
                `${ROUTES.RULES_EDITOR.path}/:ruleId?`,
                `${ROUTES.RULES_EDITOR_READ_ONLY.path}/:ruleId`
              ]}
              exact={true}
              component={RulesEditor}
              title={ROUTES.RULES_EDITOR.title}
            />
            <PrivateRoute
              path={`${ROUTES.TAGGINGS_AND_EMBELLISHMENTS.path}`}
              exact={true}
              component={TaggingsAndEmbellishments}
              title={ROUTES.TAGGINGS_AND_EMBELLISHMENTS.title}
            />
            <PrivateRoute
              path={ROUTES.MANAGE_USERS.path}
              exact={true}
              component={ManageUsers}
              title={ROUTES.MANAGE_USERS.title}
              permissions={[PERMISSIONS.MANAGE_USERS_V2]}
            />
            <PrivateRoute
              path={`${ROUTES.EDIT_USER.path}/:userId`}
              exact={true}
              component={EditUser}
              title={ROUTES.EDIT_USER.title}
              permissions={[PERMISSIONS.MANAGE_USERS_V2]}
            />
            <PrivateRoute path="/impersonate" component={Impersonate} />
            <PrivateRoute
              path="/passthrough/remote/login"
              exact={true}
              component={PassthroughCreate}
              componentProps={{ login: true }}
            />
            <PrivateRoute
              path="/passthrough/remote/logout"
              exact={true}
              component={PassthroughCreate}
              componentProps={{ login: false }}
            />
            <PrivateRoute
              path={ROUTES.TEAM_SUPPORT.path}
              component={TeamSupport}
            />
            <LoggedRoute
              title={t(ROUTES.SIGN_IN.titleKey)}
              path={ROUTES.SIGN_IN.path}
              component={SignIn}
            />
            <LoggedRoute path="/auth/:token" component={SignUp} />
            <LoggedRoute
              title={t(ROUTES.RESET_PASSWORD.titleKey)}
              path={ROUTES.RESET_PASSWORD.path}
              exact={true}
              component={ResetEmail}
            />
            <LoggedRoute
              title={t(ROUTES.RESET_PASSWORD_TOKEN.titleKey)}
              path={ROUTES.RESET_PASSWORD_TOKEN.path}
              component={ResetPassword}
            />
            <PublicRoute
              path="/privacy-policy"
              exact={true}
              component={PrivacyPolicy}
            />
            <PublicRoute
              path="/passthrough/local/login"
              exact={true}
              component={PassthroughVerify}
              componentProps={{ login: true }}
            />

            <PublicRoute
              path="/passthrough/local/logout"
              exact={true}
              component={PassthroughVerify}
              componentProps={{ login: false }}
            />
            <PrivateRoute path="/403" exact={true} component={ForbiddenPage} />
            <PublicRoute
              title={t(ROUTES.TERMS_OF_USE.titleKey)}
              path={ROUTES.TERMS_OF_USE.path}
              exact={true}
              component={TermsOfUse}
            />
            <PublicRoute path="/404" exact={true} component={PageNotFound} />
            <PublicRoute
              path="/expired"
              exact={true}
              component={TokenExpired}
            />
            <Route path="/503" exact={true} component={MaintenancePage} />
            <PrivateRoute
              title={t(ROUTES.HOME.titleKey)}
              path={ROUTES.HOME.path}
              exact={true}
              component={Home}
            />
            <WildcardRoute />
          </Switch>
        </Router>
      </Suspense>
    );
  }
};

export default Routes;
