import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Icon from 'assets/icons/settings-icon';

import { IconWrapperStyled } from './icon-wrapper.styles';

const SettingsIcon = ({ tabIndex }) => {
  const { t } = useTranslation();
  return (
    <IconWrapperStyled
      aria-label={t('navbar_settings')}
      title={t('navbar_settings')}
      role="button"
      data-testid="navbar-settings"
      tabIndex={tabIndex >= 0 ? tabIndex : -1}
    >
      <Icon />
    </IconWrapperStyled>
  );
};

SettingsIcon.propTypes = {
  tabIndex: PropTypes.number
};

export default SettingsIcon;
