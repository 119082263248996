import React from 'react';

export default ({ className }) => (
  <svg
    className={className}
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Plus</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00001 3.16666C8.3682 3.16666 8.66668 3.46513 8.66668 3.83332V13.1667C8.66668 13.5348 8.3682 13.8333 8.00001 13.8333C7.63182 13.8333 7.33334 13.5348 7.33334 13.1667V3.83332C7.33334 3.46513 7.63182 3.16666 8.00001 3.16666Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.66666 8.50001C2.66666 8.13182 2.96513 7.83334 3.33332 7.83334H12.6667C13.0348 7.83334 13.3333 8.13182 13.3333 8.50001C13.3333 8.8682 13.0348 9.16668 12.6667 9.16668H3.33332C2.96513 9.16668 2.66666 8.8682 2.66666 8.50001Z"
      fill="white"
    />
  </svg>
);
