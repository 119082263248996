import navbar from './navbar';

export default {
  manage_users_selected_users: 'Utilisateurs sélectionnés',
  manage_users_title: 'Gérez vos utilisateurs',
  users_title: navbar.navbar_users,
  users_search: 'Rechercher des utilisateurs',
  users_add_button: 'Ajouter un utilisateur',
  users_export_button: 'Exporter',
  users_export_all: 'Tous les utilisateurs',
  users_export_selected: 'Utilisateurs sélectionnés',
  users_col_name: 'Nom',
  users_col_firstName: 'Prénom',
  users_col_lastName: 'Nom de famille',
  users_col_email: 'Courriel',
  users_col_program: 'Programme',
  users_col_programs_and_permissions: 'Programmes (autorisations)',
  users_col_roles: 'Rôle',
  users_col_all: 'Toutes',
  users_col_createdAt: 'Créé le',
  users_col_actions: 'Actions',
  users_col_permissions: 'Autorisations',
  users_selected: '{{count}} utilisateur sélectionné',
  users_selected_plural: '{{count}} utilisateurs sélectionnés',
  users_modal_add_title: 'Ajouter un utilisateur',
  users_modal_add_alert: 'Une invitation par courriel a été envoyée à',
  users_invite_add_confirmation_title:
    'Il existe déjà un compte associé à cette adresse courriel.',
  users_invite_internal_error: 'User is an internal account!', //   @TODO english, french TBD
  users_invite_add_confirmation_message: `Voulez-vous quand même créer cet utilisateur ? Les autorisations seront remplacées.`,
  users_modal_add_confirmation_message: `Voulez-vous envoyer l'invitation quand même ?`,
  users_invite_email_placeholder: 'Par exemple, jon@doe.com',
  users_invite_select_permission:
    'Sélectionnez les autorisations de cet utilisateur pour ce programme.',
  users_invite_permissions_title: 'Autorisations',
  users_invite_permissions_not_available_admin: `{{product}} n'est pas disponible pour votre programme actuellement. Veuillez nous contacter pour plus d'informations.`,
  users_invite_permissions_not_available_team: `Ce produit n'est pas exigé pour votre programme. Veuillez nous contacter via la bulle de discussion ci-dessous pour demander des informations.`,
  users_invite_permissions_general_title: 'Général',
  users_invite_permissions_error:
    'Veuillez sélectionner au moins une autorisation.',
  users_invite_review_title: 'Évaluer',
  users_invite_review_text: `Veuillez vérifier les autorisations avant d'envoyer l'invitation.`,
  users_invite_review_not_available: ' - non disponible',
  users_invite_review_send_invite: `Envoyer l'invitation`,
  users_invite_exit: 'Sortir',
  users_invite_success_title: 'Invitation réussie !',
  users_invite_success_message: `L'invitation a été envoyée par courriel à {{email}}.`,
  users_invite_error_title: 'Oh non !',
  users_invite_error_message: `Nous n'avons pas pu envoyer l'invitation à {{email}}. Veuillez essayer d'inviter cet utilisateur à nouveau. Si le problème persiste, veuillez nous contacter.`,
  users_modal_edit_title: `Modifier l'utilisateur`,
  users_modal_edit_button: `Modifier l'utilisateur`,
  users_modal_edit_alert: 'a été mis à jour avec succès',
  users_modal_delete_title: `Supprimer l'utilisateur`,
  users_modal_delete_button: `Supprimer l'utilisateur`,
  users_modal_delete_message: `Êtes-vous sûr(e) de vouloir supprimer l'utilisateur {{email}} ?`,
  users_modal_delete_alert: `Si vous supprimez cet utilisateur, la personne n'aura plus accès à Acuity Insights. Vous pourrez inviter cette personne de nouveau à l'avenir si vous le souhaitez.`,
  users_modal_export_selected_title: 'Exporter les utilisateurs',
  users_modal_export_selected_message:
    'Êtes-vous sûr(e) de vouloir exporter les données sélectionnées ?',
  users_modal_export_selected_alert:
    'Veuillez sélectionner des utilisateurs à exporter.',
  users_modal_export_all_title: 'Exporter tous les utilisateurs',
  users_modal_export_all_message:
    'Êtes-vous sûr(e) de vouloir exporter tous les utilisateurs ?',
  users_modal_export_all_alert: `Une erreur s'est produite, veuillez essayer plus tard ou rafraîchir la page.`,
  users_modal_view_title: 'Programme(s) et autorisations',
  users_modal_view_subtitle:
    'Cet utilisateur a accès aux programmes suivants :',
  users_all_programs_admin: 'Tous les programmes - Administrateur',
  users_no_programs: 'Aucun programme attribué'
};
