import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from '../checkbox/checkbox';

import {
  TableStyled,
  TheadThStyled,
  TheadTrStyled,
  TbodyTdStyled,
  TbodyTrStyled,
  ArrowDownStyled,
  SelectedItemsStyled,
  ActionsStyled,
  ActionsTHeadStyled,
  ActionsTBodyStyled,
  TextButtonStyled
} from './table.styles';

const Table = ({
  columns,
  data,
  sortingBy,
  handleSorting,
  handleSelectAll,
  handleSelect,
  selectedAllChecked,
  selectedCheckbox,
  handleEdit,
  handleDelete,
  shouldHideEdit,
  handleDownload,
  handleOpenModal,
  headerMessage,
  isFrench,
  editText,
  deleteText,
  downloadText,
  viewText,
  className
}) => {
  const hasCheckbox = columns.findIndex(c => c.name === 'checkbox');
  return (
    <>
      <SelectedItemsStyled
        show={selectedCheckbox.length}
        data-testid="table-selected-items"
      >
        {selectedCheckbox.length > 0 && <div>{headerMessage}</div>}
      </SelectedItemsStyled>
      <TableStyled data-testid="table" className={className}>
        <thead>
          <TheadTrStyled>
            {columns.map((column, index) =>
              column.name === 'checkbox' ? (
                <TheadThStyled key={`column_${index}`} customWidth="50px">
                  <Checkbox
                    name="select-all"
                    checked={selectedAllChecked}
                    handleClick={() => handleSelectAll(!selectedAllChecked)}
                  />
                </TheadThStyled>
              ) : column.name === 'Actions' ? (
                <ActionsTHeadStyled
                  key={`column_${index}`}
                  customWidth={column.width || '348px'}
                >
                  {column.name}
                </ActionsTHeadStyled>
              ) : column.name === 'Download' ? (
                <ActionsTHeadStyled
                  key={`column_${index}`}
                  customWidth={column.width || '348px'}
                />
              ) : (
                <TheadThStyled
                  key={`column_${index}`}
                  customWidth={column.width || '348px'}
                >
                  {column.sortable && (
                    <ArrowDownStyled
                      handleClick={() => handleSorting(column.name)}
                      isSorting={
                        sortingBy?.column === column.selector
                          ? sortingBy?.order
                          : null
                      }
                    />
                  )}
                  {column.name}
                </TheadThStyled>
              )
            )}
          </TheadTrStyled>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => {
            const {
              _id,
              disableActions,
              order,
              isDownloadable,
              ...filteredRow
            } = row;
            const col = Object.values(filteredRow);
            const checked = Boolean(
              selectedCheckbox.find(ch => ch._id === row._id && ch.checked)
            );
            return (
              <TbodyTrStyled key={`row_${rowIndex}`}>
                <>
                  {hasCheckbox !== -1 && (
                    <TbodyTdStyled
                      key={`col_checkbox_${rowIndex}`}
                      customWidth="50px"
                      checked={checked}
                    >
                      <Checkbox
                        name={`select ${row._id}`}
                        checked={checked}
                        handleClick={() =>
                          handleSelect({ ...row, checked: !checked })
                        }
                      />
                    </TbodyTdStyled>
                  )}
                  {col.map((item, index) => {
                    const inc = hasCheckbox !== -1 ? 1 : 0;
                    const isDownloadableCol =
                      typeof item === 'object' && item?.col === 'download';
                    const isModal = columns?.[index + inc]?.isModal;
                    return item === 'actions' || isDownloadableCol ? (
                      <ActionsTBodyStyled
                        key={`col_${index}`}
                        checked={checked}
                      >
                        <ActionsStyled isFrench={isFrench}>
                          {!row.disableActions ? (
                            <>
                              {shouldHideEdit && shouldHideEdit(row) ? null : (
                                <TextButtonStyled
                                  handleClick={() => handleEdit(row)}
                                >
                                  {editText}
                                </TextButtonStyled>
                              )}
                              <TextButtonStyled
                                handleClick={() => handleDelete(row)}
                              >
                                {deleteText}
                              </TextButtonStyled>
                            </>
                          ) : isDownloadable ? (
                            <TextButtonStyled
                              handleClick={() => handleDownload(item?.url)}
                            >
                              {downloadText}
                            </TextButtonStyled>
                          ) : (
                            <></>
                          )}
                        </ActionsStyled>
                      </ActionsTBodyStyled>
                    ) : (
                      <TbodyTdStyled
                        key={`col_${index}`}
                        customWidth={columns[index + inc]?.width || '348px'}
                        checked={checked}
                        data-testid={`table_cell_${rowIndex}_${columns[index + inc].name}`}
                      >
                        {isModal && item?.length > 0 ? (
                          <TextButtonStyled
                            handleClick={() =>
                              handleOpenModal({
                                item,
                                selector: columns?.[index + inc]?.selector
                              })
                            }
                          >
                            {typeof item === 'string'
                              ? viewText
                              : `${viewText}(${item?.length})`}
                          </TextButtonStyled>
                        ) : isModal && item?.optionalText ? (
                          item.optionalText
                        ) : Array.isArray(item) ? (
                          item.toString()
                        ) : (
                          item
                        )}
                      </TbodyTdStyled>
                    );
                  })}
                </>
              </TbodyTrStyled>
            );
          })}
        </tbody>
      </TableStyled>
    </>
  );
};

Table.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  selectedCheckbox: PropTypes.array,
  isFrench: PropTypes.bool.isRequired,
  editText: PropTypes.string.isRequired,
  deleteText: PropTypes.string.isRequired,
  downloadText: PropTypes.string.isRequired,
  viewText: PropTypes.string.isRequired
};

Table.defaultProps = {
  columns: [],
  data: [],
  selectedCheckbox: [],
  isFrench: false,
  editText: 'Edit',
  deleteText: 'Delete',
  downloadText: 'Download',
  viewText: 'View'
};

export default Table;
