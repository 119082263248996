export default {
  account_tab_overview: 'Overview',
  account_tab_security: 'Security',
  account_information_title: 'My information',
  account_information_first_name: 'First name',
  account_information_last_name: 'Last name',
  account_information_update: 'Update',
  account_information_confirmation: `User's information updated successfully.`,
  account_programs_title: 'Programs',
  account_programs_subtitle: 'You have access to these programs:',
  account_terms_title: 'Terms and Conditions',
  account_terms_and_conditions: 'Terms and Conditions',
  account_terms_privacy_policy: 'Privacy Policy',
  account_terms_message_1: 'View our',
  account_terms_message_2: 'and',
  account_language: 'Language',
  account_language_disclaimer:
    'We’re growing rapidly and not all features may be fully translated yet',
  account_security_password_title: 'Change password',
  account_security_password_current: 'Current password',
  account_security_password_new: 'New password',
  account_security_password_confirm: 'Confirm password',
  account_security_2fa_title: '2-Factor Authentication',
  account_security_2fa_subtitle:
    'We use two-factor authentication to add an extra layer of security to your account.',
  account_security_2fa_message:
    'Currently, you are receiving your verification code via',
  account_security_2fa_button: 'Update 2-factor authentication',
  account_security_2fa_modal_title: 'Update 2-factor authentication',
  account_security_2fa_modal_password: 'Please enter your current password.',
  account_security_2fa_modal_verification_sms:
    'We have just sent a text message to',
  account_security_2fa_modal_verification_call: 'We have just called',
  account_security_2fa_modal_verification_code_message2:
    'Please enter the 6-digit verification code below',
  account_security_password_alert: 'Password updated successfully.',
  account_details: 'Account details',
  account_details_updated: 'Account details successfully updated'
};
