import React from 'react';

export default ({ className }) => (
  <svg
    className={className}
    width="49"
    height="49"
    viewBox="0 0 49 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <title>Snapshot training</title>
    <circle cx="24.5" cy="24.5" r="24.5" fill="#FEF1EC" />
    <path
      d="M24.3334 38.1333V15.8834C24.3334 15.8834 21.1964 12.3989 10.6203 12.2583C10.4442 12.2566 10.2748 12.3256 10.1501 12.45C10.0256 12.5758 9.95579 12.7457 9.95583 12.9227V33.8451C9.95288 34.2034 10.2366 34.4984 10.5947 34.5095C21.1939 34.6462 24.3334 38.1333 24.3334 38.1333"
      stroke="#F26F3E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.5002 24.148C18.3257 23.4166 16.0674 22.9637 13.7791 22.8"
      stroke="#F26F3E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.5002 29.5083C18.3252 28.7798 16.067 28.329 13.7791 28.1666"
      stroke="#F26F3E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.1667 24.148C30.3412 23.4166 32.5996 22.9637 34.8879 22.8"
      stroke="#F26F3E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.1667 29.5083C30.3417 28.7798 32.5999 28.329 34.8879 28.1666"
      stroke="#F26F3E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.8879 12.4116C35.8591 12.3273 36.9094 12.2736 38.0466 12.2583C38.2218 12.2572 38.3902 12.3262 38.5143 12.4499C38.6387 12.5758 38.7085 12.7457 38.7085 12.9227V33.8451C38.7114 34.2033 38.4277 34.4984 38.0696 34.5095C27.473 34.6462 24.3335 38.1333 24.3335 38.1333"
      stroke="#F26F3E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.3335 38.1333V15.8834C24.3335 15.8834 25.794 14.2606 30.0835 13.186"
      stroke="#F26F3E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.8752 18.9666V10.5333C33.2602 10.662 31.658 10.9184 30.0835 11.3V18.9666L32.4793 17.05L34.8752 18.9666Z"
      stroke="#F26F3E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
