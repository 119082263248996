import styled from 'styled-components/macro';
import { Card } from 'components';
import { devices } from 'shared/css/devices';

export const SelectionProcessCardStyled = styled(Card)`
  ${({ theme: { spacing }, extraTopMargin }) => `
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: ${spacing?.(2)};
    & > svg {
      margin-bottom: ${spacing?.(2)};
      margin-right: 0;
    }
    & svg:last-child {
      margin-left: 4px;
    }
    @media ${devices.tablet} {
      flex-direction: row;
      & > svg {
        margin-bottom: 0;
        margin-right: ${spacing?.(2)};
      }
    }
    ${extraTopMargin && `margin-top: ${spacing?.(2)};`}
  `};
`;
