import React from 'react';

interface iBook {
  className?: string;
  color?: string;
}

const Book = ({ className, color = '#555555' }: iBook) => (
  <svg
    className={className}
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 6.45332C19.3042 6.24373 20.6225 6.13396 21.9433 6.12499C22.0568 6.12324 22.1664 6.16637 22.2483 6.24499C22.3287 6.32286 22.3743 6.42977 22.375 6.54165V19.6817C22.3727 19.9108 22.1875 20.0961 21.9583 20.0983C15.0475 20.1842 13 22.375 13 22.375V8.40165C13 8.40165 10.9542 6.21332 4.05667 6.12499C3.94259 6.1228 3.8323 6.16596 3.75 6.24499C3.67027 6.32314 3.62524 6.43001 3.625 6.54165V19.6817C3.62726 19.9108 3.81249 20.0961 4.04167 20.0983C10.9525 20.1842 13 22.375 13 22.375"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13 21.9642C13.1317 20.8841 13.61 19.8759 14.3633 19.0908C15.3327 18.0452 16.6039 17.3277 18 17.0383V4.03583C17.9974 3.89965 17.9259 3.77411 17.81 3.7025C17.6872 3.62534 17.537 3.60527 17.3983 3.6475C16.229 3.98349 15.1775 4.64092 14.3633 5.54499C13.6104 6.32964 13.132 7.33721 13 8.41666"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Book;
