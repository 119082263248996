/**
 * Clears all items from local storage except for those in the whitelist
 * @param whitelist - An array of keys to keep in local storage
 */
export default (whitelist: string[] = []): void => {
    const keys = Object.keys(localStorage);

    keys.forEach(key => {
        if (!whitelist.includes(key)) {
            localStorage.removeItem(key);
        }
    });
}