import React from 'react';
import ReactSelect from 'react-select';
import PropTypes from 'prop-types';

import { WrapperStyled, LabelStyled, MessageStyled } from './select.styles';
import theme from 'shared/css/theme';

const customStyles = {
  option: (provided, state) => {
    const {
      selectProps: { useSecondaryColor } = {},
      isSelected = {},
      isFocused = false
    } = state || {};
    return {
      ...provided,
      borderLeft: `4px solid ${
        isSelected
          ? useSecondaryColor
            ? theme.colors.secondary.default
            : theme.colors.primary.default
          : 'transparent'
      }`,
      backgroundColor: isSelected
        ? useSecondaryColor
          ? theme.colors.secondary.lightest
          : theme.colors.primary.lightest
        : isFocused
          ? theme.colors.grey.lighter
          : 'transparent',
      color: theme.colors.primary.darkest,
      paddingBottom: theme.spacing(2),
      paddingTop: theme.spacing(2),
      transition: `all ${theme.transitions.fast} ease-in-out`,
      '&:active': {
        backgroundColor: 'transparent'
      },
      '&:hover': {
        backgroundColor: isSelected
          ? useSecondaryColor
            ? theme.colors.secondary.lightest
            : theme.colors.primary.lightest
          : theme.colors.grey.lighter,
        cursor: 'pointer'
      }
    };
  },
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isDisabled
      ? theme.inputs.item.disabled.backgroundColor
      : '#ffffff',
    border: theme.inputs.item.border,
    boxShadow: theme.inputs.item.boxShadow['default'],
    color: theme.inputs.item.color,
    cursor: state.selectProps.hideArrow ? 'default' : 'pointer',
    fontSize: '1rem',
    height: theme.spacing(5),
    margin: `${theme.spacing(0.5)} 0`,
    minHeight: theme.spacing(5),
    outline: 'none',
    transition: `all ${theme.transitions.fast} ease-in-out`,
    '&:hover': {
      borderColor: state.isFocused
        ? theme.colors.primary.default
        : 'transparent',
      boxShadow: state.isFocused
        ? 'none'
        : theme.inputs.item.hover.boxShadow['default']
    },
    '&:focus-within': {
      borderColor: theme.colors.primary.default,
      boxShadow: theme.inputs.item.hover.boxShadow['default']
    }
  }),
  placeholder: provided => ({
    ...provided,
    color: theme.colors.grey.dark
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    display: state.selectProps.hideArrow ? 'none' : 'block',
    '& svg': {
      color: theme.colors.grey.default
    }
  }),
  menuList: (provided, state) => ({
    ...provided,
    padding: '0',
    maxHeight: `${state?.selectProps?.menuListHeight || '170'}px`,
    overflowX: 'hidden',
    overflowY: 'scroll'
  }),
  menu: provided => ({
    ...provided,
    borderRadius: '0px',
    filter: 'drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.15))'
  })
};

const Select = ({
  className,
  name,
  label,
  placeholder,
  selectedOption,
  hideArrow,
  options,
  handleChange,
  handleInputChange,
  isSearchable,
  error,
  errorMessage,
  errorPlaceholder,
  isDisabled,
  hideLabel,
  menuListHeight,
  useSecondaryColor,
  noOptionsMessage,
  ...props
}) => {
  const message = error ? errorMessage : '';
  const errorPlaceholderMessage = errorPlaceholder ? 'message' : '';

  return (
    <WrapperStyled
      className={className}
      error={error}
      data-testid={props['data-testid'] || name}
      data-pendoid={props['data-pendoid']}
    >
      <LabelStyled htmlFor={name} hideLabel={hideLabel}>
        {label}
      </LabelStyled>
      <ReactSelect
        value={selectedOption}
        placeholder={placeholder}
        hideArrow={hideArrow}
        onChange={handleChange}
        onInputChange={handleInputChange}
        tabSelectsValue={false}
        options={options}
        styles={customStyles}
        isSearchable={isSearchable}
        isDisabled={isDisabled}
        aria-label={name}
        name={name}
        menuListHeight={menuListHeight}
        useSecondaryColor={useSecondaryColor}
        noOptionsMessage={() => noOptionsMessage}
        instanceId="custom"
      />
      <MessageStyled
        error={error}
        hide={!Boolean(message)}
        data-testid="select-message"
      >
        {message || errorPlaceholderMessage}
      </MessageStyled>
    </WrapperStyled>
  );
};

Select.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  hideArrow: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isSearchable: PropTypes.bool,
  hideLabel: PropTypes.bool,
  menuListHeight: PropTypes.string,
  selectedOption: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    })
  ),
  handleChange: PropTypes.func,
  useSecondaryColor: PropTypes.bool,
  noOptionsMessage: PropTypes.string
};

Select.defaultProps = {
  hideArrow: false,
  isDisabled: false,
  options: [],
  isSearchable: true,
  hideLabel: false,
  handleChange: () => {},
  menuListHeight: '170',
  useSecondaryColor: false,
  noOptionsMessage: 'No options'
};

export default Select;
