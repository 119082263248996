import navbar from './navbar';

export default {
  duet_profile_title: navbar.navbar_program_profile,
  duet_profile_participants: 'Total program participants:',
  duet_profile_last_calculated:
    'Last calculated on: {{profileLastGenerated}}, with {{participantsTotal}}/{{participantsInvited}} program participant(s)',
  duet_profile_participants_not_included_alert:
    'There are {{additionalParticipants}} additional program participants who completed the Duet assessment since {{profileLastGenerated}}. Their responses are not included in the results below. We recommend generating your Program Profile again to include these results.',
  duet_profile_participants_not_included_note:
    'Note: Generating a new Program Profile will change all available Duet scores for applicants.',
  duet_profile_cross_category_title: 'Cross-category Ranking',
  duet_profile_cross_category_message_binary:
    'Cross-category Ranking shows the frequency that program participants selected each category out of the total number of possible comparisons across all categories',
  duet_profile_cross_category_message_likert:
    'Cross-category Ranking shows the degree to which program participants endorsed each category, taking into account both how often each category was chosen and the strength of those choices',
  duet_profile_characteristic_title: 'Within Category Ranking',
  duet_profile_characteristic_message_binary:
    'Within Category Ranking shows the frequency that program participants selected each characteristic out of the total number of possible comparisons across all categories',
  duet_profile_characteristic_message_likert:
    'Within Category Ranking show the degree to which program participants endorsed each characteristic, taking into account both how often each characteristic was chosen and the strength of those choices',
  duet_profile_empty_state_initial_title:
    'Your Program Profile is not yet available.',
  duet_profile_empty_state_invitedUsers_title:
    'Your Program Profile is not yet available.',
  duet_profile_empty_state_partial_title:
    'Your Program Profile can be generated.',
  duet_profile_empty_state_initial_message:
    'You need to invite your program participants to take Duet. Please go to the Users page to invite new users or edit permissions to enable existing users to take the Duet assessment. Once program participants have completed Duet, you will be able to generate your Program Profile here.',
  duet_profile_empty_state_invitedUsers_message:
    'At this time, none of your program participants have completed Duet. You can set or edit the deadline for program participants on the Manage tab. We strongly recommend that you wait until at least 10 participants from your program have completed Duet for a more accurate representation of your program. Once program participants have completed Duet, you will be able to generate your Program Profile here.',
  duet_profile_empty_state_partial_message:
    'You can generate your Program Profile at any time. To encourage participation, you can also set or change a deadline for your program participants on the Manage page. We strongly recommend that you wait until at least 10 participants from your program have completed Duet for a more accurate representation of your program.',
  duet_profile_empty_state_initial_button: 'Invite users to Duet',
  duet_profile_empty_state_invitedUsers_button: 'Manage program participants',
  duet_profile_empty_state_partial_button: 'Generate Program Profile',
  duet_profile_number_of_assessments_completed: 'Total Completed Assessments: ',
  duet_profile_coming_soon_title: 'This feature is coming soon!',
  duet_profile_coming_soon_message:
    'We are working on the ability for you to set up your own Duet Program Profile generation date, and view the Duet completion status of program participants. Please stay tuned.',
  duet_profile_tab_setup: 'Manage',
  duet_profile_tab_profile: 'Program Profile',
  duet_profile_calculate_button: 'Generate Program Profile',
  duet_profile_modal_title: 'Generate Program Profile',
  duet_profile_modal_subtitle:
    'Are you sure you want to generate your Duet Program Profile?',
  duet_profile_modal_message:
    'This will generate a new Program Profile based on your current program participants’ aggregated responses.',
  duet_profile_modal_confirmation1:
    'I understand that if I have an existing Duet Program Profile, it will be replaced and the applicants’ Duet scores will be recalculated based on the new Program Profile',
  duet_profile_modal_confirmation2:
    'I understand that using Duet scores calculated based on multiple versions of my Program Profile during the same application cycle is not advised and may skew the evaluation of the applicants. I will ensure that existing applicant Duet scores recorded in other systems are replaced with the new Duet scores',
  duet_profile_manage_title: 'Manage your Program Profile for {{cycle}}',
  duet_profile_deadline_title: 'Duet program participant deadline',
  duet_profile_deadline_text:
    'You can choose a deadline for your program participants to complete Duet. Reminder emails will be sent to all program participants encouraging them to take the assessment before this deadline. You may change this date at any time.',
  duet_profile_deadline_note:
    '<strong>Note:</strong> Your program participants will not be able to complete Duet and contribute to your Program Profile after this deadline has passed, unless you change the date here. Your applicants will still be able to complete Duet until your program’s distribution deadline.',
  duet_profile_deadline_label:
    'Program Participant Deadline <strong>(Optional)</strong> (YYYY-MM-DD)',
  duet_profile_cancel: 'Cancel',
  duet_profile_update: 'Update',
  duet_profile_participants_title: 'Duet program participants',
  duet_profile_participants_text:
    'Use this page to track the completion status of your Duet program participants. To invite faculty and non-faculty program participants to complete Duet, go to the User management page. We recommend <i>at least</i> 10 program participants, half faculty and half non-faculty, to generate a statistically significant Program Profile (there is no limit on the number of program participants).',
  duet_profile_go_to_user_management: 'Go to User management',
  duet_col_email: 'Email',
  duet_col_givenname: 'First name',
  duet_col_surname: 'Last name',
  duet_col_roles: 'Role',
  duet_col_duetStatus: 'Duet status',
  duet_status_completed: 'Completed',
  duet_status_not_completed: 'Not completed',
  duet_status_invited: 'Invited'
};
