import React from 'react';
import PropTypes from 'prop-types';

import { TextButtonStyled } from './text-button.styles';

const TextButton = ({
  className,
  handleClick,
  tabIndex,
  children,
  ...props
}) => (
  <TextButtonStyled
    className={className}
    onClick={handleClick}
    tabIndex={tabIndex}
    onKeyPress={e => {
      e.preventDefault();
      if (e.which === 32 || e.which === 13) {
        handleClick();
      }
    }}
    role="button"
    data-testid={props['data-testid'] ? props['data-testid'] : 'text-button'}
  >
    {children}
  </TextButtonStyled>
);

TextButton.propTypes = {
  className: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
  tabIndex: PropTypes.string,
  'data-testid': PropTypes.string
};

TextButton.defaultProps = {
  className: '',
  tabIndex: '0'
};

export default TextButton;
