import { DataGridPro } from '@mui/x-data-grid-pro';
import { styled as MuiStyled } from '@mui/material/styles';
import theme from 'shared/css/theme';

export const DataGridStyled = MuiStyled(DataGridPro)`
  .MuiDataGrid-columnHeaderTitle {
    font-family: 'Lato', 'Roboto', sans-serif;
    ${theme.typography(14, 'bold')};
  }
  .MuiDataGrid-cell {
    font-family: 'Lato', 'Roboto', sans-serif;
  }
  .MuiDataGrid-toolbarContainer > button {
    color: ${theme.colors.primary.default};
    margin: ${theme.spacing(1)} 0;
  }
`;
