import colors from './colors';
import config from './config';
import spacing from './spacing';

const wrapper = {
  backgroundColor: '#ffffff',
  height: '100%',
  margin: `0 0 ${spacing?.(4)} 0`
};

const header = {
  border: '2px solid transparent',
  height: '100%',
  minHeight: spacing?.(16),
  padding: `${spacing?.(4)} ${spacing?.(6)}`,
  focus: {
    borderColor: colors.primary?.default,
    borderRadius: config.borderRadius
  }
};

const content = {
  padding: {
    bottom: spacing?.(4),
    left: spacing?.(6),
    right: spacing?.(6),
    top: 0
  }
};

export default {
  content,
  header,
  wrapper
};
