import React from 'react';
export default ({ className, stroke = '#00A59B' }) => (
  <svg
    className={className}
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_826_4509)">
      <path
        d="M20 0.496094H5C2.79086 0.496094 1 2.28695 1 4.49609V19.4961C1 21.7052 2.79086 23.4961 5 23.4961H20C22.2091 23.4961 24 21.7052 24 19.4961V4.49609C24 2.28695 22.2091 0.496094 20 0.496094Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 12.5H17C17.2652 12.5 17.5196 12.3946 17.7071 12.2071C17.8946 12.0196 18 11.7652 18 11.5V8.5"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 9.49609L7 12.4961L10 15.4961"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_826_4509">
        <rect width="24" height="24" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);
