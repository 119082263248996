import React from 'react';

export default () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Circle Checkmark</title>
      <circle
        cx="16"
        cy="16"
        r="15"
        fill="white"
        stroke="#4DA81F"
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.3931 11.1899C23.7023 11.499 23.7023 12.0003 23.3931 12.3095L14.6848 21.0178C14.3756 21.327 13.8744 21.327 13.5652 21.0178L9.60687 17.0595C9.29771 16.7503 9.29771 16.249 9.60687 15.9399C9.91604 15.6307 10.4173 15.6307 10.7265 15.9399L14.125 19.3384L22.2735 11.1899C22.5827 10.8807 23.084 10.8807 23.3931 11.1899Z"
        fill="#4DA81F"
      />
    </svg>
  );
};
