import navbar from './navbar';

export default {
  manage_users_selected_users: 'Selected users',
  manage_users_title: 'Manage Users',
  users_title: navbar.navbar_users,
  users_search: 'Search users',
  users_add_button: 'Add user',
  users_export_button: 'Export',
  users_export_all: 'All users',
  users_export_selected: 'Selected users',
  users_col_name: 'Name',
  users_col_firstName: 'First Name',
  users_col_lastName: 'Last Name',
  users_col_email: 'Email',
  users_col_program: 'Program',
  users_col_programs_and_permissions: 'Programs (Permissions)',
  users_col_roles: 'Role',
  users_col_all: 'All',
  users_col_createdAt: 'Created At',
  users_col_actions: 'Actions',
  users_col_permissions: 'Permissions',
  users_selected: '{{count}} user selected',
  users_selected_plural: '{{count}} users selected',
  users_modal_add_title: 'Add user',
  users_modal_add_alert: 'has been sent an invite email',
  users_invite_add_confirmation_title:
    'An account with this email address already exists.',
  users_invite_internal_error: 'User is an internal account!',
  users_invite_add_confirmation_message:
    'Do you still want to proceed with creating this user? The permissions will be overwritten.',
  users_modal_add_confirmation_message: 'Do you still want to send an invite?',
  users_invite_email_placeholder: 'For example, john@doe.com',
  users_invite_select_permission: 'Select user’s permissions for this program',
  users_invite_permissions_title: 'Permissions',
  users_invite_permissions_not_available_admin:
    '{{product}} is not currently available for your program. Please contact us for more information.',
  users_invite_permissions_not_available_team:
    'This product is not a requirement for your program. Please connect with us via the chat bubble below to request information.',
  users_invite_permissions_general_title: 'General',
  users_invite_permissions_error: 'Please select at least one permission',
  users_invite_review_title: 'Review',
  users_invite_review_text:
    'Please review the permissions before sending the invite.',
  users_invite_review_not_available: ' - not available',
  users_invite_review_send_invite: 'Send Invite',
  users_invite_exit: 'Exit',
  users_invite_success_title: 'Success!',
  users_invite_success_message: '{{email}} has been sent an email invitation.',
  users_invite_error_title: 'Oh no!',
  users_invite_error_message:
    'We couldn’t send your invitation to {{email}}. Please try inviting this user again. Should you continue to experience issues, please contact us.',
  users_modal_edit_title: 'Edit user',
  users_modal_edit_button: 'Edit user',
  users_modal_edit_alert: '{{email}} has been updated successfully',
  users_modal_delete_title: 'Delete user',
  users_modal_delete_button: 'Delete user',
  users_modal_delete_message: 'Are you sure you want to delete user {{email}}?',
  users_modal_delete_alert: `By deleting this user, you are removing this person's access to Acuity Insights. You will be able to invite this person again in the future, if desired.`,
  users_modal_export_selected_title: 'Export users',
  users_modal_export_selected_message:
    'Are you sure you want to export the data selected?',
  users_modal_export_selected_alert: 'Please select users to export.',
  users_modal_export_all_title: 'Export all users',
  users_modal_export_all_message: 'Are you sure you want to export all users?',
  users_modal_export_all_alert:
    'Something went wrong, please try again later or refresh the page.',
  users_modal_view_title: 'Program(s) and permissions',
  users_modal_view_subtitle: 'This user has access to these programs:',
  users_all_programs_admin: 'All programs - Admin',
  users_no_programs_assigned: 'No programs assigned'
};
