import React, { useState, useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useHistory, useLocation } from 'react-router-dom';
import { datadogLogs } from '@datadog/browser-logs';
import { useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { pendoIdentify } from 'pendo-init';
import { flushErrorsState } from 'shared/helpers/flushErrorsState';
import {
  programFilterAtom,
  userPermissionsPerSchool
} from 'shared/store/program-filter';
import { SCHOOLS } from 'graphql/queries/school';
import SelectProgram from 'components/select-program/select-program';
import { WrapperStyled } from 'shared/components/program-cycle-selector/program-cycle-selector.styles';
import {
  NON_ANALYTICS_PERMISSIONS,
  ANALYTICS_PERMISSIONS,
  STORAGE_KEY
} from 'shared/enums';
import {
  generateAdmissionCycles,
  generateAdmissionCycleOptions,
  generateSchools,
  getLabel,
  getSelectedSchool,
  updateIntercomProperties
} from './helpers';

import isAltan from 'shared/helpers/isAltan';
import hasPermissionOrAdmin from '../../helpers/hasPermissionOrAdmin';

const SelectorNav = ({ user }) => {
  const history = useHistory();

  const programFilter = useRecoilValue(programFilterAtom);
  const setProgramFilter = useSetRecoilState(programFilterAtom);
  const permissionsArray = useRecoilValue(userPermissionsPerSchool);

  const [schoolSearch, setSchoolSearch] = useState('');
  const [schoolLabel, setSchoolLabel] = useState('Select A School');
  const [cycleLabel, setCycleLabel] = useState('Select A Cycle');
  const [schools, setSchools] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState(programFilter?.schoolId);
  const [admissionCycles, setAdmissionCycles] = useState([]);
  const [page, setPage] = useState(1);

  const { t } = useTranslation();

  const { search } = useLocation();
  const schoolId =
    new URLSearchParams(search).get('schoolId') ||
    localStorage.getItem('passthrough');

  let initialLoad = true;

  const [
    schoolQuery,
    { loading, data: { schools: { total: maxPages = 0 } = {} } = {}, error }
  ] = useLazyQuery(SCHOOLS, {
    onCompleted: data => {
      // Initial data load
      if (initialLoad) {
        initialLoad = false;
        setSchools([...generateSchools({ schools: data.schools.docs || [] })]);
      }
    }
  });

  const getSchools = () =>
    schoolQuery({
      variables: {
        page: page,
        limit: 20,
        search: schoolSearch,
        selectedSchoolId: programFilter?.schoolId || ''
      }
    });

  // Helper to set host query param for Analytics schools.
  // Removes the param if a non-analytics school is passed in.
  const setHostQueryParam = school => {
    const host = school?.qlik?.userDirectory?.toLowerCase();
    history.push({
      search: host ? `?host=${host}` : ``
    });
  };

  useEffect(() => {
    if (selectedSchool.value) {
      const tempSelectedSchool = getSelectedSchool({
        schools,
        schoolId: programFilter?.schoolId || schoolId
      });
      const tempAdmissionCycles = generateAdmissionCycles({
        school: tempSelectedSchool,
        schools
      });
      handleUpdateRecoil({
        school: tempSelectedSchool,
        admissionCycle:
          { value: programFilter?.admissionCycle } ||
          tempAdmissionCycles?.at(-1) ||
          []
      });
    }
  }, [selectedSchool.value]);

  useEffect(() => {
    if (schools?.length > 0) {
      const tempSelectedSchool = getSelectedSchool({
        schools,
        schoolId: programFilter?.schoolId || schoolId
      });
      const tempAdmissionCycles = generateAdmissionCycles({
        school: tempSelectedSchool,
        schools
      });

      setSelectedSchool(tempSelectedSchool);
      setAdmissionCycles(tempAdmissionCycles);
      setHostQueryParam(tempSelectedSchool);
    }
  }, [schools, programFilter]);

  const admissionCycleOptions = generateAdmissionCycleOptions({
    admissionCycles
  });

  const selectedCycle =
    admissionCycleOptions.find(
      ({ value }) => value === programFilter?.admissionCycle
    ) || admissionCycleOptions?.at(-1);

  const handleUpdateRecoil = ({ school, admissionCycle }) => {
    const admissionCycleValue = school?.applicationCycles?.includes(
      admissionCycle?.value
    )
      ? admissionCycle?.value
      : school?.applicationCycles?.at(-1);
    const { testMasterId = '', duetDeadlineAt = null } =
      school?.productCycles?.find(cycle => cycle.key === admissionCycleValue) ||
      {};

    setProgramFilter({
      admissionCycle: admissionCycleValue,
      schoolId: school?.value,
      schoolLabel: school?.label,
      testMasterId,
      duetDeadlineAt,
      salesforceAccountId: school?.salesforceAccountId,
      userDirectory: school?.qlik?.userDirectory,
      products: school?.products
    });
    updateIntercomProperties({
      program_selection: school?.label,
      cycle_and_test_selection: admissionCycleValue,
      roles: user?.roles,
      createdAt: user?.createdAt || new Date()
    });
  };

  useEffect(() => {
    getSchools().then(data => {
      setSchools([
        ...schools,
        ...generateSchools({ schools: data?.data?.schools?.docs || [] })
      ]);
    });
  }, [page, schoolSearch, programFilter.duetDeadlineAt]);

  useEffect(() => {
    const underImpersonation = localStorage.getItem(
      STORAGE_KEY.REAUTHENTICATE_TOKEN
    );
    if (!underImpersonation) {
      localStorage.setItem('programFilter', JSON.stringify(programFilter));
      pendoIdentify({
        visitor: {
          id: user?._id,
          userId: user?._id,
          isAltan: isAltan(user?.emails),
          role: user?.roles.find(role =>
            role.match(/school-admin|team-member|admin/)
          ),
          permissions: permissionsArray.find(
            ({ schoolId }) => schoolId === programFilter?.schoolId
          )?.keys,
          uses_admissions: hasNonAnalytics,
          uses_analytics: isUsingAnalytics,
          screenWidth: window.screen.width,
          screenHeight: window.screen.height,
          viewportWidth: window.innerWidth,
          viewportHeight: window.innerHeight
        },
        account: {
          id: selectedSchool?.label,
          schoolLabel: selectedSchool?.label,
          testMasterId: programFilter.testMasterId,
          opportunityId: selectedSchool?.salesforceAccountId,
          salesforce_account_ID: selectedSchool?.salesforceAccountId
        }
      });
    }
  }, [selectedSchool, programFilter, user?._id]);

  const handleScroll = e => {
    const { offsetHeight, scrollTop, scrollHeight } = e.target;
    if (
      Math.ceil(offsetHeight + scrollTop) >= Math.floor(scrollHeight) &&
      !loading &&
      maxPages > page
    ) {
      setPage(page + 1);
    }
  };

  if (error) {
    datadogLogs.logger.error(
      'Insights "Select school" header bar SCHOOLS query error',
      {
        userId: user?._id
      },
      error
    );
  }

  const permissions =
    user?.schoolPermissions?.find(
      schoolPermission => schoolPermission.schoolId === programFilter.schoolId
    )?.keys || [];

  const hasNonAnalytics = hasPermissionOrAdmin(
    NON_ANALYTICS_PERMISSIONS,
    user?.roles,
    permissions
  );

  const isUsingAnalytics = hasPermissionOrAdmin(
    ANALYTICS_PERMISSIONS,
    user?.roles,
    permissions
  );

  return (
    <WrapperStyled>
      <SelectProgram
        title={t('select_program_filter')}
        name="select-program"
        data-testid="select-program"
        options={schools}
        selectedObject={selectedSchool}
        selectedOption={getLabel(selectedSchool, schoolLabel, setSchoolLabel)}
        handleChange={option => {
          flushErrorsState({
            errors: [],
            name: 'schoolId',
            value: option,
            updateErrors: () => {},
            updateState: option => {
              handleUpdateRecoil({
                school: option || selectedSchool,
                admissionCycle: admissionCycleOptions?.at(-1) || []
              });
            }
          });
        }}
        handleInputChange={schoolSearchInput => {
          setSchools([]);
          setPage(1);
          if (schoolSearchInput.length > 3 || schoolSearchInput.length === 0) {
            flushErrorsState({
              errors: [],
              name: 'schoolIds',
              value: schoolSearchInput,
              updateErrors: () => {},
              updateState: option => {
                setSchoolSearch(option);
                if (schoolSearchInput.length === 0) {
                  getSchools();
                }
              }
            });
          }
        }}
        handleScroll={handleScroll}
      />
      {hasNonAnalytics && admissionCycles.length > 0 && (
        <SelectProgram
          title={t('select_cycle_filter')}
          name="admissionCycle"
          data-testid="select-cycle"
          options={admissionCycleOptions}
          selectedObject={selectedCycle}
          selectedOption={getLabel(selectedCycle, cycleLabel, setCycleLabel)}
          handleChange={option => {
            flushErrorsState({
              errors: [],
              name: 'admissionCycle',
              value: option,
              updateErrors: () => {},
              updateState: option => {
                handleUpdateRecoil({
                  school: selectedSchool,
                  admissionCycle: option
                });
              }
            });
          }}
        />
      )}
    </WrapperStyled>
  );
};

export default SelectorNav;
