import React from 'react';

export default ({
  className,
  onKeyPress,
  handleClick,
  tabIndex = '1',
  ...rest
}) => (
  <span data-testid={rest['data-testid']}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
      onClick={handleClick}
      onKeyPress={onKeyPress}
      tabIndex={tabIndex}
    >
      <title>Close</title>
      <rect width="24" height="24" fill="none" />
      <path
        d="M7.21739 16.7826L16.7826 7.21739"
        stroke="#545454"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.7826 16.7826L7.21739 7.21739"
        stroke="#545454"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </span>
);
