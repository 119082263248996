export default {
  learner_id: "Identifiant de l'apprenant",
  graduation_year: "Année de fin d'études",
  learner_campus: "Campus de l'apprenant",
  phase: 'Phase',
  catalog_code: 'Numéro du cours',
  academic_year: 'Année scolaire',
  event_site: 'Lieu',
  event_campus: 'Campus éducatif'
};
