export default ({ currentPage, totalPages } = {}) => {
  let firstItem = 1;
  const limit = totalPages > 5 ? 5 : totalPages;
  if (totalPages >= 5) {
    const diff = totalPages - currentPage;
    firstItem =
      currentPage <= 2
        ? 1
        : diff <= 1
        ? currentPage - 4 + diff
        : currentPage - 2;
  }
  return [
    firstItem,
    ...Array.from({ length: limit - 1 }, (item, index) => firstItem + index + 1)
  ];
};
