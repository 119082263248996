import styled from 'styled-components/macro';
import { devices } from 'shared/css/devices';
import { Card } from 'components';

export const IconWrapperStyled = styled.div`
  ${({ theme: { spacing } }) => `
  display: flex;
  width: 120px;
  justify-content: center;
  padding: 0 ${spacing?.(3.25)};

  @media ${devices.tablet} {
    display: block;
  }
  `}
`;

export const CardStyled = styled(Card)`
  ${({ theme: { spacing } }) => `
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.08);
    margin: ${spacing?.(2)} 0;
    padding: ${spacing?.(3)} ${spacing?.(4)};
    @media ${devices.tablet} {
      flex-direction: row;
    }
  `}
`;

export const WrapperStyled = styled.div`
  ${({ theme: { colors, spacing, typography } }) => `
    display: flex;
    flex-direction: column;
    align-items: center;
    h3 {
      ${typography(20, 700)};
      color: ${colors?.grey?.darkest};

      & + a, p {
        padding: ${spacing?.(1)} 0 ${spacing?.(2)};
        svg {
          margin-left: 10px;
        }
      }
    }
    @media ${devices.tablet} {
      align-items: flex-start;
      margin-left: ${spacing?.(5)};
    }
  `};
`;
