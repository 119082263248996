import styled from 'styled-components';
import Link from '../link/link';
import ArrowRight from 'assets/icons/arrow-right';

export const ArrowRightStyled = styled(ArrowRight)`
  margin: 0 6px -2px 8px;
`;

export const LinkStyled = styled(Link)`
  text-decoration: none;
  color: ${({ theme: { colors } }) => colors?.primary?.default};
  :focus {
    padding: 5px;
    border: 1px dashed ${({ theme: { colors } }) => colors?.primary?.default};
  }
`;

export const BreadcrumbContainerStyled = styled.div`
  display: flex;
  align-items: baseline;
  padding-bottom: 2rem;
`;
