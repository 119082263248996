import React from 'react';

export default ({ className }) => (
  <svg
    className={className}
    width="49"
    height="49"
    viewBox="0 0 49 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <circle cx="24.5" cy="24.5" r="24.5" fill="#FEF1EC" />
    <path
      d="M28.1667 31.0416V33.9166C28.1667 34.9752 27.3085 35.8333 26.25 35.8333H22.4167C21.3581 35.8333 20.5 34.9752 20.5 33.9166V31.0416"
      stroke="#F26F3E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.3334 9.95831V11.875"
      stroke="#F26F3E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.9167 23.375H12.8334"
      stroke="#F26F3E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.7917 13.7916L15.1972 15.1422"
      stroke="#F26F3E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M37.75 23.375H35.8334"
      stroke="#F26F3E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.875 13.7916L33.4695 15.1422"
      stroke="#F26F3E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.3914 31.0417H28.2753C31.1808 29.5994 33.0017 26.6185 32.9583 23.375C32.8744 18.6467 29.0616 14.8339 24.3333 14.75C19.6031 14.834 15.7895 18.6498 15.7083 23.3801C15.667 26.6218 17.4875 29.6002 20.3914 31.0417V31.0417Z"
      stroke="#F26F3E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.3334 35.8333V38.7083"
      stroke="#F26F3E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.3334 26.25V31.0417"
      stroke="#F26F3E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.4584 23.375L24.3334 26.25L27.2084 23.375"
      stroke="#F26F3E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
