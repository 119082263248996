export default {
  reset_email_title: 'Réinitialisez votre mot de passe',
  reset_email_return: 'Retourner à la page de connexion',
  reset_email_message:
    'Veuillez vérifier votre boîte de réception. Un courriel de réinitialisation du mot de passe a été envoyé à',
  reset_password_title: 'Réinitialisez votre mot de passe',
  reset_password_success_title: 'Réinitialisation du mot de passe réussie !',
  reset_password_success_1:
    'Votre mot de passe a été réinitialisé avec succès.',
  reset_password_success_2:
    'Cliquez sur le bouton ci-dessous pour accéder à votre compte.',
  reset_password_page_title: 'Réinitialiser mot de passe',
  reset_password_error:
    "L'envoi du courriel a échoué. Veuillez réessayer ou contacter notre équipe d'assistance."
};
