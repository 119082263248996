import navbar from './navbar';

export default {
  applicants_title: navbar.navbar_assessment_results,
  applicants_subtitle: 'Accédez aux résultats des candidats',
  applicants_filter_zScore: 'Filtrer le z-Score Casper',
  applicants_filter_zScore_greaterOrEqual: 'est supérieur ou égal à',
  applicants_filter_zScore_greater: 'est supérieur à',
  applicants_filter_zScore_lesserOrEqual: 'est inférieur ou égal à',
  applicants_filter_zScore_lesser: 'est inférieur à',
  applicants_filter_zScore_between: 'se situe entre',
  applicants_filter_zScore_exactly: 'est égal à',
  applicants_filter_apply: 'Appliquer le filtre',
  applicants_columns_configuration: 'Configurer les colonnes',
  applicants_filter_reset: 'Réinitialiser',
  applicants_axis_x: 'z-Score Casper',
  applicants_axis_y: 'Nombre de candidats',
  applicants_table_title: 'Candidats',
  applicants_table_search: 'Chercher par nom, courriel ou ID',
  applicants_table_export: 'Exporter',
  applicants_table_export_all: 'Tous les candidats au format .csv',
  applicants_table_export_selected: 'Les candidats sélectionnés au format .csv',
  applicants_table_applicant_selected: '{{count}} candidat sélectionné',
  applicants_table_applicant_selected_plural:
    '{{count}} candidats sélectionnés',
  applicants_export_message1:
    'Êtes-vous sûr(e) de vouloir exporter le candidat ?',
  applicants_export_message1_plural:
    'Êtes-vous sûr(e) de vouloir exporter les {{totalApplicants}} candidats ?',
  applicants_export_message2:
    'Toutes les colonnes et données du candidat seront exportées.',
  applicants_export_message2_plural:
    'Toutes les colonnes et données des {{totalApplicants}} candidats seront exportées.',
  applicants_export_title: 'Exporter les candidats',
  applicants_export_selected_warning:
    'Veuillez sélectionner des candidats à exporter.',
  applicants_red_flag_title: `Commentaire de l'évaluateur`,
  applicants_snapshot_modal_comments_title: 'S - Commentaire(s)',
  applicants_snapshot_modal_ratings_title: 'S - Score(s)',
  applicants_snapshot_modal_reviewer: 'Examinateur :',
  applicants_snapshot_modal_comments: 'Commentaire',
  applicants_snapshot_modal_ratings: 'Score',
  applicants_config_modal_title: 'Configurer les colonnes',
  applicants_config_modal_subtitle:
    'Sélectionnez les colonnes que vous souhaitez voir apparaître dans votre tableau des candidat(e)s.',
  applicants_config_modal_alert:
    "Note : Les modifications apportées ici seront appliquées pour toutes les périodes d'admission de ce programme et seront reflétées pour tous les utilisateurs.",
  applicants_config_modal_update_button: 'Mettre à jour',
  applicants_config_modal_success:
    "Les colonnes ont été configurées avec succès pour tous les membres de l'équipe."
};
