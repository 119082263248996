import styled, { css, keyframes } from 'styled-components/macro';
import { fullCenter } from 'shared/css/common-classes';

const load8 = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const baseButtonStyles = css`
  ${({ theme: { buttons = {}, transitions = {} }, category, size }) => `
    ${fullCenter};
    background-color: ${buttons?.[category]?.backgroundColor};
    border: ${buttons?.[category]?.border};
    border-radius: ${buttons?.[category]?.borderRadius};
    color: ${buttons?.[category]?.color};
    font-size: ${buttons?.[category]?.fontSize?.[size]};
    min-width: ${buttons?.[category]?.minWidth};
    padding: ${buttons?.[category]?.padding?.[size]};
    transition: all ${transitions?.fast} ease-in-out;

    &:hover {
      background-color: ${buttons?.[category]?.hover?.backgroundColor};
      border-color: ${buttons?.[category]?.hover?.borderColor};
      box-shadow: ${buttons?.[category]?.hover?.boxShadow};
      color: ${buttons?.[category]?.hover?.color};
    }

    &:focus {
      background-color: ${buttons?.[category]?.focus?.backgroundColor};
      border-color: ${buttons?.[category]?.focus?.borderColor};
      box-shadow: ${buttons?.[category]?.focus?.boxShadow};
      color: ${buttons?.[category]?.focus?.color};
    }

    &:active {
      background-color: ${buttons?.[category]?.active?.backgroundColor};
      border-color: ${buttons?.[category]?.active?.borderColor};
      box-shadow: ${buttons?.[category]?.active?.boxShadow};
      color: ${buttons?.[category]?.active?.color};
    }
  `}
`;

export const LoaderStyled = styled.div`
  animation: ${load8} 1.1s infinite linear;
  ${({ theme: { colors = {}, spacing }, size = '16px' }) => `
    border-top: 3px solid ${colors.grey?.light};
    border-right: 3px solid ${colors.grey?.light};
    border-bottom: 3px solid ${colors.grey?.light};
    border-left: 3px solid ${colors.grey?.default};
    border-radius: 50%;
    height: ${size};
    margin: 0 ${spacing?.(1)};
    width: ${size};
  `}
`;

export const AnimationStyled = css`
  ${({ show }) => `
    height: ${show ? 'auto' : '0px'};
    visibility: ${show ? 'visible' : 'hidden'};
    transition: all 1ms ease-in-out;
    ${
      !show
        ? `
          padding: 0;
        `
        : ''
    }
  `}
`;
