import colors from './colors';
import config from './config';
import spacing from './spacing';

const box = {
  backgroundColor: {
    success: colors?.success?.lightest,
    info: '#E6F7FF',
    celebrate: '#E6F7FF',
    alert: colors?.alert?.lightest,
    error: colors?.danger?.lightest
  },
  borderRadius: config.borderRadius,
  border: {
    success: `1px solid ${colors?.success?.lighter}`,
    info: '1px solid #91D5FF',
    celebrate: '1px solid #91D5FF',
    alert: `1px solid ${colors?.alert?.lighter}`,
    error: `1px solid ${colors?.danger?.lighter}`
  },
  color: {
    success: colors?.grey?.darker,
    info: colors?.grey?.darker,
    celebrate: colors?.grey?.darker,
    alert: colors?.grey?.darker,
    error: colors?.grey?.darker
  },
  margin: `${spacing?.(2)} 0`,
  minHeight: `${spacing?.(6)}`,
  padding: `${spacing?.(2)}`
};

const title = {
  color: colors?.primary?.darkest,
  marginBottom: `${spacing?.(1.5)}`
};

export default { box, title };
