import React from 'react';
import { SelectionProcessCardStyled } from './selection-process-card.styles';
import DemographicImage from 'assets/icons/demographic-banner';
import ExternalLinkIcon from 'assets/icons/arrow-external-link';
import { ExternalLink } from 'components';

const SelectionProcessCard = ({ t, extraTopMargin = false }) => {
  return (
    <SelectionProcessCardStyled extraTopMargin={extraTopMargin}>
      <DemographicImage />
      <ExternalLink
        data-pendoid="home_recommended_selection_link"
        href={t?.('home_recommended_selection_link')}
        target="_blank"
      >
        {t?.('home_recommended_selection')}
        <ExternalLinkIcon />
      </ExternalLink>
    </SelectionProcessCardStyled>
  );
};

export default SelectionProcessCard;
