import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import { CURRENT_USER } from 'graphql/queries/user';
import intercomSetup from 'shared/helpers/intercomSetup';
import { getSingleRole } from 'shared/helpers/roles';
import { DocumentTitle } from 'shared/components';

const convertLanguage = lang => {
  return lang.startsWith('fr') ? 'fr' : 'en';
};

export default ({ component: Component, componentProps, title, ...rest }) => {
  const { i18n } = useTranslation();
  intercomSetup();

  useQuery(CURRENT_USER, {
    onCompleted: ({ user }) => {
      if (user) {
        if (
          convertLanguage(i18n.languages[0]) !==
          convertLanguage(navigator.language)
        ) {
          i18n.changeLanguage(convertLanguage(navigator.language));
        }
        intercomSetup({
          userProperties: {
            user_role: getSingleRole(user?.roles),
            user_since: format(
              new Date(user?.createdAt || new Date()),
              'MMM d, y h:mm:ss a'
            )
          }
        });
      }
    }
  });

  if (process.env.REACT_APP_MAINTENANCE_MODE === 'true') {
    return <Redirect to="/503" />;
  }

  return (
    <Route
      {...rest}
      render={props => (
        <DocumentTitle title={title}>
          <Component {...props} {...componentProps} />
        </DocumentTitle>
      )}
    />
  );
};
