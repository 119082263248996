import React from 'react';

export default ({ className, color = '#A8071A' }) => (
  <svg
    viewBox="0 0 10 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <title>Not Checked</title>
    <path
      d="M5.70831 4L9.29718 0.333203C9.35734 0.272266 9.30675 0.179688 9.21378 0.179688H8.12277C8.05851 0.179688 7.99698 0.204297 7.9546 0.246484L4.99464 3.27109L2.03468 0.246484C1.99366 0.204297 1.93214 0.179688 1.86652 0.179688H0.7755C0.682531 0.179688 0.631945 0.272266 0.692102 0.333203L4.28097 4L0.692102 7.6668C0.678626 7.68038 0.669981 7.69699 0.667193 7.71466C0.664404 7.73233 0.667589 7.75031 0.67637 7.76648C0.685151 7.78264 0.699159 7.79631 0.71673 7.80585C0.734302 7.81539 0.754699 7.82041 0.7755 7.82031H1.86652C1.93077 7.82031 1.9923 7.7957 2.03468 7.75352L4.99464 4.72891L7.9546 7.75352C7.99562 7.7957 8.05714 7.82031 8.12277 7.82031H9.21378C9.30675 7.82031 9.35734 7.72774 9.29718 7.6668L5.70831 4Z"
      fill={color}
    />
  </svg>
);
