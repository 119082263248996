import React from 'react';

export default ({ className, color = '#4C55A5' }) => (
  <svg
    className={className}
    width="6"
    height="10"
    viewBox="0 0 6 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Arrow link</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.528514 0.52859C0.788864 0.268241 1.21097 0.268241 1.47132 0.52859L5.47132 4.52859C5.73167 4.78894 5.73167 5.21105 5.47132 5.4714L1.47132 9.4714C1.21097 9.73175 0.788864 9.73175 0.528514 9.4714C0.268165 9.21105 0.268165 8.78894 0.528514 8.52859L4.05711 5L0.528514 1.4714C0.268165 1.21105 0.268165 0.78894 0.528514 0.52859Z"
      fill={color}
    />
  </svg>
);
