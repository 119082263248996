export default {
  navbar_home: 'Accueil',
  navbar_admissions: 'Admissions',
  navbar_download_assessment_results:
    'Télécharger les résultats des évaluations',
  navbar_users: 'Utilisateurs',
  navbar_access_logs: 'Accéder aux journaux de données',
  navbar_analytics: 'Analytics',
  navbar_account: 'Mon compte',
  navbar_program_profile: 'Gérer le profil',
  navbar_help: 'Aide',
  navbar_take_duet: 'Compléter Duet',
  navbar_logout: 'Se déconnecter',
  navbar_suite: 'Explorer {{acuity}} Insights',
  navbar_suite_navigate: 'Aller à ...',
  navbar_insights: 'Explorer {{acuity}} Insights',
  navbar_settings: 'Paramètres',
  navbar_user_icon: 'Menu du profil utilisateur',
  navbar_assessment_results: 'Résultats des évaluations'
};
