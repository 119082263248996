import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';
import Calendar from 'assets/icons/calendar';

import {
  DatePickerInputStyled,
  DateContainerStyled,
  DateInputStyled,
  CalendarIconStyled
} from './date-picker-input.styles';

import 'react-datepicker/dist/react-datepicker.css';

const DatePickerInput = ({
  date,
  setDate,
  lang,
  label,
  ignoreDatesBefore,
  dateFormat,
  ...rest
}) => {
  registerLocale('fr', fr);

  useEffect(() => {
    setDefaultLocale(lang);
  }, [lang]);

  return (
    <>
      <DatePickerInputStyled />
      <label htmlFor="date-picker-custom">{label}</label>
      <DateContainerStyled>
        <DateInputStyled data-pendoid={rest['data-pendoid'] || 'date-picker'}>
          <DatePicker
            data-pendoid="date-picker-custom"
            id="date-picker-custom"
            selected={date}
            onChange={date => setDate(date)}
            dateFormat={dateFormat}
            minDate={ignoreDatesBefore ?? null}
            {...rest}
          />
        </DateInputStyled>
        <CalendarIconStyled>
          <Calendar />
        </CalendarIconStyled>
      </DateContainerStyled>
    </>
  );
};

DatePickerInput.propTypes = {
  date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  setDate: PropTypes.func,
  lang: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.object.isRequired
  ]),
  ignoreDatesBefore: PropTypes.instanceOf(Date) || null,
  dateFormat: PropTypes.string
};

DatePickerInput.defaultProps = {
  ignoreDatesBefore: null,
  dateFormat: 'yyyy-MM-dd'
};

export default DatePickerInput;
