export default {
  home_greeting: 'Bienvenue, {{name}} !',
  home_subheader_assessments:
    'Votre portail vers les résultats aux évaluations.',
  home_subheader_analytics: "Votre portail vers l'aperçu complet des données.",
  home_subheader_assessments_analytics:
    "Votre portail vers les résultats aux évaluations et l'aperçu complet des données.",
  home_content_title_admin: 'Bienvenue sur Acuity Insights',
  home_content_subtitle_admin: `Vous pouvez utiliser la page Candidats pour rechercher et accéder aux résultats des programmes. Vous pouvez aussi gérer tous les utilisateurs des programmes et membres de l'équipe sur la page Utilisateurs.`,
  home_applicants_title_program: 'Candidats',
  home_passthrough: '<passthrough>Accédez-y ici</passthrough>',
  home_passthrough_title:
    "Vous cherchez MSPE Builder, l'outil de téléchargement, le dictionnaire de données ou la configuration SAML?",
  home_applicants_subtitle_program: `pour la période d'admission et l'examen sélectionnés`,
  home_results_title_program: 'Nouveaux résultats',
  home_results_subtitle_program: 'depuis le',
  home_files_title_program:
    'Rendez-vous sur la page Fichiers pour télécharger les résultats au format .csv',
  home_files_link_program: 'Voir les fichiers',
  home_scores_title_program: 'Résultats Casper',
  home_scores_subtitle_program:
    'Utilisez les outils de visualisation Insights pour guider votre processus de sélection des candidats',
  home_scores_link_program: 'Voir les résultats',
  home_demographic_title: `Vous voulez en savoir plus à propos de la composition démographique de votre bassin de candidats ?`,
  home_demographic_link: 'Cliquez ici',
  home_intro_modal_apps_title:
    'Accédez à tous vos résultats dans un seul et même endroit',
  home_intro_modal_apps_message:
    'Les résultats pour Casper et Snapshot sont disponibles sur la page Candidats.',
  home_program_admin_title_en: '',
  home_program_admin_title_fr:
    'candidats ont distribué leurs résultats à votre programme.',
  home_program_admin_title_fr_empty: `Aucun candidat n'a distribué son résultat à votre programme.`,
  home_program_admin_subtitle: `Tous les résultats des candidats ont été mis à jour pour la dernière fois {{distanceFromNow}} heures, le {{lastDate}} à {{timeStamp}}.`,
  home_program_admin_results_link: 'Aller à tous les résultats des candidats',
  home_program_admin_results_available: 'Résultats disponibles',
  home_program_admin_results_new: 'cette semaine',
  home_program_admin_card_casper: 'Aller aux résultats des candidats',
  home_program_admin_card_casper_empty: `Vos résultats Casper ne sont pas encore arrivés. Les résultats peuvent prendre un peu de temps avant d'être disponibles ici. Veuillez revenir bientôt.`,
  home_program_admin_card_snapshot: 'Évaluer les réponses vidéos',
  home_program_admin_card_snapshot_empty: `Aucun résultat n'est disponible pour le moment pour vos candidats. Veuillez aller à Snapshot pour évaluer les réponses vidéos.`,
  home_program_admin_card_duet: `Compléter l'évaluation`,
  home_program_admin_card_duet_empty: `Aucun résultat n'est disponible pour le moment pour vos candidats. Si ce n'est pas déjà fait, veuillez aller à Duet pour compléter l'évaluation.`,
  home_team_member_card_snapshot:
    'Accédez à Snapshot pour évaluer les réponses vidéos des candidats à votre programme.',
  home_team_member_card_duet: `Accédez à Duet pour compléter une évaluation basée sur l'alignement des valeurs pour votre programme.`,
  home_eras_main_card_title: 'Bientôt disponible !',
  home_eras_main_card_message: 'Vos résultats seront disponibles le',
  home_preparation_title: 'Préparation',
  home_recommended_selection:
    'Accéder à un processus de sélection recommandé spécifique à votre programme',
  home_recommended_selection_link: 'https://forms.gle/YfvqBkWEkthgXThS9',
  home_snapshot_training_link: 'Voir la formation des examinateurs Snapshot',
  home_duet_profile_link: 'Voir et gérer le profil de programme Duet',
  home_results_title: 'Résultats des évaluations',
  home_analysis_title: 'Analyse',
  home_book_a_call_title: 'Des changements sont arrivés!',
  home_book_a_call_message:
    'Nous sommes en train de faire des mises à jour pour améliorer votre expérience. Dites-nous ce que vous en pensez',
  home_book_a_call_button: 'Réservez un appel pour partager vos commentaires',
  home_survey_button: 'Répondez à un court sondage',
  home_take_tour: 'Visite guidée',
  home_analytics_title: "En apprendre davantage à propos d'Analytics.",
  home_analytics_description:
    'Voir les données et en apprendre davantage sur vos apprenants et votre programme',
  home_recently_viewed_dashboards: 'Reprenez là où vous vous êtes arrêté(e) :',
  home_frequented_dashboards: 'Tableaux de bord les plus populaires :',
  home_applicant_results:
    'Voir les résultats à Casper, Snapshot et Duet de vos candidats',
  home_formative_title: 'Formative',
  home_formative_description:
    'Le SJT Formative soutient efficacement le développement des compétences interpersonnelles et professionnelles des étudiants de l’enseignement supérieur.',
  home_formative_sjt: 'Accéder au SJT Formative'
};
