import React from 'react';

export default ({ className, datatestId = 'password-error' }) => (
  <svg
    className={className}
    width="15"
    height="14"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    data-testid={datatestId}
  >
    <title>Error on adding input value</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.998 23.25C19.2112 23.25 24.248 18.2132 24.248 12C24.248 5.7868 19.2112 0.75 12.998 0.75C6.78478 0.75 1.74799 5.7868 1.74799 12C1.74799 18.2132 6.78478 23.25 12.998 23.25Z"
      stroke="#F5222D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.49799 16.5L17.497 7.5"
      stroke="#F5222D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.498 16.5L8.49698 7.5"
      stroke="#F5222D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
