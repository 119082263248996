import React from 'react';
import PropTypes from 'prop-types';
import getAlertIcon from '../../assets/helpers/getAlertIcon';
import { TextStyled, WrapperStyled, TitleStyled } from './toast-body.styles';

const ToastBody = ({ category, title, text }) => {
  const AlertIcon = getAlertIcon(category);
  return (
    <WrapperStyled data-testid="toast-body">
      <span>
        <AlertIcon data-testid="alertIcon" />
      </span>
      {title ? (
        <div>
          <TitleStyled data-testid="toast-title">{title}</TitleStyled>
          <TextStyled data-testid="toast-text">{text}</TextStyled>
        </div>
      ) : (
        <TextStyled data-testid="toast-text">{text}</TextStyled>
      )}
    </WrapperStyled>
  );
};

ToastBody.propTypes = {
  category: PropTypes.oneOf(['success', 'info', 'alert', 'error']).isRequired,
  text: PropTypes.string.isRequired
};

ToastBody.defaultProps = {
  category: 'info'
};

export default ToastBody;
