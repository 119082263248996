import React from 'react';
import { useRecoilValue } from 'recoil';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { userRoutes } from 'shared/store/userRoutes';

export default () => {
  const { menu } = useRecoilValue(userRoutes);
  const { state, pathname } = useLocation();

  return (
    <Route
      path="*"
      component={() => (
        <Redirect
          to={{
            pathname: menu ? '/404' : '/signin',
            state: { ...state, previousPath: pathname }
          }}
        />
      )}
    />
  );
};
