import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import i18nen from 'locales/en';
import i18nfr from 'locales/fr';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: {
          ...i18nen
        }
      },
      fr: {
        translations: {
          ...i18nfr
        }
      }
    },
    lng: navigator.language || navigator.userLanguage,
    fallbackLng: 'en',
    debug: process.env.NODE_ENV === 'development',
    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false, // we use content as keys
    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ','
    },
    react: {
      wait: true
    }
  });

// lang = short form of the language (en, fr)
i18n.on('languageChanged', lang => {
  const { REACT_APP_COOKIE_YES = false } = process.env;
  // Other plugins can be added here to change the language of the app

  // CookieYes plugin
  if (REACT_APP_COOKIE_YES === 'true') {
    window.ckySettings = {
      documentLang: lang
    };
  }
});

export default i18n;
