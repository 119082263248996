export default {
  navbar_home: 'Home',
  navbar_download_assessment_results: 'Download assessment results',
  navbar_admissions: 'Admissions',
  navbar_users: 'Users',
  navbar_access_logs: 'Access Logs',
  navbar_analytics: 'Analytics',
  navbar_account: 'My account',
  navbar_program_profile: 'View & manage profile',
  navbar_help: 'Help',
  navbar_take_duet: 'Take Duet',
  navbar_logout: 'Log out',
  navbar_suite: 'Go to {{acuity}} Insights',
  navbar_suite_navigate: 'Navigate to ...',
  navbar_insights: 'Go to {{acuity}} Insights',
  navbar_settings: 'Settings',
  navbar_user_icon: 'User profile menu',
  navbar_assessment_results: 'Assessment results'
};
