export default {
  select_program_filter: 'Program',
  select_program_filter_plural: 'Program(s)',
  select_program_search: 'Type to search',
  select_program_error: 'Please select one or more programs',
  select_cycle_filter: 'Cycle',
  select_role: 'Role',
  select_role_search: `Select user's role`,
  select_role_error: `Please select a role`,
  select_code_error: 'Please select a code',
  select_phone_number: 'phone number',
  select_phone_number_error: 'Please enter a valid',
  select_default_message: 'No options'
};
