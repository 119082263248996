export default {
  account_tab_overview: 'Tableau de bord',
  account_tab_security: 'Sécurité',
  account_information_title: 'Mes informations',
  account_information_first_name: 'Prénom',
  account_information_last_name: 'Nom de famille',
  account_information_update: 'Mettre à jour',
  account_information_confirmation: `Les informations de l'utilisateur ont été mises à jour avec succès.`,
  account_programs_title: 'Programmes',
  account_programs_subtitle: 'Vous avez accès aux programmes suivants :',
  account_terms_title: `Conditions d'utilisation`,
  account_terms_and_conditions: `conditions d'utilisation`,
  account_terms_privacy_policy: 'politique de confidentialité',
  account_terms_message_1: 'Consultez nos',
  account_terms_message_2: 'et notre',
  account_language: 'Langue',
  account_language_disclaimer:
    'Nous sommes en pleine croissance et il se peut que certaines fonctionnalités ne soient pas encore traduites',
  account_security_password_title: 'Modifiez votre mot de passe',
  account_security_password_current: 'Mot de passe actuel',
  account_security_password_new: 'Nouveau mot de passe',
  account_security_password_confirm: 'Confirmez le mot de passe',
  account_security_2fa_title: 'Authentification à deux facteurs',
  account_security_2fa_subtitle: `Nous utilisation l'authentification à deux facteurs pour renforcer la sécurité de votre compte.`,
  account_security_2fa_message:
    'Actuellement, vous recevez votre code de vérification par',
  account_security_2fa_button: `Mettre à jour l'authentification à deux facteurs`,
  account_security_2fa_modal_title: `Mettre à jour l'authentification à deux facteurs`,
  account_security_2fa_modal_password:
    'Veuillez entrer votre mot de passe actuel.',
  account_security_2fa_modal_verification_sms: `Nous venons d'envoyer un message texte au`,
  account_security_2fa_modal_verification_call: `Nous venons d'appeler`,
  account_security_2fa_modal_verification_code_message2:
    'Veuillez entrer le code de vérification à 6 chiffres ci-dessous.',
  account_security_password_alert: 'Mot de passe mis à jour avec succès.',
  account_details: 'Détails du compte',
  account_details_updated: 'Mise à jour des détails du compte réussie'
};
