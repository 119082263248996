import React from 'react';

export default ({ className }) => (
  <svg
    className={className}
    data-testid="header-logo-icon"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    id="Layer_1"
    x="0px"
    y="0px"
    style={{
      enableBackground: 'new 0 0 1112.1 792'
    }}
    xmlSpace="preserve"
    viewBox="406.5 328.9 114.7 37.6"
    role="img"
    aria-label="Acuity Insights"
  >
    <style type="text/css">
      {'\n\t.st0{fill:#4C56A5;}\n\t.st1{fill:#4B56A4;}\n'}
    </style>
    <g>
      <path
        className="st0"
        d="M424,366.3v-10.9h3.1v10.9H424z M426.1,365.3v-8.8h-1v8.8H426.1z"
      />
      <path
        className="st0"
        d="M424.2,366.1h2.7v-10.5h-2.7V366.1z M426.3,356.3v9.2h-1.4v-9.2H426.3z"
      />
      <path
        className="st0"
        d="M437.6,366.3l-4.1-5.4v5.4h-3.1v-10.9h2.7l4,5.2v-5.2h3.1v10.9H437.6z M439.1,365.3v-8.8h-1v7.2l-5.5-7.2h-1.1   v8.8h1v-7.4l5.7,7.4H439.1z"
      />
      <path
        className="st0"
        d="M437.2,361.2l-4.3-5.6h-2.4v10.5h2.7v-5.8l4.4,5.8h2.3v-10.5h-2.7V361.2z M437.9,363.1v-6.8h1.4v9.2H438   l-5.4-7v7h-1.4v-9.2h1.4L437.9,363.1z"
      />
      <path
        className="st0"
        d="M447.2,366.4c-1.7,0-3.3-0.6-4.5-1.7l-0.4-0.3l1.9-2.3l0.4,0.3c0.9,0.8,1.7,1.1,2.6,1.1c0.1,0,1,0,1-0.5   c0-0.2,0-0.4-1.6-0.8c-1.9-0.5-3.7-1.1-3.7-3.5c0-2.1,1.6-3.5,4-3.5c1.5,0,2.8,0.5,4,1.4l0.4,0.3l-1.7,2.4l-0.4-0.3   c-0.8-0.6-1.6-0.9-2.3-0.9c-0.5,0-0.8,0.2-0.8,0.4c0,0.3,0,0.4,1.8,0.9c3.1,0.8,3.5,2.2,3.5,3.4C451.3,365,449.7,366.4,447.2,366.4   z M443.8,364.3c1,0.7,2.2,1.1,3.4,1.1c1.9,0,3.1-1,3.1-2.5c0-1.3-0.7-1.9-2.7-2.5c-1.6-0.4-2.6-0.7-2.6-1.9c0-0.8,0.6-1.5,1.8-1.5   c0.8,0,1.6,0.3,2.5,0.8l0.5-0.7c-0.9-0.6-1.8-0.8-2.9-0.8c-1.7,0-2.9,1-2.9,2.4c0,1.6,0.8,2,2.9,2.5c1.6,0.4,2.4,0.8,2.4,1.8   c0,0.9-0.8,1.6-2,1.6c-1,0-2-0.3-2.9-1L443.8,364.3z"
      />
      <path
        className="st0"
        d="M447.8,359.6c-1.8-0.4-1.9-0.6-1.9-1v0c0-0.4,0.4-0.6,1-0.6c0.7,0,1.6,0.3,2.4,0.9l0.3,0.2l1.5-2.1l-0.2-0.2   c-1.1-0.9-2.4-1.3-3.8-1.3c-2.2,0-3.8,1.3-3.8,3.3v0c0,2.3,1.6,2.8,3.5,3.3c1.8,0.5,1.8,0.7,1.8,1c0,0.5-0.6,0.7-1.2,0.7   c-1,0-1.8-0.4-2.8-1.1l-0.3-0.2l-1.6,2l0.2,0.2c1.2,1.1,2.7,1.7,4.4,1.7c2.4,0,3.9-1.3,4-3.4v0   C451.2,361.7,450.8,360.4,447.8,359.6z M449.1,363.1L449.1,363.1c0-0.8-0.5-1.2-2.3-1.6c-2.1-0.5-3-1-3-2.7v0   c0-1.5,1.3-2.6,3.1-2.6c1.2,0,2.3,0.3,3.2,1l-0.7,1.1c-0.9-0.6-1.7-0.9-2.5-0.9c-1.1,0-1.6,0.6-1.6,1.3v0c0,0.9,0.7,1.2,2.4,1.7   c2.1,0.5,2.9,1.3,2.9,2.6v0c0,1.7-1.3,2.7-3.3,2.7c-1.4,0-2.7-0.5-3.7-1.3l0.8-1c1,0.7,1.9,1.1,2.9,1.1   C448.3,364.4,449.1,363.9,449.1,363.1z"
      />
      <path
        className="st0"
        d="M454.1,366.3v-10.9h3.1v10.9H454.1z M456.1,365.3v-8.8h-1v8.8H456.1z"
      />
      <path
        className="st0"
        d="M454.3,366.1h2.7v-10.5h-2.7V366.1z M456.3,356.3v9.2h-1.4v-9.2H456.3z"
      />
      <path
        className="st0"
        d="M465.6,366.5c-3.3,0-5.7-2.4-5.7-5.6c0-3.2,2.5-5.6,5.7-5.6c1.8,0,3,0.4,4.2,1.5l0.4,0.3l-2,2.4l-0.4-0.3   c-0.7-0.6-1.3-0.9-2.3-0.9c-1.3,0-2.4,1.2-2.4,2.6c0,1.6,1.1,2.7,2.5,2.7c0.6,0,1.1-0.1,1.6-0.4v-0.7H465v-2.8h5.3v5l-0.2,0.2   C468.9,365.9,467.3,366.5,465.6,366.5z M465.6,356.3c-2.6,0-4.6,2-4.6,4.6c0,2.7,1.9,4.6,4.6,4.6c1.4,0,2.6-0.4,3.7-1.3v-3.5h-3.2   v0.7h2.3v2.3l-0.2,0.1c-0.7,0.5-1.5,0.7-2.4,0.7c-2.1,0-3.6-1.6-3.6-3.7c0-2.1,1.5-3.7,3.4-3.7c1.2,0,1.9,0.4,2.5,0.8l0.6-0.8   C468.1,356.7,467.2,356.3,465.6,356.3z"
      />
      <path
        className="st0"
        d="M465.2,362.3h2.3v1c-0.5,0.3-1.1,0.5-1.8,0.5c-1.6,0-2.7-1.2-2.7-2.9v0c0-1.6,1.2-2.8,2.6-2.8   c1.1,0,1.7,0.3,2.4,0.9l0.3,0.2l1.7-2.1l-0.2-0.2c-1.2-1-2.4-1.4-4.1-1.4c-3.1,0-5.5,2.4-5.5,5.4v0c0,3.1,2.3,5.4,5.5,5.4   c1.6,0,3.1-0.6,4.4-1.6l0.1-0.1v-4.7h-4.9V362.3z M462.3,360.8L462.3,360.8c0,2.1,1.4,3.6,3.4,3.6c0.9,0,1.7-0.2,2.3-0.7l0.1-0.1   v-2h-2.3v-1.1h3.6v3.8c-1.1,0.9-2.4,1.4-3.9,1.4c-2.9,0-4.8-1.9-4.8-4.7v0c0-2.7,2.1-4.8,4.8-4.8c1.8,0,2.7,0.5,3.4,1l-0.9,1.1   c-0.7-0.5-1.4-0.9-2.6-0.9C463.8,357.3,462.3,358.9,462.3,360.8z"
      />
      <path
        className="st0"
        d="M479.3,366.3v-4h-3.1v4h-3.1v-10.9h3.1v3.9h3.1v-3.9h3.1v10.9H479.3z M481.4,365.3v-8.8h-1v3.9h-5.2v-3.9h-1   v8.8h1v-4h5.2v4H481.4z"
      />
      <path
        className="st0"
        d="M479.5,359.6h-3.5v-3.9h-2.7v10.5h2.7v-4h3.5v4h2.7v-10.5h-2.7V359.6z M480.2,360.2v-3.9h1.4v9.2h-1.4v-4h-4.8   v4H474v-9.2h1.4v3.9H480.2z"
      />
      <path
        className="st0"
        d="M487.9,366.3v-7.9h-3v-3h9.2v3h-3v7.9H487.9z M490,365.3v-7.9h3v-0.9h-7.1v0.9h3v7.9H490z"
      />
      <path
        className="st0"
        d="M485,358.2h3v7.9h2.7v-7.9h3v-2.6H485V358.2z M490.2,357.5v7.9h-1.4v-7.9h-3v-1.3h7.5v1.3H490.2z"
      />
      <path
        className="st0"
        d="M500.2,366.4c-1.7,0-3.3-0.6-4.5-1.7l-0.4-0.3l1.9-2.3l0.4,0.3c0.9,0.8,1.7,1.1,2.6,1.1c0.1,0,1,0,1-0.5   c0-0.2,0-0.4-1.6-0.8c-1.9-0.5-3.7-1.1-3.7-3.5c0-2.1,1.6-3.5,4-3.5c1.5,0,2.8,0.5,4,1.4l0.4,0.3l-1.7,2.4l-0.4-0.3   c-0.8-0.6-1.6-0.9-2.3-0.9c-0.5,0-0.8,0.2-0.8,0.4c0,0.3,0,0.4,1.8,0.9c3.1,0.8,3.5,2.2,3.5,3.4C504.3,365,502.7,366.4,500.2,366.4   z M496.8,364.3c1,0.7,2.2,1.1,3.4,1.1c1.9,0,3.1-1,3.1-2.5c0-1.3-0.7-1.9-2.7-2.5c-1.6-0.4-2.6-0.7-2.6-1.9c0-0.8,0.6-1.5,1.8-1.5   c0.8,0,1.6,0.3,2.5,0.8l0.5-0.7c-0.9-0.6-1.8-0.8-2.9-0.8c-1.7,0-2.9,1-2.9,2.4c0,1.6,0.8,2,2.9,2.5c1.6,0.4,2.4,0.8,2.4,1.8   c0,0.9-0.8,1.6-2,1.6c-1,0-2-0.3-2.9-1L496.8,364.3z"
      />
      <path
        className="st0"
        d="M500.8,359.6c-1.8-0.4-1.9-0.6-1.9-1v0c0-0.4,0.4-0.6,1-0.6c0.7,0,1.6,0.3,2.4,0.9l0.3,0.2l1.5-2.1l-0.2-0.2   c-1.1-0.9-2.4-1.3-3.8-1.3c-2.2,0-3.8,1.3-3.8,3.3v0c0,2.3,1.6,2.8,3.5,3.3c1.8,0.5,1.8,0.7,1.8,1c0,0.5-0.6,0.7-1.2,0.7   c-1,0-1.8-0.4-2.8-1.1l-0.3-0.2l-1.6,2l0.2,0.2c1.2,1.1,2.7,1.7,4.4,1.7c2.4,0,3.9-1.3,4-3.4v0   C504.2,361.7,503.8,360.4,500.8,359.6z M500.2,365.6c-1.4,0-2.7-0.5-3.7-1.3l0.8-1c1,0.7,1.9,1.1,2.9,1.1c1.1,0,1.8-0.5,1.8-1.3v0   c0-0.8-0.5-1.2-2.3-1.6c-2.1-0.5-3-1-3-2.7v0c0-1.5,1.3-2.6,3.1-2.6c1.2,0,2.3,0.3,3.2,1l-0.7,1.1c-0.9-0.6-1.7-0.9-2.5-0.9   c-1.1,0-1.6,0.6-1.6,1.3v0c0,0.9,0.7,1.2,2.4,1.7c2.1,0.5,2.9,1.3,2.9,2.6v0C503.5,364.6,502.2,365.6,500.2,365.6z"
      />
    </g>
    <path
      className="st1"
      d="M429.3,338.1L429.3,338.1c0-5.1,3.8-9.2,9.3-9.2c3.3,0,5.3,1.1,7,2.7l-2.4,2.8c-1.4-1.3-2.8-2.1-4.6-2.1  c-3.1,0-5.3,2.6-5.3,5.7v0c0,3.1,2.2,5.7,5.3,5.7c2.1,0,3.3-0.8,4.8-2.1l2.4,2.4c-1.8,1.9-3.9,3.2-7.3,3.2  C433.2,347.3,429.3,343.2,429.3,338.1z"
    />
    <path
      className="st1"
      d="M451.7,339.4v-10.2h3.8v10.1c0,3,1.5,4.5,4,4.5s4-1.5,4-4.4v-10.2h3.7v10c0,5.4-3,8-7.8,8  C454.6,347.2,451.7,344.6,451.7,339.4z"
    />
    <path className="st1" d="M474.5,329.2h3.8V347h-3.8V329.2z" />
    <path
      className="st1"
      d="M490.1,332.7h-5.5v-3.5h14.7v3.5h-5.4V347h-3.8V332.7z"
    />
    <path
      className="st1"
      d="M510.5,340l-6.9-10.8h4.4l4.4,7.3l4.5-7.3h4.3l-6.9,10.7v7.1h-3.8V340z"
    />
    <polygon
      className="st1"
      points="417.6,329.1 414.2,329.1 406.5,347 410.3,347 415.9,333.6 421.4,347 425.3,347 "
    />
    <polygon
      className="st1"
      points="414.9,344.9 416.6,344.9 417.5,346.9 414.1,346.9 "
    />
  </svg>
);
