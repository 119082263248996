import React from 'react';

export default ({ className, width = '59', height = '59' }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 59 59"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginLeft: '2px' }}
    aria-hidden="true"
  >
    <title>Acuity Insights - Duet</title>
    <path
      d="M27.8185 0.545471H14.4996H12.5958H10.692V34.9547L6.61789 25.7683V0.545471H2.8103V56.5487H2.83315V58.4546H21.3266H27.8185C43.7685 58.4546 56.7409 45.4639 56.7409 29.5C56.7409 13.5361 43.7647 0.545471 27.8185 0.545471ZM14.4996 4.35729H18.5737V52.7292L14.4996 43.5427V4.35729ZM6.61789 35.1606L15.2535 54.6351L6.61789 54.6466V35.1606ZM22.3813 4.35729H27.8185C41.027 4.35729 51.8786 14.6187 52.8534 27.5941H48.8326C47.835 16.8639 38.792 8.43593 27.8147 8.43593H22.3775V4.35729H22.3813ZM22.3813 12.2477H27.8185C36.694 12.2477 44.0236 18.9718 45.0059 27.5979H40.8404C39.9152 21.2322 34.4285 16.3264 27.8185 16.3264H22.3813V12.2477ZM22.3813 20.1382H27.8185C32.9778 20.1382 37.1737 24.3388 37.1737 29.5038C37.1737 34.665 32.9778 38.8657 27.8223 38.8695H22.3813V20.1382ZM22.3813 54.6123V42.6775H27.8185C27.8223 42.6775 27.8223 42.6775 27.8261 42.6775H28.4049V42.6622C33.1491 42.4526 37.2499 39.7195 39.3936 35.7666C39.405 36.0182 39.424 36.2698 39.424 36.5252C39.424 46.1653 31.8698 54.0634 22.3813 54.6123ZM35.0986 53.564C40.0561 49.5387 43.2316 43.4017 43.2316 36.529C43.2316 34.7908 43.026 33.0755 42.6262 31.4059H52.8534C52.0652 41.896 44.8232 50.6098 35.0986 53.564Z"
      fill="#F36F3F"
    />
  </svg>
);
