export default {
  create_bookmark: 'Créer un signet',
  bookmark_modal_heading_paragraph: 'Un lien unique et permanent a été créé !',
  bookmark_modal_body_paragraph:
    "Vous pouvez maintenant ajouter cette page à vos favoris ou partager le lien avec d'autres utilisateurs·trices.",
  link_success: 'Le lien a été copié dans votre presse-papiers.',
  create_permalink_failure: 'La création du permalien a échoué',
  bookmark_title: 'Titre',
  bookmark_modal_set_title: 'Veuillez donner un titre à votre marque-page',
  bookmark_modal_set_title_error: 'Veuillez entrer un titre',
  bookmark_modal_title_placeholder: 'Titre du marque-page',
  view_vertically: 'View vertically',
  view_horizontally: 'View horizontally',
  view_grouped: 'View grouped',
  view_stacked: 'View stacked',
  download_data: 'Download as data',
  download_pdf: 'Download as PDF',
  download_image: 'Download as an image'
};
