import React from 'react';
import PropTypes from 'prop-types';

import { PillStyled } from './pill.styles';

const Pill = ({ active, className, text, handleClick }) => (
  <PillStyled
    role="button"
    active={active}
    className={className}
    data-pendoid={`${text}-tab`}
    onClick={handleClick}
    onKeyPress={e => {
      e.preventDefault();
      if (e.which === 32 || e.which === 13) {
        handleClick();
      }
    }}
    tabIndex="0"
    data-testid="pill"
  >
    <span data-testid="pill-text">{text}</span>
  </PillStyled>
);

Pill.propTypes = {
  active: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  className: PropTypes.string
};

Pill.defaultProps = {
  active: false,
  icon: null
};

export default Pill;
