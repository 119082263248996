import styled from 'styled-components/macro';

import { Card } from 'components';
import { devices } from 'shared/css/devices';

export const CardStyled = styled(Card)`
  ${({ theme: { spacing, typography, colors }, noPadding }) => `
    margin: ${spacing?.(2)} 0;
    padding: ${noPadding ? `0 ${spacing?.(3)}` : spacing?.(3)};
    h2 {
      ${typography?.(24, 500)}
    }

    @media ${devices.tablet} {
      display: grid;
      grid-template-columns: repeat(11, 1fr);
      gap: 50px;
      grid-auto-rows: minmax(100px, auto);
    }
  `}
`;

export const IconWrapperStyled = styled.div`
  ${({ theme: { spacing }, noPadding }) => `
    width: 120px;
    margin: auto;
    padding-top: ${noPadding ? spacing?.(3) : '0'};

    @media ${devices.tablet} {
      margin: 0;
    }
  `};
`;

export const TitleStyled = styled.h3`
  ${({ theme: { colors, typography } }) => `
    ${typography(20, 700)};
    color: ${colors?.grey?.darkest};
    text-align: center;

    @media ${devices.tablet} {
      text-align: left;
    }
  `};
`;

export const WrapperStyled = styled.div`
  @media ${devices.tablet} {
    align-items: flex-start;
    grid-column: 2/11;
    grid-row: 1;
  }
`;
