import styled from 'styled-components/macro';

export const WrapperStyled = styled.div`
  display: flex;
  line-height: 22px;
`;

export const TextStyled = styled.span`
  ${({ theme: { colors = {}, typography } }) => `
    ${typography?.(14)}
    color: ${colors.grey?.darker};
    flex: 1;
    word-break: break-word;
  `};
`;

export const TitleStyled = styled.h2`
  ${({ theme: { typography, alerts = {} } }) => `
    ${typography?.(16, 700)}
    color: ${alerts.title?.color};
    margin-bottom: ${alerts.title?.marginBottom};
  `};
`;
