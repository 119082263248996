import { hasError, removeError } from './auth';

export const flushErrorsState = ({
  errors,
  name,
  value,
  updateErrors,
  updateState
}) => {
  if (errors.length && hasError({ name, errors })) {
    updateErrors(removeError({ name, errors }));
  }
  updateState(value);
};
