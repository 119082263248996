import React from 'react';
import { Checkbox, Icon } from '@takecasper/acuity-design-system';
import { Tooltip } from '@mui/material';
import { Container, IconWrapper } from './checkbox-with-tooltip.styles.js';
import { CheckboxWithTooltipProps } from './types';
import theme from 'shared/css/theme';

const tooltipProps = {
  tooltip: {
    sx: {
      bgcolor: theme.colors.grey.darker,
      '& .MuiTooltip-arrow': {
        color: theme.colors.grey.darker
      }
    }
  }
};

const CheckboxWithTooltip = ({
  id,
  checked,
  setChecked,
  checkboxLabel,
  tooltipText
}: CheckboxWithTooltipProps) => {
  return (
    <Container>
      <Checkbox
        checked={checked}
        data-pendoid={id}
        data-testid={id}
        id={id}
        label={checkboxLabel}
        onChange={() => setChecked(!checked)}
      />
      <Tooltip title={tooltipText} componentsProps={tooltipProps} arrow>
        <IconWrapper>
          <Icon name="questionCircle" size="small" />
        </IconWrapper>
      </Tooltip>
    </Container>
  );
};

export default CheckboxWithTooltip;
