export default ({ userProperties = {} } = {}) => {
  window.intercomSettings = {
    user_role: null,
    program_selection: null,
    cycle_and_test_selection: null,
    page_of_contact: null
  };
  window.Intercom('boot', {
    app_id: process.env.REACT_APP_INTERCOM_APP_ID || '',
    page_of_contact: window.location.pathname,
    ...userProperties
  });
};
