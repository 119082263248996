import React from 'react';
import PropTypes from 'prop-types';

import { ProgramItemStyled, CloseStyled } from './program-item.styles';
import { KEYBOARD_EVENT_VALUES } from 'shared/enums';

const ProgramItem = ({ label, value, handleClick, hideCloseButton }) => {
  return (
    <ProgramItemStyled>
      {label}{' '}
      {!hideCloseButton && (
        <CloseStyled
          handleClick={() => handleClick(value)}
          onKeyPress={e => {
            e.preventDefault();
            if (
              e.key === KEYBOARD_EVENT_VALUES.SPACE ||
              e.key === KEYBOARD_EVENT_VALUES.ENTER
            ) {
              handleClick(value);
            }
          }}
          tabIndex="0"
        />
      )}
    </ProgramItemStyled>
  );
};

ProgramItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  handleClick: PropTypes.func,
  hideCloseButton: PropTypes.bool
};

ProgramItem.defaultProps = {
  hideCloseButton: false
};

export default ProgramItem;
