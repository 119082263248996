import styled from 'styled-components/macro';

export const TextButtonStyled = styled.div`
  ${({ theme: { textButtons = {}, transitions = {} } }) => `
    color: ${textButtons.color};
    cursor:  ${textButtons.cursor};
    text-decoration:  ${textButtons.textDecoration};
    transition: all ${transitions.fast} ease-in-out;
    &:hover, &:focus {
      color: ${textButtons.hover?.color};
      text-decoration: ${textButtons.hover?.textDecoration};
    }
  `}
`;
