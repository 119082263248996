import { datadogLogs } from '@datadog/browser-logs';

const getIpAddr = async () => {
  let ip: string = '';
  const pendoIpAddr = localStorage.getItem('pendoIpAddr');
  try {
    if (!pendoIpAddr) {
      const response = await fetch('https://api.ipify.org/');
      ip = await response.text();
      if (ip) {
        localStorage.setItem('pendoIpAddr', ip);
      }
    }
  } catch (e) {
    console.warn(e);
    datadogLogs.logger.warn(
      'GET api.ipify.org failed',
      { url: window.location.href },
      e
    );
  }
  return ip || pendoIpAddr;
};

export default getIpAddr;
