import React from 'react';
import PropTypes from 'prop-types';

const Email = ({ className, color, ariaHidden }) => {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      aria-hidden={ariaHidden}
    >
      <title>Email</title>
      <path
        d="M16.25 11.7333V2.875C16.25 2.18464 15.6904 1.625 15 1.625H5C4.30964 1.625 3.75 2.18464 3.75 2.875V11.7333"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.7367 10.5541C17.9245 10.4052 18.1809 10.3768 18.3967 10.481C18.6125 10.5852 18.7498 10.8036 18.75 11.0433V19.1266C18.75 19.817 18.1904 20.3766 17.5 20.3766H2.5C1.80964 20.3766 1.25 19.817 1.25 19.1266V11.0433C1.25021 10.8036 1.38746 10.5852 1.60329 10.481C1.81913 10.3768 2.07554 10.4052 2.26333 10.5541L8.45583 15.4658C9.36221 16.1776 10.6378 16.1776 11.5442 15.4658L17.7367 10.5541Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.25 11.4117C9.71005 11.9561 7.99481 11.4412 7.00937 10.1386C6.02393 8.83594 5.99499 7.0453 6.93781 5.71152C7.88064 4.37774 9.57834 3.80763 11.1351 4.30204C12.6918 4.79644 13.7495 6.24162 13.75 7.87499"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 9.125C10.6904 9.125 11.25 8.56536 11.25 7.875C11.25 7.18464 10.6904 6.625 10 6.625C9.30964 6.625 8.75 7.18464 8.75 7.875C8.75 8.56536 9.30964 9.125 10 9.125Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.25 7.875C11.25 8.56536 11.8096 9.125 12.5 9.125C13.1904 9.125 13.75 8.56536 13.75 7.875"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Email;

Email.propTypes = {
  ariaHidden: PropTypes.string,
  className: PropTypes.string
};

Email.defaultProps = {
  color: '#080810',
  ariaHidden: 'true'
};
