import styled from 'styled-components/macro';

import { fullCenter } from 'shared/css/common-classes';

export const DropdownStyled = styled.div`
  ${({ theme: { dropdowns = {}, transitions, typography }, size }) => `
    ${fullCenter};
    ${typography?.(16)}
    background-color: ${dropdowns.group?.backgroundColor};
    border: ${dropdowns.group?.border};
    border-radius: ${dropdowns.group?.borderRadius};
    color: ${dropdowns.group?.color};
    min-width: ${dropdowns.group?.minWidth};
    padding: ${dropdowns.group?.padding?.[size]};
    outline: ${dropdowns.group?.outline};
    transition: all ${transitions?.fast} ease-in-out;
    &:hover {
      background-color: ${dropdowns.group?.hover?.backgroundColor};
      border-color: ${dropdowns.group?.hover?.borderColor};
      box-shadow:${dropdowns.group?.hover?.boxShadow};
    }
    &:focus {
      background-color: ${dropdowns.group?.focus?.backgroundColor};
      border-color: ${dropdowns.group?.focus?.borderColor};
      box-shadow: ${dropdowns.group?.focus?.boxShadow};
    }
  `};
  cursor: pointer;
  position: relative;
`;

export const TitleStyled = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const DropdownOptionsStyled = styled.div`
  ${({ theme: { dropdowns = {}, transitions, typography }, open }) => `
    background-color: ${dropdowns.options?.backgroundColor};
    border-radius: ${dropdowns.options?.borderRadius};
    box-shadow: ${dropdowns.options?.boxShadow};
    margin: ${dropdowns.options?.margin};
    min-width: ${dropdowns.options?.minWidth};
    opacity: ${open ? '1' : '0'};
    padding: ${dropdowns.options?.padding};
    position: ${dropdowns.options?.position};
    right: ${dropdowns.options?.right};
    top: ${dropdowns.options?.top};
    transition: all ${transitions?.fast} ease-in-out;
    visibility: ${open ? 'visible' : 'hidden'};
    width: ${dropdowns.options?.width};
    z-index: ${open ? '10' : '0'};
    ${typography?.(14)}
  `};
`;

export const WrapperStyled = styled.div`
  position: relative;
`;

export const DropdownItemStyled = styled.div`
  ${({ theme: { dropdowns = {}, transitions }, disabled }) => `
    border: ${dropdowns.item?.border};
    color: ${dropdowns.item?.color?.[disabled ? 'disabled' : 'default']};
    cursor: ${disabled ? 'default' : 'pointer'};
    padding: ${dropdowns.item?.padding};
    outline: ${dropdowns.item?.outline};
    transition: all ${transitions?.fast} ease-in-out;
    &:hover {
      background-color: ${
        dropdowns.item?.hover?.backgroundColor?.[
          disabled ? 'disabled' : 'default'
        ]
      };
      border-color: ${dropdowns.item?.hover?.borderColor};
    }
    &:active {
      background-color: ${
        dropdowns.item?.active?.backgroundColor?.[
          disabled ? 'disabled' : 'default'
        ]
      };
      border-color: ${dropdowns.item?.active?.borderColor};
    }
    &:focus {
      border-color: ${dropdowns.item?.focus?.borderColor};
    }
  `};
`;
