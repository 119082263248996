import styled from 'styled-components/macro';

import { devices } from 'shared/css/devices';
import { fullCenter } from 'shared/css/common-classes';

export const WrapperStyled = styled.div`
  ${({ theme: { spacing }, fullScreen }) => `
    ${fullCenter};
    flex-direction: column;
    height: ${spacing?.(51)};
    min-height: ${fullScreen ? '80vh' : spacing?.(51)};
    & img {
      max-width: 90%;
      @media ${devices.tablet} {
        max-width: ${spacing?.(80)};
      }
    }
  `};
`;

export const TitleStyled = styled.h3`
  ${({ theme: { spacing, typography } }) => `
    ${typography?.(20, 700)};
    margin: ${spacing?.(3)} 0;
    @media ${devices.tablet} {
      ${typography?.(30, 700)};
    }
  `};
`;

export const SubtitleStyled = styled.p`
  ${({ theme: { colors = {}, spacing, typography } }) => `
    ${typography?.(16)};
    color: ${colors.grey?.dark};
    max-width: ${spacing?.(62)};
    text-align: center;
  `};
`;
