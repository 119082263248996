import React from 'react';

export default ({ className }) => (
  <svg
    className={className}
    width="34"
    height="32"
    viewBox="0 0 34 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <title>User terms</title>
    <path
      d="M32.9375 7.5V27.6875C32.9375 29.4479 31.5104 30.875 29.75 30.875C27.9896 30.875 26.5625 29.4479 26.5625 27.6875V3.25C26.5625 2.07639 25.6111 1.125 24.4375 1.125H3.1875C2.01389 1.125 1.0625 2.07639 1.0625 3.25V27.6875C1.0625 29.4479 2.48959 30.875 4.25 30.875H29.75"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.375 20.25H21.25"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.375 24.5H14.875"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="6.375"
      y="6.4375"
      width="14.875"
      height="8.5"
      rx="0.75"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
