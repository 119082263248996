import React from 'react';
import PropTypes from 'prop-types';

import { TabStyled, TextStyled } from './tab.styles';

const Tab = ({ active, className, icon, tabIndex, children }) => (
  <TabStyled
    active={active}
    className={className}
    tabIndex={tabIndex}
    data-testid="tab"
  >
    {icon && <span>{icon}</span>}
    <TextStyled>{children}</TextStyled>
  </TabStyled>
);

Tab.propTypes = {
  active: PropTypes.bool.isRequired,
  className: PropTypes.string,
  tabIndex: PropTypes.string
};

Tab.defaultProps = {
  active: false,
  className: '',
  icon: null,
  tabIndex: '0'
};

export default Tab;
