import React from 'react';

export default ({ className, handleClick }) => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    onClick={handleClick}
    data-testid="arrow-down"
  >
    <title>Arrow down</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.23997 2.16667C6.52716 2.16667 6.75997 2.40918 6.75997 2.70834V10.2917C6.75997 10.5908 6.52716 10.8333 6.23997 10.8333C5.95278 10.8333 5.71997 10.5908 5.71997 10.2917V2.70834C5.71997 2.40918 5.95278 2.16667 6.23997 2.16667Z"
      fill="#8C8C8C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.23232 6.11698C2.43539 5.90544 2.76464 5.90544 2.96771 6.11698L6.24002 9.52563L9.51232 6.11698C9.71539 5.90544 10.0446 5.90544 10.2477 6.11698C10.4508 6.32851 10.4508 6.67148 10.2477 6.88301L6.60771 10.6747C6.40464 10.8862 6.07539 10.8862 5.87232 10.6747L2.23232 6.88301C2.02925 6.67148 2.02925 6.32851 2.23232 6.11698Z"
      fill="#8C8C8C"
    />
  </svg>
);
