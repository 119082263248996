import { useLocation } from 'react-router-dom';
import removeSpecialChars from 'shared/helpers/removeSpecialChars';
import capitalizeFirstLetter from 'shared/helpers/capitalizeFirstLetter';

const DocumentTitle = ({ title, children }) => {
  const location = useLocation()?.pathname?.split('/')?.at(-1);
  const tabName = title || capitalizeFirstLetter(removeSpecialChars(location));
  document.title = `${tabName} - Acuity Insights`;
  return children;
};

export default DocumentTitle;
