import React from 'react';

import ScoresNoDataGraph from './scores-no-data.png';

export default ({ className }) => (
  <img
    className={className}
    src={ScoresNoDataGraph}
    alt="Scores no data graph"
  />
);
