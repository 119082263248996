export const hasError = ({ name = '', errors = [] } = {}) =>
  Boolean(errors.find(error => error.field === name));

export const removeError = ({ name = '', errors = [] } = {}) =>
  errors.filter(error => error.field !== name);

export const getError = ({ name = '', errors = [], t } = {}) => {
  const { message = '', messageKey } =
    errors.find(error => error.field === name) || {};
  return t && messageKey ? t(messageKey) : message;
};

export const getDynamicError = ({ type, errors = [], t } = {}) => {
  const {
    message = '',
    messageKey,
    messageValue
  } = errors.find(error => error.type === type) || {};

  return t && messageKey && messageValue
    ? `${t(`${messageKey}_prefix`) || ''} ${messageValue} ${
        t(`${messageKey}_suffix`) || ''
      }`
    : message;
};
