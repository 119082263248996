export default {
  learner_id: 'Learner ID',
  graduation_year: 'Grad year',
  learner_campus: 'Learner campus',
  phase: 'Phase',
  catalog_code: 'Course code',
  academic_year: 'Academic year',
  event_site: 'Site',
  event_campus: 'Event campus'
};
