import styled from 'styled-components/macro';

export const AlertStyled = styled.div`
  ${({ theme: { alerts = {} }, border, category }) => `
    background-color: ${alerts.box?.backgroundColor?.[category]};
    border: ${border ? alerts.box?.border?.[category] : 'none'};
    border-radius: ${alerts.box?.borderRadius};
    color: ${alerts.box?.color?.[category]};
    margin: ${alerts.box?.margin};
    min-height: ${alerts.box?.minHeight};
    padding: ${alerts.box?.padding};
  `};
  display: flex;
`;

export const TitleStyled = styled.h1`
  ${({ theme: { typography, alerts = {} } }) => `
    ${typography?.(16, 700)}
    color: ${alerts.title?.color};
    margin-bottom: ${alerts.title?.marginBottom};
  `};
`;

export const TextStyled = styled.span`
  ${({ theme: { typography } }) => `
    ${typography?.(14)}
    flex: 1;
  `};
`;
