import {
  WrapperStyled,
  ItemStyled,
  ArrowLeftStyled,
  ArrowRightStyled,
  ItemsGroupStyled
} from '../../pagination/pagination.styles';

import generatePaginationNumbers from 'shared/helpers/generatePaginationNumbers';

const Pagination = ({
  pageIndex,
  pageCount,
  gotoPage,
  previousPage,
  nextPage,
  canPreviousPage,
  canNextPage
}) => {
  const items = generatePaginationNumbers({
    currentPage: pageIndex + 1,
    totalPages: pageCount
  });

  return (
    <WrapperStyled>
      <ItemsGroupStyled>
        <ItemStyled
          key={'pagination_first'}
          active={0 === pageIndex}
          edge={true}
          onClick={() => gotoPage(0)}
          tabIndex="0"
        >
          First
        </ItemStyled>
        <ArrowLeftStyled
          disabled={!canPreviousPage}
          handleClick={() => previousPage()}
        />
        {items.map((item, index) => (
          <ItemStyled
            key={`pagination_${index}`}
            active={pageIndex === index}
            onClick={() => gotoPage(index)}
          >
            {item}
          </ItemStyled>
        ))}
        <ArrowRightStyled
          disabled={!canNextPage}
          handleClick={() => nextPage()}
        />
        <ItemStyled
          key={'pagination_last'}
          active={pageIndex === pageCount - 1}
          edge={true}
          onClick={() => gotoPage(pageCount - 1)}
          tabIndex="0"
        >
          Last
        </ItemStyled>
      </ItemsGroupStyled>
    </WrapperStyled>
  );
};

export default Pagination;
