import styled, { createGlobalStyle } from 'styled-components';

import theme from 'shared/css/theme';

export const DatePickerInputStyled = createGlobalStyle`
  .react-datepicker__input-container input {
    background-color: ${theme?.inputs.item?.backgroundColor};
    border: ${theme?.inputs.item?.border};
    border-radius: ${theme?.inputs.item?.borderRadius};
    color: ${theme?.inputs.item?.color};
    box-shadow: ${theme?.inputs.item?.boxShadow?.['default']};
    line-height: ${theme?.inputs.item?.lineHeight};
    margin: ${theme?.inputs.item?.margin};
    outline: ${theme?.inputs.item?.outline};
    padding: ${theme?.inputs.item?.padding};
    transition: all ${theme?.transitions?.fast} ease-in-out;
    width: 100%;
  }

  .react-datepicker {
    border: none !important;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    font-family: 'Lato', 'Roboto', sans-serif !important;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker__triangle {
    display: none !important;
  }

  .react-datepicker__month {
    margin: 0.8rem !important;
  }

  .react-datepicker__current-month {
    padding: 0.6rem 0 !important;
    border-bottom: 1px solid #E8E8E8;
    color: ${theme?.colors?.primary?.darkest} !important;
    font-size: 0.8rem !important;
  }

  .react-datepicker__header {
    background-color: #fff !important;
    border-bottom: none !important;
    padding-bottom: 0 !important;
  }

  .react-datepicker__day-names {
    padding-top: 0.4rem;
    padding-bottom: 0 !important;
    color: ${theme?.colors?.grey?.default} !important;
  }

  .react-datepicker__day-name {
    color: ${theme?.colors?.grey?.dark} !important;
    ${theme?.typography?.(11)}
  }

  .react-datepicker__day--selected:not(.react-datepicker__day--outside-month) {
    background-color: ${theme?.colors?.primary?.default} !important;
    border-radius: ${theme?.config?.borderRadius} !important;
    color: #ffffff !important;
  }

  .react-datepicker__day--selected.react-datepicker__day--outside-month {
    background-color: transparent !important;
  }

  .react-datepicker__navigation {
    top: 13px !important;
  }

  .react-datepicker__navigation--next {
    right: 14px !important;
  }

  .react-datepicker__navigation--previous {
    left: 14px !important;
  }

  .react-datepicker__navigation-icon::before {
    border-color: ${theme?.colors?.grey?.default} !important;
  }

  .react-datepicker__tab-loop *:not(.react-datepicker__day--disabled):focus {
    background-color: transparent !important;
    color: ${theme?.colors?.primary?.darkest} !important;
    outline: 2px solid ${theme?.colors?.primary?.default} !important;
    border-radius: ${theme?.inputs?.item?.borderRadius} !important;
  }

  .react-datepicker__day--keyboard-selected {
    background-color: transparent !important;
    color: ${theme?.colors?.primary?.darkest} !important;
  }

  .react-datepicker__navigation:hover, .react-datepicker__day:not(.react-datepicker__day--outside-month):not(.react-datepicker__day--selected):not(.react-datepicker__day--disabled):hover {
    background-color: ${theme?.colors?.primary?.lightest} !important;
    border-radius: ${theme?.inputs?.item?.borderRadius} !important;
  }

  .react-datepicker__day--outside-month {
    color: ${theme?.colors?.grey?.light} !important;
  }
`;

export const DateContainerStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const DateInputStyled = styled.span`
  width: 100%;

  #date-picker-custom {
    width: calc(100% + 35px);
    z-index: 1;
  }
`;

export const CalendarIconStyled = styled.span`
  width: 35px;

  svg {
    margin-top: 5px;
  }
`;
