import React from 'react';

export default ({ className, color = '#080810' }) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    aria-hidden="true"
  >
    <title>User</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.25 8.5C9.14848 8.5 10.6875 6.96098 10.6875 5.0625C10.6875 3.16402 9.14848 1.625 7.25 1.625C5.35152 1.625 3.8125 3.16402 3.8125 5.0625C3.8125 6.96098 5.35152 8.5 7.25 8.5Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.875 9.79833C7.66779 9.77 7.4591 9.75386 7.25 9.75C4.1434 9.75 1.625 12.2684 1.625 15.375"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="10.375"
      y="10.375"
      width="10"
      height="10"
      rx="1.5"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.875 12.875H17.875"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.875 15.375H17.875"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.875 17.875H14.75"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
