import React from 'react';

export default ({
  className,
  color = '#595959',
  tabIndex = '0',
  handleClick
}) => (
  <svg
    className={className}
    width="7"
    height="12"
    viewBox="0 0 7 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={handleClick}
    onKeyPress={e => {
      e.preventDefault();
      if (e.which === 32 || e.which === 13) {
        handleClick();
      }
    }}
    tabIndex={tabIndex}
    data-testid="pagination-arrow-left"
  >
    <title>Arrow left</title>
    <path
      d="M6.89846 1.98427V0.927437C6.89846 0.835835 6.79318 0.785249 6.72209 0.841304L0.558809 5.65517C0.506443 5.69589 0.464071 5.74804 0.434924 5.80763C0.405777 5.86722 0.390625 5.93268 0.390625 5.99902C0.390625 6.06536 0.405777 6.13082 0.434924 6.19041C0.464071 6.25 0.506443 6.30214 0.558809 6.34287L6.72209 11.1567C6.79455 11.2128 6.89846 11.1622 6.89846 11.0706V10.0138C6.89846 9.94677 6.86701 9.88251 6.81506 9.8415L1.89318 5.9997L6.81506 2.15654C6.86701 2.11552 6.89846 2.05126 6.89846 1.98427V1.98427Z"
      fill={color}
    />
  </svg>
);
