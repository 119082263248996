import React from 'react';

interface iBookmark {
  className?: string;
  color?: string;
}

const Bookmark = ({ className, color = '#707070' }: iBookmark) => (
    <svg className={className} width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M12.2667 18.9718L7 15.2852L1.73333 18.9718C1.54247 19.1054 1.29316 19.1217 1.08651 19.0142C0.879853 18.9067 0.750153 18.6931 0.75 18.4602V2.16016C0.75 1.4698 1.30964 0.910156 2 0.910156H12C12.6904 0.910156 13.25 1.4698 13.25 2.16016V18.4602C13.2498 18.6931 13.1201 18.9067 12.9135 19.0142C12.7068 19.1217 12.4575 19.1054 12.2667 18.9718Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M4.5 0.910156V17.0343" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M9.5 0.910156V17.0343" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

export default Bookmark;
