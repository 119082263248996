import { datadogLogs } from '@datadog/browser-logs';

/* eslint-disable no-unused-vars */
const pendoRequire = () => {
  return new Promise((resolve, reject) => {
    (function (p, e, n, d, o) {
      var v, w, x, y, z;
      o = p[d] = p[d] || {};
      o._q = o._q || [];
      v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
      (function (m) {
        o[m] =
          o[m] ||
          function () {
            o._q[m === v[0] ? 'unshift' : 'push'](
              [m].concat([].slice.call(arguments, 0))
            );
          };
      })(v[w]);
      y = e.createElement(n);
      y.async = true;
      y.onload = () => {
        resolve();
      };
      y.onerror = () => reject('Pendo script failed to load');
      y.src =
        'https://cdn.pendo.io/agent/static/' +
        process.env.REACT_APP_PENDO_APP_ID +
        '/pendo.js';
      z = e.getElementsByTagName(n)[0];
      z.parentNode.insertBefore(y, z);
    })(window, document, 'script', 'pendo');
  });
};

export const pendoInitialize = () => {
  const ip = localStorage.getItem('pendoIpAddr');

  let timeout;
  clearTimeout(timeout);
  timeout = setTimeout(() => {
    if (window?.pendo?.initialize) {
      window.pendo.initialize({
        visitor: {
          id: undefined,
          isAltan: undefined,
          role: undefined,
          permissions: undefined,
          uses_admissions: undefined,
          uses_analytics: undefined,
          screenWidth: undefined,
          screenHeight: undefined,
          viewportWidth: undefined,
          viewportHeight: undefined
        },
        account: {
          id: undefined,
          testMasterId: undefined,
          opportunityId: undefined,
          salesforce_account_ID: undefined
        }
      });
    } else {
      datadogLogs.logger.warn('Pendo failed to initialize', {
        browser: navigator.userAgent,
        ip
      });
      pendoInitialize();
    }
  }, 500);
};

export const pendoIdentify = ({ visitor, account }) => {
  let timeout;

  clearTimeout(timeout);
  timeout = setTimeout(() => {
    if (window?.pendo?.identify) {
      window.pendo.identify({
        visitor,
        account
      });

      clearTimeout(timeout);
    } else {
      const ip = localStorage.getItem('pendoIpAddr');
      datadogLogs.logger.warn('Pendo failed to identify', {
        visitor,
        account,
        ip,
        browser: navigator.userAgent
      });
      pendoIdentify({ visitor, account });
    }
  }, 1000);
};

export default pendoRequire;
