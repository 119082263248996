import React from 'react';
import PropTypes from 'prop-types';
import { KEYBOARD_EVENT_VALUES } from 'shared/enums';

import {
  CheckboxWrapperStyled,
  LabelStyled,
  CheckboxHiddenStyled,
  CheckboxStyled,
  CheckMarkIconStyled,
  TextWrapperStyled,
  TitleWrapperStyled
} from './checkbox.styles';

import theme from 'shared/css/theme';

const CustomCheckbox = React.forwardRef(
  ({ id, isChecked, type, name, wrapperId, ...rest }, ref) => {
    return (
      <>
        <CheckboxHiddenStyled
          type={type}
          {...rest}
          tabIndex="-1"
          aria-hidden="true"
          ref={ref}
        />
        <CheckboxStyled
          {...rest}
          checked={isChecked}
          aria-label={name}
          tabIndex={rest.tabIndex}
          onKeyPress={e => {
            e.preventDefault();
            if (
              e.key === KEYBOARD_EVENT_VALUES.SPACE ||
              e.key === KEYBOARD_EVENT_VALUES.ENTER
            ) {
              document.querySelector(`#${wrapperId}`).click();
            }
          }}
          data-testid={'checkbox-input-' + name}
          data-pendoid={rest['data-pendoid'] || 'checkbox'}
          id={id}
          role="checkbox"
          aria-checked={isChecked}
        >
          <CheckMarkIconStyled
            color={rest.disabled ? theme.colors?.grey?.light : '#FFFFFF'}
          />
        </CheckboxStyled>
      </>
    );
  }
);

const Checkbox = React.forwardRef(
  (
    {
      className,
      text,
      name,
      checked,
      disabled,
      title,
      handleClick,
      tabIndex,
      ...props
    },
    ref
  ) => {
    const wrapperId = `checkboxId_${name.replace(/\s+/g, '-')}`;
    return (
      <CheckboxWrapperStyled
        onClick={e => !disabled && handleClick(name, !checked, e)}
        className={className}
        id={wrapperId}
        data-testid="checkbox"
      >
        <LabelStyled htmlFor={name} disabled={disabled} aria-label={name}>
          <CustomCheckbox
            id={name}
            ref={ref}
            type="checkbox"
            aria-labelledby={`${name}-label`}
            name={name}
            disabled={disabled}
            isChecked={checked}
            wrapperId={wrapperId}
            tabIndex={tabIndex}
            data-testid={props?.['data-testid']}
            data-pendoid={props?.['data-pendoid']}
          />
          {text ? (
            <TextWrapperStyled id={`${name}-label`}>{text}</TextWrapperStyled>
          ) : (
            <TitleWrapperStyled id={`${name}-label`}>
              {title}
            </TitleWrapperStyled>
          )}
        </LabelStyled>
      </CheckboxWrapperStyled>
    );
  }
);

Checkbox.propTypes = {
  text: PropTypes.string,
  title: PropTypes.string,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  tabIndex: PropTypes.string.isRequired
};

Checkbox.defaultProps = {
  text: '',
  title: 'Hidden checkbox',
  checked: false,
  disabled: false,
  tabIndex: '0'
};

export default Checkbox;
