import React, { Component, ErrorInfo } from 'react';
import { datadogLogs } from '@datadog/browser-logs';
import withRecoil from 'shared/hoc/withRecoil';
import { Container, ButtonStyled } from './error-boundary.styles.js';
import { IProps, IState } from './types';

/**
 * @description
 * Creates an error boundary component that will catch any errors thrown by
 * the application and log them to Datadog.
 *
 * This component must be rendered within the Recoil Provider component.
 *
 * @class ErrorBoundary
 * @extends {Component<IProps, IState>}
 */
class ErrorBoundary extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { hasError: false };
  }

  public static getDerivedStateFromError(_: Error): IState {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    const programFilter = localStorage.getItem('programFilter');

    console.error('Uncaught error:', error, errorInfo);
    datadogLogs.logger.error(
      'ERROR BOUNDARY - Uncaught error:',
      {
        errorInfo,
        programFilter,
        userId: this.props.userProfile?.userId,
        url: window.location.href
      },
      error
    );
  }

  private handleClick = () => {
    this.setState({ hasError: false });
    window.location.href = '/';
  };

  public render() {
    if (this.state.hasError) {
      return (
        <Container>
          <h1>Sorry, something unexpected occured.</h1>
          <ButtonStyled handleClick={this.handleClick}>Go back</ButtonStyled>
        </Container>
      );
    }
    return this.props.children;
  }
}

export default withRecoil(ErrorBoundary);
