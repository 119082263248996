export default {
  create_bookmark: 'Create bookmark',
  bookmark_modal_heading_paragraph:
    'A unique and permanent link has been created!',
  bookmark_modal_body_paragraph:
    "Use your browser's bookmark feature to come back to this page with the current filters applied.",
  link_success: 'Link has been copied to your clipboard.',
  create_permalink_failure: 'Creating shareable link failed',
  bookmark_title: 'Title',
  bookmark_modal_set_title: 'Please give your bookmark a title.',
  bookmark_modal_set_title_error: 'Please set a title.',
  bookmark_modal_title_placeholder: 'Bookmark title',
  view_vertically: 'View vertically',
  view_horizontally: 'View horizontally',
  view_grouped: 'View grouped',
  view_stacked: 'View stacked',
  download_data: 'Download as data',
  download_pdf: 'Download as PDF',
  download_image: 'Download as an image'
};
