const getLineHeight = size => {
  const options = {
    12: '20px',
    14: '22px',
    16: '24px',
    20: '28px',
    24: '32px',
    30: '38px',
    38: '46px',
    46: '54px',
    56: '64px'
  };
  return options[size] || '1.6';
};

export default (size = 16, weight = 'normal') => {
  const lineHeight = getLineHeight(size);
  return `
    font-size: ${size / 16}rem;
    font-weight: ${weight};
    line-height: ${lineHeight};
  `;
};
