import React from 'react';

export default ({ className }) => (
  <svg
    className={className}
    width="23"
    height="24"
    viewBox="0 0 23 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Acuity Insights</title>
    <g clipPath="url(#clip0)">
      <path
        d="M11.5 23.5C17.8513 23.5 23 18.3513 23 12C23 5.64873 17.8513 0.5 11.5 0.5C5.14873 0.5 0 5.64873 0 12C0 18.3513 5.14873 23.5 11.5 23.5Z"
        fill="#4C57A5"
      />
      <path
        d="M13.4778 8.10311C13.7067 8.00079 13.8094 7.73267 13.7071 7.50375C13.6048 7.27484 13.3367 7.17215 13.1078 7.27447C12.8789 7.37679 12.7762 7.64491 12.8785 7.87382C12.9808 8.10236 13.2493 8.20505 13.4778 8.10311Z"
        fill="white"
      />
      <path
        d="M13.6187 9.62145C13.721 9.85036 13.9892 9.95306 14.2181 9.85074C14.447 9.74842 14.5497 9.4803 14.4474 9.25139C14.345 9.02248 14.0769 8.91979 13.848 9.0221C13.6191 9.12442 13.5164 9.39292 13.6187 9.62145Z"
        fill="white"
      />
      <path
        d="M14.359 11.3695C14.4613 11.5984 14.7294 11.7011 14.9583 11.5988C15.1872 11.4964 15.2899 11.2283 15.1876 10.9994C15.0853 10.7705 14.8172 10.6678 14.5882 10.7701C14.3593 10.8724 14.2566 11.1409 14.359 11.3695Z"
        fill="white"
      />
      <path
        d="M12.3246 9.62145C12.4269 9.85036 12.695 9.95306 12.9239 9.85074C13.1528 9.74842 13.2555 9.4803 13.1532 9.25139C13.0509 9.02248 12.7828 8.91979 12.5538 9.0221C12.3253 9.12442 12.2226 9.39292 12.3246 9.62145Z"
        fill="white"
      />
      <path
        d="M13.2943 10.7701C13.0654 10.8724 12.9627 11.1406 13.065 11.3695C13.1673 11.5984 13.4355 11.7011 13.6644 11.5988C13.8933 11.4964 13.996 11.2283 13.8937 10.9994C13.7913 10.7709 13.5232 10.6682 13.2943 10.7701Z"
        fill="white"
      />
      <path
        d="M7.8694 17.9242L8.74172 15.9589H14.0085L14.8809 17.9242H19.4046L16.8283 12.1208V12.1193H16.8275L13.2789 4.12424H9.47103L3.3457 17.9242H4.33939L10.0618 5.03241H10.8546L5.1318 17.9242H7.8694ZM15.8342 12.1193H13.2979L11.8722 8.90709L13.1403 6.05L15.8342 12.1193ZM16.1598 13.0275L14.9996 15.9525L13.7012 13.0275H16.1598ZM10.446 12.1193L11.3751 10.0263L12.3042 12.1193H10.446ZM7.27864 17.0161H6.52879L11.8479 5.03279H12.5977L7.27864 17.0161ZM9.14465 15.0507L9.79255 13.591C9.94976 14.1541 10.2552 14.6556 10.6649 15.0507H9.14465ZM12.9095 15.0507C11.7291 15.0507 10.7523 14.1676 10.6025 13.0275H12.7075L13.6056 15.0507H12.9095ZM15.5545 17.0161L16.7121 14.0977L18.0076 17.0161H15.5545Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="23"
          height="23"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
