import React from 'react';
import PropTypes from 'prop-types';

import { LinkButtonStyled } from './link-button.styles';
import { LoaderStyled } from 'components/common.styles';

const LinkButton = ({
  to,
  category,
  size,
  className,
  autoFocus,
  loading,
  label,
  handleClick,
  children
}) => (
  <LinkButtonStyled
    to={to}
    category={category}
    size={size}
    className={className}
    autoFocus={autoFocus}
    aria-label={label}
    onClick={handleClick}
    data-testid="link-button"
  >
    {children} {loading && <LoaderStyled data-testid="link-button-loader" />}
  </LinkButtonStyled>
);

LinkButton.propTypes = {
  to: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.shape({
      pathname: PropTypes.string,
      state: PropTypes.object
    })
  ]),
  category: PropTypes.oneOf(['primary', 'outline', 'danger']).isRequired,
  size: PropTypes.oneOf(['large', 'medium']).isRequired,
  className: PropTypes.string,
  autoFocus: PropTypes.bool,
  loading: PropTypes.bool,
  label: PropTypes.string.isRequired
};

LinkButton.defaultProps = {
  to: '',
  category: 'primary',
  size: 'large',
  className: '',
  autoFocus: false,
  loading: false
};

export default LinkButton;
