import React from 'react';
import PropTypes from 'prop-types';

import ScoresEmptyGraph from 'assets/images/scores-empty-graph';
import ScoresNoDataGraph from 'assets/images/scores-no-data';
import {
  WrapperStyled,
  TitleStyled,
  SubtitleStyled
} from './scores-empty-graph.styles';

const options = ({ t }) => ({
  noUsers: {
    key: 'common_no_results_title',
    title: t?.('common_no_results_title'),
    subtitle: t?.('common_no_results_subtitle'),
    Image: ScoresEmptyGraph
  },
  noData: {
    key: 'common_no_data_title',
    title: t?.('common_no_data_title'),
    subtitle: t?.('common_no_data_subtitle'),
    Image: ScoresNoDataGraph
  },
  noProduct: {
    key: 'common_no_product_title',
    title: t?.('common_no_product_title'),
    subtitle: t?.('common_no_product_subtitle'),
    Image: ScoresNoDataGraph
  }
});

const ScoresEmpty = ({ option, fullScreen, t }) => {
  const { title, subtitle, Image, key } = options({ t })[option];
  return (
    <WrapperStyled fullScreen={fullScreen} data-pendoid={key}>
      <Image />
      <TitleStyled>{title}</TitleStyled>
      <SubtitleStyled>{subtitle}</SubtitleStyled>
    </WrapperStyled>
  );
};

ScoresEmpty.propTypes = {
  option: PropTypes.string,
  fullScreen: PropTypes.bool
};

ScoresEmpty.defaultProps = {
  option: 'noUsers',
  fullScreen: false
};

export default ScoresEmpty;
