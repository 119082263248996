import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

export const IconWrapper = styled.div`
  margin-left: 0.25rem;
  padding-top: 5px;
`;
