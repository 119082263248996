import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from 'styled-components/macro';
import { LicenseInfo } from '@mui/x-license';
import { ApolloProvider } from '@apollo/client';
import { RecoilRoot } from 'recoil';
import { hotjar } from 'react-hotjar';
import { I18nextProvider } from 'react-i18next';
import { datadogLogs } from '@datadog/browser-logs';
import { CookiesProvider } from 'react-cookie';

import getIpAddr from 'shared/helpers/getIpAddr';
import { ErrorBoundary } from 'shared/components';
import client from 'shared/client';
import Theme from 'shared/css/theme';
import GlobalStyle from 'shared/css/globalStyle';
import { APP_NODE_ENV, LANGUAGE } from 'shared/enums';
import Routes from 'routes';

import i18n from './i18n';
import { Toast } from 'components';
import pendoRequire, { pendoInitialize } from './pendo-init';
import './intercom-init';

const {
  NODE_ENV,
  REACT_APP_HOTJAR_ID,
  REACT_APP_ENABLE_DATADOG,
  REACT_APP_DATADOG_KEY,
  REACT_APP_DATADOG_SERVICE,
  REACT_APP_COOKIE_YES
} = process.env;

const isProd = NODE_ENV === APP_NODE_ENV.PRODUCTION;
const isDev = NODE_ENV === APP_NODE_ENV.DEVELOPMENT;

if (isDev) {
  const axe = require('@axe-core/react');
  axe.default(React, ReactDOM, 1000);
}

if (isProd) {
  hotjar.initialize(REACT_APP_HOTJAR_ID, 6);
}

const underImpersonation = localStorage.getItem('reauthenticateToken');
if (process.env.REACT_APP_PENDO_ENABLED === 'true' && !underImpersonation) {
  pendoRequire()
    .then(async () => {
      await getIpAddr();
      pendoInitialize();
    })
    .catch(e => console.error(e));
}

if (REACT_APP_COOKIE_YES === 'true') {
  const cookieYesScript = document.createElement('script');
  cookieYesScript.setAttribute('id', 'cookieyes');
  cookieYesScript.setAttribute(
    'src',
    'https://cdn-cookieyes.com/client_data/190c816496ac1fc399b6be35/script.js'
  );

  window.ckySettings = {
    documentLang: i18n.language || LANGUAGE.ENGLISH.code
  };
  document.head.appendChild(cookieYesScript);
}

if (REACT_APP_ENABLE_DATADOG === 'true') {
  const service =
    REACT_APP_DATADOG_SERVICE || 'altus-insights-client-localhost';
  try {
    datadogLogs.init({
      clientToken: REACT_APP_DATADOG_KEY,
      forwardErrorsToLogs: true,
      sampleRate: 100,
      env: NODE_ENV,
      service
    });

    console.log('Datadog is initialized');
  } catch (error) {
    console.error('Datadog is failing or misconfigured:', error);
  }
}
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <ThemeProvider theme={Theme}>
    <CookiesProvider>
      <GlobalStyle />
      <ApolloProvider client={client}>
        <RecoilRoot>
          <I18nextProvider i18n={i18n}>
            <ErrorBoundary>
              <Toast />
              <Routes />
            </ErrorBoundary>
          </I18nextProvider>
        </RecoilRoot>
      </ApolloProvider>
    </CookiesProvider>
  </ThemeProvider>
);
