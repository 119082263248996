import styled from 'styled-components/macro';

import { TextButton } from 'components';
import { devices } from 'shared/css/devices';
import ArrowDownIcon from 'assets/icons/arrow-down';
import Checkbox from 'components/checkbox/checkbox';

export const TableStyled = styled.table`
  ${({ theme: { tables = {} } }) => `
    border-collapse: ${tables.table?.borderCollapse};
    display: ${tables.table?.display};
    margin: ${tables.table?.margin};
    overflow-y: ${tables.table?.overflowY};
    overflow-x: ${tables.table?.overflowX};
    padding: ${tables.table?.padding};
    position: ${tables.table?.position};
    min-width: ${tables.trThead?.minWidth?.default};
    table-layout: ${tables.table?.tableLayout};
    white-space: ${tables.table?.whiteSpace};
  `}
`;

export const TheadStyled = styled.thead`
  background-color: white;
  position: sticky;
  top: 0px;
  margin: 0;
`;

export const TheadThStyled = styled.th`
  ${({ theme: { tables = {}, transitions, spacing } }) => `
    color: ${tables.thead?.color};
    padding: ${tables.thead?.padding};
    min-width: ${spacing?.(25)};
    text-align: ${tables.thead?.textAlign};
    transition: all ${transitions?.fast_50} ease-in-out;
    width: ${spacing?.(25)};
  `}
`;

export const ArrowDownStyled = styled(ArrowDownIcon)`
  ${({ theme: { tables = {}, transitions }, isSorting }) => `
    display: ${tables.sortArrow?.display};
    margin-bottom: ${tables.sortArrow?.marginBottom};
    margin-right: ${tables.sortArrow?.marginRight};
    transform: ${tables.sortArrow?.transform?.[isSorting ? 'desc' : 'default']};
    transition: all ${transitions?.fast} ease-in-out;
    vertical-align: ${tables.sortArrow?.verticalAlign};
    &:hover {
      cursor: ${tables.sortArrow?.hover?.cursor};
    }
  `}
`;

export const TheadTrStyled = styled.tr`
  ${({ theme: { tables = {}, transitions, spacing } }) => `
    border: ${tables.trThead?.border};
    border-top-color: ${tables.trThead?.borderTopColor};
    min-width: ${tables.trThead?.minWidth?.default};
    transition: all ${transitions?.fast_50} ease-in-out;
    white-space: ${tables.td?.whiteSpace};
    word-break: ${tables.td?.wordBreak};
    word-wrap: ${tables.td?.wordWrap};
    height: ${spacing?.(7)};
    @media ${devices.laptopL} {
      min-width: ${tables.trThead?.minWidth?.desktop};
    }
    &:hover {
      background-color: ${tables.trThead?.hover?.backgroundColor};
      border-bottom-color: ${tables.trThead?.hover?.borderBottomColor};
      border-top-color: ${tables.trThead?.hover?.borderTopColor};
      th {
        background-color: ${tables.trThead?.hover?.th?.backgroundColor};
        color: ${tables.trThead?.hover?.th?.color};
      }
    }
    &:last-child {
      border-bottom-color: ${tables.trThead?.lastChild?.borderBottomColor};
    }
  `}
`;

export const TbdoyStyled = styled.tbody`
  overflow-y: scroll;
`;

export const TbodyTdStyled = styled.td`
  ${({
    theme: { tables = {}, spacing, transitions },
    checked,
    isClickable
  }) => `
    background-color: ${
      tables.td?.backgroundColor?.[checked ? 'checked' : 'default']
    };
    color: ${tables.td?.color};
    max-width: ${spacing?.(25)};
    min-width: ${spacing?.(25)};
    padding: ${tables.td?.padding};
    transition: all ${transitions?.fast} ease-in-out;
    text-align: ${tables.td?.textAlign};
    width: ${spacing?.(25)};
    white-space: ${tables.td?.whiteSpace};
    word-break: ${tables.td?.wordBreak};
    word-wrap: ${tables.td?.wordWrap};
    &:hover {
      ${isClickable ? 'cursor: pointer;' : ''}
    }
  `}
`;

export const TbodyTrStyled = styled.tr`
  ${({ theme: { tables = {}, transitions }, isSelected }) => `
    border: ${tables.trTbody?.border};
    border-bottom-color: ${tables.trTbody?.borderBottomColor};
    border-top-color: ${tables.trTbody?.borderTopColor};
    min-width: ${tables.trTbody?.minWidth?.default};
    transition: all ${transitions?.fast} ease-in-out;
    @media ${devices.laptopL} {
      min-width: ${tables.trThead?.minWidth?.desktop};
    }
    ${
      isSelected
        ? `td {background-color: ${tables.selectedItem?.backgroundColor?.show};}`
        : ''
    }
    &:hover {
      td {
        background-color: ${tables.selectedItem?.backgroundColor?.show};
      }
    }
  `}
`;

export const SelectedItemsStyled = styled.div`
  ${({ theme: { tables = {}, transitions, typography }, show }) => `
    ${typography?.(16)};
    background-color: ${
      tables.selectedItem?.backgroundColor?.[show ? 'show' : 'default']
    };
    height: ${tables.selectedItem?.height?.[show ? 'show' : 'default']};
    padding: ${tables.selectedItem?.padding};
    transition: all ${transitions?.fast} ease-in-out;
    visibility: ${show ? 'visible' : 'hidden'};
    width: ${tables.selectedItem?.width};
  `}
  align-items: center;
  display: flex;
`;

export const ActionsStyled = styled.div`
  ${({ isFrench }) => `
    display: flex;
    flex-direction: ${isFrench ? 'column' : 'row'};
    justify-content: space-between;
    width: 100%;
  `}
`;

export const ActionsTHeadStyled = styled.th`
  ${({ theme: { tables = {}, transitions } }) => `
    background-color: ${tables.actionsThead?.backgroundColor};
    color: ${tables.actionsThead?.color};
    max-width: ${tables.actionsThead?.maxWidth};
    min-width: ${tables.actionsThead?.minWidth};
    padding: ${tables.actionsThead?.padding};
    position: ${tables.actionsThead?.position};
    right: ${tables.actionsThead?.right};
    text-align: ${tables.actionsThead?.textAlign};
    transition: all ${transitions?.fast_50} ease-in-out;
    width: ${tables.actionsThead?.width};
  `}
`;

export const ActionsTBodyStyled = styled.td`
  ${({ theme: { tables = {}, transitions }, checked }) => `
    background-color: ${
      tables.actionsTbody?.backgroundColor?.[checked ? 'checked' : 'default']
    };
    color: ${tables.actionsTbody?.color};
    max-width: ${tables.actionsTbody?.maxWidth};
    min-width: ${tables.actionsTbody?.minWidth};
    padding: ${tables.actionsTbody?.padding};
    position: ${tables.actionsTbody?.position};
    right: ${tables.actionsTbody?.right};
    text-align: ${tables.actionsTbody?.textAlign};
    transition: all ${transitions?.fast} ease-in-out;
    width: ${tables.actionsTbody?.width};
  `}
`;

export const TextButtonStyled = styled(TextButton)`
  margin: 0 ${props => props.theme?.spacing?.(0.5)};
`;

export const CheckboxStyled = styled(Checkbox)``;
