import colors from './colors';
import config from './config';
import spacing from './spacing';

const wrapper = {
  margin: `${spacing?.(0.5)} 0`,
  position: 'relative'
};

const label = {
  color: colors.primary?.darkest,
  textAlign: 'left'
};

const item = {
  backgroundColor: 'transparent',
  border: '2px solid transparent',
  borderRadius: config.borderRadius,
  color: colors?.primary?.darkest,
  boxShadow: {
    error: `0 0 0 1px ${colors?.danger?.default}`,
    default: `0 0 0 1px ${colors?.grey?.light}`
  },
  lineHeight: '130%',
  margin: `${spacing?.(0.5)} 0`,
  outline: 'none',
  padding: `${spacing?.(1)} ${spacing?.(1.5)}`,
  width: '100%',
  hover: {
    borderColor: {
      default: 'transparent',
      error: colors?.danger?.default,
      disabled: 'none'
    },
    boxShadow: {
      default: `0 0 0 1px ${colors?.primary?.default}`,
      error: 'none',
      disabled: `0 0 0 1px ${colors?.grey?.light}`
    }
  },
  focus: {
    borderColor: {
      default: colors?.primary?.default,
      error: colors?.danger?.default
    },
    boxShadow: {
      default: 'none',
      error: `0px 0px 4px ${colors?.danger?.lighter}`
    }
  },
  disabled: {
    backgroundColor: colors?.grey?.lighter,
    color: colors?.grey?.dark
  },
  placeholder: {
    color: colors?.grey?.dark
  }
};

const message = {
  color: {
    default: colors?.primary?.default,
    error: colors?.danger?.default
  },
  textAlign: 'left'
};

export default { wrapper, label, item, message };
