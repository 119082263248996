import React from 'react';

import {
  TitleStyled,
  HeaderStyled,
  SubtitleStyled
} from './header-title.styles';

const HeaderTitle = ({ title, subtitle, children }) => {
  return (
    <HeaderStyled>
      <div>
        <TitleStyled role="heading" aria-level="1">
          {title}
        </TitleStyled>
        {subtitle ? <SubtitleStyled>{subtitle}</SubtitleStyled> : null}
      </div>
      {children}
    </HeaderStyled>
  );
};

export default HeaderTitle;
