import React from 'react';

export default ({ className }) => (
  <svg
    width="14"
    height="16"
    viewBox="0 -2 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M7.00049 2.84375V10.3438"
      stroke="#4C55A5"
      strokeWidth="0.980447"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.37549 7.53125L7.00049 10.3438L9.62549 7.53125"
      stroke="#4C55A5"
      strokeWidth="0.980447"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.563 10.3438V11.2813C13.563 11.7785 13.3786 12.2554 13.0504 12.6071C12.7222 12.9587 12.2771 13.1563 11.813 13.1563H2.18799C1.72386 13.1563 1.27874 12.9587 0.950551 12.6071C0.622363 12.2554 0.437988 11.7785 0.437988 11.2813V10.3438"
      stroke="#4C55A5"
      strokeWidth="0.980447"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
