import { gql } from '@apollo/client';

export const QLIK_TOKEN = gql`
  query getAnalyticsToken($schoolId: String) {
    getAnalyticsToken(schoolId: $schoolId) {
      token
      appName
      appId
      theme
      userDirectory
    }
  }
`;

export const GET_QLIK_PERMISSIONS = gql`
  query getQlikPermissions {
    getQlikPermissions
  }
`;

export const GET_LEARNER_PROFILE_PICTURE = gql`
  query getLearnerProfilePicture($schoolId: String!, $learnerId: String!) {
    getLearnerProfilePicture(schoolId: $schoolId, learnerId: $learnerId)
  }
`;

export const GET_MOST_FREQUENTED_DASHBOARDS = gql`
  query getMostFrequentedDashboards($schoolId: String!, $limit: Int!) {
    getMostFrequentedDashboards(schoolId: $schoolId, limit: $limit) {
      url
      label {
        en
        fr
      }
    }
  }
`;
