import styled from 'styled-components/macro';
import { devices } from 'shared/css/devices';
import ExternalLinkIcon from 'assets/icons/arrow-external-link';

export const ExternalLinkStyled = styled.a`
  ${({ theme: { colors = {}, links = {}, typography } }) => `
    ${typography?.(16)}
    color: ${colors.primary?.default};
    text-decoration: none;
    display: flex;
    align-items: center;
    svg {
        display: none;
    }
    &:hover,
    &:focus {
      color: ${links.hover?.color};
      text-decoration: ${links.hover?.textDecoration};
    }
    @media ${devices.tablet} {
      svg {
        display: initial;
      }
    }
  `};
`;

export const ExternalLinkIconStyled = styled(ExternalLinkIcon)`
  margin-left: 8px;
`;
