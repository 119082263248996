import getHostParam from './getHostParam';
import { STORAGE_KEY } from 'shared/enums';
import { ISelectedSchool } from './types';

/**
 * @description
  This function helps sync the URL host, localStorage & programFilter recoil state
  to keep consistent and prevent data being mis-synced on the client
*/

export default ({
  user,
  storedProgramFilter,
  setProgramFilter,
  history,
  search
}): void => {
  const host: string = getHostParam();
  const hostSchool =
    user.profile.schools.find(
      school =>
        school?.qlik?.userDirectory?.toLowerCase() === host?.toLowerCase()
    ) || null;

  const { admissionCycle, schoolId, salesforceAccountId } =
    storedProgramFilter || {};

  const hasAccessToStoredSchool: boolean = Boolean(
    user.schoolPermissions?.find(s => s.schoolId === schoolId)
  );

  let selectedSchool: ISelectedSchool = undefined;
  let admissionCycleSelected = undefined;

  if (hostSchool) {
    selectedSchool = {
      _id: hostSchool._id,
      label: hostSchool.name,
      applicationCycles: hostSchool?.applicationCycles,
      userDirectory: hostSchool?.qlik?.userDirectory,
      productCycles: hostSchool?.productCycles || [],
      products: hostSchool?.products
    };
    admissionCycleSelected = hostSchool.applicationCycles?.at(-1) || '';
  } else if (storedProgramFilter && hasAccessToStoredSchool) {
    const school = user.profile?.schools?.find(s => s._id === schoolId);
    selectedSchool = {
      _id: school._id,
      label: school.name,
      applicationCycles: school.applicationCycles,
      userDirectory: school?.qlik?.userDirectory,
      productCycles: school?.productCycles || [],
      products: school?.products
    };
    admissionCycleSelected = admissionCycle;
  } else if (user.profile?.schools?.length > 0) {
    const tempSchools = [...user.profile?.schools];
    const {
      _id = '',
      name = '',
      applicationCycles = [],
      productCycles = [],
      qlik = {},
      products
    } = tempSchools
      .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
      .at(0);
    selectedSchool = {
      _id,
      label: name,
      applicationCycles,
      productCycles,
      userDirectory: qlik?.userDirectory,
      products
    };
    admissionCycleSelected = applicationCycles?.at(-1);
  } else {
    selectedSchool = {
      _id: '',
      label: '',
      applicationCycles: [],
      productCycles: [],
      userDirectory: '',
      products: []
    };
    admissionCycleSelected = '';
  }

  const { testMasterId = '', duetDeadlineAt = null } =
    selectedSchool?.productCycles?.find(
      cycle => cycle.key === admissionCycleSelected
    ) || {};

  const programFilter = {
    admissionCycle: admissionCycleSelected || '',
    schoolId: selectedSchool?._id,
    schoolLabel: selectedSchool?.label || selectedSchool?.name,
    userDirectory: selectedSchool.userDirectory,
    products: selectedSchool.products || [],
    testMasterId,
    duetDeadlineAt,
    salesforceAccountId
  };

  // Sync URL query params
  if (
    host?.toLowerCase() !== selectedSchool.userDirectory?.toLowerCase() &&
    !!host
  ) {
    history.replace(
      (search.length ? `${search}&` : '?') +
        `host=${selectedSchool?.userDirectory?.toLowerCase()}`
    );
  }

  // Sync localStorage
  localStorage.setItem(
    STORAGE_KEY.PROGRAM_FILTER,
    JSON.stringify(programFilter)
  );

  // Sync recoil state
  setProgramFilter(programFilter);
};
