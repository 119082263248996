export default {
  snapshot_training_title: 'Formation des examinateurs Snapshot',
  snapshot_training_subtitle: `Avant d'examiner et d'évaluer les réponses à l'entrevue vidéo automatisée Snapshot, vous devez compléter la formation pour les examinateurs(-trices). Il faut environ <b>20 minutes</b> pour compléter cette formation.`,
  snapshot_training_section_1_title: `Section 1 : Qu'est-ce que Snapshot ?`,
  snapshot_training_section_1_subtitle: '~2 min',
  snapshot_training_section_1_p1:
    'Visionnez cette vidéo pour découvrir Snapshot et son fonctionnement.',
  snapshot_training_section_2_title:
    'Section 2 : Comment évaluer Snapshot et savoir à quoi prêter attention',
  snapshot_training_section_2_subtitle: '~10 min',
  snapshot_training_section_2_p1:
    'Visionnez cette vidéo pour apprendre comment examiner et évaluer les entrevues automatisées Snapshot. Référez-vous au guide pendant vos évaluations.',
  snapshot_training_section_2_link: 'Comment évaluer Snapshot : Guide',
  snapshot_training_section_3_title:
    'Section 3 : Formation sur les préjugés inconscients',
  snapshot_training_section_3_subtitle: '~8 min',
  snapshot_training_section_3_p1: `Visionnez cette vidéo pour apprendre ce que sont les préjugés inconscients et comment réduire leur impact sur vous, en tant qu'examinateur(-trice) Snapshot. Référez-vous au guide pendant vos évaluations.`,
  snapshot_training_section_3_link:
    'Formation sur les préjugés inconscients : Guide',
  snapshot_training_confirmation: `J'ai complété toutes les sections de la formation Snapshot`,
  snapshot_training_confirmation_button: 'Évaluer les réponses vidéos'
};
