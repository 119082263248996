import {
  NON_ANALYTICS_PERMISSIONS,
  ANALYTICS_PERMISSIONS,
  STORAGE_KEY
} from 'shared/enums';
import hasPermissionOrAdmin from './hasPermissionOrAdmin';
import { useRecoilValue } from 'recoil';
import {
  programFilterAtom,
  userPermissionsPerSchool
} from 'shared/store/program-filter';
import isAltan from 'shared/helpers/isAltan';
import { pendoIdentify } from '../../pendo-init';
import { IUser, IProgramFilterAtom, UserPermissionPerSchoolAtom } from '@types';

const usePendoIdentity = (user: IUser): void => {
  const programFilter = useRecoilValue<IProgramFilterAtom>(programFilterAtom);
  const permissionsArray = useRecoilValue<UserPermissionPerSchoolAtom[]>(
    userPermissionsPerSchool
  );

  const permissions =
    user?.schoolPermissions?.find(
      schoolPermission => schoolPermission.schoolId === programFilter.schoolId
    )?.keys || [];
  const underImpersonation: String = localStorage.getItem(
    STORAGE_KEY.REAUTHENTICATE_TOKEN
  );
  const isUsingAdmissions: Boolean = hasPermissionOrAdmin(
    NON_ANALYTICS_PERMISSIONS,
    user?.roles,
    permissions
  );
  const isUsingAnalytics: Boolean = hasPermissionOrAdmin(
    ANALYTICS_PERMISSIONS,
    user?.roles,
    permissions
  );

  if (
    window.pendo &&
    user?._id &&
    programFilter?.schoolLabel &&
    !underImpersonation
  ) {
    const visitor = {
      id: user?._id,
      isAltan: isAltan(user?.emails),
      role: user?.roles?.[0],
      permissions: permissionsArray.find(
        ({ schoolId }) => schoolId === programFilter?.schoolId
      )?.keys,
      uses_admissions: isUsingAdmissions,
      uses_analytics: isUsingAnalytics,
      screenWidth: window.screen.width,
      screenHeight: window.screen.height,
      viewportWidth: window.innerWidth,
      viewportHeight: window.innerHeight
    };
    const account = {
      id: programFilter?.schoolLabel,
      testMasterId: programFilter?.testMasterId,
      opportunityId: programFilter?.salesforceAccountId,
      salesforce_account_ID: programFilter?.salesforceAccountId
    };
    pendoIdentify({ visitor, account });
  }
};

export default usePendoIdentity;
