import React from 'react';

export default ({ className, color = '#C6C6C6' }) => {
  return (
    <svg
      className={className}
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="nav-dots"
    >
      <path
        d="M7.02148 15.9722C7.02148 16.4943 7.22891 16.9951 7.59812 17.3643C7.96733 17.7335 8.46809 17.9409 8.99023 17.9409C9.51238 17.9409 10.0131 17.7335 10.3824 17.3643C10.7516 16.9951 10.959 16.4943 10.959 15.9722C10.959 15.45 10.7516 14.9493 10.3824 14.5801C10.0131 14.2108 9.51238 14.0034 8.99023 14.0034C8.46809 14.0034 7.96733 14.2108 7.59812 14.5801C7.22891 14.9493 7.02148 15.45 7.02148 15.9722V15.9722Z"
        stroke={color}
        strokeWidth="1.35"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.02148 3.03467C7.02148 3.55681 7.22891 4.05757 7.59812 4.42678C7.96733 4.796 8.46809 5.00342 8.99023 5.00342C9.51238 5.00342 10.0131 4.796 10.3824 4.42678C10.7516 4.05757 10.959 3.55681 10.959 3.03467C10.959 2.51252 10.7516 2.01176 10.3824 1.64255C10.0131 1.27334 9.51238 1.06592 8.99023 1.06592C8.46809 1.06592 7.96733 1.27334 7.59812 1.64255C7.22891 2.01176 7.02148 2.51252 7.02148 3.03467V3.03467Z"
        stroke={color}
        strokeWidth="1.35"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.02148 9.50342C7.02148 10.0256 7.22891 10.5263 7.59812 10.8955C7.96733 11.2647 8.46809 11.4722 8.99023 11.4722C9.51238 11.4722 10.0131 11.2647 10.3824 10.8955C10.7516 10.5263 10.959 10.0256 10.959 9.50342C10.959 8.98127 10.7516 8.48051 10.3824 8.1113C10.0131 7.74209 9.51238 7.53467 8.99023 7.53467C8.46809 7.53467 7.96733 7.74209 7.59812 8.1113C7.22891 8.48051 7.02148 8.98127 7.02148 9.50342V9.50342Z"
        stroke={color}
        strokeWidth="1.35"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
