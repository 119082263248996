import React from 'react';
import PropTypes from 'prop-types';
import { Player } from '@lottiefiles/react-lottie-player';

import LottieLoader from './loader-lottie.json';

const Loader = ({ height, width, className }) => (
  <Player
    autoplay
    loop
    src={LottieLoader}
    style={{ height, width }}
    className={className}
  />
);

Loader.propTypes = {
  height: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired
};

Loader.defaultProps = {
  height: '300px',
  width: '300px'
};

export default Loader;
