import colors from './colors';
import config from './config';
import spacing from './spacing';

const wrapper = {
  margin: '0 8px'
};

const item = {
  backgroundColor: {
    disabled: colors.grey?.lighter,
    checked: colors?.primary?.default,
    default: '#FFFFFF'
  },
  borderRadius: config.borderRadius,
  border: {
    checked: '1px solid transparent',
    default: `1px solid ${colors.grey?.light}`
  },
  height: `${spacing?.(2)}`,
  margin: `0 ${spacing?.(1)} 0 0`,
  width: `${spacing?.(2)}`,
  hover: {
    backgroundColor: colors.primary?.lighter,
    borderColor: colors?.primary?.default
  },
  focus: {
    borderColor: 'transparent',
    boxShadow: `0 0 0 2px ${colors.primary?.default}`
  }
};

const label = {
  color: {
    disabled: colors.grey?.darker,
    default: colors.primary?.darkest
  },
  borderTop: `1px solid ${colors.grey?.light}`,
  padding: `${spacing?.(4)}`
};

export default {
  item,
  label,
  wrapper
};
