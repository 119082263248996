import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  useTable,
  useSortBy,
  usePagination,
  useRowSelect,
  useFilters
} from 'react-table';

import Pagination from './components/pagination';

import {
  TableStyled,
  TheadTrStyled,
  TheadThStyled,
  ArrowDownStyled,
  TbodyTrStyled,
  TbodyTdStyled,
  TbdoyStyled,
  TheadStyled,
  CheckboxStyled
} from './table-new.styles';

const TableNew = ({
  columns,
  data,
  pageCount: controlledPageCount, // > 1 if using pagination
  isProcessingSelectedRows, // true to enable checkboxes for rows
  setSelectedRowIds, // use to handle interactions with checkboxes
  handleRowClicked, // use to handle interactions with clicking a row
  refetch, // must have refetch query if using sort or pagination
  initialSort = [],
  className
}) => {
  // hook for checkbox select
  const hooks = hooks => {
    hooks.visibleColumns.push(columns => [
      {
        id: 'selection',
        // The header can use the table's getToggleAllRowsSelectedProps method
        // to render a checkbox
        Header: ({ toggleAllRowsSelected, isAllRowsSelected }) => (
          <CheckboxStyled
            name="select-all"
            checked={isAllRowsSelected}
            handleClick={() => toggleAllRowsSelected(!isAllRowsSelected)}
          />
        ),
        Cell: ({ row }) => (
          <CheckboxStyled
            name="select"
            checked={row.isSelected}
            handleClick={() => row.toggleRowSelected(!row.isSelected)}
          />
        )
      },
      ...columns
    ]);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    pageCount,
    gotoPage,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    state: { pageIndex, selectedRowIds, sortBy }
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      pageCount: controlledPageCount,
      disableSortRemove: true,
      autoResetPage: false,
      autoResetSelectedRows: false,
      initialState: {
        sortBy: initialSort
      },
      getRowId: row => row._id,
      width: 200,
      minWidth: 200,
      maxWidth: 200
    },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    isProcessingSelectedRows ? hooks : () => {}
  );
  useEffect(() => {
    if (sortBy.length > 0 && pageCount > 1) {
      refetch(pageIndex + 1, sortBy);
    }
  }, [sortBy]);

  useEffect(() => {
    if (pageCount > 1) refetch(pageIndex + 1, sortBy);
  }, [pageIndex]);

  if (isProcessingSelectedRows) {
    setSelectedRowIds(Object.keys(selectedRowIds));
  }

  // react-table@v7 arbitrarily adds role to each element.
  // This is not needed if we are using the proper html elements
  // and is only required when we use other elements like divs.
  // https://github.com/TanStack/table/issues/2862
  return (
    <>
      <TableStyled {...getTableProps()} className={className}>
        <TheadStyled>
          {headerGroups.map(headerGroup => (
            <TheadTrStyled {...headerGroup.getHeaderGroupProps()} role={false}>
              {headerGroup.headers.map(column => (
                <TheadThStyled
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  {...column.getHeaderProps({
                    style: { width: column.width }
                  })}
                  role={false}
                >
                  {column.canSort && column.isSorted && (
                    <ArrowDownStyled isSorting={column.isSortedDesc} />
                  )}
                  {column.render('Header')}
                </TheadThStyled>
              ))}
            </TheadTrStyled>
          ))}
        </TheadStyled>
        <TbdoyStyled {...getTableBodyProps()} role={false}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <TbodyTrStyled
                data-testid={`table-row-${index}`}
                isSelected={row.isSelected}
                {...row.getRowProps()}
                role={false}
              >
                {row.cells.map(cell => {
                  const isSelect = cell?.column?.id === 'selection';
                  return (
                    <TbodyTdStyled
                      onClick={
                        isSelect ? () => {} : () => handleRowClicked(row.id)
                      }
                      isClickable={isSelect ? false : !!handleRowClicked}
                      {...cell.getCellProps()}
                      role={false}
                    >
                      {cell.render('Cell')}
                    </TbodyTdStyled>
                  );
                })}
              </TbodyTrStyled>
            );
          })}
        </TbdoyStyled>
      </TableStyled>
      {pageCount > 1 && (
        <Pagination
          pageIndex={pageIndex}
          pageCount={pageCount}
          gotoPage={gotoPage}
          previousPage={previousPage}
          nextPage={nextPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
        />
      )}
    </>
  );
};

TableNew.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  controlledPageCount: PropTypes.number,
  isProcessingSelectedRows: PropTypes.bool,
  setSelectedRowIds: PropTypes.func,
  handleRowClicked: PropTypes.func,
  refetch: PropTypes.func,
  initialSort: PropTypes.array
};

TableNew.defaultProps = {
  columns: [],
  data: [],
  controlledPageCount: 1,
  isProcessingSelectedRows: false,
  setSelectedRowIds: () => {},
  handleRowClicked: () => {},
  refetch: () => {},
  initialSort: []
};

export default TableNew;
