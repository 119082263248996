export default {
  buttons_cancel: 'Cancel',
  buttons_next: 'Next',
  buttons_back: 'Back',
  buttons_confirm: 'Confirm',
  buttons_export: 'Export',
  buttons_invite: 'Send invite',
  buttons_submit: 'Submit',
  buttons_close: 'Close',
  buttons_copy: 'Copy',
  buttons_save: 'Save'
};
