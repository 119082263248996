import React, { useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import hasPermissionOrAdmin from 'shared/helpers/hasPermissionOrAdmin';
import { programFilterAtom } from 'shared/store/program-filter';

const WithPermissions = ({ user, routePermissions, children }) => {
  const programFilter = useRecoilValue(programFilterAtom);

  const permissions = useMemo(
    () =>
      user?.schoolPermissions.find(
        schoolPermission =>
          schoolPermission.schoolId === programFilter?.schoolId
      )?.keys || [],
    [programFilter.schoolId]
  );

  const hasPermissions = hasPermissionOrAdmin(
    routePermissions,
    user?.roles,
    permissions
  );

  if (!hasPermissions && !!programFilter?.schoolId) {
    return <Redirect to="/403" />;
  }

  return children;
};

export default WithPermissions;
