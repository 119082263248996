export default {
  home_greeting: 'Welcome, {{name}}!',
  home_subheader_assessments: `Your portal to assessment results.`,
  home_subheader_analytics: `Your portal to comprehensive data insights.`,
  home_subheader_assessments_analytics: `Your portal to assessment results and comprehensive data insights.`,
  home_content_title_admin: 'Welcome to Acuity Insights',
  home_passthrough: '<passthrough>Access them here</passthrough>',
  home_passthrough_title:
    'Looking for the MSPE Builder, File Upload tool, Data Dictionary or SAML configuration?',
  home_content_subtitle_admin:
    'You can use the Applicants page to search and access program results. You can also manage all program users and team members using the Users page.',
  home_applicants_title_program: 'Applicants',
  home_applicants_subtitle_program: 'in selected admission cycle and test',
  home_results_title_program: 'New results',
  home_results_subtitle_program: 'as of',
  home_files_title_program: 'Go to Files to download scores in .csv format',
  home_files_link_program: 'View files',
  home_scores_title_program: 'Casper Scores',
  home_scores_subtitle_program:
    'Use Insights visualization tools to drive your applicant selection process',
  home_scores_link_program: 'View scores',
  home_demographic_title:
    'Want to learn more about the demographic composition of your applicant pool?',
  home_demographic_link: 'Click here',
  home_intro_modal_apps_title: 'Access all your results in one place',
  home_intro_modal_apps_message:
    'Results for Casper, Snapshot, and Duet are all available on the Applicants page.',
  home_program_admin_title_en:
    'There are {{number}} applicants who have distributed to your program.',
  home_program_admin_title_fr: '',
  home_program_admin_title_fr_empty: '',
  home_program_admin_subtitle: `All applicant results were last updated {{distanceFromNow}} on {{lastDate}} at {{timeStamp}}.`,
  home_program_admin_results_link: 'Go to all applicant results',
  home_program_admin_results_available: 'Results available',
  home_program_admin_results_new: 'this week',
  home_program_admin_card_casper: 'Go to Applicant results',
  home_program_admin_card_casper_empty:
    'Your Casper results haven’t arrived yet. It may take some time for the results to become available here. Please check back soon.',
  home_program_admin_card_snapshot: 'Review video responses',
  home_program_admin_card_snapshot_empty:
    'There are no results available at the moment for your applicants. Please go to Snapshot to review video responses.',
  home_program_admin_card_duet: 'Complete assessment',
  home_program_admin_card_duet_empty:
    'There are no results available at the moment for your applicants. If not yet completed, please go to Duet to complete the assessment.',
  home_team_member_card_snapshot:
    'Access Snapshot to review applicant video responses for your program.',
  home_team_member_card_duet:
    'Access Duet to complete a value-alignment assessment for your program.',
  home_eras_main_card_title: 'Coming soon!',
  home_eras_main_card_message: 'Your results will be available at',
  home_preparation_title: 'Preparation',
  home_recommended_selection:
    'Access a recommended selection process specific to your program',
  home_recommended_selection_link: 'https://forms.gle/tDz7BytZxSFMs8hj8',
  home_snapshot_training_link: 'View Snapshot reviewer training',
  home_duet_profile_link: 'View and Manage Duet Program Profile',
  home_results_title: 'Assessment results',
  home_analysis_title: 'Analysis',
  home_book_a_call_title: 'Changes have arrived!',
  home_book_a_call_message:
    "We're actively making updates to improve your experience. Tell us what you think.",
  home_book_a_call_button: 'Book a call to share feedback',
  home_survey_button: 'Fill out a quick survey',
  home_take_tour: 'Take a tour',
  home_analytics_title: 'Learn more about Analytics',
  home_analytics_description:
    'View metrics and draw insights about your learners and program',
  home_recently_viewed_dashboards: 'Continue where you left off:',
  home_frequented_dashboards: 'Most popular dashboards:',
  home_applicant_results:
    'View your applicants’ Casper, Snapshot, and Duet results',
  home_formative_title: 'Formative',
  home_formative_description:
    'Formative SJT effectively supports the development of interpersonal and professional competencies in higher education students.',
  home_formative_sjt: 'Go to Formative SJT'
};
