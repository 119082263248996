import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Radio } from 'components';
import { flushErrorsState } from 'shared/helpers/flushErrorsState';
import { twoFaMethod } from 'shared/helpers/twoFaMethod';

import {
  RadioButtonsGroup,
  MethodErrorStyled,
  NextIconStyled,
  ButtonsGroupStyled,
  MessageStyled,
  FormStyled,
  BackIconStyled
} from '../two-fa-method/two-fa-method.styles';

export default ({ handleSubmit, handleBack, hideArrows = false }) => {
  const { t } = useTranslation();
  const [radioButtonItems, setRadioButtonsItems] = useState(twoFaMethod({ t }));
  const [errors, setErrors] = useState([]);

  const handleClickRadioButton = (name, checked) => {
    flushErrorsState({
      errors,
      name: 'method',
      value: radioButtonItems.map(item =>
        item.name === name ? { ...item, checked } : { ...item, checked: false }
      ),
      updateErrors: setErrors([]),
      updateState: setRadioButtonsItems
    });
  };

  return (
    <FormStyled
      onSubmit={e => {
        e.preventDefault();
        const selectedName = radioButtonItems.filter(x => x.checked)[0]?.name;
        if (selectedName) {
          handleSubmit(selectedName);
        } else {
          setErrors([
            {
              name: 'method',
              message: t?.('select_a_valid_method')
            }
          ]);
        }
      }}
    >
      <div style={{ flex: '1' }}>
        <MessageStyled data-pendoid="common_verification_code_message">
          {t?.('common_verification_code_message')}
        </MessageStyled>
        <RadioButtonsGroup>
          {radioButtonItems.map(item => (
            <Radio handleClick={handleClickRadioButton} {...item} />
          ))}
        </RadioButtonsGroup>
        <MethodErrorStyled>
          {errors.map(item => {
            return item.message;
          })}
        </MethodErrorStyled>
      </div>
      <ButtonsGroupStyled buttonsAlignment={handleBack}>
        {handleBack ? (
          <Button
            data-pendoid="buttons_back"
            category="outline"
            handleClick={handleBack}
            label={t?.('buttons_back')}
          >
            {!hideArrows && <BackIconStyled color={'#000000'} />}
            {t?.('buttons_back')}
          </Button>
        ) : null}
        <Button
          data-pendoid="buttons_next"
          type="submit"
          label={t?.('buttons_next')}
        >
          {t?.('buttons_next')}{' '}
          {!hideArrows && <NextIconStyled color="#ffffff" />}
        </Button>
      </ButtonsGroupStyled>
    </FormStyled>
  );
};
