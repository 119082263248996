import React from 'react';

export default ({ className, color = '#080810' }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <title>User's 2-factor authentication</title>
    <path
      d="M12 13.875C11.7929 13.875 11.625 14.0429 11.625 14.25C11.625 14.4571 11.7929 14.625 12 14.625C12.2071 14.625 12.375 14.4571 12.375 14.25C12.3753 14.1505 12.3358 14.0549 12.2655 13.9845C12.1951 13.9142 12.0995 13.8747 12 13.875V13.875"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.75 9.75V7.5C15.7445 5.43121 14.0688 3.75549 12 3.75V3.75C9.93144 3.75604 8.25604 5.43144 8.25 7.5V9.75"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.75 3.75V2.25C0.75 1.42157 1.42157 0.75 2.25 0.75H3.75"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.25 3.75V2.25C23.25 1.42157 22.5784 0.75 21.75 0.75H20.25"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.75 20.25V21.75C0.75 22.5784 1.42157 23.25 2.25 23.25H3.75"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.25 20.25V21.75C23.25 22.5784 22.5784 23.25 21.75 23.25H20.25"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="6.75"
      y="9.75"
      width="10.5"
      height="9"
      rx="1.5"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
