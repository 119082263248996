import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Notification from 'layouts/notification/notification';

import { LinkStyled } from './error-panel.styles';
import { Button } from 'components';

const ErrorPanel = ({ user, title, message }) => {
  const { t } = useTranslation();
  const getRedirectInfo = () => {
    if (user) {
      return {
        redirectLink: '/',
        key: 'not_found_button_auth',
        redirectMessage: t('not_found_button_auth')
      };
    }
    return {
      redirectLink: '/signin',
      key: 'not_found_button',
      redirectMessage: t('not_found_button')
    };
  };

  const { redirectLink, redirectMessage, key } = getRedirectInfo();

  return (
    <Notification title={title} message={message}>
      <LinkStyled ariaLabel={key} to={redirectLink} data-pendoid={key}>
        <Button label={redirectMessage}>{redirectMessage}</Button>
      </LinkStyled>
    </Notification>
  );
};

ErrorPanel.propTypes = {
  user: PropTypes.object,
  title: PropTypes.string,
  message: PropTypes.string
};

ErrorPanel.defaultProps = {
  user: null,
  title: '',
  message: ''
};

export default ErrorPanel;
