import styled from 'styled-components/macro';

import { fullCenter } from 'shared/css/common-classes';

export const WrapperStyled = styled.div`
  ${({ theme: { selects = {} } }) => `
    margin: ${selects.wrapper?.margin};
    position: relative;
  `};
`;

export const LabelStyled = styled.label`
  ${({ theme: { selects = {}, typography } }) => `
    ${typography?.(14)}
    color: ${selects.label?.color};
    display: ${selects.label?.display};
    text-align: ${selects.label?.textAlign};
  `};
`;

export const SelectStyled = styled.div`
  ${({ theme: { inputs = {}, transitions, spacing, typography }, error }) => `
    ${fullCenter};
    ${typography?.(16)}
    background-color: #ffffff;
    border: ${inputs.item?.border};
    border-radius: ${inputs.item?.borderRadius};
        box-shadow: ${inputs.item?.boxShadow?.[error ? 'error' : 'default']};
    color: ${inputs.item?.color};
    height: ${spacing?.(5)};
    margin: ${spacing?.(0.5)} 0;
    min-width: ${spacing?.(5)};
    outline: none;
    transition: all ${transitions?.fast_50} ease-in-out;
    &:hover {
      border-color: ${
        inputs.item?.hover?.borderColor?.[error ? 'error' : 'default']
      };
      box-shadow: ${
        inputs.item?.hover?.boxShadow?.[error ? 'none' : 'default']
      };
    }
    &:focus {
      border-color: ${
        inputs.item?.focus?.borderColor?.[error ? 'error' : 'default']
      };
      box-shadow: ${
        inputs.item?.focus?.boxShadow?.[error ? 'error' : 'default']
      };
    }
  `};
  cursor: pointer;
  position: relative;
`;

export const TitleStyled = styled.div`
  ${({ theme: { inputs = {}, selects = {}, spacing } }) => `
    align-items: center;
    color: ${selects.label?.color};
    display: flex;
    justify-content: space-between;
    padding: ${inputs.item?.padding};
    padding-right: ${spacing?.(1)};
    width: 100%;
  `};
`;

export const MessageStyled = styled.div`
  ${({ theme: { inputs = {} }, error, hide }) => `
    color: ${inputs.message?.color?.[error ? 'error' : 'primary']};
    text-align: left;
    visibility: ${hide ? 'hidden' : 'visible'};
  `}
`;

export const SelectOptionsStyled = styled.div`
  ${({ theme: { dropdowns = {}, transitions, typography }, open }) => `
    ${typography?.(14)}
    background-color: ${dropdowns.options?.backgroundColor};
    border-radius: ${dropdowns.options?.borderRadius};
    box-shadow: ${dropdowns.options?.boxShadow};
    display: ${open ? 'block' : 'none'};
    min-width: ${dropdowns.options?.minWidth};
    padding: ${dropdowns.options?.padding};
    position: ${dropdowns.options?.position};
    right: ${dropdowns.options?.right};
    transition: all ${transitions?.fast_50} ease-in-out;
    width: ${dropdowns.options?.width};
    z-index: ${open ? '10' : '0'};
  `};
`;

export const SelectItemStyled = styled.div`
  ${({ theme: { dropdowns = {}, spacing, transitions }, disabled }) => `
    border: ${dropdowns.item?.border};
    color: ${dropdowns.item?.color?.[disabled ? 'disabled' : 'default']};
    cursor: ${disabled ? 'default' : 'pointer'};
    padding: ${spacing?.(1)} ${spacing?.(0.5)};
    outline: ${dropdowns.item?.outline};
    transition: all ${transitions?.fast_50} ease-in-out;
    &:hover {
      background-color: ${
        dropdowns.item?.hover?.backgroundColor?.[
          disabled ? 'disabled' : 'default'
        ]
      };
      border-color: ${dropdowns.item?.hover?.borderColor};
    }
    &:active {
      background-color: ${
        dropdowns.item?.active?.backgroundColor?.[
          disabled ? 'disabled' : 'default'
        ]
      };
      border-color: ${dropdowns.item?.active?.borderColor};
    }
    &:focus {
      border-color: ${dropdowns.item?.focus?.borderColor};
    }
  `};
`;
