import { countries } from 'countries-list';

export default () => {
  const firstCodes = [];
  let phoneCodeList = Object.values(countries)
    .map(item => ({
      value: `${item.phone.split(',')[0]},${item.name}`,
      label: `${item.emoji} +${item.phone.split(',')[0]}`,
      name: item.name.toLowerCase()
    }))
    .filter(item => {
      if (item.name === 'canada' || item.name === 'united states') {
        firstCodes.push(item);
        return null;
      }
      return item;
    });
  phoneCodeList = [...firstCodes, ...phoneCodeList];
  return phoneCodeList;
};
