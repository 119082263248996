import React from 'react';
import { KEYBOARD_EVENT_VALUES } from '../../shared/enums';

export default ({
  className,
  color = '#C6C6C6',
  handleClick = () => {},
  title,
  ...props
}) => {
  return (
    <svg
      data-pendoid={props?.['data-pendoid'] || ''}
      className={className}
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={handleClick}
      onKeyPress={e => {
        e.preventDefault();
        if (
          e.key === KEYBOARD_EVENT_VALUES.SPACE ||
          e.key === KEYBOARD_EVENT_VALUES.ENTER
        ) {
          handleClick(e);
        }
      }}
      data-testid="zoom-in"
      tabIndex={0}
      title={title}
    >
      <path
        d="M13.5833 7.04133C13.5834 8.23639 13.2291 9.40464 12.5653 10.3983C11.9014 11.392 10.9578 12.1666 9.85375 12.624C8.74969 13.0813 7.53479 13.201 6.3627 12.9679C5.1906 12.7348 4.11396 12.1594 3.26893 11.3143C2.4239 10.4693 1.84844 9.39267 1.61533 8.22057C1.38222 7.04848 1.50193 5.83358 1.95932 4.72952C2.41671 3.62546 3.19123 2.68184 4.18493 2.01798C5.17863 1.35413 6.34689 0.999868 7.54194 1C9.14414 1.00018 10.6807 1.63673 11.8136 2.76966C12.9465 3.90259 13.5831 5.43913 13.5833 7.04133Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 7H10.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 10V4"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.814 11.3135L16.5 16.0001"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5833 7.04133C13.5834 8.23639 13.2291 9.40464 12.5653 10.3983C11.9014 11.392 10.9578 12.1666 9.85375 12.624C8.74969 13.0813 7.53479 13.201 6.3627 12.9679C5.1906 12.7348 4.11396 12.1594 3.26893 11.3143C2.4239 10.4693 1.84844 9.39267 1.61533 8.22057C1.38222 7.04848 1.50193 5.83358 1.95932 4.72952C2.41671 3.62546 3.19123 2.68184 4.18493 2.01798C5.17863 1.35413 6.34689 0.999868 7.54194 1C9.14414 1.00018 10.6807 1.63673 11.8136 2.76966C12.9465 3.90259 13.5831 5.43913 13.5833 7.04133Z"
        strokeOpacity="0%"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 7H10.5"
        strokeOpacity="0%"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 10V4"
        strokeOpacity="0%"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.814 11.3135L16.5 16.0001"
        strokeOpacity="0%"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
