import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { Button, Input } from 'components';

import {
  BackIconStyled,
  ArrowLinkStyled,
  TextButtonStyled,
  ButtonsGroupStyled,
  NextIconStyled,
  MessageStyled,
  FormStyled
} from './user-verification-code.styles';

export default ({
  handleSubmit,
  handleResend,
  handleBack,
  mutation,
  error,
  setError,
  buttonsAlignment,
  submitButton,
  backButton,
  hideArrows = false,
  isModal = false
}) => {
  const { t } = useTranslation();
  const [verificationCode, setVerificationCode] = useState('');
  const [mutationFunction] = useMutation(mutation);

  return (
    <FormStyled
      onSubmit={e => {
        e.preventDefault();
        handleSubmit({ mutationFunction, data: { verificationCode } });
      }}
    >
      <div>
        <Input
          data-pendoid="input_verification_code"
          label={t?.('input_verification_code')}
          name="verificationCode"
          value={verificationCode}
          handleChange={e => {
            if (error) {
              setError('');
            }
            setVerificationCode(e.target.value);
          }}
          required={true}
          errorPlaceholder={true}
          error={Boolean(error)}
          errorMessage={error}
        />
        <MessageStyled
          data-pendoid="common_verification_code_question"
          customMargin="12px"
        >
          {t?.('common_verification_code_question')}
        </MessageStyled>
        <TextButtonStyled
          handleClick={() => handleResend()}
          customMargin={isModal ? null : '96px'}
        >
          {t?.('common_verification_code_resend')} <ArrowLinkStyled />
        </TextButtonStyled>
      </div>
      <ButtonsGroupStyled buttonsAlignment={buttonsAlignment}>
        <Button
          data-pendoid="buttons_back"
          category="outline"
          handleClick={handleBack}
          label={backButton || t?.('buttons_back')}
        >
          {!hideArrows && <BackIconStyled color={'#000000'} />}
          {backButton || t?.('buttons_back')}
        </Button>
        <Button
          data-pendoid="buttons_next"
          type="submit"
          label={submitButton || t?.('buttons_next')}
        >
          {submitButton || t?.('buttons_next')}{' '}
          {!hideArrows && <NextIconStyled color="#ffffff" />}
        </Button>
      </ButtonsGroupStyled>
    </FormStyled>
  );
};
