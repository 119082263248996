import colors from './colors';
import config from './config';
import spacing from './spacing';

const item = {
  backgroundColor: {
    active: colors.primary?.lighter,
    default: 'transparent'
  },
  border: '2px solid transparent',
  borderRadius: config.borderRadius,
  color: {
    active: colors.primary?.darkest,
    edge: colors.grey?.light,
    default: colors.primary?.dark
  },
  textAlign: 'center',
  width: `${spacing?.(5)}`,
  hover: {
    backgroundColor: {
      edge: '',
      default: colors.primary?.lightest
    }
  },
  focus: {
    borderColor: colors.primary?.default
  }
};

const arrows = {
  color: {
    disabled: colors.grey?.light,
    default: colors.primary?.dark
  },
  fill: colors.secondary?.default,
  width: `${spacing?.(4)}`
};

const wrapper = {
  margin: `${spacing?.(1.5)} 0`,
  child: {
    marginLeft: `${spacing?.(1)}`,
    marginRight: `${spacing?.(1)}`
  }
};

export default {
  item,
  arrows,
  wrapper
};
