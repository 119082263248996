export default {
  input_email: 'Courriel',
  input_email_blank: 'Veuillez entrer une adresse courriel',
  input_email_invalid: 'Veuillez entrer une adresse courriel valide',
  input_email_invalid_role: `La connexion a échoué. Vous n'avez peut-être pas l'autorisation d'accéder à cette plateforme.`,
  input_password: 'Mot de passe',
  input_password_blank: 'Veuillez entrer un mot de passe',
  input_password_invalid: 'Veuillez entrer un mot de passe valide',
  input_password_invalid_role: `La connexion a échoué. Vous n'avez peut-être pas l'autorisation d'accéder à cette plateforme.`,
  input_password_not_match: 'Les mots de passe ne sont pas identiques',
  input_phone_number: 'Numéro de téléphone',
  input_country_code: 'Indicatif pays',
  input_phone_extension: 'Extension (si applicable)',
  input_phone_extension_error:
    'Veuillez entrer une extension de téléphone valide',
  input_verification_code: 'Code de vérification',
  input_verification_code_error:
    'Code de vérification erroné. Veuillez réessayer ou cliquez sur renvoyer le code de vérification.',
  input_verification_code_message:
    'Veuillez entrer un code de vérification valide',
  input_string_error: 'Veuillez entrer un',
  input_string_invalid_characters:
    'ne peut pas commencer avec les caractères suivants : =, +, -, @, TABULATION ou RETOUR.',
  input_givenname: 'prénom',
  input_surname: 'nom de famille',
  input_password_validation_between: 'entre 8 et 30 caractères',
  input_password_validation_lowercase: 'au moins 1 minuscule',
  input_password_validation_uppercase: 'au moins 1 majuscule',
  input_password_validation_digit: 'au moins 1 chiffre',
  input_password_validation_special: 'au moins 1 caractère spécial',
  input_password_validation_whitespace: `ne peut pas contenir d'espace`,
  input_login_disabled_prefix: 'Connexion désactivée pendant',
  input_login_disabled_suffix:
    'en raison de plusieurs tentatives infructueuses.',
  input_first_name_required: 'Prénom requis',
  input_last_name_required: 'Nom de famille requis',
  input_email_required: 'Courriel requis',
  input_first_name_invalid: 'Prénom contient des caractères invalides',
  input_last_name_invalid: 'Nom contient des caractères invalides',
  input_email_duplicate: 'Cette adresse courriel est déjà prise'
};
