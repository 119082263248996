export default {
  buttons_cancel: 'Annuler',
  buttons_next: 'Suivant',
  buttons_back: 'Retour',
  buttons_confirm: 'Confirmer',
  buttons_export: 'Exporter',
  buttons_invite: `Envoyer l'invitation`,
  buttons_submit: 'Envoyer',
  buttons_close: 'Fermer',
  buttons_copy: 'Copier',
  buttons_save: 'Enregistrer'
};
