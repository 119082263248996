import React from 'react';

export default ({ className }) => {
  return (
    <svg
      className={className}
      width="49"
      height="49"
      viewBox="0 0 49 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
    >
      <title>Duet profile setup</title>
      <circle cx="24.5" cy="24.5" r="24.5" fill="#FEF1EC" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.0417 32C32.1002 32 32.9583 31.1419 32.9583 30.0833C32.9583 29.0248 32.1002 28.1666 31.0417 28.1666C29.9831 28.1666 29.125 29.0248 29.125 30.0833C29.125 31.1419 29.9831 32 31.0417 32Z"
        stroke="#F26F3E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.6695 22.6722L33.2343 24.5288C33.4277 25.1682 34.0834 25.5486 34.7344 25.399L36.6153 24.9633C37.3471 24.7983 38.101 25.1293 38.4748 25.7797C38.8486 26.43 38.7553 27.248 38.2445 27.7974L36.9271 29.217C36.471 29.7074 36.471 30.4669 36.9271 30.9573L38.2445 32.3769C38.7553 32.9263 38.8486 33.7443 38.4748 34.3946C38.101 35.045 37.3471 35.376 36.6153 35.211L34.7344 34.7753C34.0834 34.6257 33.4277 35.0061 33.2343 35.6455L32.6695 37.4944C32.454 38.2135 31.7923 38.7059 31.0417 38.7059C30.291 38.7059 29.6293 38.2135 29.4138 37.4944L28.849 35.6378C28.6556 34.9984 27.9999 34.6181 27.3489 34.7676L25.468 35.2034C24.7362 35.3683 23.9823 35.0373 23.6085 34.387C23.2347 33.7366 23.328 32.9186 23.8388 32.3693L25.1562 30.9496C25.6123 30.4592 25.6123 29.6998 25.1562 29.2093L23.8388 27.7897C23.328 27.2403 23.2347 26.4223 23.6085 25.772C23.9823 25.1217 24.7362 24.7907 25.468 24.9556L27.3489 25.3913C27.9999 25.5409 28.6556 25.1606 28.849 24.5211L29.4138 22.6645C29.631 21.946 30.2939 21.4551 31.0445 21.4569C31.7951 21.4586 32.4557 21.9527 32.6695 22.6722V22.6722Z"
        stroke="#F26F3E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.7916 27.2083H19.5416"
        stroke="#F26F3E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5416 27.2083V17.625"
        stroke="#F26F3E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.375 21.4583V19.5416"
        stroke="#F26F3E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.7083 27.2083V21.4583"
        stroke="#F26F3E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.5833 34.875H11.875C10.8164 34.875 9.95831 34.0169 9.95831 32.9583V11.875C9.95831 10.8164 10.8164 9.95831 11.875 9.95831H25.4565C25.9645 9.95842 26.4516 10.1602 26.8109 10.5193L30.4807 14.189C30.8398 14.5483 31.0415 15.0355 31.0416 15.5435V16.6666"
        stroke="#F26F3E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
