import React, { FC, MutableRefObject } from 'react';
import { useRecoilValue } from 'recoil';
import { IUserProfileAtom } from '@types';
import {
  DataGridProProps,
  GridApiPro,
  GridCellParams,
  GridColDef,
  GridRowClassNameParams,
  GridRowsProp
} from '@mui/x-data-grid-pro';
import { enUS, frFR } from '@mui/x-data-grid/locales';
import { DataGridStyled } from './data-grid.styles';
import { verifyColumns } from './helpers/verifyColumns';
import { LANGUAGE } from 'shared/enums';
import colors from 'shared/css/theme';
import { userProfileAtom } from 'shared/store/user';

interface IDataGridProps {
  apiRef?: MutableRefObject<GridApiPro>;
  checkboxSelection?: boolean;
  className?: string;
  columnHeaderHeight?: number;
  columns: GridColDef[];
  density?: 'comfortable' | 'compact' | 'standard';
  disableRowSelectionOnClick?: boolean;
  getCellClassName?: (params: GridCellParams) => string;
  getRowClassName?: (params: GridRowClassNameParams) => string;
  getRowId?: (row: any) => string;
  getRowHeight?: () => any;
  getTreeDataPath?: DataGridProProps['getTreeDataPath'];
  groupingColDef?: DataGridProProps['groupingColDef'];
  hideFooter?: boolean;
  initialState?: any;
  onRowSelectionModelChange: (nextSelection: (string | number)[]) => void;
  onRowClick: (params: GridRowClassNameParams) => void;
  pageSizeOptions?: number[];
  rows: GridRowsProp[];
  rowSelectionModel: string[];
  hideFooterSelectedRowCount?: boolean;
  slots?: any;
  slotProps?: { [key: string]: any };
  treeData?: boolean;
  props?: any;
}

const DataGrid: FC<IDataGridProps> = ({
  apiRef = null,
  checkboxSelection = false,
  columnHeaderHeight = 56,
  columns,
  density = 'standard',
  disableRowSelectionOnClick = true,
  className,
  getCellClassName = () => '',
  getRowClassName = () => '',
  getRowId,
  getTreeDataPath = () => null,
  groupingColDef = null,
  hideFooter = false,
  initialState = null,
  rows,
  slots = null,
  slotProps = {},
  treeData = false,
  getRowHeight = () => 'auto',
  onRowSelectionModelChange,
  pageSizeOptions = [25, 50, 100],
  rowSelectionModel,
  onRowClick = () => {},
  hideFooterSelectedRowCount,
  ...props
}) => {
  const user = useRecoilValue<IUserProfileAtom>(userProfileAtom);
  const localeText =
    user?.lang === LANGUAGE.FRENCH.code
      ? frFR.components.MuiDataGrid.defaultProps.localeText
      : enUS.components.MuiDataGrid.defaultProps.localeText;

  const verifiedColumns = verifyColumns(columns);

  return (
    <DataGridStyled
      apiRef={apiRef}
      columnHeaderHeight={columnHeaderHeight}
      checkboxSelection={checkboxSelection}
      columns={verifiedColumns}
      data-pendoid={props['data-pendoid'] || 'data-grid'}
      data-testid={props['data-testid'] || 'data-grid'}
      density={density}
      disableRowSelectionOnClick={disableRowSelectionOnClick}
      getCellClassName={getCellClassName}
      getRowClassName={getRowClassName}
      getRowHeight={getRowHeight}
      getRowId={getRowId || (row => row.id)}
      getTreeDataPath={getTreeDataPath}
      groupingColDef={groupingColDef}
      hideFooter={hideFooter}
      initialState={initialState}
      localeText={localeText}
      onRowSelectionModelChange={onRowSelectionModelChange}
      pageSizeOptions={pageSizeOptions}
      rows={rows}
      rowSelectionModel={rowSelectionModel}
      hideFooterSelectedRowCount={hideFooterSelectedRowCount}
      slots={slots}
      slotProps={{
        toolbar: { printOptions: { disableToolbarButton: true } },
        panel: {
          sx: {
            '.MuiCheckbox-root': {
              color: `${colors?.colors?.grey?.default}`,
              '&.Mui-checked, &.MuiCheckbox-indeterminate': {
                color: `${colors?.colors?.base?.altusPurple?.default}`
              },
              '.MuiTouchRipple-root': {
                display: 'none'
              },
              ':hover': {
                backgroundColor: 'transparent'
              }
            },
            '.MuiButton-text': {
              color: `${colors?.colors?.base?.altusPurple?.default}`,
              ':focus': {
                backgroundColor: 'transparent'
              }
            },
            '.MuiButtonBase-root': {
              '.MuiTouchRipple-root': {
                display: 'none'
              },
              ':hover': {
                backgroundColor: 'transparent'
              }
            },
            '.MuiOutlinedInput-root': {
              '&.Mui-focused fieldset': {
                borderColor: `${colors?.colors?.base?.altusPurple?.default}`
              }
            },
            '.MuiFormLabel-root': {
              '&.Mui-focused': {
                color: `${colors?.colors?.base?.altusPurple?.default}`
              }
            },
            '.MuiInputBase-root': {
              ':after': {
                borderBottomColor: `${colors?.colors?.base?.altusPurple?.default}`
              }
            }
          }
        },
        ...slotProps
      }}
      treeData={treeData}
      className={className}
      pagination={true}
      onRowClick={onRowClick}
      {...props}
    />
  );
};

export default DataGrid;
