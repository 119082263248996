import React from 'react';
import PropTypes from 'prop-types';

import {
  CardStyled,
  WrapperStyled,
  IconWrapperStyled,
  TitleStyled,
  IconStyled
} from './sign-in-card.styles';

const SignInCard = ({ className, iconLink, title, children }) => {
  return (
    <CardStyled className={className} data-testid={`sign-in-card-${className}`}>
      <IconWrapperStyled>
        <IconStyled src={iconLink} alt="Not found"></IconStyled>
      </IconWrapperStyled>
      <WrapperStyled>
        <TitleStyled>{title}</TitleStyled>
        {children}
      </WrapperStyled>
    </CardStyled>
  );
};

SignInCard.propTypes = {
  className: PropTypes.string,
  iconLink: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.element
};

SignInCard.defaultProps = {
  className: '',
  icon: '',
  title: '',
  children: null
};

export default SignInCard;
