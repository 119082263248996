import { gql } from '@apollo/client';

export const SCHOOLS = gql`
  query getSchools(
    $page: Int!
    $limit: Int
    $sortField: String
    $sortDir: String
    $search: String
    $excludeIds: [String]
    $selectedSchoolId: String
  ) {
    schools(
      page: $page
      limit: $limit
      sortField: $sortField
      sortDir: $sortDir
      search: $search
      excludeIds: $excludeIds
      selectedSchoolId: $selectedSchoolId
    ) {
      docs {
        _id
        name
        short
        country
        applicationCycles
        productCycles {
          key
          applicationCycle
          testMasterId
          duetDeadlineAt
        }
        qlik {
          userDirectory
        }
        salesforceAccountId
        products
      }
      total
    }
  }
`;

export const SCHOOL_BY_ID = gql`
  query schoolById($id: String!) {
    schoolById(_id: $id) {
      _id
      name
      tests {
        testMasterId
        requiredIds
      }
      useLambda
      FTP
      ftpOptions
      exportFormat
      country
      analytics {
        host
      }
      qlik {
        theme
        userDirectory
      }
      salesforceAccountId
    }
  }
`;
