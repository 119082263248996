import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { KEYBOARD_EVENT_VALUES } from 'shared/enums';

import {
  ArrowStyled,
  ContentStyled,
  HeaderStyled,
  HeaderWrapperStyled,
  SubtitleStyled,
  TitleStyled,
  WrapperStyled
} from './accordion.styles';

const Accordion = ({
  className,
  title,
  subtitle,
  children,
  isOpen = false,
  stateChangeCallback = () => {},
  ...props
}) => {
  const accordionRef = useRef(null);
  const [open, setOpen] = useState(isOpen);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleChange = () => {
    setOpen(prevState => !prevState);
    stateChangeCallback(!open);
  };

  return (
    <WrapperStyled
      className={className}
      ref={accordionRef}
      data-testid={props['data-testid'] || 'accordion'}
      tabIndex="0"
      onKeyDown={e => {
        if (
          (e.code === KEYBOARD_EVENT_VALUES.ENTER ||
            e.code === KEYBOARD_EVENT_VALUES.SPACE_CODE) &&
          document.activeElement === accordionRef.current
        ) {
          e.preventDefault();
          handleChange();
        }
      }}
      open={open}
    >
      <HeaderStyled
        data-testid={`${props['data-testid'] || 'accordion'}-header`}
        onClick={handleChange}
        className={'accordion-header'}
      >
        <HeaderWrapperStyled>
          <TitleStyled
            data-testid={`${props['data-testid'] || 'accordion'}-title`}
            className={'accordion-title'}
          >
            {title}
          </TitleStyled>
          <SubtitleStyled
            data-testid={`${props['data-testid'] || 'accordion'}-subtitle`}
            className={'accordion-subtitle'}
          >
            {subtitle}
          </SubtitleStyled>
        </HeaderWrapperStyled>
        <ArrowStyled
          data-testid={`${props['data-testid'] || 'accordion'}-icon`}
          className={'accordion-icon'}
          open={open}
        />
      </HeaderStyled>
      <ContentStyled open={open} className={'accordion-content'}>
        {open && children}
      </ContentStyled>
    </WrapperStyled>
  );
};

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  className: PropTypes.string
};

export default Accordion;
