import styled from 'styled-components/macro';

export const TextAreaWrapperStyled = styled.div`
  ${({ theme: { inputs } }) => `
    margin: ${inputs?.wrapper?.margin};
  `};
`;

export const TextAreaContentWrapperStyled = styled.div`
  ${({ theme: { inputs } }) => `
    position: ${inputs?.wrapper?.position};
  `};
`;

export const LabelStyled = styled.label`
  ${({ theme: { inputs, typography } }) => `
    ${typography?.(14)}
    color: ${inputs?.label?.color};
    text-align: ${inputs?.label?.textAlign};
  `};
  display: flex;
  flex-direction: column;
`;

export const TextAreaStyled = styled.textarea`
  ${({ theme: { inputs, typography, transitions }, error, disabled }) => `
    ${typography?.(16)}
    background-color: ${inputs?.item?.backgroundColor};
    border: ${inputs?.item?.border};
    border-radius: ${inputs?.item?.borderRadius};
    color: ${inputs?.item?.color};
    box-shadow: ${
      inputs?.item?.boxShadow?.[error && !disabled ? 'error' : 'default']
    };
    line-height: ${inputs?.item?.lineHeight};
    margin: ${inputs?.item?.margin};
    outline: ${inputs?.item?.outline};
    padding: ${inputs?.item?.padding};
    transition: all ${transitions?.fast} ease-in-out;
    width: ${inputs?.item?.width};
    &:hover,
    &:focus {
      box-shadow: ${disabled ? '' : 'none'};
    }
    &:hover {
      border-color: ${
        inputs?.item?.hover?.borderColor?.[
          disabled ? 'none' : error ? 'error' : 'default'
        ]
      };
      box-shadow: ${
        inputs?.item?.hover?.boxShadow?.[
          error ? 'none' : disabled ? 'disabled' : 'default'
        ]
      };
    }
    &:focus {
      border-color: ${
        inputs?.item?.focus?.borderColor?.[error ? 'error' : 'default']
      };
      box-shadow: ${
        inputs?.item?.focus?.boxShadow?.[error ? 'error' : 'default']
      };
    }
    &:disabled {
      background-color: ${inputs?.item?.disabled?.backgroundColor};
      color: ${inputs?.item?.disabled?.color};
    }
    &::placeholder {
      color: ${inputs?.item?.placeholder?.color};
      opacity: 1;
    }
  `};
`;

export const MessageStyled = styled.div`
  ${({ theme: { inputs, colors }, error, hide }) => `
    color: ${error ? colors?.danger?.default : colors?.grey?.darker};
    text-align: ${inputs?.message?.textAlign};
    visibility: ${hide ? 'hidden' : 'visible'};
  `}
`;
