import styled from 'styled-components/macro';

export const CardStyled = styled.div`
  ${({ theme: { cards = {} } }) => `
    background-color: ${cards?.backgroundColor};
    border: ${cards?.border};
    border-radius: ${cards?.borderRadius};
    box-shadow: ${cards?.boxShadow};
    padding: ${cards?.padding};
  `};
`;
