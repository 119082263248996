import React, { FC } from 'react';

type ShareProps = {
  title: string;
  className: string;
  color: string;
};

const Share: FC<ShareProps> = ({ title, className, color = '#4C55A5' }) => {
  return (
    <svg
      className={className}
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title || 'Share'}</title>
      <path
        d="M10.0625 5.8125H10.9375C11.4207 5.8125 11.8125 6.20425 11.8125 6.6875V13.6875C11.8125 14.1707 11.4207 14.5625 10.9375 14.5625H3.0625C2.57925 14.5625 2.1875 14.1707 2.1875 13.6875V6.6875C2.1875 6.20425 2.57925 5.8125 3.0625 5.8125H3.9375"
        stroke={color}
        strokeWidth="1.05"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 1.4375V7.5625"
        stroke={color}
        strokeWidth="1.05"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.8125 3.625L7 1.4375L9.1875 3.625"
        stroke={color}
        strokeWidth="1.05"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Share;
