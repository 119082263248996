import styled from 'styled-components/macro';

export const WrapperStyled = styled.div`
  position: relative;
`;

export const LanguageDisclaimer = styled.div`
  ${({ theme: { spacing, typography, colors } = {} }) => `
    ${typography?.(14)}
    color: ${colors.grey?.dark};
    margin-top: ${spacing?.(2)};
  `};
`;
