import React from 'react';

export default () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Edit Cycle</title>
      <path
        d="M14.0666 3.75781L12.3291 4.70969L13.1516 6.50344"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.8128 7.87676C16.6101 7.31935 16.289 6.8125 15.8716 6.39114C15.4372 5.96554 14.9158 5.63903 14.3432 5.43405C13.7707 5.22906 13.1605 5.15046 12.5547 5.20364"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.83887 17.4351L8.57574 16.4832L7.75387 14.6895"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.09375 13.3164C4.29528 13.8735 4.61512 14.3803 5.03125 14.802C5.46585 15.229 5.98754 15.5572 6.56064 15.7639C7.13375 15.9707 7.74475 16.0513 8.35187 16.0002"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.625 3.70703H8.86625C8.86625 3.70703 9.49125 3.70703 9.49125 4.33203V10.4033C9.49125 10.4033 9.49125 11.0283 8.86625 11.0283H4.625C4.625 11.0283 4 11.0283 4 10.4033V4.33203C4 4.33203 4 3.70703 4.625 3.70703Z"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9463 10.0859H16.1875C16.1875 10.0859 16.8125 10.0859 16.8125 10.7109V16.8103C16.8125 16.8103 16.8125 17.4353 16.1875 17.4353H11.9463C11.9463 17.4353 11.3213 17.4353 11.3213 16.8103V10.7109C11.3213 10.7109 11.3213 10.0859 11.9463 10.0859Z"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
