import navbar from './navbar';

export default {
  files_title: navbar.navbar_download_assessment_results,
  files_subtitle: 'Téléchargez les résultats au format .csv',
  files_col_name: 'Nom',
  files_col_size: 'Taille',
  files_col_lastModified: 'Modifié le',
  files_empty_state: `Il n'y a pas de fichiers disponibles actuellement pour la période d'admission sélectionnée`
};
