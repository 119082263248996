export default {
  signup_create_account_title: 'Create your Acuity Insights account',
  signup_create_account_email: 'For email account:',
  signup_secure_account_title: 'Secure your account',
  signup_secure_account_subtitle:
    'Add an extra layer of security to your account. You will receive a one-time code each time you log in.  Choose how you would like to receive that code.',
  signup_2fa_title: '2-Factor Authentication',
  signup_2fa_title_sms: 'SMS 2-Factor Authentication',
  signup_2fa_title_call: 'Phone Call 2-Factor Authentication',
  signup_2fa_title_email: 'Email 2-Factor Authentication',
  signup_terms_title: 'Terms and conditions',
  signup_terms_before: 'Before using Acuity Insights, you must review the',
  signup_terms_and: 'and',
  signup_terms_checkbox:
    'I agree to the Terms and Conditions and have read the Privacy Policy.',
  signup_confirmation_title: 'Success!',
  signup_confirmation_message:
    'Your account has been set up successfully. Click proceed to sign in to access your account.',
  signup_confirmation_button: 'Proceed to sign in',
  signup_preferred_language: 'Preferred language',
  signup_provide_phone_number_sms:
    'Provide your phone number to receive your authentication code via SMS',
  signup_provide_phone_number_call:
    'Provide your phone number to receive your authentication code through an automated phone call',
  select_a_valid_method: 'Please select a valid method'
};
