import React from 'react';

export default ({ className }) => (
  <svg
    className={className}
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Dashboard scores</title>;
    <path
      d="M0.75 23.32H23.25"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 17.56H3C2.58579 17.56 2.25 17.8824 2.25 18.28V23.32H6.75V18.28C6.75 17.8824 6.41421 17.56 6 17.56Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5 10.36H10.5C10.0858 10.36 9.75 10.6824 9.75 11.08V23.32H14.25V11.08C14.25 10.6824 13.9142 10.36 13.5 10.36Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 14.68H18C17.5858 14.68 17.25 15.0024 17.25 15.4V23.32H21.75V15.4C21.75 15.0024 21.4142 14.68 21 14.68Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.25 12.52C3.07843 12.52 3.75 11.8753 3.75 11.08C3.75 10.2847 3.07843 9.64 2.25 9.64C1.42157 9.64 0.75 10.2847 0.75 11.08C0.75 11.8753 1.42157 12.52 2.25 12.52Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 4.6C9.82843 4.6 10.5 3.95529 10.5 3.16C10.5 2.36471 9.82843 1.72 9 1.72C8.17157 1.72 7.5 2.36471 7.5 3.16C7.5 3.95529 8.17157 4.6 9 4.6Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.75 8.2C22.5784 8.2 23.25 7.55529 23.25 6.76C23.25 5.96471 22.5784 5.32 21.75 5.32C20.9216 5.32 20.25 5.96471 20.25 6.76C20.25 7.55529 20.9216 8.2 21.75 8.2Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.20001 9.96544L8.05001 4.27456"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.311 6.35296L10.439 3.56608"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
