import React from 'react';
import PropTypes from 'prop-types';

import HeaderLayout from 'layouts/header/header';

import {
  ContainerStyled,
  TitleStyled,
  MessageStyled
} from './notification.styles';

const Notification = ({ title, message, children }) => {
  return (
    <div data-testid="notification">
      <HeaderLayout hideBorder={true} />
      <ContainerStyled>
        <TitleStyled>{title}</TitleStyled>
        {message && <MessageStyled>{message}</MessageStyled>}
        {children}
      </ContainerStyled>
    </div>
  );
};

Notification.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  children: PropTypes.object
};

Notification.defaultProps = {
  title: '',
  message: '',
  children: null
};

export default Notification;
