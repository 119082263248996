import styled from 'styled-components/macro';
import { baseButtonStyles } from 'components/common.styles';

export const ButtonStyled = styled.button`
  ${baseButtonStyles}
  ${({ theme: { buttons = {} }, category }) => `
    &:disabled {
      background-color: ${buttons?.[category]?.disabled?.backgroundColor};
      border-color: ${buttons?.[category]?.disabled?.borderColor};
      box-shadow: ${buttons?.[category]?.disabled?.boxShadow};
      color: ${buttons?.[category]?.disabled?.color};
      cursor: default;
    }
  `}
`;
