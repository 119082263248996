import colors from './colors';
import config from './config';
import spacing from './spacing';

const defaultConfig = {
  borderRadius: config.borderRadius,
  cursor: 'pointer',
  fontSize: {
    large: '1rem',
    medium: '1rem'
  },
  minWidth: `${spacing?.(13)}`,
  padding: {
    large: `${spacing?.(1)} ${spacing?.(2)}`,
    medium: `${spacing?.(0.5)} ${spacing?.(2)}`
  },
  disabled: {
    backgroundColor: colors.grey.lighter,
    borderColor: colors.grey.light,
    boxShadow: 'none',
    color: colors.grey.light
  }
};

const primary = {
  ...defaultConfig,
  backgroundColor: colors.primary.default,
  border: '1px solid transparent',
  color: '#FFFFFF',
  active: {
    backgroundColor: colors.primary.dark,
    borderColor: 'transparent',
    boxShadow: 'none',
    color: '#FFFFFF'
  },
  hover: {
    backgroundColor: colors.primary.light,
    borderColor: 'transparent',
    boxShadow: 'none',
    color: '#FFFFFF'
  },
  focus: {
    backgroundColor: colors.primary.light,
    borderColor: 'transparent',
    boxShadow: `0 0 0 2px ${colors.primary.darkest}`,
    color: '#FFFFFF'
  }
};

const secondary = {
  ...defaultConfig,
  backgroundColor: colors.secondary.default,
  border: '1px solid transparent',
  color: '#FFFFFF',
  active: {
    backgroundColor: colors.secondary.dark,
    borderColor: 'transparent',
    boxShadow: 'none',
    color: '#FFFFFF'
  },
  hover: {
    backgroundColor: colors.secondary.light,
    borderColor: 'transparent',
    boxShadow: 'none',
    color: '#FFFFFF'
  },
  focus: {
    backgroundColor: colors.secondary.light,
    borderColor: 'transparent',
    boxShadow: `0 0 0 2px ${colors.secondary.darkest}`,
    color: '#FFFFFF'
  }
};

const outline = {
  ...defaultConfig,
  backgroundColor: colors.outline.default,
  border: `1px solid ${colors.outline.dark}`,
  color: colors.primary.darkest,
  active: {
    backgroundColor: colors.outline.light,
    borderColor: colors.outline.dark,
    boxShadow: 'none',
    color: colors.primary.darkest
  },
  hover: {
    backgroundColor: colors.outline.lighter,
    borderColor: colors.outline.dark,
    boxShadow: 'none',
    color: colors.primary.darkest
  },
  focus: {
    backgroundColor: colors.outline.lighter,
    borderColor: 'transparent',
    boxShadow: `0 0 0 2px ${colors.primary.darkest}`,
    color: colors.primary.darkest
  }
};

const danger = {
  ...defaultConfig,
  backgroundColor: colors.danger.default,
  border: '1px solid transparent',
  color: '#FFFFFF',
  active: {
    backgroundColor: colors.danger.dark,
    borderColor: 'transparent',
    boxShadow: 'none',
    color: '#FFFFFF'
  },
  hover: {
    backgroundColor: colors.danger.light,
    borderColor: 'transparent',
    boxShadow: 'none',
    color: '#FFFFFF'
  },
  focus: {
    backgroundColor: colors.danger.light,
    borderColor: 'transparent',
    boxShadow: `0 0 0 2px ${colors.primary.dark}`,
    color: '#FFFFFF'
  }
};

export default {
  primary,
  secondary,
  outline,
  danger
};
