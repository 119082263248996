import colors from './colors';
import spacing from './spacing';

const wrapper = {
  margin: `0 ${spacing?.(1)}`,
  textAlign: 'center'
};

const item = {
  backgroundColor: {
    disabled: colors.grey?.lighter,
    default: colors.grey?.lightest
  },
  border: '1px solid transparent',
  borderColor: {
    disabled: colors.grey?.light,
    checked: colors.primary?.default,
    default: colors.grey?.light
  },
  borderRadius: '50%',
  display: 'inline-block',
  height: `${spacing?.(2)}`,
  marginRight: `${spacing?.(1)}`,
  width: `${spacing?.(2)}`,
  hover: {
    backgroundColor: {
      disabled: colors.grey?.lighter,
      checked: colors.grey?.lighter,
      default: colors.primary?.lighter
    },
    borderColor: {
      disabled: colors.grey?.light,
      checked: colors.primary?.default,
      default: colors.primary?.default
    }
  }
};

const label = {
  color: {
    disabled: colors.grey?.light,
    default: colors.primary?.darkest
  },
  focus: {
    disabled: 'transparent',
    default: colors.primary?.default
  }
};

export default {
  wrapper,
  item,
  label
};
