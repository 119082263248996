import React from 'react';

export default ({ className, color = '#000000' }) => (
  <svg
    className={className}
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>2fa authentication</title>
    <path
      d="M17 19.6563C16.7066 19.6563 16.4688 19.8941 16.4688 20.1875C16.4688 20.4809 16.7066 20.7188 17 20.7188C17.2934 20.7188 17.5313 20.4809 17.5313 20.1875C17.5316 20.0465 17.4758 19.9111 17.3761 19.8114C17.2764 19.7117 17.141 19.6559 17 19.6563V19.6563"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.3125 13.8125V10.625C22.3047 7.69422 19.9308 5.32028 17 5.3125V5.3125C14.0695 5.32106 11.6961 7.69454 11.6875 10.625V13.8125"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.0625 5.3125V3.1875C1.0625 2.01389 2.01389 1.0625 3.1875 1.0625H5.3125"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32.9375 5.3125V3.1875C32.9375 2.01389 31.9861 1.0625 30.8125 1.0625H28.6875"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.0625 28.6875V30.8125C1.0625 31.9861 2.01389 32.9375 3.1875 32.9375H5.3125"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32.9375 28.6875V30.8125C32.9375 31.9861 31.9861 32.9375 30.8125 32.9375H28.6875"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="9.5625"
      y="13.8125"
      width="14.875"
      height="12.75"
      rx="1.5"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
