import React, { useState } from 'react';
import { ListStyled, ShowMoreLess } from './expandable-list.styles.js';
import { IExpandableListProps } from './types';

const ExpandableList = ({
  items,
  limit = 5,
  defaultIsExpanded = false
}: IExpandableListProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(defaultIsExpanded);

  return (
    <ListStyled>
      {items.slice(0, isExpanded ? items.length : limit).map((item, index) => (
        <li key={index}>{item}</li>
      ))}
      {items.length > limit && (
        <ShowMoreLess onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded ? 'Show less' : `Show more`}
        </ShowMoreLess>
      )}
    </ListStyled>
  );
};

export default ExpandableList;
