import navbar from './navbar';

export default {
  duet_profile_title: navbar.navbar_program_profile,
  duet_profile_participants: 'Total des participants au programme:',
  duet_profile_last_calculated:
    'Dernier calcul le: {{profileLastGenerated}}, avec {{participantsTotal}}/{{participantsInvited}} participants de votre programme',
  duet_profile_participants_not_included_alert:
    '{{additionalParticipants}} participants de votre programme supplémentaires ont complété l’évaluation Duet depuis le {{profileLastGenerated}}. Leurs réponses ne sont pas incluses dans les résultats ci-dessous. Nous vous recommandons de générer à nouveau votre Profil de programme pour inclure ces résultats.',
  duet_profile_participants_not_included_note:
    'Remarque: Générer un nouveau Profil de programme changera tous les scores Duet disponibles pour les candidats.',
  duet_profile_cross_category_title: 'Classement par catégorie',
  duet_profile_cross_category_message_binary:
    'Le classement par catégorie indique la fréquence à laquelle les participants au programme ont choisi chaque catégorie parmi le nombre total de comparaisons possibles pour toutes les catégories',
  duet_profile_cross_category_message_likert:
    'Le classement par catégorie indique le niveau de préférence des participants au programme pour chaque caractéristique, en tenant compte du nombre de fois où chaque caractéristique a été choisie et de la force de ces choix',
  duet_profile_characteristic_title: 'Classement des caractéristiques',
  duet_profile_characteristic_message_binary:
    'Le classement par catégorie indique la fréquence à laquelle les participants au programme ont choisi chaque caractéristique parmi le nombre total de comparaisons possibles dans toutes les catégories',
  duet_profile_characteristic_message_likert:
    'Le classement des caractéristiques démontre dans quelle mesure les participants au programme valorisent chaque caractéristique, et prend en compte à la fois la fréquence à laquelle chaque caractéristique a été choisie et le poids donné à ces choix',
  duet_profile_empty_state_initial_title: `Votre profil de programme n'est pas encore disponible.`,
  duet_profile_empty_state_invitedUsers_title: `Votre profil de programme n'est pas encore disponible.`,
  duet_profile_empty_state_partial_title:
    'Votre profil de programme peut être généré.',
  duet_profile_empty_state_initial_message: `Vous as besoin inviter les participants de votre programme à compléter Duet. Veuillez accéder sur la page Utilisateurs pour inviter de nouveaux utilisateurs ou modifier les permissions afin de permettre aux utilisateurs existants de compléter l'évaluation Duet. Une fois que les participants au programme auront complété Duet, vous pourrez générer votre profil de programme ici.`,
  duet_profile_empty_state_invitedUsers_message: `Pour le moment, aucun des participants à votre programme n'a complété Duet. Vous pouvez définir ou modifier une date limite pour les participants au programme sur la page « Gérer ». Nous vous recommandons fortement d’attendre qu’un minimum de 10 participants de votre programme aient complété Duet pour une représentation plus précise de votre programme. Une fois que les participants au programme auront complété Duet, vous pourrez générer votre profil de programme ici.`,
  duet_profile_empty_state_partial_message:
    'Vous pouvez générer votre profil de programme à tout moment. Pour encourager la participation, vous pouvez également changer, définir ou modifier une date limite pour les participants au programme invités sur la page « Gérer ». Nous vous recommandons fortement d’attendre qu’un minimum de 10 participants de votre programme aient complété Duet pour une représentation plus précise de votre programme.',
  duet_profile_empty_state_initial_button: 'Inviter des utilisateurs à Duet',
  duet_profile_empty_state_invitedUsers_button:
    'Gérer les participants au programme',
  duet_profile_empty_state_partial_button: 'Générer le profil du programme',
  duet_profile_number_of_assessments_completed:
    'Nombre d’évaluations terminées : ',
  duet_profile_coming_soon_title:
    'Cette fonctionnalité sera bientôt disponible !',
  duet_profile_coming_soon_message:
    'Nous travaillons sur la possibilité pour vous de configurer votre propre date de génération de profil de programme Duet et de visualiser le statut d’achèvement de Duet des participants de votre programme. Restez à l’écoute !',
  duet_profile_tab_setup: 'Gérer',
  duet_profile_tab_profile: 'Profil de programme',
  duet_profile_calculate_button: 'Générer le profil du programme',
  duet_profile_modal_title: 'Générer le profil du programme',
  duet_profile_modal_subtitle:
    'Êtes-vous sûr de vouloir générer votre profil de programme Duet ?',
  duet_profile_modal_message:
    'Ceci va générer un nouveau profil de programme basé sur les réponses agrégées actuelles de vos participants au programme.',
  duet_profile_modal_confirmation1:
    'Je comprends que si j’ai déjà un profil de programme Duet, il sera remplacé et les scores Duet des candidats seront recalculés en fonction du nouveau profil de programme.',
  duet_profile_modal_confirmation2:
    'Je comprends que l’utilisation des scores Duet calculés en fonction de plusieurs versions de mon profil de programme au cours de la même période d’admission n’est pas conseillée et peut biaiser l’évaluation des candidats. Je m’assurerai que les scores Duet des candidats existants enregistrés dans d’autres systèmes soient remplacés par les nouveaux scores Duet.',
  duet_profile_manage_title: 'Gérez votre profil de programme pour {{cycle}}',
  duet_profile_deadline_title: 'Date limite de participation à Duet',
  duet_profile_deadline_text:
    'Vous pouvez choisir une date limite de participation à Duet pour vos participants. Des courriels de rappel leur seront envoyés pour les encourager à compléter l’évaluation avant cette date. Vous pouvez changer cette date à n’importe quel moment.',
  duet_profile_deadline_note:
    '<strong>Veuillez noter :</strong> Les participants de votre programme ne pourront pas terminer l’évaluation Duet après cette date et ne contribueront pas au profil de programme, sauf si vous changez la date. Vos candidats, eux, pourront compléter leur évaluation Duet jusqu’à la date limite de distribution.',
  duet_profile_deadline_label:
    'Date limite du participant au programme <strong>(optionnel)</strong> (AAAA-MM-JJ)',
  duet_profile_cancel: 'Annuler',
  duet_profile_update: 'Mettre à jour',
  duet_profile_participants_title: 'Participants au programme Duet',
  duet_profile_participants_text:
    'Utilisez cette page pour faire le suivi du statut de progression des participants à votre programme Duet. Pour inviter des participants au programme à compléter Duet, qu’ils soient membres du personnel enseignant ou non, rendez-vous sur la page de gestion des utilisateurs. Nous recommandons <i>un minimum</i> de 10 participants au programme, dont la moitié sont des membres du personnel enseignant et l’autre moitié non, pour générer un profil de programme statistiquement significatif (il n’y a pas de limite au nombre de participants au programme).',
  duet_profile_go_to_user_management: 'Aller à Gestion des utilisateurs',
  duet_col_email: 'Courriel',
  duet_col_givenname: 'Prénom',
  duet_col_surname: 'Nom de famille',
  duet_col_roles: 'Rôle',
  duet_col_duetStatus: 'Statut de Duet',
  duet_status_completed: 'Complété',
  duet_status_not_completed: 'Non complété',
  duet_status_invited: 'Invité'
};
