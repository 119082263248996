import React from 'react';

export default ({ className, color = '#666666' }) => (
  <svg
    className={className}
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Settings Icon</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.78833 3.03167C10.0976 3.37496 10.538 3.57096 11 3.57096C11.462 3.57096 11.9024 3.37496 12.2117 3.03167L13 2.16667C13.463 1.65429 14.1983 1.48864 14.8362 1.75299C15.4742 2.01734 15.8768 2.65451 15.8417 3.34417L15.7825 4.51084C15.7594 4.97107 15.9319 5.41962 16.2575 5.7457C16.5831 6.07178 17.0314 6.24497 17.4917 6.22251L18.6583 6.16334C19.3475 6.12936 19.9837 6.53238 20.2473 7.17006C20.511 7.80774 20.3453 8.54233 19.8333 9.00501L18.965 9.78834C18.6222 10.098 18.4265 10.5384 18.4265 11.0004C18.4265 11.4624 18.6222 11.9028 18.965 12.2125L19.8333 12.9958C20.3457 13.4588 20.5114 14.1941 20.247 14.8321C19.9827 15.47 19.3455 15.8726 18.6558 15.8375L17.4892 15.7783C17.0279 15.7546 16.5782 15.9275 16.2516 16.2541C15.925 16.5807 15.7521 17.0304 15.7758 17.4917L15.835 18.6583C15.8656 19.3444 15.4635 19.9762 14.8292 20.2391C14.1948 20.502 13.4637 20.3398 13 19.8333L12.2158 18.9658C11.9063 18.623 11.466 18.4274 11.0042 18.4274C10.5423 18.4274 10.102 18.623 9.7925 18.9658L9.005 19.8333C8.542 20.3423 7.80962 20.5063 7.17379 20.2434C6.53795 19.9806 6.13516 19.3473 6.16667 18.66L6.22667 17.4933C6.25039 17.0321 6.07752 16.5824 5.75092 16.2558C5.42432 15.9292 4.9746 15.7563 4.51333 15.78L3.34667 15.8392C2.65728 15.8752 2.01984 15.4734 1.7549 14.8359C1.48996 14.1985 1.65486 13.4633 2.16667 13L3.03417 12.2167C3.37702 11.907 3.57268 11.4666 3.57268 11.0046C3.57268 10.5426 3.37702 10.1022 3.03417 9.79251L2.16667 9.00501C1.65657 8.5423 1.49192 7.80914 1.75519 7.17275C2.01845 6.53636 2.65293 6.13379 3.34083 6.16667L4.5075 6.22584C4.96966 6.25014 5.4204 6.07703 5.74749 5.74963C6.07458 5.42222 6.24725 4.97131 6.2225 4.50917L6.16667 3.34167C6.13441 2.65406 6.53693 2.0201 7.17295 1.75681C7.80897 1.49351 8.5418 1.65746 9.005 2.16667L9.78833 3.03167Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 14.7509C13.0711 14.7509 14.75 13.0719 14.75 11.0009C14.75 8.92979 13.0711 7.25085 11 7.25085C8.92893 7.25085 7.25 8.92979 7.25 11.0009C7.25 13.0719 8.92893 14.7509 11 14.7509Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
