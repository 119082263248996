import colors from './colors';
import spacing from './spacing';

export default {
  boxShadow: {
    active: `inset 0px -${spacing?.(0.5)} 0px ${colors.primary?.default}`,
    default: 'none'
  },
  color: {
    active: colors.primary?.default,
    default: colors.grey?.darker
  },
  height: '100%',
  padding: `${spacing?.(3)} 0`,
  hover: {
    color: colors.grey?.default
  },
  active: {
    textDecoration: 'none'
  }
};
