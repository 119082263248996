import React from 'react';
import PropTypes from 'prop-types';

import {
  CardStyled,
  WrapperStyled,
  IconWrapperStyled
} from './home-card.styles';

const HomeCard = ({ className, icon, title, noPadding, children }) => {
  return (
    <CardStyled className={className} noPadding={noPadding}>
      <IconWrapperStyled noPadding={noPadding}>{icon}</IconWrapperStyled>
      <WrapperStyled>
        <h2>{title}</h2>
        {children}
      </WrapperStyled>
    </CardStyled>
  );
};

HomeCard.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.element,
  title: PropTypes.string,
  noPadding: PropTypes.bool,
  children: PropTypes.element
};

HomeCard.defaultProps = {
  className: '',
  icon: null,
  title: '',
  noPadding: false,
  children: null
};

export default HomeCard;
