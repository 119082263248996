import styled from 'styled-components/macro';

import { devices } from 'shared/css/devices';
import { fullCenter } from 'shared/css/common-classes';

import Link from '../link/link';
import ArrowDropdown from 'assets/icons/arrow-dropdown';
import ExternalLinkIcon from 'assets/icons/external-link';
import colors from 'shared/css/theme/colors';

export const DropdownStyled = styled.div`
  ${({ theme: { dropdownsNavbar = {}, typography } }) => `
    ${fullCenter};
    ${typography?.(16)}
    background-color: ${dropdownsNavbar.group?.backgroundColor};
    color: ${dropdownsNavbar.group?.color};
    cursor: ${dropdownsNavbar.group?.cursor};
    outline: ${dropdownsNavbar.group?.outline};
  `};
`;

export const TitleStyled = styled.div`
  ${({ theme: { dropdownsNavbar = {}, typography } }) => `
    ${typography?.(16, 700)}
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: ${dropdownsNavbar.title?.padding};
    width: ${dropdownsNavbar.title?.width};
    background: transparent;
  `};
`;

export const ArrowStyled = styled(ArrowDropdown)`
  ${({ theme: { dropdownsNavbar = {}, transitions }, open }) => `
    margin: ${dropdownsNavbar.arrow?.margin};
    transform: ${open ? 'rotate(180deg)' : 'rotate(0)'};
    transition: all ${transitions?.fast} ease-in-out;
  `};
`;

export const ExternalLinkStyled = styled(ExternalLinkIcon)`
  ${({ showIcon }) => `
    opacity: ${showIcon ? '1' : '0'}
  `};
`;

export const DropdownOptionsStyled = styled.div`
  ${({
    theme: { dropdownsNavbar = {}, typography, transitions },
    open,
    isInnerDropdown
  }) => `
    ${typography?.(14)}
    background-color: ${dropdownsNavbar.options?.backgroundColor};
    border-radius: ${
      isInnerDropdown ? '0' : dropdownsNavbar.options?.borderRadius
    };
    box-shadow: ${
      isInnerDropdown ? 'none' : dropdownsNavbar.options?.boxShadow
    };
    height: ${isInnerDropdown && !open ? '0' : 'auto'};
    margin: ${isInnerDropdown ? '0' : dropdownsNavbar.options?.margin};
    min-width: 50vw;
    opacity: ${open ? '1' : '0'};
    position: ${
      isInnerDropdown ? 'initial' : dropdownsNavbar.options?.position
    };
    right: 0.25rem;
    top: ${dropdownsNavbar.options?.top};
    transition: all ${transitions?.fast} ease-in-out;
    visibility: ${open ? 'visible' : 'hidden'};
    width: ${dropdownsNavbar.options?.width};
    z-index: ${open ? '10' : '0'};
    @media ${devices.tablet} {
      min-width: ${dropdownsNavbar.options?.minWidth};
    }
  `};
`;

export const WrapperStyled = styled.div`
  position: relative;
  @media ${devices.tablet} {
    display: block;
  }
`;

export const DropdownItemLinkStyled = styled(Link)`
  ${({
    theme: { dropdownsNavbar = {}, typography, transitions, colors },
    active,
    disabled,
    showLink,
    topDivider
  }) => `
    ${typography?.(14, active ? '700' : '400')}
    align-items: center;
    border: ${dropdownsNavbar.itemLink?.border};
    cursor: ${active ? 'default' : 'pointer'};
    pointer-events: ${disabled ? 'none' : 'auto'};
    display: flex;
    height: ${dropdownsNavbar.itemLink?.height};
    outline: ${dropdownsNavbar.itemLink?.outline};
    padding: ${dropdownsNavbar.itemLink?.padding};
    transition: all ${transitions?.fast} ease-in-out;
    @media ${devices.tablet} {
      display: ${showLink ? 'flex' : 'none'};
    }
    &:hover {
      background-color: ${dropdownsNavbar.itemLink?.hover?.backgroundColor}};
      border-radius: ${dropdownsNavbar.options?.borderRadius};
      border-color: ${dropdownsNavbar.itemLink?.hover?.borderColor};
      color: ${
        dropdownsNavbar.itemLink?.color?.[active ? 'active' : 'default']
      };
      text-decoration: ${dropdownsNavbar.itemLink?.hover?.textDecoration}};
    }
    &:active {
      background-color: ${
        dropdownsNavbar.itemLink?.active?.backgroundColor?.[
          disabled ? 'disabled' : 'default'
        ]
      }};
      border-color: ${dropdownsNavbar.itemLink?.active?.borderColor};
    }
    &:focus {
      color: ${
        dropdownsNavbar.itemLink?.color?.[
          disabled ? 'disabled' : active ? 'active' : 'default'
        ]
      };
      background-color: ${
        dropdownsNavbar.itemLink?.focus?.backgroundColor?.[
          disabled ? 'disabled' : 'default'
        ]
      }};
      border-radius: ${dropdownsNavbar.options?.borderRadius};
    }
    border-top-color: ${topDivider ? colors?.grey?.lighter : 'transparent'};
    color: ${
      dropdownsNavbar.itemLink?.color?.[
        disabled ? 'disabled' : active ? 'active' : 'default'
      ]
    };
  `};
`;

export const IconWrapperStyled = styled.span`
  margin-right: 8px;
`;

export const DropdownItemStyled = styled.div`
  ${({
    theme: { dropdownsNavbar = {}, transitions },
    disabled,
    topDivider
  }) => `
    border: ${dropdownsNavbar.item?.border};
    color: ${dropdownsNavbar.item?.color?.[disabled ? 'disabled' : 'default']};
    cursor: ${disabled ? 'default' : 'pointer'};
    display: block;
    height: ${dropdownsNavbar.item?.height};
    outline: ${dropdownsNavbar.item?.outline};
    padding: ${dropdownsNavbar.item?.padding};
    transition: all ${transitions?.fast} ease-in-out;
    &:hover {
      background-color: ${
        dropdownsNavbar.item?.hover?.backgroundColor?.[
          disabled ? 'disabled' : 'default'
        ]
      }};
      border-color: ${dropdownsNavbar.item?.hover?.borderColor};
      border-radius: ${dropdownsNavbar.options?.borderRadius};
    }
    &:active {
      background-color: ${
        dropdownsNavbar.item?.active?.backgroundColor?.[
          disabled ? 'disabled' : 'default'
        ]
      }};
      border-radius: ${dropdownsNavbar.options?.borderRadius};
    }
    &:focus {
      background-color: ${
        dropdownsNavbar.item?.focus?.backgroundColor?.[
          disabled ? 'disabled' : 'default'
        ]
      }};
    }
    border-top-color: ${topDivider ? colors?.grey?.lighter : 'transparent'};
  `};
`;
