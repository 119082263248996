import React, { useState, useRef } from 'react';
import Select, { components } from 'react-select';
import PropTypes from 'prop-types';
import theme from 'shared/css/theme';
import useOutsideAlerter from 'shared/hooks/useOutsideAlerter';

import { SelectButton } from './select-program-button';
import { WrapperStyled } from './select-program.styles';
import { Dropdown } from './select-program-dropdown';

const selectProgramStyles = {
  // container = entire dropdown container
  container: provided => ({
    ...provided,
    minWidth: '250px',
    maxWidth: '350px',
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${theme?.colors?.primary?.lightest}`,
    boxShadow: '0px 4px 7px rgba(0, 0, 0, 0.06)',
    borderRadius: '2px'
  }),
  // control = search bar area - set to display: none to hide it
  control: (provided, state) => ({
    ...provided,
    margin: '0px 3px',
    borderColor: 'transparent',
    borderWidth: 0,
    boxShadow: 'none',
    borderBottom: `1px solid ${theme?.colors?.primary?.lightest}`,
    borderRadius: '0px',
    cursor: state.selectProps.hideArrow ? 'default' : 'pointer',
    '&:hover': {
      // dont use provided
    }
  }),
  indicatorsContainer: () => ({
    display: 'none'
  }),
  valueContainer: provided => ({
    ...provided,
    border: `1px solid ${theme?.colors?.grey?.lighter}`,
    borderRadius: '3px',
    margin: '10px'
  }),
  dropdownIndicator: () => ({
    display: 'none'
  }),
  // menu = entire area under search element (menulist + scrollbar)
  menu: () => ({
    margin: '10px'
    // do not used default provided
  }),
  // menulist = area which is populated by options prop
  menuList: provided => ({
    ...provided,
    padding: '0px',
    '::-webkit-scrollbar': {
      width: '5px'
    },
    '::-webkit-scrollbar-track': {
      background: '#E8E8E8',
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px',
      borderBottomRightRadius: '4px'
    },
    '::-webkit-scrollbar-thumb': {
      background: '#888888',
      borderRadius: '5px'
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#555555'
    },
    overflowX: 'hidden',
    overflowY: 'scroll'
  }),
  // option = each element which populaates menulist
  option: (provided, state) => {
    const { isSelected = {}, isFocused = false } = state || {};
    return {
      ...provided,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '10px',
      backgroundColor: isSelected
        ? theme?.colors?.primary?.lightest
        : isFocused
          ? theme?.colors?.grey?.lighter
          : 'transparent',
      color: theme?.colors.grey.darkest,
      '&:active': {
        backgroundColor: 'transparent'
      },
      '&:hover': {
        backgroundColor: isSelected
          ? theme?.colors?.primary?.lightest
          : theme?.colors?.grey?.lighter,
        cursor: 'pointer'
      }
    };
  }
};

// Good: Custom component declared outside of the Select scope
const MenuList = ({ children, ...props }) => {
  return (
    <components.MenuList
      {...props}
      innerProps={{
        ...props.innerProps,
        onScroll: props.selectProps.handleScroll
      }}
    >
      {children}
    </components.MenuList>
  );
};

const SelectProgram = ({
  autoFocus,
  isDisabled,
  menuIsOpen,
  tabSelectsValue,
  controlShouldRenderValue,
  maxMenuHeight,
  placeholder,
  title,
  name,
  options,
  className,
  classNamePrefix,
  handleChange,
  handleInputChange,
  selectedOption,
  selectedObject,
  handleScroll,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const wrapperRef = useRef(null);

  const handleClick = () => {
    setOpen(current => !current);
  };

  useOutsideAlerter({
    ref: wrapperRef,
    handleClose: setOpen,
    currentValue: open
  });

  const onChangeWrapper = option => {
    handleChange(option);
    handleClick();
  };

  return (
    <WrapperStyled ref={wrapperRef} className={className} data-testid={name}>
      <Dropdown
        isOpen={open}
        onClose={handleClick}
        target={
          <SelectButton
            isOpen={open}
            handleClick={handleClick}
            title={title}
            text={selectedOption}
            data-testid={`${props['data-testid']}-button`}
          />
        }
        data-testid={`${props['data-testid']}-dropdown`}
      >
        <Select
          placeholder={placeholder}
          autoFocus={autoFocus}
          isDisabled={isDisabled}
          controlShouldRenderValue={controlShouldRenderValue}
          name={name}
          maxMenuHeight={maxMenuHeight}
          menuIsOpen={menuIsOpen}
          options={options}
          styles={selectProgramStyles}
          className={className}
          classNamePrefix={classNamePrefix}
          tabSelectsValue={tabSelectsValue}
          onChange={onChangeWrapper}
          onInputChange={handleInputChange}
          value={selectedObject}
          aria-label={name}
          onKeyDown={e => {
            // 27 = esc, 9 = tab
            if (e.which === 27 || e.which === 9) {
              setOpen(false);
            }
          }}
          handleScroll={handleScroll}
          components={{ MenuList }}
        />
      </Dropdown>
    </WrapperStyled>
  );
};

SelectProgram.propTypes = {
  autofocus: PropTypes.bool,
  isDisabled: PropTypes.bool,
  menuIsOpen: PropTypes.bool,
  tabSelectsValue: PropTypes.bool,
  controlShouldRenderValue: PropTypes.bool,
  maxMenuHeight: PropTypes.number,
  placeholder: PropTypes.string,
  title: PropTypes.string.isRequired,
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    })
  ),
  className: PropTypes.string,
  classNamePrefix: PropTypes.string,
  handleChange: PropTypes.func,
  handleInputChange: PropTypes.func,
  selectedOption: PropTypes.string,
  selectedObject: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string
  }),
  handleScroll: PropTypes.func
};

SelectProgram.defaultProps = {
  autoFocus: true,
  menuIsOpen: true,
  tabSelectsValue: false,
  controlShouldRenderValue: false,
  maxMenuHeight: 160,
  placeholder: 'Search',
  className: 'basic-multi-select',
  classNamePrefix: 'select',
  handleScroll: () => {}
};

export default SelectProgram;
