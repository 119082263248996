import Close from 'assets/icons/close';
import styled from 'styled-components/macro';
import { fullCenter } from 'shared/css/common-classes';

export const ProgramItemStyled = styled.span`
  ${({ theme: { colors = {}, spacing } }) => `
    background-color: ${colors.grey?.lighter};
    border: 1px solid ${colors.grey?.light};
    border-radius: 2px;
    color: ${colors.grey?.darker};
    display: flex;
    align-items: center;
    margin-top: ${spacing?.(1)};
    padding: ${spacing?.(1)};
  `};
`;

export const CloseStyled = styled(Close)`
  border: 2px solid transparent;
  display: flex;
  margin-left: 8px;
  cursor: pointer;
  &:focus {
    ${fullCenter};
    border: 2px solid ${props => props.theme?.colors?.primary?.default};
    outline: none;
  }
`;
