import React from 'react';
import { SelectButtonStyled } from './select-program-button.styles';
import { ArrowDropDownStyled } from './select-program-button.styles';
import { SelectTextStyled } from './select-program-button.styles';

export const SelectButton = ({
  isOpen,
  className,
  title,
  text,
  handleClick,
  ...props
}) => (
  <SelectButtonStyled
    role="button"
    isOpen={isOpen}
    className={className}
    onClick={handleClick}
    onKeyPress={e => {
      e.preventDefault();
      if (e.which === 32 || e.which === 13) {
        handleClick();
      }
    }}
    tabIndex="0"
    data-testid="program-select-button"
    aria-label={title}
  >
    <div className="title" data-testid={`${props['data-testid']}-label`}>
      {title}
    </div>
    <SelectProgramText
      isOpen={isOpen}
      text={text}
      data-testid={props['data-testid']}
    ></SelectProgramText>
  </SelectButtonStyled>
);

export const SelectProgramText = ({ text, isOpen, ...props }) => (
  <SelectTextStyled isOpen={isOpen}>
    <div className="text" data-testid={`${props['data-testid']}-value`}>
      {text}
    </div>
    <ArrowDropDownStyled isOpen={isOpen} />
  </SelectTextStyled>
);
