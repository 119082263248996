import React, { ComponentType, FC } from 'react';
import { useRecoilValue } from 'recoil';
import { userProfileAtom } from 'shared/store/user';
import { iWithRecoil } from './types';

const withRecoil =
  (Component: ComponentType<any>): FC =>
  ({ children }: iWithRecoil) => {
    // Add any Recoil state that needs to be passed to the component here
    const userProfile: any = useRecoilValue(userProfileAtom);

    return <Component userProfile={userProfile}>{children}</Component>;
  };

export default withRecoil;
