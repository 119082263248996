import styles from 'styled-components';
import { fullCenter } from 'shared/css/common-classes';

export const IconWrapperStyled = styles.div`
  width: 38px;
  height: 38px;
  border-radius: 3px;
  ${fullCenter};
  cursor: pointer;
  &:hover, &:focus {
    background-color: #E7E7E7
  }
`;
