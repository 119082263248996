import navbar from './navbar';

export default {
  files_title: navbar.navbar_download_assessment_results,
  files_subtitle: 'Download scores in .csv format',
  files_col_name: 'Name',
  files_col_size: 'Size',
  files_col_lastModified: 'Last Modified',
  files_empty_state:
    'There are currently no files available for the selected admission cycle'
};
