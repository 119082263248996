import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControlLabel } from '@mui/material';
import { LabelStyled, SwitchStyled } from './toggle-switch.styles.js';
import { ToggleSwitchProps } from './types';

const ToggleSwitch: FC<ToggleSwitchProps> = ({
  id,
  checked,
  visible = true,
  handleChange,
  className = '',
  checkedLabel,
  uncheckedLabel,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <FormControlLabel
      value="start"
      control={
        <SwitchStyled
          checked={checked}
          data-pendoid={props['data-pendoid']}
          data-testid={props['data-testid']}
          onChange={handleChange}
          size="small"
        />
      }
      label={
        <LabelStyled>
          {checked
            ? checkedLabel || t('common_on')
            : uncheckedLabel || t('common_off')}
        </LabelStyled>
      }
      labelPlacement="start"
    />
  );
};

export default ToggleSwitch;
