import styled from 'styled-components/macro';

export const PillStyled = styled.div`
  ${({ theme: { pills = {}, typography }, active }) => `
    ${typography?.(16, active ? '700' : '400')};
    background-color: ${pills.backgroundColor?.[active ? 'active' : 'default']};
    border: ${pills.border};
    border-radius: ${pills.borderRadius};
    color: ${pills.color?.[active ? 'active' : 'default']};
    cursor: ${active ? 'default' : 'pointer'};
    margin-right: ${pills.marginRight};
    min-height: ${pills.minHeight};
    padding: ${pills.padding};
    &:focus {
      border-color: ${pills.focus?.borderColor};
    }
    ${
      !active
        ? `
        &:hover {
          color: ${pills.hover?.color};
        }
        
        &:active {
          color: ${pills.active?.color};
        }
      `
        : ''
    }
  `};
  align-items: center;
  display: flex;
  justify-content: space-between;
`;
