import styled from 'styled-components/macro';

import { Input, Select } from 'components';
import NextIcon from 'assets/icons/next-right';

export const BackIconStyled = styled(NextIcon)`
  padding-left: 4px;
  padding-bottom: 1px;
  transform: rotate(180deg);
`;

export const InputGroupStyled = styled.div`
  display: flex;
`;

export const CountryCodeInpuStyled = styled(Select)`
  width: 25%;
`;

export const PhoneInputStyled = styled(Input)`
  flex: 1;
  margin-left: 16px;
`;

export const ExtensionInputStyled = styled(Input)`
  width: 75%;
`;

export const RadioButtonsGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 1rem;
  margin-left: -8px;
`;

export const MethodErrorStyled = styled.div`
  ${({ theme }) => `
    color: ${theme.colors?.danger?.default};
    ${theme.typography?.(14)}
  `};
  margin-bottom: 48px;
  text-align: left;
`;

export const FormStyled = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const MessageStyled = styled.div`
  ${({ theme, customMargin }) => `
    color: ${theme.colors?.grey?.darker};
    font-size: 1rem;
    margin-bottom: ${customMargin || '24px'};
  `};
`;

export const NextIconStyled = styled(NextIcon)`
  padding-left: 4px;
  padding-top: 1px;
`;

export const ButtonsGroupStyled = styled.div`
  ${props => `
    display: flex;
    justify-content: ${props.buttonsAlignment ? 'space-between' : 'flex-end'};
  `}
`;
