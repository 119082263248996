import React, { useState, useEffect, useMemo, useContext, FC } from 'react';
import { useQuery } from '@apollo/client';
import { useRecoilValue } from 'recoil';
import { useHistory } from 'react-router-dom';
import {
  importQlikResources,
  openQlikWebsocket,
  handleQlikAppError
} from './helpers';
import { QLIK_TOKEN } from 'graphql/queries/qlik';
import { programFilterAtom } from 'shared/store/program-filter';
import { IProgramFilterAtom } from '@types';
import hasPermissionOrAdmin from 'shared/helpers/hasPermissionOrAdmin';
import { PERMISSIONS } from 'shared/enums';
import { IQlikAppProviderProps, IQlikAppContext } from './types';

const qlikAppDefaultState = {
  qlikApp: null,
  hasQlikAccess: false,
  closeQlikApp: () => {}
};

const QlikAppContext =
  React.createContext<IQlikAppContext>(qlikAppDefaultState);

export const QlikAppProvider: FC<IQlikAppProviderProps> = ({
  schoolId,
  user,
  children
}) => {
  const { REACT_APP_QLIK_URL, REACT_APP_QLIK_PROXY_PREFIX: PROXY_PREFIX } =
    process.env;
  const [qlikApp, setQlikApp] = useState(qlikAppDefaultState.qlikApp);
  const [qlikBank, setQlikBank] = useState({});

  // Use the userDirectory to verify a program has Analytics enabled
  const programFilter = useRecoilValue<IProgramFilterAtom>(programFilterAtom);
  const userDirectory: string = programFilter?.userDirectory?.toLowerCase();
  const userPermissions = useMemo(
    () =>
      user.schoolPermissions.find(
        schoolPermission => schoolPermission.schoolId === programFilter.schoolId
      )?.keys || [],
    [programFilter.schoolId]
  );
  const history = useHistory();

  const hasQlikAccess =
    hasPermissionOrAdmin(
      [PERMISSIONS.BASIC_ACCESS],
      user?.roles,
      userPermissions
    ) && !!userDirectory;

  const closeQlikApp = () => {
    // Ensure all required conditions are met before closing the Qlik App.
    // This is done to avoid race conditions and delays in the WebSocket.
    //  1. The Qlik App must exist.
    //  2. The location state must not be a dashboard page.
    if (qlikApp && !history?.location?.state?.dashboard) {
      qlikApp.close();
      setQlikApp(null);
    }
  };

  // Close existing WS if we change schools, so there is only one active connection at a time.
  // This condition should only occur if someone changes the school using the dropdown selector.
  useEffect(() => {
    closeQlikApp();
  }, [schoolId]);

  // Get data for Qlik Token, only run the query if userDirectory is defined
  useQuery(QLIK_TOKEN, {
    variables: {
      schoolId
    },
    skip: !userDirectory || !hasQlikAccess, // Skip on True
    // Policy set to network-only for the case where a Qlik session expires while the socket is closed.
    // Upon returning to this component we want to fetch using a valid token, rather than use an expired cached token.
    fetchPolicy: 'network-only',
    onCompleted: data => {
      fetch(
        `${REACT_APP_QLIK_URL}/${PROXY_PREFIX}/sense/app/${data.getAnalyticsToken.appName}`,
        {
          headers: {
            Authorization: `Bearer ${data.getAnalyticsToken.token}`
          },
          credentials: 'include',
          mode: 'cors'
        }
      ).then(async response => {
        if (response.status === 200) {
          const APP_NAME = data.getAnalyticsToken.appName;
          const APP_ID = data.getAnalyticsToken.appId;

          // Window.require is a required dependancy that is dynamically injected
          // if it is already loaded into the application we can skip this step
          // and move onto initializing the qlik connection.
          if (!window.require) {
            // Initializes qlik dependencies first
            await importQlikResources({
              prefix: PROXY_PREFIX
            });
          }
          // Open websocket to qlik
          let connection = await openQlikWebsocket({
            host: REACT_APP_QLIK_URL.replace(/^https?:\/\//, ''),
            prefix: PROXY_PREFIX,
            qlikBank,
            appName: APP_NAME
          }).catch(error => {
            handleQlikAppError(error, APP_ID, user?._id);
          });

          // open qlik app
          const app = connection.qlik.openApp(APP_ID, connection.configuration);
          connection.qlik.theme.apply(data.getAnalyticsToken.theme);
          const qlikBankClone = { ...qlikBank };
          qlikBankClone[APP_NAME] = {
            qlik: app,
            appId: APP_ID
          };
          setQlikBank(qlikBank);
          setQlikApp(app);
        }
      });
    },
    onError: error => {
      console.error(error);
      setQlikApp(null);
    }
  });

  return (
    <QlikAppContext.Provider value={{ qlikApp, hasQlikAccess, closeQlikApp }}>
      {children}
    </QlikAppContext.Provider>
  );
};

export const useQlikApp = () => {
  const { qlikApp, hasQlikAccess, closeQlikApp } =
    useContext<IQlikAppContext>(QlikAppContext) || {};
  return { qlikApp, hasQlikAccess, closeQlikApp };
};
