import styled from 'styled-components/macro';
import ArrowDownIcon from 'assets/icons/arrow-dropdown';

export const WrapperStyled = styled.div`
  ${({ theme: { accordion = {}, cards = {}, colors = {} }, open }) => `
    background-color: ${accordion.wrapper?.backgroundColor};
    border-radius: ${cards?.borderRadius};
    box-shadow: ${cards?.boxShadow};
    border: ${accordion.header?.border};
    margin: ${accordion.wrapper?.margin};
    padding: 0;
    &:focus {
      border-color: ${accordion.header?.focus?.borderColor};
    }
    &:focus:not(:focus-visible) {
      border-color: transparent;
    }
    &:focus-visible {
      border-color: ${accordion.header?.focus?.borderColor};
      border-radius: ${accordion.header?.focus?.borderRadius};
    }

    // Special case for the menu accordion
    &.menu-accordion {
      ${open && 'background-color:' + colors.grey?.lighter}
    }
  `}
`;

export const HeaderStyled = styled.div`
  ${({ theme: { accordion = {} } }) => `
    align-items: center;
    cursor: pointer;
    display: flex;
    height: ${accordion.header?.height};
    justify-content: space-between;
    min-height: ${accordion.header?.minHeight};
    padding: ${accordion.header?.padding};
  `};
`;

export const HeaderWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ContentStyled = styled.div`
  ${({ theme: { accordion = {}, transitions }, open }) => `
    height: ${open ? '100%' : '0px'};
    padding: ${
      open
        ? `${accordion.content?.padding?.top} ${accordion.content?.padding?.right} ${accordion.content?.padding?.bottom} ${accordion.content?.padding?.left}`
        : `0 ${accordion.content?.padding?.right}`
    };
    transition: all ${transitions?.fast_50} ease-in-out;
  `};
`;

export const TitleStyled = styled.h2`
  ${({ theme: { colors = {}, spacing, typography } }) => `
    ${typography?.(20, 700)}
    color: ${colors?.primary?.darkest};
    margin-bottom: ${spacing?.(1)};
  `};
`;

export const SubtitleStyled = styled.span`
  ${({ theme: { colors = {}, typography } }) => `
    ${typography?.(16)}
    color: ${colors?.grey?.dark};
  `};
`;

export const ArrowStyled = styled(ArrowDownIcon)`
  ${({ theme: { transitions }, open }) => `
    transform: ${open ? 'rotate(180deg)' : 'rotate(0)'};
    transition: all ${transitions?.fast} ease-in-out;
  `};
`;
