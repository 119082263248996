import React, { FC } from "react";

type SubtractCircleProps = {
    className?: string;
    color?: string;
};

const SubtractCircle: FC<SubtractCircleProps> = ({ className, color = '#000000' }) => {
    return (
        <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 22.3125C17.6954 22.3125 22.3125 17.6955 22.3125 12.0001C22.3125 6.30462 17.6954 1.6875 12 1.6875C6.30457 1.6875 1.6875 6.30462 1.6875 12.0001C1.6875 17.6955 6.30457 22.3125 12 22.3125Z" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.729 11.9541H17.0874" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
};

export default SubtractCircle;