import React from 'react';
import PropTypes from 'prop-types';

import { CardStyled } from './card.styles';

const Card = ({ className, cardId, tabIndex, children, dataTestId }) => {
  const tabIndexProp = tabIndex === '-1' ? {} : { tabIndex };
  return (
    <CardStyled
      className={className}
      data-testid={dataTestId}
      id={cardId}
      {...tabIndexProp}
    >
      {children}
    </CardStyled>
  );
};

Card.propTypes = {
  className: PropTypes.string,
  tabIndex: PropTypes.string,
  dataTestId: PropTypes.string
};

Card.defaultProps = {
  className: '',
  tabIndex: '-1',
  dataTestId: 'card'
};

export default Card;
