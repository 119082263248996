import React from 'react';

import { SkipStyled } from './skip-main-content.styles';

export default () => {
  return (
    <SkipStyled
      onKeyPress={e => {
        e.preventDefault();
        if (e.which === 32 || e.which === 13) {
          const main = document.querySelector('main');
          if (main) {
            main.focus();
          }
        }
      }}
      tabIndex="0"
      data-testid="skip-main-content"
    >
      Skip to content
    </SkipStyled>
  );
};
