import styled from 'styled-components';
import { Switch } from '@mui/material';
import { styled as MuiStyled } from '@mui/material/styles';
import theme from 'shared/css/theme';

export const SwitchStyled = MuiStyled(Switch)`
  .Mui-checked {
    color: ${theme.colors.primary.default} !important;
  }
  .MuiSwitch-track {
    background-color: ${theme.colors.primary.light} !important;
  }
`;

export const LabelStyled = styled.label`
  font-family: 'Lato', 'Roboto', sans-serif;
  ${theme.typography(14)}
`;
