import styled from 'styled-components/macro';

import { fullCenter } from 'shared/css/common-classes';

export const TitleStyled = styled.h1`
  ${({ theme: { colors = {}, spacing, typography } }) => `
    ${typography?.(30, 700)}
    color: ${colors.primary?.darkest};
    margin-top: -${spacing?.(8)};
  `};
`;

export const ContainerStyled = styled.main`
  ${fullCenter};
  flex-direction: column;
  min-height: calc(100vh - 80px);
`;

export const MessageStyled = styled.h2`
  ${({ theme: { colors = {}, spacing, typography } }) => `
    ${typography?.(24)}
    color: ${colors.grey?.darker};
    margin: ${spacing?.(4)} 0 ${spacing?.(6)} 0;
    max-width: ${spacing?.(78)};
    text-align: center;
  `};
`;
