import React, { FC, useState, useEffect, useLayoutEffect, useRef } from 'react';
import { select, pie, arc } from 'd3';
import { IPieChartProps } from './types';

import { PieChartContainer } from './pie-chart.styles';

/**
 * @description
 *  Base PieChart component
 *
 * @param props[data] // Data to be displayed
 * @param props[height] // Height of the drawing board to draw
 * @param props[innerRadius] // Inner radius of the pie chart
 * @param props[outerRadius] // Outer radius of the pie chart, (should not exceed the hieight)
 *
 * @return React.FC<IPieChartProps>
 */
const PieChart: FC<IPieChartProps> = ({
  data,
  height,
  innerRadius,
  outerRadius
}) => {
  const [width, setWidth] = useState<number>();

  const containerRef = useRef<HTMLElement>();
  const graphicRef = useRef<SVGSVGElement>();

  const pieGenerator = pie().value(d => d.value);
  const arcGenerator = arc().innerRadius(innerRadius).outerRadius(outerRadius);

  const pieData = pieGenerator(data);

  // Needs to redraw piechart
  // on window resize to keep responsiveness
  const handleWindowResize = () => {
    setWidth(containerRef?.current?.offsetWidth);
  };

  // Initial load to set
  // width and height of piechart container
  useLayoutEffect(() => {
    setWidth(containerRef?.current?.offsetWidth);
  }, []);

  // Trigger to resize
  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  // Redraw effect
  useEffect(() => {
    const draw = () => {
      select(graphicRef?.current).selectAll('g').remove();

      select(graphicRef?.current)
        .append('g')
        .attr('transform', `translate(${width / 2}, ${height / 2})`);

      select(graphicRef?.current)
        .selectAll('g')
        .selectAll('path')
        .data(pieData)
        .join('path')
        .attr('d', arcGenerator)
        .attr('stroke', 'white')
        .attr('fill', (arc, index) => {
          if (arc?.data?.image) {
            return `url(#img_${index})`;
          } else {
            return arc?.data?.color;
          }
        });
    };
    draw();
  }, [width]);

  return (
    <PieChartContainer ref={containerRef}>
      <svg
        width={width}
        height={height}
        ref={graphicRef}
        aria-label="pie-chart"
      >
        <defs>
          {data
            .map((slice, index) =>
              slice?.image ? (
                <pattern
                  key={`key_${index}`}
                  id={`img_${index}`}
                  width="100"
                  height="100"
                  patternUnits="userSpaceOnUse"
                >
                  <image
                    href={slice.image}
                    x="0"
                    y="0"
                    width="100"
                    height="100"
                  />
                </pattern>
              ) : null
            )
            .filter(Boolean)}
        </defs>
      </svg>
    </PieChartContainer>
  );
};

export default PieChart;
