import colors from './colors';
import config from './config';
import spacing from './spacing';

export default {
  backgroundColor: {
    active: colors.primary?.lightest,
    default: 'transparent'
  },
  border: '2px solid transparent',
  borderRadius: config.borderRadius,
  color: {
    active: colors.primary?.darkest,
    default: colors.grey?.darker
  },
  marginRight: `${spacing?.(2)}`,
  minHeight: `${spacing?.(6)}`,
  padding: `0 ${spacing?.(2)}`,
  hover: {
    color: colors.grey?.default
  },
  active: {
    color: colors.grey?.darkest
  },
  focus: {
    borderColor: colors.primary?.default
  }
};
