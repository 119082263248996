import React from 'react';

export default ({ className, color = '#000000' }) => (
  <svg
    className={className}
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <title>User information</title>
    <path
      d="M1.0625 24.4375C1.06469 21.2264 2.67766 18.2307 5.35711 16.4611C8.03656 14.6915 11.4251 14.3842 14.3792 15.6428"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.625 12.75C13.8524 12.75 16.4687 10.1337 16.4687 6.90625C16.4687 3.67884 13.8524 1.0625 10.625 1.0625C7.39759 1.0625 4.78125 3.67884 4.78125 6.90625C4.78125 10.1337 7.39759 12.75 10.625 12.75Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.4375 32.9375C29.1319 32.9375 32.9375 29.1319 32.9375 24.4375C32.9375 19.7431 29.1319 15.9375 24.4375 15.9375C19.7431 15.9375 15.9375 19.7431 15.9375 24.4375C15.9375 29.1319 19.7431 32.9375 24.4375 32.9375Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.4375 28.6875V24.4375"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.4375 20.1875C24.1441 20.1875 23.9062 20.4254 23.9062 20.7188C23.9062 21.0122 24.1441 21.25 24.4375 21.25C24.7309 21.25 24.9688 21.0122 24.9688 20.7188C24.9691 20.5777 24.9133 20.4424 24.8136 20.3427C24.7139 20.243 24.5785 20.1871 24.4375 20.1875"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
