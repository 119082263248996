import React from 'react';
import { KEYBOARD_EVENT_VALUES } from 'shared/enums';

export default ({
  className,
  color = 'black',
  handleClick = () => {},
  tabIndex = 0,
  title = null,
  ...props
}) => (
  <svg
    className={className}
    data-pendoid={props?.['data-pendoid']}
    data-testid="external-link"
    width="17"
    height="16"
    viewBox="0 0 13 13"
    fill="none"
    style={{ marginLeft: '0.1rem' }}
    xmlns="http://www.w3.org/2000/svg"
    onClick={handleClick}
    tabIndex={tabIndex}
    onKeyDown={e => {
      if (
        e.key === KEYBOARD_EVENT_VALUES.SPACE ||
        e.key === KEYBOARD_EVENT_VALUES.ENTER
      ) {
        handleClick(e);
      }
    }}
  >
    {title && <title>{title}</title>}
    <path
      d="M9.5 6.58333V10.0833C9.5 10.3928 9.37708 10.6895 9.15829 10.9083C8.9395 11.1271 8.64275 11.25 8.33333 11.25H1.91667C1.60725 11.25 1.3105 11.1271 1.09171 10.9083C0.872916 10.6895 0.75 10.3928 0.75 10.0833V3.66667C0.75 3.35725 0.872916 3.0605 1.09171 2.84171C1.3105 2.62292 1.60725 2.5 1.91667 2.5H5.41667"
      stroke={color}
      strokeWidth="0.875"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.75 0.75H11.25V4.25"
      stroke={color}
      strokeWidth="0.875"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.83331 7.16667L11.25 0.75"
      stroke={color}
      strokeWidth="0.875"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
