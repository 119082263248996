import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { Button } from 'components';
import { getError } from 'shared/helpers/auth';
import { flushErrorsState } from 'shared/helpers/flushErrorsState';
import getCountries from 'shared/helpers/getCountries';
import {
  CountryCodeInpuStyled,
  MethodErrorStyled,
  InputGroupStyled,
  PhoneInputStyled,
  ExtensionInputStyled,
  BackIconStyled,
  SubTitleStyled,
  NextIconStyled,
  ButtonsGroupStyled,
  FormStyled
} from './user-phone.styles';
import { TWO_FA_METHODS } from 'shared/enums';

export default ({
  handleSubmit,
  handleBack,
  mutation,
  errors,
  setErrors,
  buttonsAlignment,
  hideArrows = false,
  method
}) => {
  const { t } = useTranslation();
  const [countryCode, setCountryCode] = useState({});
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneExtension, setPhoneExtension] = useState('');
  const [mutationFunction] = useMutation(mutation);

  const phoneCodeList = getCountries();

  const countryCodeError = getError({ name: 'countryCode', errors });
  const phoneNumberError = getError({ name: 'phoneNumber', errors });
  const phoneExtensionError = getError({
    name: 'phoneExtension',
    errors
  });
  const methodError = getError({ name: 'method', errors }) || '';

  return (
    <FormStyled
      onSubmit={e => {
        e.preventDefault();
        handleSubmit({
          mutationFunction,
          data: {
            countryCode,
            phoneNumber,
            phoneExtension
          }
        });
      }}
    >
      <div style={{ flex: '1' }}>
        <SubTitleStyled data-pendoid="signup_secure_account_subtitle">
          {method === TWO_FA_METHODS.CALL
            ? t?.('signup_provide_phone_number_call')
            : t?.('signup_provide_phone_number_sms')}
        </SubTitleStyled>
        <InputGroupStyled>
          <CountryCodeInpuStyled
            data-pendoid="input_country_code"
            name="countryCode"
            label={t?.('input_country_code')}
            placeholder=""
            selectedOption={countryCode}
            options={phoneCodeList}
            handleChange={value =>
              flushErrorsState({
                errors,
                name: 'countryCode',
                value: Object.keys(value) ? value : countryCode,
                updateErrors: setErrors,
                updateState: setCountryCode
              })
            }
            errorPlaceholder={true}
            error={Boolean(countryCodeError)}
            errorMessage={errors.length ? countryCodeError : null}
          />
          <PhoneInputStyled
            data-pendoid="input_phone_number"
            label={t?.('input_phone_number')}
            name="phoneNumber"
            value={phoneNumber}
            handleChange={e =>
              flushErrorsState({
                errors,
                name: 'phoneNumber',
                value: e.target.value,
                updateErrors: setErrors,
                updateState: setPhoneNumber
              })
            }
            required={true}
            errorPlaceholder={true}
            error={Boolean(phoneNumberError)}
            errorMessage={errors.length ? phoneNumberError : null}
          />
        </InputGroupStyled>
        {method === TWO_FA_METHODS.CALL && (
          <ExtensionInputStyled
            data-pendoid="input_phone_extension"
            label={t?.('input_phone_extension')}
            name="phoneExtension"
            value={phoneExtension}
            handleChange={e =>
              flushErrorsState({
                errors,
                name: 'phoneExtension',
                value: e.target.value,
                updateErrors: setErrors,
                updateState: setPhoneExtension
              })
            }
            errorPlaceholder={true}
            error={Boolean(phoneExtensionError)}
            errorMessage={errors.length ? phoneExtensionError : null}
          />
        )}
        <MethodErrorStyled>{methodError}</MethodErrorStyled>
      </div>
      <ButtonsGroupStyled buttonsAlignment={buttonsAlignment}>
        <Button
          data-pendoid="buttons_back"
          category="outline"
          handleClick={handleBack}
          label={t?.('buttons_back')}
        >
          {!hideArrows && <BackIconStyled color={'#000000'} />}
          {t?.('buttons_back')}
        </Button>
        <Button
          data-pendoid="buttons_next"
          type="submit"
          label={t?.('buttons_next')}
        >
          {t?.('buttons_next')}{' '}
          {!hideArrows && <NextIconStyled color="#ffffff" />}
        </Button>
      </ButtonsGroupStyled>
    </FormStyled>
  );
};
