import React from 'react';
import PropTypes from 'prop-types';

import {
  RadioWrapperStyled,
  LabelStyled,
  RadioHiddenStyled,
  RadioStyled
} from './radio.styles';

import RadioMarkIcon from 'assets/icons/radio-mark';
import theme from 'shared/css/theme';

const CustomRadiobox = ({ isChecked, name, ...rest }) => (
  <>
    <RadioHiddenStyled
      name={name}
      aria-label={name}
      defaultChecked={isChecked}
      {...rest}
    />
    <RadioStyled checked={isChecked} {...rest}>
      <RadioMarkIcon
        color={rest.disabled ? '#BFBFBF' : theme?.colors?.primary?.default}
      />
    </RadioStyled>
  </>
);

const Radio = ({ text, name, checked, disabled, handleClick }) => (
  <RadioWrapperStyled
    onClick={() => !disabled && handleClick(name, !checked)}
    data-testid="radio"
  >
    <LabelStyled htmlFor={name} disabled={disabled}>
      <CustomRadiobox
        type="radio"
        name={name}
        disabled={disabled}
        isChecked={checked}
      />
      {text}
    </LabelStyled>
  </RadioWrapperStyled>
);

Radio.propTypes = {
  text: PropTypes.string,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired
};

Radio.defaultProps = {
  text: '',
  checked: false,
  disabled: false
};

export default Radio;
