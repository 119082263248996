import { TextField, Box } from '@mui/material';
import styled from 'styled-components/macro';

export const TextFieldStyled = styled(TextField)`
  ${({
    theme: { transitions, spacing, inputs = {}, colors = {} },
    error,
    disabled,
    hideArrow,
    multiple
  }) => `
      background-color: ${
        disabled ? inputs.item.disabled.backgroundColor : colors.white
      };
      color: ${inputs.item.color};
      cursor: ${hideArrow ? 'default' : 'pointer'};
      font-size: '1rem';
      margin: ${spacing(0.5)} 0 !important;
      min-height: ${spacing(5)};
      outline: none;
      transition: all ${transitions.fast} ease-in-out;
      border: ${inputs.item.border};
      box-shadow: ${inputs.item.boxShadow?.['default']};
      border-radius: ${inputs.item?.borderRadius};
      &:hover {
        border-color: ${colors.primary.default};
        box-shadow: ${inputs.item.hover.boxShadow?.['default']};
      }
      &:focus {
        border-color: ${colors.primary.default};
        box-shadow: ${inputs.item.focus.boxShadow?.['default']};
      }
      &:focus-within {
        border-color: ${colors.primary.default};
        box-shadow: 0 0 0 2px ${colors?.primary?.default};
      }
      & > div.MuiOutlinedInput-root {
        padding: 0;
        margin-top: 2px;
        padding-bottom: 1px;
        padding-left:  ${multiple ? '0' : '12px'};
        & > input::placeholder {
          color: ${inputs.item?.placeholder?.color};
          opacity: 1;
        }
        & > fieldset {
          border-style: none;
        }
        & > div.MuiAutocomplete-tag {
          border-radius: 3px;
          border: 1px solid ${colors.grey.light};
          font-size: 14px;
        }
      }
  `}
`;

export const OptionStyled = styled(Box)`
  ${({ theme: { transitions, spacing, colors = {} }, selected, ...props }) => `
    color: ${colors.primary.darkest};
    paddingBottom: ${spacing(2)};
    paddingTop: ${spacing(2)};
    transition: all ${transitions.fast} ease-in-out;
    border-left: 4px solid ${
      selected
        ? props.$secondaryColor
          ? colors.secondary.default
          : colors.primary.default
        : 'transparent'
    };
    brackground-color: ${
      selected
        ? props.$secondaryColor
          ? colors.secondary.lightest
          : colors.primary.lightest
        : 'transparent'
    };
    &:focus {
      background-color: ${colors.grey.lighter};
    }
    &:active {
      background-color: 'transparent'
    };
    &:hover {
      background-color: ${
        selected
          ? props.$secondaryColor
            ? colors.secondary.lightest
            : colors.primary.lightest
          : colors.grey.lighter
      };
      cursor: pointer;
    }
  `}
`;

export const WrapperStyled = styled.div`
  ${({ theme: { inputs = {}, selects = {} }, error }) => `
    ${
      error
        ? `
      & > div > div:nth-child(1) {
        box-shadow: ${inputs.item?.boxShadow?.['error']};
        &:hover {
          border-color: ${inputs.item?.hover?.borderColor?.['error']};
          box-shadow: ${inputs.item?.hover?.boxShadow?.['none']};
        }
      }
    `
        : ''
    };
    margin: ${selects.wrapper?.margin};
  `};
`;

export const LabelStyled = styled.label`
  ${({ theme: { selects = {}, typography }, hideLabel }) => `
    ${typography?.(14)}
    color: ${selects.label?.color};
    display: ${selects.label?.display};
    text-align: ${selects.label?.textAlign};
    visibility: ${hideLabel ? 'hidden' : 'visible'};
  `};
`;

export const MessageStyled = styled.div`
  ${({ theme: { inputs = {} }, error, hide }) => `
    color: ${inputs.message?.color?.[error ? 'error' : 'primary']};
    text-align: left;
    visibility: ${hide ? 'hidden' : 'visible'};
  `}
`;
