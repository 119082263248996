import React, { FC } from "react";

type AddCircleProps = {
    className?: string;
    color?: string;
};

const AddCircle: FC<AddCircleProps> = ({ className, color = '#000000' }) => {
    return (
        <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 7.78125V16.2188" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.78125 12H16.2188" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M1.45313 12C1.45312 13.385 1.72593 14.7565 2.25596 16.0361C2.78599 17.3157 3.56287 18.4784 4.54223 19.4578C5.5216 20.4371 6.68428 21.214 7.96389 21.744C9.24349 22.2741 10.615 22.5469 12 22.5469C13.385 22.5469 14.7565 22.2741 16.0361 21.744C17.3157 21.214 18.4784 20.4371 19.4578 19.4578C20.4371 18.4784 21.214 17.3157 21.744 16.0361C22.2741 14.7565 22.5469 13.385 22.5469 12C22.5469 10.615 22.2741 9.24349 21.744 7.96389C21.214 6.68428 20.4371 5.5216 19.4578 4.54223C18.4784 3.56287 17.3157 2.78599 16.0361 2.25596C14.7565 1.72593 13.385 1.45313 12 1.45312C10.615 1.45313 9.24349 1.72593 7.96389 2.25596C6.68428 2.78599 5.5216 3.56287 4.54223 4.54223C3.56287 5.5216 2.78599 6.68428 2.25596 7.96389C1.72593 9.24349 1.45312 10.615 1.45313 12Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
};

export default AddCircle;