import colors from './colors';
import config from './config';
import spacing from './spacing';

const title = {
  padding: `0 ${spacing?.(0.5)}`,
  width: '100%'
};

const group = {
  backgroundColor: colors.outline?.default,
  color: colors.primary?.darkest,
  cursor: 'pointer',
  outline: 'none',
  focus: {
    backgroundColor: colors.outline?.lighter,
    borderColor: 'transparent',
    boxShadow: `0 0 0 2px ${colors.primary?.default}`
  }
};

const arrow = {
  margin: `${spacing?.(0.5)} 0 0 ${spacing?.(1)}`
};

const options = {
  backgroundColor: '#ffffff',
  borderRadius: config.borderRadius,
  boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.15)',
  margin: `${spacing?.(0.5)} 0`,
  minWidth: `${spacing?.(25)}`,
  padding: `${spacing?.(1)} 0`,
  position: 'absolute',
  right: '-1px',
  top: `${spacing?.(4)}`,
  width: '100%'
};

const itemDefault = {
  border: '2px solid transparent',
  height: 'auto',
  outline: 'none',
  padding: `${spacing?.(1)} ${spacing?.(2)}`,
  active: {
    backgroundColor: {
      disabled: 'transparent',
      default: colors.primary?.lighter
    },
    borderColor: 'transparent'
  },
  focus: {
    borderColor: colors.primary?.default
  }
};

const itemLink = {
  ...itemDefault,
  color: {
    disabled: colors.grey?.light,
    active: colors.primary?.default,
    default: colors.primary?.darkest
  },
  hover: {
    backgroundColor: colors.outline?.lighter,
    borderColor: 'transparent',
    color: {
      active: colors.primary?.default,
      default: colors.grey?.darker
    },
    textDecoration: 'none !important'
  },
  active: {
    backgroundColor: {
      disabled: 'transparent',
      default: colors.outline?.lighter
    }
  },
  focus: {
    borderColor: colors.primary?.default,
    backgroundColor: {
      disabled: 'transparent',
      default: colors.outline?.lighter
    }
  }
};

const item = {
  ...itemDefault,
  color: {
    disabled: colors.grey?.light,
    default: colors.primary?.darkest
  },
  hover: {
    backgroundColor: {
      disabled: 'transparent',
      default: colors.outline?.lighter
    },
    borderColor: 'transparent'
  },
  active: {
    backgroundColor: {
      disabled: 'transparent',
      default: colors.outline?.lighter
    }
  },
  focus: {
    backgroundColor: {
      disabled: 'transparent',
      default: colors.outline?.lighter
    }
  }
};

export default {
  arrow,
  title,
  group,
  options,
  itemLink,
  item
};
