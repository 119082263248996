const { REACT_APP_PASSTHROUGH_ENV } = process.env;

// This file can be deleted once the Analytics sites are deprecated.
export const redirectToAnalytics = (): void => {
  const win: Window = window;
  const host: string = new URLSearchParams(win.location.search).get('host');
  const domain: string =
    REACT_APP_PASSTHROUGH_ENV === 'production' ? 'one45' : 'one45test';
  const targetPath: string = encodeURIComponent(win.location.href);
  if (host) {
    win.location = `https://${host}.unify.${domain}.com/passthrough/remote/login?target_path=${targetPath}`;
  }
};
