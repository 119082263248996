import colors from './colors';
import config from './config';
import spacing from './spacing';

const wrapper = {
  backgroundColor: 'rgba(10, 10, 10, 0.5)',
  bottom: '0',
  height: '100%',
  minHeight: '100vh',
  left: '0',
  position: 'fixed',
  right: '0',
  top: '0',
  width: '100vw',
  zIndex: '20'
};

const box = {
  backgroundColor: '#ffffff',
  borderRadius: config.borderRadius,
  boxShadow: '0px 2px 4px rgba(3, 55, 103, 0.1)',
  minHeight: {
    large: `${spacing?.(75)}`,
    medium: `${spacing?.(57)}`,
    small: `${spacing?.(32)}`
  },
  width: {
    mobile: '90vw',
    desktop: `${spacing?.(75)}`
  }
};

const header = {
  color: colors.grey?.darkest,
  minHeight: `${spacing?.(8)}`,
  padding: `0 ${spacing?.(3)}`
};

const body = {
  borderTop: `1px solid ${colors.grey?.light}`,
  padding: `${spacing?.(3)}`
};

const footer = {
  marginLeft: `${spacing?.(1)}`,
  minHeight: `${spacing?.(8)}`,
  padding: `0 ${spacing?.(3)}`
};

export default {
  body,
  box,
  header,
  footer,
  wrapper
};
