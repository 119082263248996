import colors from './colors';
import spacing from './spacing';

const wrapper = {
  margin: `${spacing?.(0.5)} 0`,
  textAlign: 'center'
};

const label = {
  color: colors.primary?.darkest,
  display: 'block',
  textAlign: 'left'
};

export default {
  wrapper,
  label
};
