import styled from 'styled-components/macro';

import { devices } from 'shared/css/devices';

export const HeaderStyled = styled.div`
  ${({ theme: { spacing } }) => `
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: ${spacing?.(2)};
    margin-top: ${spacing?.(2)};
    & > * {
      flex: 1;
    }
    @media ${devices.tablet} {
      flex-direction: row;
    }
  `};
`;

export const TitleStyled = styled.h1`
  ${({ theme: { typography, colors } }) => `
    ${typography?.(30, 'normal')};
    color: ${colors.primary?.darkest};
  `};
`;

export const SubtitleStyled = styled.h2`
  ${({ theme: { spacing, typography, colors } = {} }) => `
    ${typography?.(18)}
    color: ${colors.grey?.darker};
    margin-right: ${spacing?.(2)};
    margin-top: ${spacing?.(2)};
  `};
`;
