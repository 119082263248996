import accordion from './accordion';
import alerts from './alerts';
import buttons from './buttons';
import cards from './cards';
import checkboxes from './checkboxes';
import colors from './colors';
import config from './config';
import dropdowns from './dropdowns';
import dropdownsNavbar from './dropdowns-navbar';
import dropdownsMashup from './dropdowns-mashup';
import inputs from './inputs';
import links from './links';
import modals from './modals';
import paginations from './paginations';
import pills from './pills';
import radios from './radios';
import selects from './selects';
import spacing from './spacing';
import tabs from './tabs';
import tables from './tables';
import textButtons from './text-buttons';
import transitions from './transitions';
import typography from './typography';

export default {
  accordion,
  alerts,
  buttons,
  cards,
  checkboxes,
  colors,
  config,
  dropdowns,
  dropdownsNavbar,
  dropdownsMashup,
  inputs,
  links,
  modals,
  paginations,
  pills,
  radios,
  selects,
  spacing,
  tabs,
  tables,
  textButtons,
  transitions,
  typography
};
