import { gql } from '@apollo/client';

export const USER_ROUTES = gql`
  query getRoutes {
    getRoutes {
      routes {
        tabLabel
        sheetId
        route
        insightPermissions
      }
      menu {
        label
        href
        type
        content
        insightPermissions
      }
    }
  }
`;
